import { addDays, format } from 'date-fns';

export const COLUMNS = [
  {
    Header: 'Название',
    accessor: 'anketa_title',
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ cell }) => (
      <>
        {/* // * анкетирование ещё открыто && таймера либо нет, либо он не истёк && анкета ещё не пройдена полностью  */}
        {cell.row.original.writing_deadline_left > 0 && (cell.row.original.time_left === null || cell.row.original.time_left > 0) && cell.row.original.progress < 100 ? (
          <a className='link' href={`/client/anketa/${cell.row.original.anketa_id}/intro/`}>
            {cell.value}
          </a>
        ) : (
          cell.value
        )}
      </>
    ),
  },
  {
    Header: 'Прогресс',
    accessor: 'progress',
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ cell }) => (
      <div className={'progressContainer ' + (cell.value <= 33 && ' red ') + (cell.value > 33 && cell.value <= 66 && ' green ') + (cell.value >= 67 && ' blue')}>
        <div className='progressText'>{cell.value}%</div>
        {/* @ts-ignore */}
        <div className='progress' style={{ width: `${cell.value}%` }}></div>
      </div>
    ),
  },
  {
    Header: 'Дедлайн письменной части',
    accessor: 'writing_deadline_left',
    disableSortBy: true,
    disableFilters: true,
    Cell: ({ cell }) =>
      cell.value ? (
        <span>
          {format(addDays(new Date(), cell.value), 'dd/MM ')} <b>{cell.value > 0 ? `- ${cell.value} дней` : '- 0 дней'}</b>
        </span>
      ) : (
        '—'
      ),
  },
  {
    Header: 'Статус анкеты',
    accessor: 'process_status',
    disableFilters: true,
    Cell: ({ cell }) => (
      <>
        {cell.value === 0 ? 'Не начата' : null}
        {cell.value === 1 && cell.row.original.progress < 100 ? 'Не заполнена' : null}
        {cell.row.original.progress === 100 && !cell.row.original.pdf_url ? 'Заполнена' : null}
        {cell.row.original.pdf_url ? (
          <a href={cell.row.original.pdf_url} target='download' className='link'>
            Скачать PDF
          </a>
        ) : null}
      </>
    ),
  },
  {
    Header: 'Интервью',
    accessor: 'interview_status',
    disableSortBy: true,
    disableFilters: true,
    Cell: ({ cell, row }) => (
      <>
        {cell.value === 'Отсутствует' ? cell.value : null}
        {cell.value === 'Не назначено' && row.original.deadline_left > 0 ? cell.value : null}
        {cell.value === 'Назначить интервью' && row.original.deadline_left > 0 ? (
          <a className='link' href={`/client/anketa/${cell.row.original.anketa_id}/call_schedulling/`}>
            {cell.value}
          </a>
        ) : null}
        {cell.value === 'Назначено' ? (
          <a className='link' href={`/client/anketa/${cell.row.original.anketa_id}/call_schedulling/`}>
            {cell.value}
          </a>
        ) : null}
        {cell.value === 'Пройдено' ? <b>{cell.value}</b> : null}
        {cell.value === 'Пропущено' && row.original.deadline_left > 0 ? (
          <a className='link' href={`/client/anketa/${cell.row.original.anketa_id}/call_schedulling/`}>
            {cell.value}
          </a>
        ) : null}

        {/* {cell.value === 'Не назначено' && row.original.deadline_left <= 0 ? 'Недоступно, дейдлайн' : null} */}
        {cell.value === 'Назначить интервью' && row.original.deadline_left <= 0 ? 'Недоступно' : null}
        {cell.value === 'Пропущено' && row.original.deadline_left <= 0 ? 'Недоступно' : null}
      </>
    ),
  },
  {
    Header: 'Дедлайн интервью',
    accessor: 'deadline_left',
    disableSortBy: true,
    disableFilters: true,
    Cell: ({ cell }) =>
      cell.value ? (
        <span>
          {format(addDays(new Date(), cell.value), 'dd/MM ')} <b>{cell.value > 0 ? `- ${cell.value} дней` : '- 0 дней'}</b>
        </span>
      ) : (
        '—'
      ),
  },
];
