import { backendURL, debug } from '../App';

import __ from 'lodash';
import axios from 'axios';
import consoleLog from '../helpers/consoleLog';

const getAnketasData = async (_, clientID, streamID) => {
  try {
    const { data } = await axios({
      method: 'get',
      url: `${backendURL}anketas/`,
      headers: {
        Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
      },
      params: {
        client_id: clientID,
        stream_id: __.isNil(streamID) ? '' : streamID,
      },
    });
    debug && consoleLog('success', 'Get anketas', data);
    return data;
  } catch (error) {
    debug && consoleLog('error', 'Get anketas', error);
  }
};

export default getAnketasData;
