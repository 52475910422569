import { 
  SCHEDULE_CHANGE_SELECTED_DATES, 
  SCHEDULE_CLIENT_SELECT_DATE,
  SCHEDULE_CLIENT_CHANGING_DATE_STATUS_TOGGLE,
  EXPERT_RESPONDENTS_ACTIVE_DATE_SELECT } from './types';

export const schedule_change_selected_dates = (newSelectedStartDate, newSelectedEndDate) => ({
  type: SCHEDULE_CHANGE_SELECTED_DATES,
  payload: [newSelectedStartDate, newSelectedEndDate],
});

export const schedule_client_select_date = (clientSelectedDate) => ({
  type: SCHEDULE_CLIENT_SELECT_DATE,
  payload: clientSelectedDate,
});

export const schedule_client_changing_date_status_toggle = (status) => ({
  type: SCHEDULE_CLIENT_CHANGING_DATE_STATUS_TOGGLE,
  payload: status
})

export const expertRespondentsActiveDateSelect = (active_date) => ({
  type: EXPERT_RESPONDENTS_ACTIVE_DATE_SELECT,
  payload: active_date
})