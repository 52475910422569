import './AdminExperts.scss';


import { motion } from 'framer-motion';
import _ from 'lodash';

import { standardPageVariants } from '../../animationsVariants/StandardPageVariants';
import useAdminExpertsData from '../../hooks/useAdminExpertsData';

import Title from '../../components/Title';
import AdminExpertsTable from './AdminExpertsTable';
import PageLoader from '../../components/PageLoader';
import AnimatedRedirect from '../../components/AnimatedRedirect';

export const AdminExperts: React.FC<{}> = () => {
  const { data: expertsData, status: expertsDataLoadingStatus } = useAdminExpertsData();

  const normalizeDataForTable = () => {
    return _.map(expertsData.experts, (expert) => ({
      full_name: expert.full_name,
      clients_count: expert.clients_count,
      future_interview_count: expert.future_interview_count,
      free_interview_slots: expert.free_interview_slots,
      interview_pass: expert.interview_pass,
      total_percent: expert.total_percent,
      schedule: expert.id,
      actions: expert.id,
      // * Дополнительные данные
      block_expert: expert.block_expert,
      block_users: expert.block_users,
      clients: expert.clients,
    }));
  }

  if (expertsDataLoadingStatus === 'loading') {
    return <PageLoader />;
  }

  if (expertsDataLoadingStatus === 'error') {
    return <AnimatedRedirect redirectTo='/500/' />;
  }

  return (
    <motion.main className='adminExperts' variants={standardPageVariants} initial='initial' animate='animate' exit='exit'>
      <Title>Эксперты</Title>
      <AdminExpertsTable tableData={normalizeDataForTable()} />
    </motion.main>
  );
};
