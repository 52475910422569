import { useQuery } from 'react-query';
import axios from 'axios';
import { backendURL } from '../App';

const getClientsData = async (_) => {
  if ( localStorage.getItem('skillsSurveyUserType') === 'is_moderator' ) {
    const { data } = await axios({
      method: 'get', 
      url: `${backendURL}clients/`,
      headers: {
        Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`
      },
    });
    return data;
  }
};

export default function useClientsData() {
  return useQuery(['clientsData'], getClientsData, { retry: false });
}
