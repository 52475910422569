import './AdminClientReportPDF.scss';
import 'react-circular-progressbar/dist/styles.css';

import { useLocation, useParams } from 'react-router-dom';

import AnimatedRedirect from '../../components/AnimatedRedirect';
import { CTP } from '../../App';
import CompetentionsCircles from '../../components/ReportsSharedComponents/CompetentionsCircles';
import CompetentionsLevels from '../../components/ReportsSharedComponents/CompetentionsLevels';
import CompetentionsSpider from '../../components/ReportsSharedComponents/CompetentionsSpider';
import PageLoader from '../../components/PageLoader';
import RecomendationsForIndicators from '../../components/ReportsSharedComponents/RecomendationsForIndicators';
import { TribeResultsPDF } from '../../components/ReportsSharedComponents/TribeResults';
import _ from 'lodash';
import api_getReportPdfData from '../../hooks/api_getReportPdfData';
import logo_blue from '../../assets/images/logo-blue.svg';
import logo_blue_ctp from '../../assets/images/logo-blue_ctp.svg';
import { motion } from 'framer-motion';
import { standardPageVariants } from '../../animationsVariants/StandardPageVariants';
import { useEffect } from 'react';
import { useQuery } from 'react-query';

export const AdminClientReportPDF: React.FC<{}> = () => {
  const { addoID, key } = useParams<{ addoID: string; key: string }>();
  const { data, status: reportDataLoadingStatus } = useQuery('reportData', () => api_getReportPdfData({ addo_id: +addoID, key }));

  const transformDataForRadarChart = spider => {
    let chartColumns = {};
    let radarRequired = { class: 'required' };
    let radarClient = { class: 'client' };
    let radarClient2 = { class: 'client2' };

    if (data.streams_count === 1) {
      spider.diagram_data.map((competention, index) => {
        chartColumns = { ...{ [index]: competention[0] }, ...chartColumns };
        radarRequired = { ...{ [index]: competention[2] / 100 }, ...radarRequired };
        radarClient = { ...{ [index]: competention[1] / 100 }, ...radarClient };
      });

      return [chartColumns, radarRequired, radarClient];
    } else if (data.streams_count === 2) {
      spider[1].diagram_data.map((competention, index) => {
        chartColumns = { ...{ [index]: competention[0] }, ...chartColumns };
        radarRequired = { ...{ [index]: spider[0].diagram_data[index]?.[2] / 100 }, ...radarRequired };
        radarClient = { ...{ [index]: spider[0].diagram_data[index]?.[1] / 100 }, ...radarClient };
        radarClient2 = { ...{ [index]: competention[1] / 100 }, ...radarClient2 };
      });

      return [chartColumns, radarRequired, radarClient, radarClient2];
    }
  };

  useEffect(() => {
    document.getElementsByTagName('html')[0].classList.add('pdfPage');

    return () => {
      document.getElementsByTagName('html')[0].classList.remove('pdfPage');
    };
  }, []);

  if (reportDataLoadingStatus === 'loading') {
    return <PageLoader />;
  }

  if (reportDataLoadingStatus === 'error') {
    return <AnimatedRedirect redirectTo='/500/' />;
  }

  return (
    <motion.main className='adminClientReportPDF' variants={standardPageVariants} initial='initial' animate='animate' exit='exit'>
      <>
        <div className='header'>
          <div className='topHeader'>
            <img src={CTP ? logo_blue_ctp : logo_blue} alt='logo' />
            <img src={data.client_logo_url} alt='clientLogo' />
          </div>
          <div className='bottomHeader'>
            <div className='bottomHeaderSection'>
              <span className='key'>Клиент</span>
              <span className='value'>{data.client_title}</span>
            </div>
            <div className='bottomHeaderSection'>
              <span className='key'></span>
              <span className='value'>Групповой отчёт</span>
            </div>
            {data.show_tribe_title && (
              <div className='bottomHeaderSection'>
                {/* <span className='key'>Трайб</span> */}
                <span className='value'>{data.tribe_title}</span>
              </div>
            )}
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <td>
                <div className='page-header-space'></div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <CompetentionsCircles data={data} type='client' />
                {/* // * Временный костыль, работает только для отп-банка */}
                {/* <CompetentionsCirclesByRole data={data} type='client' /> */}
                <CompetentionsLevels data={data} />

                {data.spiders.map(spider => (
                  <CompetentionsSpider radarData={transformDataForRadarChart(spider)} radarInfo={spider} data={data} type='tribe' />
                ))}
                {!_.isEmpty(data.cases) && <TribeResultsPDF data={data} withHeader={false} />}
                <RecomendationsForIndicators data={data} />
                <footer></footer>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    </motion.main>
  );
};
