import './RespondentsTabs.scss';


import { useLocation, Link } from 'react-router-dom';

export const RespondentsTabs: React.FC<{}> = () => {
  const location = useLocation()

  return (
    <div className='respondentsTabs'>
      <Link to='/expert/respondents/' className={`respondentTab${location.pathname === '/expert/respondents/' ? ' active' : ''}`}>Все</Link>
      <Link to='/expert/respondents/without_evaluation/' className={`respondentTab${location.pathname === '/expert/respondents/without_evaluation/' ? ' active' : ''}`}>Без оценок</Link>
      <Link to='/expert/respondents/evaluation/' className={`respondentTab${location.pathname === '/expert/respondents/evaluation/' ? ' active' : ''}`}>Оцениваются</Link>
      <Link to='/expert/respondents/with_evaluation/' className={`respondentTab${location.pathname === '/expert/respondents/with_evaluation/' ? ' active' : ''}`}>Завершены</Link>
      <Link to='/expert/respondents/finished/' className={`respondentTab${location.pathname === '/expert/respondents/finished/' ? ' active' : ''}`}>Проверены</Link>
    </div>
  )
}
