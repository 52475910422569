import './TemplateStage4Form.scss';
import 'react-quill/dist/quill.snow.css';

import QuestionForm from './QuestionForm';
import QuestionsList from './QuestionsList';
import Title from '../../../components/Title';
import icon_loading from '../../../assets/images/icon_loadingBlue.svg';
import { motion } from 'framer-motion';
import useAnketaQuetionsData from '../../../hooks/useAnketaQuetionsData';
import { useParams } from 'react-router-dom';

export const TemplateStage4Form: React.FC<{}> = () => {
  const { anketaID, questionIndex } = useParams<{ anketaID: string; questionIndex: string }>();
  const { data: questionsData, status: questionsDataLoadingStatus } = useAnketaQuetionsData(anketaID);

  if (questionsDataLoadingStatus === 'loading') {
    return (
      <div className='isLoading'>
        <motion.img src={icon_loading} initial={{ rotate: 0 }} animate={{ rotate: 360 }} transition={{ duration: 2, loop: Infinity, ease: 'linear' }} />
      </div>
    );
  }

  return (
    <section className='templateStage4Form'>
      <QuestionsList questionsData={questionsData} />
      <Title>Создание списка вопросов</Title>
      <QuestionForm questionData={questionIndex !== undefined ? questionsData[parseInt(questionIndex) - 1] : undefined} questionsCount={questionsData.length} />
    </section>
  );
};
