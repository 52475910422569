import { 
  SCHEDULE_CHANGE_SELECTED_DATES, 
  SCHEDULE_CLIENT_SELECT_DATE, 
  SCHEDULE_CLIENT_CHANGING_DATE_STATUS_TOGGLE,
  EXPERT_RESPONDENTS_ACTIVE_DATE_SELECT } from '../actions/types';
import { add, getDay, addDays } from 'date-fns';

let start = new Date();
start.setHours(8);
start.setMinutes(0);
start.setSeconds(0);  
switch (getDay(start)) {
  case 1:
    break;
  case 2:
    start = addDays(start, -1);
    break;
  case 3: 
    start = addDays(start, -2);
    break;
  case 4:
    start = addDays(start, -3);
    break;
  case 5:
    start = addDays(start, -4);
    break;
  case 6:
    start = addDays(start, -5);
    break;
  case 7: 
    start = addDays(start, -6);
    break;
  default:
    break;
}
const end = add(start, {days: 6, hours: 13})

const INITIAL_STATE = {
  firstWeekStartDate: start,
  firstWeekEndDate: end,
  selectedStartDate: start,
  selectedEndDate: end,
  clientSelectedDate: undefined,
  clientChangingDateStatus: false,
  expertRespondentsActiveDate: start
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) { 
    case SCHEDULE_CHANGE_SELECTED_DATES: 
      return { ...state, selectedStartDate: action.payload[0], selectedEndDate: action.payload[1] };   
    case SCHEDULE_CLIENT_SELECT_DATE:
      return { ...state, clientSelectedDate: action.payload }
    case SCHEDULE_CLIENT_CHANGING_DATE_STATUS_TOGGLE:  
      return { ...state, clientChangingDateStatus: action.payload }
    case EXPERT_RESPONDENTS_ACTIVE_DATE_SELECT:
      return { ...state, expertRespondentsActiveDate: action.payload }
    default:
      return { ...state };
  }
};
