import './ScheduleTable.scss';

import { Dispatch, SetStateAction } from 'react';

import { add, format, isEqual } from 'date-fns';
import { ru } from 'date-fns/locale';
import { utcToZonedTime } from 'date-fns-tz/esm';
import _ from 'lodash';
import __ from 'lodash/fp/__';

interface ScheduleSlot {
  time: Date;
  status: 'open' | 'busy' | 'disabled';
  data?: {
    id: number;
  };
}

interface Props {
  timeTableData: ScheduleSlot[];
  selectedWeekStart: Date;
  selectedWeekEnd: Date;
  selectedDateTime: Date | undefined;
  setSelectedDateTime: Dispatch<SetStateAction<Date | undefined>>;
  isChangingDateTime: boolean;
  isAlreadyHasInterview: boolean;
}

export const ScheduleTable: React.FC<Props> = ({
  timeTableData,
  selectedWeekStart,
  selectedDateTime,
  setSelectedDateTime,
  isChangingDateTime,
  isAlreadyHasInterview,
}) => {
  const onClick = (time, status) => {
    status === 'open' && (!isAlreadyHasInterview || isChangingDateTime) && setSelectedDateTime(new Date(time));
  };

  return (
    <div className='scheduleTable'>
      <div className='daysTable'>
        {Array(7)
          .fill(null)
          .map((items, i) => (
            <div className='day' key={_.uniqueId('day')}>
              <div className='dayValue'>{format(add(selectedWeekStart, { days: i }), 'dd')}</div>
              <div className='dayName'>{format(add(selectedWeekStart, { days: i }), 'EEEEEE', { locale: ru })}</div>
            </div>
          ))}
      </div>
      <div className='timeTable memberTimeTable'>
        {timeTableData.map((scheduleSlot: ScheduleSlot) => (
          <div
            className={
              'time ' +
              `${scheduleSlot.status === 'open' ? 'open ' : ''}` +
              `${scheduleSlot.status === 'open' && !isChangingDateTime && isAlreadyHasInterview ? 'unselectable ' : ''}` +
              `${scheduleSlot.status === 'open' && selectedDateTime && isEqual(selectedDateTime, new Date(scheduleSlot.time)) ? 'clientSelected ' : ''}` +
              `${scheduleSlot.status === 'disabled' ? 'disabled ' : ''}` +
              `${scheduleSlot.status === 'busy' && !isChangingDateTime ? 'clientSelected ' : ''}` +
              `${scheduleSlot.status === 'busy' && isChangingDateTime ? 'clientUnSelect ' : ''}`
            }
            key={_.uniqueId('scheduleSlot')}
            onClick={() => onClick(scheduleSlot.time, scheduleSlot.status)}
          >
            {format(utcToZonedTime(new Date(scheduleSlot.time), 'Europe/Moscow'), 'HH:mm')}
            {scheduleSlot.status === 'busy' && isChangingDateTime && ' - Будет отменено'}
          </div>
        ))}
      </div>
    </div>
  );
};
