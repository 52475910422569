import './ExpertTelegramModal.scss';

import Button from '../Button';
import InputError from '../InputError';
import Modal from '../Modal';
import Title from '../Title';
import { queryCache } from 'react-query';
import useExpertTelegramMutation from '../../hooks/useExpertTelegramMutation';
import { useState } from 'react';

interface Props {
  user: {
    tm_link: string;
    tm_chat_id: string;
  };
  onClose: () => void;
}

export const ExpertTelegramModal = ({ user, onClose }: Props) => {
  const [submitMessage, setSubmitMessage] = useState<string>('');

  const [mutate, { status: expertTelegramMutationStatus }] = useExpertTelegramMutation({
    onSuccess: () => {
      queryCache.invalidateQueries('userData');
      setSubmitMessage('Телеграм-бот успешно подключён. Можете закрыть это окно.');
    },
    onError: () => {
      setSubmitMessage('Телеграм-бот не подключён.');
    },
  });

  return (
    <Modal className='expertTelegramModal' onClose={onClose}>
      <Title>Не подключён телеграм бот</Title>
      <p>
        Пожалуйста, подключите{' '}
        <a href={user.tm_link} className='link' target='_blank' rel='noreferrer noopener'>
          телеграм-бот
        </a>{' '}
        для получения уведомлений о новых записях на интервью от участников анкетирования.
        <br />
        <br />
        Чтобы проверить корректность подключения, отправьте тестовое сообщение.
      </p>

      <Button
        type='button'
        onClick={() => mutate()}
        loading={expertTelegramMutationStatus === 'loading'}
        disabled={expertTelegramMutationStatus === 'loading'}
        width='300rem'
        size='large'
        color='blue'
        text='Отправить тестовое сообщение'
      />
      <InputError className={`${submitMessage !== 'Телеграм-бот не подключён.' && 'success'}`}>{submitMessage}</InputError>
    </Modal>
  );
};
