import { backendURL, backendURL_PDF, debug } from '../App';

import axios from 'axios';
import consoleLog from '../helpers/consoleLog';

interface Props {
  addo_id: number;
  key: string;
}

const api_getReportPdfData = async ({ addo_id, key }: Props) => {
  try {
    const { data } = await axios({
      method: 'get',
      url: `${backendURL}reports/pdf/`,
      headers: {
        Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
      },
      params: {
        addo_id,
        key,
      },
    });

    debug && consoleLog('success', 'Addo pdf data', data);

    return data.data;
  } catch (error) {
    debug && consoleLog('error', 'Addo pdf data', error);
  }
};

export default api_getReportPdfData;
