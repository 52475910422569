import './AnketsDashboard.scss';


import { Link } from 'react-router-dom';
import consoleLog from '../../helpers/consoleLog';

interface AnketsDashboardProps {
  anketaAnswersData: {
    withInterview: {
      all: any;
      reviewNotStated: any;
      reviewInProcess: any;
      reviewCompleted: any;
      closed: any;
    };
    withoutInterview: {
      all: any;
      reviewNotStated: any;
      reviewInProcess: any;
      reviewCompleted: any;
      closed: any;
    };
  };
}

export const AnketsDashboard: React.FC<AnketsDashboardProps> = ({ anketaAnswersData }) => (
  <div className='anketsDasboard'>
    {consoleLog('info', 'props', anketaAnswersData)}

    <div className='anketType'>
      <div className='typeDesciption'>Всего анкет</div>
      <Link to='/expert/respondents/' className='typeAmount'>
        {anketaAnswersData.withInterview.all.length + anketaAnswersData.withoutInterview.all.length}
      </Link>
    </div>
    <div className='anketType'>
      <div className='typeDesciption'>Анкеты без оценок</div>
      <Link to='/expert/respondents/without_evaluation/' className='typeAmount'>
        {anketaAnswersData.withInterview.reviewNotStated.length + anketaAnswersData.withoutInterview.reviewNotStated.length}
      </Link>
    </div>
    <div className='anketType'>
      <div className='typeDesciption'>Анкеты в процессе оценки</div>
      <Link to='/expert/respondents/evaluation/' className='typeAmount'>
        {anketaAnswersData.withInterview.reviewInProcess.length + anketaAnswersData.withoutInterview.reviewInProcess.length}
      </Link>
    </div>
    <div className='anketType'>
      <div className='typeDesciption'>Завершенные анкеты</div>
      <Link to='/expert/respondents/with_evaluation/' className='typeAmount'>
        {anketaAnswersData.withInterview.reviewCompleted.length + anketaAnswersData.withoutInterview.reviewCompleted.length}
      </Link>
    </div>
    <div className='anketType'>
      <div className='typeDesciption'>Проверенные анкеты</div>
      <Link to='/expert/respondents/finished/' className='typeAmount'>
        {anketaAnswersData.withInterview.closed.length + anketaAnswersData.withoutInterview.closed.length}
      </Link>
    </div>
  </div>
);
