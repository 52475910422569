import './PageLoader.scss';
import icon_loading from '../../assets/images/icon_loadingBlue.svg';


import { motion } from 'framer-motion';

export const PageLoader: React.FC<{}> = () => {
  return (
    <div className='pageLoader'>
      <motion.img src={icon_loading} initial={{ rotate: 0 }} animate={{ rotate: 360 }} transition={{ duration: 2, loop: Infinity, ease: 'linear' }} />
    </div>
  );
};
