import './TribeResults.scss';

import { twoStreams_tribeResults_Text1, twoStreams_tribeResults_Text2 } from '../../../helpers/textVariablesForReports';

import Title from '../../Title';
import _ from 'lodash';

interface Props {
  data: {
    cases: any;
    streams_count: number;
    tribe_title: string;
    client_color: string;
    shifted_hex_color: string;
    report_percents: number | number[];
    rate_percent: number | number[];
    rate: number | number[];
    max_rate: number | number[];
  };
  withHeader?: true | false;
}

export const TribeResultsPDF: React.FC<Props> = ({ data, withHeader }) => {
  if (data.streams_count === 2) {
    return (
      <div className='tribeResults' data-cy='tribeResultsBlock' data-test='tribeResultsBlock 2 streams'>
        {data.cases.cases.map((segment, index) => (
          <>
            {index === 0 &&
              segment[0].competensions_chunks.map(competention =>
                competention.chunks.map(chunk => (
                  <div className='tribeStats' key={_.uniqueId()}>
                    <div className='statsSection twoStreams'>
                      <div className='resultsHeader'>
                        <Title>Cравнительная оценка участников диагностики</Title>
                        <div className='resultColorsExpanation'>
                          <div className='after'>
                            <span className='square' style={{ backgroundColor: `rgb(${data.client_color})` }}></span>
                            <span className='text'>{twoStreams_tribeResults_Text1}</span>
                          </div>
                          <div className='before'>
                            <span className='square' style={{ backgroundColor: `rgb(${data.shifted_hex_color})` }}></span>
                            <span className='text'>{twoStreams_tribeResults_Text2}</span>
                          </div>
                          <div className='norm'>
                            <span className='triangle'></span>
                            <span className='text'>Необходимая норма, % от возможных 100%</span>
                          </div>
                        </div>
                      </div>
                      <div className='names single'>
                        <div className='title'>
                          <b>Имя участника</b>
                        </div>
                        <div className='namesWrapper'>
                          {chunk.names.map(element => (
                            <div className='name'>{element}</div>
                          ))}
                        </div>
                      </div>
                      <div className='roles single'>
                        <div className='title'>
                          <b>Роль участника</b>
                        </div>
                        <div className='rolesWrapper'>
                          {chunk.roles.map(element => (
                            <div className='name'>{element}</div>
                          ))}
                        </div>
                      </div>
                      <div className='numbers'>
                        <div className='title'>
                          <b>{competention.competension_1_title === 'all' ? 'Общий результат' : competention.competension_1_title},</b>
                          <br />
                          <span className='small'>% из 100 возможных</span>
                        </div>
                        <div className='numbersWrapper'>
                          <div className='guideLinesWrapper'>
                            <div className='guideLines'>
                              {/* {competention.competension_1_title === 'all' ? (
                                <>
                                  <div className='guideLine' style={{ left: '79%', borderColor: `rgb(${data.client_color})` }}>
                                    <div className='guideLineTriangle' style={{ borderBottomColor: `rgb(${data.client_color})` }} />
                                    <div className='guideLineDescription' style={{ color: `rgb(${data.client_color})` }}>
                                      Senior
                                    </div>
                                  </div>
                                  <div className='guideLine gray' style={{ left: '49%' }}>
                                    <div className='guideLineTriangle' />
                                    <div className='guideLineDescription'>Middle</div>
                                  </div>
                                </>
                              ) : (
                              )} */}
                              {competention.competension_1_recomended && (
                                <div className='guideLine gray' style={{ left: `${competention.competension_1_recomended}%` }}>
                                  <div className='guideLineTriangle'></div>
                                  <div className='guideLineDescription'>{competention.competension_1_recomended}%</div>
                                </div>
                              )}
                            </div>
                          </div>

                          {chunk.competension_1_data.map((element, index) => (
                            <div className='number'>
                              <div className='lineContainer' style={{ backgroundColor: `rgba(${data.client_color}, 0.2)` }}>
                                <div
                                  className='line'
                                  style={{
                                    width: `${element[1] ? element[1] : '0'}%`,
                                    backgroundColor: `rgb(${data.client_color})`,
                                  }}
                                />
                              </div>
                              <div className='value'>{element[1] === '' || element[1] === undefined ? '—' : element[1]}</div>
                              <div className='lineContainer' style={{ backgroundColor: `rgba(${data.shifted_hex_color}, 0.2)` }}>
                                <div
                                  className='line'
                                  style={{
                                    width: `${element[0] ? element[0] : '0'}%`,
                                    backgroundColor: `rgb(${data.shifted_hex_color})`,
                                  }}
                                />
                              </div>
                              <div className='value'>{element[0] === '' || element[0] === undefined ? '—' : element[0]}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                      {chunk.competension_2_data && (
                        <div className='numbers'>
                          <div className='title'>
                            <b>{competention.competension_2_title},</b>
                            <br />
                            <span className='small'>% из 100 возможных</span>
                          </div>
                          <div className='numbersWrapper'>
                            <div className='guideLinesWrapper'>
                              <div className='guideLines'>
                                <div className='guideLine gray' style={{ left: `${competention.competension_2_recomended}%` }}>
                                  <div className='guideLineTriangle'></div>
                                  <div className='guideLineDescription'>{competention.competension_2_recomended}%</div>
                                </div>
                              </div>
                            </div>
                            {chunk.competension_2_data.map((element, index) => (
                              <div className='number'>
                                <div className='lineContainer' style={{ backgroundColor: `rgba(${data.client_color}, 0.2)` }}>
                                  <div
                                    className='line'
                                    style={{
                                      width: `${element[1] ? element[1] : '0'}%`,
                                      backgroundColor: `rgb(${data.client_color})`,
                                    }}
                                  />
                                </div>
                                <div className='value'>{element[1] === '' || element[1] === undefined ? '—' : element[1]}</div>
                                <div className='lineContainer' style={{ backgroundColor: `rgba(${data.shifted_hex_color}, 0.2)` }}>
                                  <div
                                    className='line'
                                    style={{
                                      width: `${element[0] ? element[0] : '0'}%`,
                                      backgroundColor: `rgb(${data.shifted_hex_color})`,
                                    }}
                                  />
                                </div>
                                <div className='value'>{element[0] === '' || element[0] === undefined ? '—' : element[0]}</div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))
              )}
            {index > 0 &&
              segment[0].data.map((item, index) => (
                <>
                  {item.data[0].competensions_chunks.map(competention =>
                    competention.chunks.map(chunk => (
                      <div className='tribeStats' key={_.uniqueId()}>
                        <div className='statsSection twoStreams'>
                          <div className='resultsHeader'>
                            <div className='subTitle' style={{ color: `rgb(0,0,0)` }}>{`Оценка по компетенции «${item.data[0].title}»`}</div>
                            <div className='resultColorsExpanation'>
                              <div className='after'>
                                <span className='square' style={{ backgroundColor: `rgb(${data.client_color})` }}></span>
                                <span className='text'>{twoStreams_tribeResults_Text1}</span>
                              </div>
                              <div className='before'>
                                <span className='square' style={{ backgroundColor: `rgb(${data.shifted_hex_color})` }}></span>
                                <span className='text'>{twoStreams_tribeResults_Text2}</span>
                              </div>
                              <div className='norm'>
                                <span className='triangle'></span>
                                <span className='text'>Необходимая норма, % от возможных 100%</span>
                              </div>
                            </div>
                          </div>
                          <div className='names single'>
                            <div className='title'>
                              <b>Имя участника</b>
                            </div>
                            <div className='namesWrapper'>
                              {chunk.names.map(element => (
                                <div className='name'>{element}</div>
                              ))}
                            </div>
                          </div>
                          <div className='roles single'>
                            <div className='title'>
                              <b>Роль участника</b>
                            </div>
                            <div className='rolesWrapper'>
                              {chunk.roles.map(element => (
                                <div className='name'>{element}</div>
                              ))}
                            </div>
                          </div>
                          <div className='numbers'>
                            <div className='title'>
                              {/* <b>{competention.competension_1_title === 'all' ? 'Общий результат' : competention.competension_1_title},</b> */}
                              <br />
                              <span className='small'>% из 100 возможных</span>
                            </div>
                            <div className='numbersWrapper'>
                              <div className='guideLinesWrapper'>
                                <div className='guideLines'>
                                  {/* {competention.competension_1_title === 'all' ? (
                                    <>
                                      <div className='guideLine' style={{ left: '79%', borderColor: `rgb(${data.client_color})` }}>
                                        <div className='guideLineTriangle' style={{ borderBottomColor: `rgb(${data.client_color})` }} />
                                        <div className='guideLineDescription' style={{ color: `rgb(${data.client_color})` }}>
                                          Senior
                                        </div>
                                      </div>
                                      <div className='guideLine gray' style={{ left: '49%' }}>
                                        <div className='guideLineTriangle' />
                                        <div className='guideLineDescription'>Middle</div>
                                      </div>
                                    </>
                                  ) : (


                                  )} */}
                                  <div className='guideLine gray' style={{ left: `${competention.competension_1_recomended}%` }}>
                                    <div className='guideLineTriangle'></div>
                                    <div className='guideLineDescription'>{competention.competension_1_recomended}%</div>
                                  </div>
                                </div>
                              </div>

                              {chunk.competension_1_data.map((element, index) => (
                                <div className='number'>
                                  <div className='lineContainer' style={{ backgroundColor: `rgba(${data.client_color}, 0.2)` }}>
                                    <div
                                      className='line'
                                      style={{
                                        width: `${element[1] ? element[1] : '0'}%`,
                                        backgroundColor: `rgb(${data.client_color})`,
                                      }}
                                    />
                                  </div>
                                  <div className='value'>{element[1] === '' || element[1] === undefined ? '—' : element[1]}</div>
                                  <div className='lineContainer' style={{ backgroundColor: `rgba(${data.shifted_hex_color}, 0.2)` }}>
                                    <div
                                      className='line'
                                      style={{
                                        width: `${element[0] ? element[0] : '0'}%`,
                                        backgroundColor: `rgb(${data.shifted_hex_color})`,
                                      }}
                                    />
                                  </div>
                                  <div className='value'>{element[0] === '' || element[0] === undefined ? '—' : element[0]}</div>
                                </div>
                              ))}
                            </div>
                          </div>
                          {chunk.competension_2_data && (
                            <div className='numbers'>
                              <div className='title'>
                                <b>{competention.competension_2_title},</b>
                                <br />
                                <span className='small'>% из 100 возможных</span>
                              </div>
                              <div className='numbersWrapper'>
                                <div className='guideLinesWrapper'>
                                  <div className='guideLines'>
                                    <div className='guideLine gray' style={{ left: `${competention.competension_2_recomended}%` }}>
                                      <div className='guideLineTriangle'></div>
                                      <div className='guideLineDescription'>{competention.competension_2_recomended}%</div>
                                    </div>
                                  </div>
                                </div>
                                {chunk.competension_2_data.map((element, index) => (
                                  <div className='number'>
                                    <div className='lineContainer' style={{ backgroundColor: `rgba(${data.client_color}, 0.2)` }}>
                                      <div
                                        className='line'
                                        style={{
                                          width: `${element[1] ? element[1] : '0'}%`,
                                          backgroundColor: `rgb(${data.client_color})`,
                                        }}
                                      />
                                    </div>
                                    <div className='value'>{element[1] === '' || element[1] === undefined ? '—' : element[1]}</div>
                                    <div className='lineContainer' style={{ backgroundColor: `rgba(${data.shifted_hex_color}, 0.2)` }}>
                                      <div
                                        className='line'
                                        style={{
                                          width: `${element[0] ? element[0] : '0'}%`,
                                          backgroundColor: `rgb(${data.shifted_hex_color})`,
                                        }}
                                      />
                                    </div>
                                    <div className='value'>{element[0] === '' || element[0] === undefined ? '—' : element[0]}</div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ))
                  )}
                  {item.indicators.map((indicator, index) =>
                    indicator[0].competensions_chunks.map(competention =>
                      competention.chunks.map(chunk => (
                        <div className='tribeStats' key={_.uniqueId()}>
                          <div className='statsSection twoStreams'>
                            <div className='resultsHeader'>
                              <div className='subTitle' style={{ color: `rgb(0,0,0)` }}>{`Оценка по индикаторам компетенции «${item.data[0].title}»`}</div>
                            </div>
                            <div className='names single'>
                              <div className='title'>
                                <b>Имя участника</b>
                              </div>
                              <div className='namesWrapper'>
                                {chunk.names.map(element => (
                                  <div className='name'>{element}</div>
                                ))}
                              </div>
                            </div>
                            <div className='roles single'>
                              <div className='title'>
                                <b>Роль участника</b>
                              </div>
                              <div className='rolesWrapper'>
                                {chunk.roles.map(element => (
                                  <div className='name'>{element}</div>
                                ))}
                              </div>
                            </div>
                            <div className='numbers'>
                              <div className='title'>
                                <b>{competention.competension_1_title === 'all' ? 'Общий результат' : competention.competension_1_title},</b>
                                <br />
                                <span className='small'>% из 100 возможных</span>
                              </div>
                              <div className='numbersWrapper'>
                                <div className='guideLinesWrapper'>
                                  <div className='guideLines'>
                                    {/* {competention.competension_1_title === 'all' ? (
                                      <>
                                        <div className='guideLine' style={{ left: '79%', borderColor: `rgb(${data.client_color})` }}>
                                          <div className='guideLineTriangle' style={{ borderBottomColor: `rgb(${data.client_color})` }} />
                                          <div className='guideLineDescription' style={{ color: `rgb(${data.client_color})` }}>
                                            Senior
                                          </div>
                                        </div>
                                        <div className='guideLine gray' style={{ left: '49%' }}>
                                          <div className='guideLineTriangle' />
                                          <div className='guideLineDescription'>Middle</div>
                                        </div>
                                      </>
                                    ) : (


                                    )} */}
                                    <div className='guideLine gray' style={{ left: `${competention.competension_1_recomended}%` }}>
                                      <div className='guideLineTriangle'></div>
                                      <div className='guideLineDescription'>{competention.competension_1_recomended}%</div>
                                    </div>
                                  </div>
                                </div>

                                {chunk.competension_1_data.map((element, index) => (
                                  <div className='number'>
                                    <div className='lineContainer' style={{ backgroundColor: `rgba(${data.client_color}, 0.2)` }}>
                                      <div
                                        className='line'
                                        style={{
                                          width: `${element[1] ? element[1] : '0'}%`,
                                          backgroundColor: `rgb(${data.client_color})`,
                                        }}
                                      />
                                    </div>
                                    <div className='value'>{element[1] === '' || element[1] === undefined ? '—' : element[1]}</div>
                                    <div className='lineContainer' style={{ backgroundColor: `rgba(${data.shifted_hex_color}, 0.2)` }}>
                                      <div
                                        className='line'
                                        style={{
                                          width: `${element[0] ? element[0] : '0'}%`,
                                          backgroundColor: `rgb(${data.shifted_hex_color})`,
                                        }}
                                      />
                                    </div>
                                    <div className='value'>{element[0] === '' || element[0] === undefined ? '—' : element[0]}</div>
                                  </div>
                                ))}
                              </div>
                            </div>
                            {chunk.competension_2_data && (
                              <div className='numbers'>
                                <div className='title'>
                                  <b>{competention.competension_2_title},</b>
                                  <br />
                                  <span className='small'>% из 100 возможных</span>
                                </div>
                                <div className='numbersWrapper'>
                                  <div className='guideLinesWrapper'>
                                    <div className='guideLines'>
                                      <div className='guideLine gray' style={{ left: `${competention.competension_2_recomended}%` }}>
                                        <div className='guideLineTriangle'></div>
                                        <div className='guideLineDescription'>{competention.competension_2_recomended}%</div>
                                      </div>
                                    </div>
                                  </div>
                                  {chunk.competension_2_data.map((element, index) => (
                                    <div className='number'>
                                      <div className='lineContainer' style={{ backgroundColor: `rgba(${data.client_color}, 0.2)` }}>
                                        <div
                                          className='line'
                                          style={{
                                            width: `${element[1] ? element[1] : '0'}%`,
                                            backgroundColor: `rgb(${data.client_color})`,
                                          }}
                                        />
                                      </div>
                                      <div className='value'>{element[1] === '' || element[1] === undefined ? '—' : element[1]}</div>
                                      <div className='lineContainer' style={{ backgroundColor: `rgba(${data.shifted_hex_color}, 0.2)` }}>
                                        <div
                                          className='line'
                                          style={{
                                            width: `${element[0] ? element[0] : '0'}%`,
                                            backgroundColor: `rgb(${data.shifted_hex_color})`,
                                          }}
                                        />
                                      </div>
                                      <div className='value'>{element[0] === '' || element[0] === undefined ? '—' : element[0]}</div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      ))
                    )
                  )}
                </>
              ))}
          </>
        ))}
      </div>
    );
  }

  return (
    <div className='tribeResults'>
      {data.cases.map((segment, index) => (
        <>
          {index === 0 &&
            segment[0].competensions_chunks.map(competension =>
              competension.chunks.map(chunk => (
                <div className='tribeStats' key={_.uniqueId()}>
                  <div className='statsSection'>
                    <div className='resultsHeader'>
                      <Title>Cравнительная оценка участников диагностики</Title>
                      <div className='resultColorsExpanation'>
                        <div className='before'>
                          <span className='square' style={{ backgroundColor: `rgb(${data.client_color})` }}></span>
                          <span className='text'>Набрано</span>
                        </div>
                        <div className='norm'>
                          <span className='triangle'></span>
                          <span className='text'>Необходимая норма, % от возможных 100%</span>
                        </div>
                      </div>
                    </div>
                    <div className='names single'>
                      <div className='title'>
                        <b>Имя участника</b>
                      </div>
                      <div className='namesWrapper'>
                        {chunk.names.map(element => (
                          <div className='name'>{element}</div>
                        ))}
                      </div>
                    </div>
                    <div className='roles single'>
                      <div className='title'>
                        <b>Роль участника</b>
                      </div>
                      <div className='rolesWrapper'>
                        {chunk.roles.map(element => (
                          <div className='name'>{element}</div>
                        ))}
                      </div>
                    </div>
                    <div className='numbers'>
                      <div className='title'>
                        <b>{competension.competension_1_title === 'all' ? 'Общий результат' : competension.competension_1_title},</b>
                        <br />
                        <span className='small'>% из 100 возможных</span>
                      </div>
                      <div className='numbersWrapper'>
                        <div className='guideLinesWrapper'>
                          <div className='guideLines'>
                            {/* {competension.competension_1_title === 'all' ? (
                              <>
                                <div className='guideLine' style={{ left: '79%', borderColor: `rgb(${data.client_color})` }}>
                                  <div className='guideLineTriangle' style={{ borderBottomColor: `rgb(${data.client_color})` }} />
                                  <div className='guideLineDescription' style={{ color: `rgb(${data.client_color})` }}>
                                    Senior
                                  </div>
                                </div>
                                <div className='guideLine gray' style={{ left: '49%' }}>
                                  <div className='guideLineTriangle' />
                                  <div className='guideLineDescription'>Middle</div>
                                </div>
                              </>
                            ) : (


                            )} */}
                            {competension.competension_1_recomended && (
                              <div className='guideLine gray' style={{ left: `${competension.competension_1_recomended}%` }}>
                                <div className='guideLineTriangle'></div>
                                <div className='guideLineDescription'>{competension.competension_1_recomended}%</div>
                              </div>
                            )}
                          </div>
                        </div>

                        {chunk.competension_1_data.map((element, index) => (
                          <div className='number'>
                            <div className='lineContainer' style={{ backgroundColor: `rgba(${data.client_color}, 0.2)` }}>
                              <div
                                className='line'
                                style={{
                                  width: `${element ? element : '0'}%`,
                                  backgroundColor: `rgb(${data.client_color})`,
                                }}
                              />
                            </div>
                            <div className='value'>{element === '' || element === undefined ? '—' : element}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                    {chunk.competension_2_data && (
                      <div className='numbers'>
                        <div className='title'>
                          <b>{competension.competension_2_title},</b>
                          <br />
                          <span className='small'>% из 100 возможных</span>
                        </div>
                        <div className='numbersWrapper'>
                          <div className='guideLinesWrapper'>
                            <div className='guideLines'>
                              <div className='guideLine gray' style={{ left: `${competension.competension_2_recomended}%` }}>
                                <div className='guideLineTriangle'></div>
                                <div className='guideLineDescription'>{competension.competension_2_recomended}%</div>
                              </div>
                            </div>
                          </div>
                          {chunk.competension_2_data.map((element, index) => (
                            <div className='number'>
                              <div className='lineContainer' style={{ backgroundColor: `rgba(${data.client_color}, 0.2)` }}>
                                <div className='line' style={{ width: `${element ? element : '0'}%`, backgroundColor: `rgb(${data.client_color})` }} />
                              </div>
                              <div className='value'>{element === '' || element === undefined ? '—' : element}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))
            )}
          {index > 0 &&
            segment[0].data.map((item, index) => (
              <>
                {item.data[0].competensions_chunks.map(competension =>
                  competension.chunks.map(chunk => (
                    <div className='tribeStats' key={_.uniqueId()}>
                      <div className='statsSection'>
                        <div className='resultsHeader'>
                          <div className='subTitle' style={{ color: `rgb(0,0,0)` }}>{`Оценка по компетенции «${item.data[0].title}»`}</div>
                          <div className='resultColorsExpanation'>
                            <div className='before'>
                              <span className='square' style={{ backgroundColor: `rgb(${data.client_color})` }}></span>
                              <span className='text'>Набрано</span>
                            </div>
                            <div className='norm'>
                              <span className='triangle'></span>
                              <span className='text'>Необходимая норма, % от возможных 100%</span>
                            </div>
                          </div>
                        </div>
                        <div className='names single'>
                          <div className='title'>
                            <b>Имя участника</b>
                          </div>
                          <div className='namesWrapper'>
                            {chunk.names.map(element => (
                              <div className='name'>{element}</div>
                            ))}
                          </div>
                        </div>
                        <div className='roles single'>
                          <div className='title'>
                            <b>Роль участника</b>
                          </div>
                          <div className='rolesWrapper'>
                            {chunk.roles.map(element => (
                              <div className='name'>{element}</div>
                            ))}
                          </div>
                        </div>
                        <div className='numbers'>
                          <div className='title'>
                            {/* <b>{competension.competension_1_title === 'all' ? 'Общий результат' : competension.competension_1_title},</b> */}
                            <br />
                            <span className='small'>% из 100 возможных</span>
                          </div>
                          <div className='numbersWrapper'>
                            <div className='guideLinesWrapper'>
                              <div className='guideLines'>
                                {/* {competension.competension_1_title === 'all' ? (
                                  <>
                                    <div className='guideLine' style={{ left: '79%', borderColor: `rgb(${data.client_color})` }}>
                                      <div className='guideLineTriangle' style={{ borderBottomColor: `rgb(${data.client_color})` }} />
                                      <div className='guideLineDescription' style={{ color: `rgb(${data.client_color})` }}>
                                        Senior
                                      </div>
                                    </div>
                                    <div className='guideLine gray' style={{ left: '49%' }}>
                                      <div className='guideLineTriangle' />
                                      <div className='guideLineDescription'>Middle</div>
                                    </div>
                                  </>
                                ) : (
                                  
                                )} */}
                                <div className='guideLine gray' style={{ left: `${competension.competension_1_recomended}%` }}>
                                  <div className='guideLineTriangle'></div>
                                  <div className='guideLineDescription'>{competension.competension_1_recomended}%</div>
                                </div>
                              </div>
                            </div>

                            {chunk.competension_1_data.map((element, index) => (
                              <div className='number'>
                                <div className='lineContainer' style={{ backgroundColor: `rgba(${data.client_color}, 0.2)` }}>
                                  <div
                                    className='line'
                                    style={{
                                      width: `${element ? element : '0'}%`,
                                      backgroundColor: `rgb(${data.client_color})`,
                                    }}
                                  />
                                </div>
                                <div className='value'>{element === '' || element === undefined ? '—' : element}</div>
                              </div>
                            ))}
                          </div>
                        </div>
                        {chunk.competension_2_data && (
                          <div className='numbers'>
                            <div className='title'>
                              <b>{competension.competension_2_title},</b>
                              <br />
                              <span className='small'>% из 100 возможных</span>
                            </div>
                            <div className='numbersWrapper'>
                              <div className='guideLinesWrapper'>
                                <div className='guideLines'>
                                  <div className='guideLine gray' style={{ left: `${competension.competension_2_recomended}%` }}>
                                    <div className='guideLineTriangle'></div>
                                    <div className='guideLineDescription'>{competension.competension_2_recomended}%</div>
                                  </div>
                                </div>
                              </div>
                              {chunk.competension_2_data.map((element, index) => (
                                <div className='number'>
                                  <div className='lineContainer' style={{ backgroundColor: `rgba(${data.client_color}, 0.2)` }}>
                                    <div className='line' style={{ width: `${element ? element : '0'}%`, backgroundColor: `rgb(${data.client_color})` }} />
                                  </div>
                                  <div className='value'>{element === '' || element === undefined ? '—' : element}</div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))
                )}
                {item.indicators.map((indicator, index) =>
                  indicator[0].competensions_chunks?.map((competension, index) =>
                    competension.chunks.map(chunk => (
                      <div className='tribeStats' key={_.uniqueId()}>
                        <div className='statsSection'>
                          <div className='resultsHeader'>
                            <div className='subTitle' style={{ color: `rgb(0,0,0)` }}>{`Оценка по индикаторам компетенции «${item.data[0].title}»`}</div>
                          </div>
                          <div className='names single'>
                            <div className='title'>
                              <b>Имя участника</b>
                            </div>
                            <div className='namesWrapper'>
                              {chunk.names.map(element => (
                                <div className='name'>{element}</div>
                              ))}
                            </div>
                          </div>
                          <div className='roles single'>
                            <div className='title'>
                              <b>Роль участника</b>
                            </div>
                            <div className='rolesWrapper'>
                              {chunk.roles.map(element => (
                                <div className='name'>{element}</div>
                              ))}
                            </div>
                          </div>
                          <div className='numbers'>
                            <div className='title'>
                              <b>{competension.competension_1_title === 'all' ? 'Общий результат' : competension.competension_1_title},</b>
                              <br />
                              <span className='small'>% из 100 возможных</span>
                            </div>
                            <div className='numbersWrapper'>
                              <div className='guideLinesWrapper'>
                                <div className='guideLines'>
                                  {/* {competension.competension_1_title === 'all' ? (
                                    <>
                                      <div className='guideLine' style={{ left: '79%', borderColor: `rgb(${data.client_color})` }}>
                                        <div className='guideLineTriangle' style={{ borderBottomColor: `rgb(${data.client_color})` }} />
                                        <div className='guideLineDescription' style={{ color: `rgb(${data.client_color})` }}>
                                          Senior
                                        </div>
                                      </div>
                                      <div className='guideLine gray' style={{ left: '49%' }}>
                                        <div className='guideLineTriangle' />
                                        <div className='guideLineDescription'>Middle</div>
                                      </div>
                                    </>
                                  ) : (
                                  )} */}
                                  <div className='guideLine gray' style={{ left: `${competension.competension_1_recomended}%` }}>
                                    <div className='guideLineTriangle'></div>
                                    <div className='guideLineDescription'>{competension.competension_1_recomended}%</div>
                                  </div>
                                </div>
                              </div>

                              {chunk.competension_1_data.map((element, index) => (
                                <div className='number'>
                                  <div className='lineContainer' style={{ backgroundColor: `rgba(${data.client_color}, 0.2)` }}>
                                    <div
                                      className='line'
                                      style={{
                                        width: `${element ? element : '0'}%`,
                                        backgroundColor: `rgb(${data.client_color})`,
                                      }}
                                    />
                                  </div>
                                  <div className='value'>{element === '' || element === undefined ? '—' : element}</div>
                                </div>
                              ))}
                            </div>
                          </div>
                          {chunk.competension_2_data && (
                            <div className='numbers'>
                              <div className='title'>
                                <b>{competension.competension_2_title},</b>
                                <br />
                                <span className='small'>% из 100 возможных</span>
                              </div>
                              <div className='numbersWrapper'>
                                <div className='guideLinesWrapper'>
                                  <div className='guideLines'>
                                    <div className='guideLine gray' style={{ left: `${competension.competension_2_recomended}%` }}>
                                      <div className='guideLineTriangle'></div>
                                      <div className='guideLineDescription'>{competension.competension_2_recomended}%</div>
                                    </div>
                                  </div>
                                </div>
                                {chunk.competension_2_data.map((element, index) => (
                                  <div className='number'>
                                    <div className='lineContainer' style={{ backgroundColor: `rgba(${data.client_color}, 0.2)` }}>
                                      <div className='line' style={{ width: `${element ? element : '0'}%`, backgroundColor: `rgb(${data.client_color})` }} />
                                    </div>
                                    <div className='value'>{element === '' || element === undefined ? '—' : element}</div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ))
                  )
                )}
              </>
            ))}
        </>
      ))}
    </div>
  );
};
