import { useQuery } from 'react-query';
import axios from 'axios';
import { backendURL } from '../App';

const getTimeTableAvailableData = async _ => {
  const { data } = await axios({
    method: 'get',
    url: `${backendURL}timetable-available/`,
    headers: {
      Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
    },
  });
  return data;
};

export default function useTimeTableAvailableData() {
  return useQuery(['timeTableAvailableData'], getTimeTableAvailableData, { retry: false });
}
