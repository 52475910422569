import './AdminClientTribesReports.scss';

import Button from '../../../components/Button';
import ReportCreationInitModal from '../../../components/ReportCreationInitModal';
import Title from '../../../components/Title';
import _ from 'lodash';
import axios from 'axios';
import { backendURL } from '../../../App';
import consoleLog from '../../../helpers/consoleLog';
import { useParams } from 'react-router-dom';
import { useState } from 'react';

interface Props {
  tribesData: any;
}

export const AdminClientTribesReports: React.FC<Props> = ({ tribesData }) => {
  const { streamID } = useParams<{ streamID: string }>();
  const [isCreatingCSV, setIsCreatingCSV] = useState(false);
  const [isReportStatusModal, setIsReportStatusModal] = useState(false);

  const downloadAllCsSV = () => {
    setIsCreatingCSV(true);
    axios({
      method: 'get',
      url: `${backendURL}reports/csv/`,
      params: {
        tribe_id: _.chain(tribesData).map('id').join().value(),
      },
      headers: {
        Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
      },
      responseType: 'blob',
    })
      .then(result => {
        setIsCreatingCSV(false);
        const downloadUrl = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'csv.zip'); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
        consoleLog('success', 'Reports csv', result);
      })
      .catch(error => {
        setIsCreatingCSV(false);
        consoleLog('error', 'Reports csv', error);
      });
  };

  const createTribeReportAddo = id => {
    axios({
      method: 'post',
      url: `${backendURL}reports/pdf/`,
      data: {
        type: 'tribe',
        id,
        stream_id: streamID,
        pdf_render_params: `wait=footer&scrollPage=true&ignoreHttpsErrors=true&emulateScreenMedia=false`,
      },
      headers: {
        Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
      },
    })
      .then(result => {
        setIsReportStatusModal(true);
        consoleLog('success', 'Create tribe addo', result);
      })
      .catch(error => {
        consoleLog('error', 'Create tribe addo', error);
      });
  };

  return (
    <>
      <div className='adminClientTribesReports'>
        <Title>Отчеты по трайбам</Title>

        <Button
          type='button'
          buttonType='button'
          size='small'
          width='300rem'
          color='blue'
          onClick={() => downloadAllCsSV()}
          className='downloadAllCSVButton'
          loading={isCreatingCSV}
          disabled={isCreatingCSV}
        >
          Выгрузить все анкеты в CSV
        </Button>

        <div className='tribesTable'>
          {_.map(tribesData, tribe => (
            <div className='tribeRow' key={_.uniqueId()}>
              <div className='tribeName'>{tribe.title}</div>
              <Button type='button' buttonType='button' color='blue' width='300rem' size='small' onClick={() => createTribeReportAddo(tribe.id)}>
                Создать отчёт по трайбу
              </Button>
            </div>
          ))}
        </div>
      </div>

      {isReportStatusModal && <ReportCreationInitModal onClose={() => setIsReportStatusModal(false)} />}
    </>
  );
};
