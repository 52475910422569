import {
  ANKETA_NO_TIME_AVAILABLE_MODAL_DISPLAY_TOGGLE,
  ANKETA_CALL_TYPE_MODAL_DISPLAY_TOGGLE,
  ANKETA_CALL_ASSIGNED_MODAL_DISPLAY_TOGGLE,
  ANKETA_DESCRIPTION_MODAL_DISPLAY_TOGGLE,
  ANKETA_ZOOM_VIDEO_MODAL_DISPLAY_TOGGLE,
  SUPPORT_MODAL_DISPLAY_TOGGLE,
  ANKETA_QUESTION_RATE_HELP_MODAL_TOGGLE,
} from './types';

export const anketa_no_time_available_modal_display_toggle = (display_status: boolean) => ({
  type: ANKETA_NO_TIME_AVAILABLE_MODAL_DISPLAY_TOGGLE,
  payload: display_status,
});

export const anketa_call_type_modal_display_toggle = (display_status: boolean) => ({
  type: ANKETA_CALL_TYPE_MODAL_DISPLAY_TOGGLE,
  payload: display_status,
});

export const anketa_call_assigned_modal_display_toggle = (display_status: boolean) => ({
  type: ANKETA_CALL_ASSIGNED_MODAL_DISPLAY_TOGGLE,
  payload: display_status,
});

export const anketa_description_modal_display_toggle = (display_status: boolean) => ({
  type: ANKETA_DESCRIPTION_MODAL_DISPLAY_TOGGLE,
  payload: display_status,
});

export const support_modal_display_toggle = (display_status: boolean) => ({
  type: SUPPORT_MODAL_DISPLAY_TOGGLE,
  payload: display_status,
});

export const anketa_question_rate_help_modal_toggle = (display_status: boolean) => ({
  type: ANKETA_QUESTION_RATE_HELP_MODAL_TOGGLE,
  payload: display_status,
});

export const anketa_zoom_video_modal_display_toggle = (display_status: boolean) => ({
  type: ANKETA_ZOOM_VIDEO_MODAL_DISPLAY_TOGGLE,
  payload: display_status,
});
