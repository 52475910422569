import __ from 'lodash';
import axios from 'axios';
import { backendURL } from '../App';
import { useQuery } from 'react-query';

const getClientAnketaAnswersData = async (_, clientID, streamID) => {
  const { data } = await axios({
    method: 'get',
    url: `${backendURL}anketa-answers/client/${clientID}/`,
    headers: {
      Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
    },
    params: {
      order_by: 'call_start',
      stream_id: __.isNil(streamID) ? '' : streamID,
    },
  });
  return data;
};

export default function useClientAnketaAnswersData(clientID, streamID) {
  return useQuery(['clientAnketaAnswersData', clientID, streamID], getClientAnketaAnswersData, { retry: false, refetchOnWindowFocus: false });
}
