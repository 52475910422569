
import Select from 'react-select';

interface ReactSelectProps {
  name?: string;
  defaultValue?: any;
  onChange: Function;
  onBlur?: Function;
  options: Array<any>;
  placeholder: string;
  isDisabled?: boolean;
}

export const ReactSelect: React.FC<ReactSelectProps> = ({ name, onChange, defaultValue, onBlur, options, placeholder, isDisabled }) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: 'none',
      height: '39rem',
      alignItems: 'flexStart',
      border: 'none',
      borderBottom: '1rem solid rgba(0, 174, 239, 1) !important',
      borderRadius: '0',
      boxShadow: state.isFocused ? 0 : 0,
      paddingBottom: '8rem',
      backgroundColor: isDisabled ? 'rgba(255, 255, 255, 0)' : 'rgba(255, 255, 255, 0)',
      cursor: isDisabled ? 'no-drop' : 'pointer',
    }),
    valueContainer: provided => ({
      ...provided,
      height: '33rem',
      paddingLeft: '0',
      paddingTop: '0',
      paddingBottom: '0',
    }),
    input: provided => ({
      ...provided,
      fontSize: '24rem',
      lineHeight: '28rem',
      margin: '0',
    }),
    placeholder: provided => ({
      ...provided,
      fontSize: '14rem',
      lineHeight: '16rem',
      top: '20rem',
      marginLeft: '0',
      marginRight: '0',
    }),
    singleValue: provided => ({
      ...provided,
      fontSize: '24rem',
      lineHeight: '28rem',
      top: '15rem',
      marginLeft: '0',
    }),
    menuList: provided => ({
      ...provided,
      fontSize: '14rem',
      lineHeight: '16rem',
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none',
    }),
    dropdownIndicator: provided => ({
      ...provided,
      padding: '0',
    }),
  };

  return (
    <div className='reactSelect'>
      <Select
        defaultValue={defaultValue}
        // defaultValue='test'
        name={name}
        options={options}
        onChange={option => onChange(option.value)}
        // onBlur={() => onBlur(name, true)}
        styles={customStyles}
        placeholder={placeholder}
        isDisabled={isDisabled}
      />
    </div>
  );
};
