import './AdminExpertNoInterviewsTable.scss';

import { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import useAnketasAnswersData from '../../../../hooks/useAnketasAnswersData';
import AnimatedRedirect from '../../../../components/AnimatedRedirect';

interface Props {
  expertID: any;
}

export const AdminExpertNoInterviewsTable: React.FC<Props> = ({ expertID }) => {
  const { data: anketasAnswersData, status: anketasAnswersDataLoadingStatus } = useAnketasAnswersData();
  const [filteredAnketasAnswerData, setFilteredAnketaAnswersData] = useState([]) as any;

  useEffect(() => {
    let filteredData;
    filteredData = anketasAnswersData?.withoutInterview.all.filter(anketa => anketa.expert_id === expertID);
    setFilteredAnketaAnswersData(filteredData);
  }, [anketasAnswersData]);

  if (anketasAnswersDataLoadingStatus === 'loading') {
    return <></>;
  }

  if (anketasAnswersDataLoadingStatus === 'error') {
    return <AnimatedRedirect redirectTo='/500/' />;
  }

  if (filteredAnketasAnswerData && filteredAnketasAnswerData.length > 0) {
    return (
      <div className='adminExpertNoInterviewsTable'>
        <div className='withoutInterviewTitleRowDummy'>Анкеты без интервью</div>
        <div className='row titleRow'>
          <div className='fio'>Имя участника</div>
          <div className='client'>Клиент</div>
          <div className='id'>ID</div>
          <div className='phone'>Телефон</div>
          <div className='role'>Роль</div>
          <div className='case'>Сегмент</div>
          <div className='callInfo'>Способ связи</div>
          <div className='anketLink'></div>
          <div className='anketStatus'></div>
        </div>

        {filteredAnketasAnswerData.map(
          element =>
            element.expert_id === expertID && (
              <div className='row' key={element.id}>
                <div className='fio'>
                  {element.user.first_name} {element.user?.last_name}
                </div>
                <div className='client'>
                  <span>{element.client.short_title}</span>
                  <img src={element.client.small_logo} />
                </div>
                <div className='id'>{element.user.member_id || '------'}</div>
                <div className='phone'>{element.user.phone || '------'}</div>
                <div className='role'>{element.user.status_str}</div>
                <div className='case'>{element.user.case_str}</div>
                <div className='callInfo' />
                <div className='anketLink'>
                  {element.process_status === 4 || element.process_status === 5 ? (
                    'Анкета'
                  ) : (
                    <Link className='link' to={`/expert/review/anketa/${element.anketa_id}/${element.user.anketa_answers_id}/rate/`}>
                      Анкета
                    </Link>
                  )}
                </div>
                <div className='anketStatus'>
                  {element.process_status === 2 && 'Без оценки'}
                  {element.process_status === 3 && 'Оценивается'}
                  {element.process_status === 4 && 'Завершена'}
                  {element.process_status === 5 && 'Проверенная'}
                </div>
                <div className='zoomVideoLink empty'></div>
              </div>
            )
        )}
      </div>
    );
  }
  return <></>;
};
