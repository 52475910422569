import './Input.scss';

import { AnimatePresence, motion } from 'framer-motion';

import { FormikErrors } from 'formik';
import InputError from '../InputError';
import { useState } from 'react';

interface Props {
  type: 'text' | 'email' | 'phone' | 'password';
  name: string;
  value: string;
  placeholder?: string;
  placeholderType?: 'alwaysVisible' | 'partlyVisible';
  onChange?: any;
  onBlur?: () => void;
  isDisabled?: boolean;
  isValid?: boolean;
  validationError?: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined;
  autoComplete?: 'on' | 'off' | 'new-password';
  spellcheck?: boolean;
  size?: 'medium' | 'small';
}

export const Input: React.FC<Props> = ({
  type,
  name,
  value,
  placeholder,
  placeholderType,
  onChange,
  onBlur,
  isDisabled,
  isValid,
  validationError,
  autoComplete,
  spellcheck,
  size,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const variants = {
    placeholderDown: { y: 0 },
    placeholderUp: { y: size === 'small' ? '-25rem' : '-30rem' },
  };

  return (
    <div className={'input' + ` ${size}` + ` ${placeholderType}`}>
      {placeholderType === 'alwaysVisible' && (
        <motion.div
          className='placeholder'
          animate={!isFocused && !value ? 'placeholderDown' : 'placeholderUp'}
          variants={variants}
          transition={{ type: 'spring', velocity: 2 }}
        >
          {placeholder}
        </motion.div>
      )}
      <input
        type={type}
        name={name}
        value={value}
        placeholder={placeholderType === 'partlyVisible' ? placeholder : undefined}
        onChange={onChange}
        onBlur={() => {
          onBlur && onBlur();
          setIsFocused(false);
        }}
        onFocus={() => setIsFocused(true)}
        disabled={isDisabled}
        autoComplete={autoComplete}
        className={`${!isValid ? 'invalid' : 'valid'}`}
        spellCheck={spellcheck !== undefined ? spellcheck : true}
      />
      <AnimatePresence>
        {isValid !== undefined && (
          <motion.div initial={{ opacity: 0, y: -25 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -25 }} transition={{ duration: 0.2 }}>
            <InputError>{validationError}</InputError>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
