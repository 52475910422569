import './StagesIndicator.scss';

import { Link, useParams } from 'react-router-dom';

interface Props {
  stage: number;
}

export const StagesIndicator: React.FC<Props> = ({ stage }) => {
  const { clientID, anketaID } = useParams<{ clientID: string; anketaID: string }>();

  return (
    <nav className='stagesIndicator'>
      {stage === 0 || stage === 1 ? (
        <div className='link link-disabled-style'>Создание шаблона анкеты</div>
      ) : (
        <Link to={`/admin/${clientID}/templates/${anketaID}/case/`} className='link'>
          Создание шаблона анкеты
        </Link>
      )}
      {stage === 2 ? (
        <div className='link link-disabled-style'>Создание списка компетенций</div>
      ) : (
        <Link to={`/admin/${clientID}/templates/${anketaID}/competentions/`} className='link'>
          Создание списка компетенций
        </Link>
      )}

      {stage === 3 ? (
        <div className='link link-disabled-style'>Создание опросника</div>
      ) : (
        <Link to={`/admin/${clientID}/templates/${anketaID}/inquirer/`} className='link'>
          Создание опросника
        </Link>
      )}

      {stage === 4 ? (
        <div className='link link-disabled-style'>Создание списка вопросов</div>
      ) : (
        <Link to={`/admin/${clientID}/templates/${anketaID}/questions/`} className='link'>
          Создание списка вопросов
        </Link>
      )}

      {stage === 5 ? (
        <div className='link link-disabled-style'>Создание списка рекомендаций</div>
      ) : (
        <Link to={`/admin/${clientID}/templates/${anketaID}/recommendations/`} className='link'>
          Создание списка рекомендаций
        </Link>
      )}
    </nav>
  );
};
