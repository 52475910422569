import './AnketaQuestion.scss';
import QuizQuestion_videoPreviewPoster from '../../../assets/images/QuizQuestion_videoPreviewPoster.svg';


import Title from '../../Title';
import ContainerWYSIWYG from '../../ContainerWYSIWYG';

interface Props {
  questionData: {
    text?: string;
    question_text: string;
    video?: string;
    image?: string;
  };
  toggleModal: (() => void) | undefined;
}

export const AnketaQuestion: React.FC<Props> = ({ questionData, toggleModal }) => {
  return (
    <div className='anketaQuestion'>
      {questionData.text && <Title>{questionData.text}</Title>}
      {toggleModal && (
        <button className='showCaseDescriptionButton' type='button' onClick={toggleModal}>
          Описание кейса
        </button>
      )}
      <ContainerWYSIWYG data={questionData.question_text} size='default' />
      {questionData.video && (
        <video poster={QuizQuestion_videoPreviewPoster} controls>
          <source src={questionData.video} type='video/mp4'></source>
        </video>
      )}
      {questionData.image && <img src={questionData.image} alt='questionImage' />}
    </div>
  );
};
