import './ReportCreationInitModal.scss';

import { useHistory, useParams } from 'react-router-dom';

import Button from '../Button';
import Modal from '../Modal';
import Title from '../Title';

interface Props {
  onClose: () => void;
}

export const ReportCreationInitModal: React.FC<Props> = ({ onClose }) => {
  const history = useHistory();
  const { clientID } = useParams<{ clientID: string }>();

  return (
    <Modal className='ReportCreationInitModal' onClose={onClose} dataCy='reportCreationModal'>
      <Title>Создание отчёта</Title>
      <div className='subTitle'>
        Отчёт начал создаваться.
        <br /> Это может занять некоторое время.
        <br />
        Статус можно отслеживать на странице отчётов.
      </div>
      <Button
        type='button'
        width='220rem'
        size='medium'
        color='blue'
        text='На страницу отчётов'
        dataCy='goToReportsBtn'
        onClick={() => history.push(`/admin/${clientID}/reports/`)}
      />
    </Modal>
  );
};
