import { backendURL, debug } from '../App';

import axios from 'axios';
import consoleLog from '../helpers/consoleLog';
import { useMutation } from 'react-query';

const sendTelegramTestMessage = async () => {
  try {
    const { data } = await axios({
      method: 'post',
      url: `${backendURL}t/test/`,
      headers: {
        Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
      },
    });
    debug && consoleLog('success', 'Send telegramm test message', data);
    return data;
  } catch (error) {
    debug && consoleLog('error', 'Send telegramm test message', error);
    throw new Error();    
  }
};

export default function useExpertTelegramMutation({onSuccess, onError}) {
  return useMutation(sendTelegramTestMessage, {onSuccess, onError});
}
