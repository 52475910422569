import './styles.scss';

import { backendURL, debug } from '../../../../App';

import { Button } from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal';
import Title from '../../../../components/Title';
import axios from 'axios';
import consoleLog from '../../../../helpers/consoleLog';
import icon_loading from '../../../../assets/images/icon_loadingBlue.svg';
import { motion } from 'framer-motion';
import { queryCache } from 'react-query';
import useAdminExpertData from '../../../../hooks/useAdminExpertData';
import { useState } from 'react';

interface Props {
  expertID?: number;
  onClose: () => void;
  onSwitch: () => void;
}

export const RemoveClientForExpertModal: React.FC<Props> = ({ expertID, onClose, onSwitch }) => {
  const { data: expertData, status: expertDataLoadingStatus } = useAdminExpertData(expertID);

  // * Отвязать клиента

  const [clientsToRemove, setClientsToRemove] = useState([]) as any;

  const onClick = (id, index) => {
    let newArray = [...clientsToRemove];

    if (!newArray[index]) {
      newArray[index] = id;
      setClientsToRemove(newArray);
    } else {
      newArray[index] = '';
      setClientsToRemove(newArray);
    }
  };

  const removeClients = () => {
    const clients = clientsToRemove.filter(Boolean);
    console.log(clients.join());
    axios({
      method: 'post',
      url: `${backendURL}admin/experts/`,
      headers: {
        Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
      },
      data: {
        expert_id: expertID,
        remove_client: clients.join(),
      },
    })
      .then(result => {
        queryCache.invalidateQueries('adminExpertData');
        setClientsToRemove([]);
        debug && consoleLog('success', 'Client(s) remove', result);
      })
      .catch(error => {
        error && consoleLog('error', 'Client(s) remove', error);
      });
  };

  if (expertDataLoadingStatus === 'loading') {
    return (
      <Modal className='removeClientForExpertModal' onClose={onClose}>
        <div className='isLoading'>
          <motion.img src={icon_loading} initial={{ rotate: 0 }} animate={{ rotate: 360 }} transition={{ duration: 2, loop: Infinity, ease: 'linear' }} />
        </div>
      </Modal>
    );
  }

  return (
    <Modal className='removeClientForExpertModal' onClose={onClose}>
      <Title>Отвязать клиента</Title>
      <div className='subTitle'>Список клиентов, с которыми работает эксперт:</div>
      {expertData.expert.clients.length > 0 ? (
        <>
          <div className='clientsList'>
            {expertData.expert.clients.map((client, index) => (
              <div className={`client ${clientsToRemove[index] ? 'checked' : ''}`} onClick={() => onClick(client.id, index)} key={client.id}>
                <div className='clientCheckbox'></div>
                <div className='clientName'>{client.title}</div>
              </div>
            ))}
          </div>
          <Button
            type='button'
            width='320rem'
            size='medium'
            color='blue'
            text='Отвязать выбранных клиента'
            disabled={clientsToRemove ? false : true}
            onClick={() => removeClients()}
          />
        </>
      ) : (
        <>
          <div className='empty withMarginBottom'>У эксперта нет активных клиентов.</div>
          <Button
            type='button'
            width='220rem'
            size='medium'
            color='blue'
            text='Привязать клиентов'
            disabled={clientsToRemove ? false : true}
            onClick={onSwitch}
            className='switchButton'
          />
        </>
      )}
    </Modal>
  );
};
