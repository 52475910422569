import './MemberAnketaQuestions.scss';

import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import AnimatedRedirect from '../../components/AnimatedRedirect';
import AnketaAnswerCheckboxes from './AnketaAnswerCheckboxes';
import AnketaAnswerDraggable from './AnketaAnswerDraggable';
import AnketaAnswerForm from './AnketaAnswerForm';
import AnketaAnswerRadio from './AnketaAnswerRadio';
import AnketaDescriptionModal from '../../components/AnketaSharedComponents/AnketaDescriptionModal';
import AnketaQuestion from '../../components/AnketaSharedComponents/AnketaQuestion';
import { Detector } from 'react-detect-offline';
import ErrorInfiniteRetry from '../../components/ErrorInfiniteRetry';
import MemberAnketaHeader from '../../components/AnketaSharedComponents/MemberAnketaHeader';
import MemberAnketaProgression from '../../components/AnketaSharedComponents/MemberAnketaProgression';
import MemberAnketaSubquestionsProgression from '../../components/AnketaSharedComponents/MemberAnketaSubquestionsProgression';
import NoInterviewModal from './NoInterviewModal';
import NoTimeLeftModal from './NoTimeLeftModal';
import PageLoader from '../../components/PageLoader';
import axios from 'axios';
import { backendURL } from '../../App';
import consoleLog from '../../helpers/consoleLog';
import { motion } from 'framer-motion';
import { queryCache } from 'react-query';
import { standardPageVariants } from '../../animationsVariants/StandardPageVariants';
import useClientAnketaData from '../../hooks/useClientAnketaData';
import useInterval from '../../helpers/useInterval';
import useUserData from '../../hooks/useUserData';

export const MemberAnketaQuestions: React.FC<{}> = () => {
  const history = useHistory();
  const [errorDuringSubmit, setErrorDuringSubmit] = useState(false);
  const { anketaID } = useParams<{ anketaID: string }>();
  const { data: anketaData, status: anketaDataLoadingStatus, error: anketaDataError, failureCount: anketaDataFailureCount } = useClientAnketaData(anketaID);
  const { data: userData, status: userDataLoadingStatus, failureCount: useraDataFailureCount } = useUserData();
  const [isAnketaDescriptionModal, setIsAnketaDescriptionModal] = useState<boolean>(false);
  const [isNoInterviewModal, setIsNoInterviewModal] = useState<boolean>(false); // * initial state true + anketaData.questions_completed allows not to trigger endless rerender

  useEffect(() => {
    if (anketaData?.question_number === 1 && anketaData?.time_left === null)
      axios({
        method: 'post',
        url: `${backendURL}member-start-date/`,
        headers: {
          Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
        },
      }).then(() => queryCache.invalidateQueries('clientAnketaData'));
  }, [anketaData]);

  useInterval(() => {
    anketaData?.time_for_anketa !== null && anketaData?.time_for_anketa !== undefined && queryCache.invalidateQueries('clientAnketaData');
  }, 30000);

  if (anketaDataLoadingStatus === 'loading' || userDataLoadingStatus === 'loading') {
    return anketaDataFailureCount > 0 || useraDataFailureCount > 0 ? <ErrorInfiniteRetry /> : <PageLoader />;
  }

  if (anketaDataLoadingStatus === 'error' || userDataLoadingStatus === 'error' || errorDuringSubmit === true) {
    return (
      <>
        <ErrorInfiniteRetry resetStateErrors={() => setErrorDuringSubmit(false)} />
      </>
    );
  }

  if (
    (anketaData.status === 1 && anketaData.questions_completed && !anketaData.anketa.interview_needless) ||
    (anketaData.status !== 0 && anketaData.status !== 1 && !anketaData.anketa.interview_needless)
  ) {
    return <AnimatedRedirect redirectTo={`/client/anketa/${anketaID}/call_schedulling/`} />;
  }

  if (
    (anketaData.status === 1 && anketaData.questions_completed && anketaData.anketa.interview_needless && !isNoInterviewModal) ||
    (anketaData.status !== 0 && anketaData.status !== 1 && anketaData.anketa.interview_needless)
  ) {
    return <AnimatedRedirect redirectTo='/client/anketa/complete/' />;
  }

  return (
    <motion.main className='memberAnketaQuestions' variants={standardPageVariants} initial='initial' animate='animate' exit='exit'>
      <MemberAnketaHeader userData={userData.user} anketaData={anketaData} />
      <MemberAnketaProgression questionsCount={anketaData.questions_count} currentQuestionNumber={anketaData.question_number} />
      {anketaData.subquestion && (
        <MemberAnketaSubquestionsProgression subquestionsCount={anketaData.question.subquestions.length} currentSubquestionNumber={anketaData.subquestion_number} />
      )}
      <AnketaQuestion
        questionData={anketaData.subquestion ? { text: anketaData.question.text, question_text: anketaData.subquestion.text } : anketaData.question}
        toggleModal={anketaData.anketa.description ? () => setIsAnketaDescriptionModal(true) : undefined}
      />
      {anketaData.subquestion ? (
        <>
          {anketaData.subquestion.sq_type === 0 && (
            <AnketaAnswerRadio
              anketaData={anketaData}
              setIsNoInterviewModal={() => setIsNoInterviewModal(true)}
              setErrorDuringSubmit={() => setErrorDuringSubmit(true)}
            />
          )}
          {anketaData.subquestion.sq_type === 1 && (
            <AnketaAnswerCheckboxes
              anketaData={anketaData}
              setIsNoInterviewModal={() => setIsNoInterviewModal(true)}
              setErrorDuringSubmit={() => setErrorDuringSubmit(true)}
            />
          )}
          {anketaData.subquestion.sq_type === 2 && (
            <AnketaAnswerDraggable
              anketaData={anketaData}
              setIsNoInterviewModal={() => setIsNoInterviewModal(true)}
              setErrorDuringSubmit={() => setErrorDuringSubmit(true)}
            />
          )}
        </>
      ) : (
        <AnketaAnswerForm anketaData={anketaData} setIsNoInterviewModal={() => setIsNoInterviewModal(true)} />
      )}
      {isAnketaDescriptionModal && <AnketaDescriptionModal onClose={() => setIsAnketaDescriptionModal(false)} description={anketaData.anketa.description} />}
      {isNoInterviewModal && (
        <NoInterviewModal
          onClose={() => {
            queryCache.invalidateQueries('clientAnketaData');
            setIsNoInterviewModal(false);
          }}
        />
      )}
      {anketaData.time_left === 0 && (
        <NoTimeLeftModal
          onClose={() => {
            queryCache.invalidateQueries('clientAnketaData');
            history.push('/client/anketa/complete/');
          }}
        />
      )}
    </motion.main>
  );
};
