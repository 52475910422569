import { useQuery } from 'react-query';
import axios from 'axios';
import { backendURL } from '../App';
import __ from 'lodash';

const getAdminMembersData = async (_, clientID, streamID) => {
  const { data } = await axios({
    method: 'get',
    url: `${backendURL}admin/members/${clientID}/`,
    headers: {
      Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
    },
    params: {
      stream_id: __.isNil(streamID) ? '' : streamID,
    },
  });

  return data;
};

export default function useAdminMembersData(clientID, streamID) {
  return useQuery(['adminMembersData', clientID, streamID], getAdminMembersData, { retry: false });
}
