import './styles.scss';

import { backendURL, debug } from '../../../../App';

import Button from '../../../../components/Button/';
import Modal from '../../../../components/Modal';
import { ReactSelect } from '../../../../components/ReactSelect/ReactSelect';
import Title from '../../../../components/Title';
import axios from 'axios';
import consoleLog from '../../../../helpers/consoleLog';
import icon_loading from '../../../../assets/images/icon_loadingBlue.svg';
import { motion } from 'framer-motion';
import { queryCache } from 'react-query';
import useAdminExpertData from '../../../../hooks/useAdminExpertData';
import useClientsData from '../../../../hooks/useClientsData';
import { useState } from 'react';

interface Props {
  expertID?: number;
  onClose: () => void;
}

export const AddClientForExpertModal: React.FC<Props> = ({ expertID, onClose }) => {
  const { data: allClientsData, status: allClientsDataLoadingStatus } = useClientsData();
  const { data: expertData, status: expertDataLoadingStatus } = useAdminExpertData(expertID);

  // * Готовим съедобный массив данных для селекта
  const allClientsDataForSelect = () => {
    const data = allClientsData.map(client => ({ value: client.id, label: client.title }));
    return data;
  };

  // * Привязать эксперту нового клиентаа
  const [clientToAdd, setClientToAdd] = useState<number | null>(null);
  const addClient = () => {
    axios({
      method: 'post',
      url: `${backendURL}admin/experts/`,
      headers: {
        Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
      },
      data: {
        expert_id: expertID,
        add_client: clientToAdd,
      },
    })
      .then(result => {
        queryCache.invalidateQueries('adminExpertData');
        debug && consoleLog('success', 'Client add', result);
      })
      .catch(error => {
        error && consoleLog('error', 'Client add', error);
      });
  };

  if (allClientsDataLoadingStatus === 'loading' || expertDataLoadingStatus === 'loading') {
    return (
      <Modal className='addClientForExpertModal' onClose={onClose}>
        <div className='isLoading'>
          <motion.img src={icon_loading} initial={{ rotate: 0 }} animate={{ rotate: 360 }} transition={{ duration: 2, loop: Infinity, ease: 'linear' }} />
        </div>
      </Modal>
    );
  }

  return (
    <Modal className='addClientForExpertModal' onClose={onClose}>
      <Title>Привязать нового клиента</Title>
      <div className='subTitle'>Список клиентов, с которыми работает эксперт:</div>
      {expertData.expert.clients.length > 0 ? (
        <div className='clientsList'>
          {expertData.expert.clients.map(client => (
            <div className='client'>- {client.title}</div>
          ))}
        </div>
      ) : (
        <div className='empty withMarginBottom'>У эксперта нет активных клиентов.</div>
      )}
      <div className='subTitle'>Выберите клиента из списка:</div>
      {allClientsData.length === expertData.expert.clients.length ? (
        <div className='empty'>Эксперт уже работает cо всеми доступными клиентами.</div>
      ) : (
        <>
          <ReactSelect placeholder='Название клиента' options={allClientsDataForSelect()} onChange={setClientToAdd} />
          <Button type='button' width='220rem' size='medium' color='blue' text='Добавить клиента' disabled={clientToAdd ? false : true} onClick={() => addClient()} />
        </>
      )}
    </Modal>
  );
};
