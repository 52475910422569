// * Тип отображения хедера
export const HEADER_DISPLAY_TYPE_TOGGLE = 'header_display_type_toggle';

// * Выбираем новые дни для отображения расписания
export const SCHEDULE_CHANGE_SELECTED_DATES = 'schedule_change_selected_dates';
export const SCHEDULE_CLIENT_SELECT_DATE = 'schedule_client_select_date';
export const SCHEDULE_CLIENT_CHANGING_DATE_STATUS_TOGGLE = 'schedule_client_changing_date_status_toggle';

// * Модалки
export const ANKETA_NO_TIME_AVAILABLE_MODAL_DISPLAY_TOGGLE = 'anketa_no_time_available_modal_display_togglE';
export const ANKETA_CALL_TYPE_MODAL_DISPLAY_TOGGLE = 'anketa_call_type_modal_display_toggle';
export const ANKETA_CALL_ASSIGNED_MODAL_DISPLAY_TOGGLE = 'anketa_call_assigned_modal_display_toggle';
export const ANKETA_DESCRIPTION_MODAL_DISPLAY_TOGGLE = 'anketa_description_modal_display_toggle';
export const ANKETA_ZOOM_VIDEO_MODAL_DISPLAY_TOGGLE = 'anketa_zoom_video_modal_display_toggle';
export const SUPPORT_MODAL_DISPLAY_TOGGLE = 'support_modal_display_toggle';
export const ANKETA_QUESTION_RATE_HELP_MODAL_TOGGLE = 'anketa_question_rate_help_modal_toggle';

export const ANKETA_LIST_ACTIVE_QUESTION_CHANGE = 'anketa_list_active_question_change';

// *
export const EXPERT_RESPONDENTS_ACTIVE_DATE_SELECT = 'expert_respondents_active_date_select';

// * Фильтры админки
export const ADMIN_TABLE_STATUS_FILTER_DISPLAY_TOGGLE = 'admin_table_status_filter_display_toggle';
export const ADMIN_TABLE_MEMBER_ID_FILTER_DISPLAY_TOGGLE = 'admin_table_member_id_filter_display_toggle';
export const ADMIN_TABLE_MEMBER_NAME_FILTER_DISPLAY_TOGGLE = 'admin_table_member_name_filter_display_toggle';
export const ADMIN_TABLE_EXPERT_FILTER_DISPLAY_TOGGLE = 'admin_table_expert_filter_display_toggle';
export const ADMIN_TABLE_TRIBE_FILTER_DISPLAY_TOGGLE = 'admin_table_tribe_filter_display_toggle';
