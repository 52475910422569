import axios from 'axios';
import { backendURL } from '../App';
import { useQuery } from 'react-query';

const getUserData = async _ => {
  const { data } = await axios({
    method: 'get',
    url: `${backendURL}user/${localStorage.getItem('skillsSurveyUserID')}/`,
    headers: {
      Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
    },
  });
  return data;
};

export default function useUserData() {
  return useQuery(['userData'], getUserData, { retry: true, refetchOnWindowFocus: false, enabled: localStorage.getItem('skillsSurveyUserID') });
}
