import * as Yup from 'yup';

import { FieldArray, Form, Formik } from 'formik';
import { backendURL, debug } from '../../App';
import { useHistory, useParams } from 'react-router-dom';

import AnimatedRedirect from '../../components/AnimatedRedirect';
import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import ContainerWYSIWYG from '../../components/ContainerWYSIWYG';
import InputError from '../../components/InputError';
import PageLoader from '../../components/PageLoader';
import SubTitle from '../P_5.7_Admin_TemplateForm/SubTitle';
import TextareaWYSIWYG from '../../components/TextareaWYSIWYG';
import Title from '../../components/Title';
import axios from 'axios';
import consoleLog from '../../helpers/consoleLog';
import { motion } from 'framer-motion';
import { queryCache } from 'react-query';
import { standardPageVariants } from '../../animationsVariants/StandardPageVariants';
import useClientAnketaData from '../../hooks/useClientAnketaData';

export const MemberInquirer: React.FC<{}> = () => {
  const history = useHistory();
  const { anketaID } = useParams<{ anketaID: string }>();
  const { data: anketaData, status: anketaDataLoadingStatus } = useClientAnketaData(anketaID);

  if (anketaDataLoadingStatus === 'loading') {
    return <PageLoader />;
  }

  if (anketaDataLoadingStatus === 'error') {
    return <AnimatedRedirect redirectTo='/500/' />;
  }

  if (anketaData.inquirer.completed) {
    return <AnimatedRedirect redirectTo={`/client/anketa/${anketaID}/questions/`} />;
  }

  return (
    <motion.main className='grid bg-white px-[30rem] pb-[50rem] pt-[80rem]' variants={standardPageVariants} initial='initial' animate='animate' exit='exit'>
      <Title className='mb-[50rem]'>Опрос</Title>

      <Formik
        enableReinitialize
        initialValues={{
          inquirer: anketaData.inquirer.questions.map(question => ({ answer: question.variants.length > 0 ? 'Закрытый вопрос' : '', ...question })),
        }}
        validationSchema={Yup.object({
          inquirer: Yup.array().of(
            Yup.object().shape({
              answer: Yup.string().required('Поле обязательно для заполнения'),
            })
          ),
        })}
        onSubmit={async (values, { setSubmitting, setStatus }) => {
          try {
            values.inquirer.forEach(async question => {
              const { data } = await axios({
                method: 'post',
                url: `${backendURL}inquirer-answer/`,
                headers: {
                  Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
                },
                data: {
                  anketa_answers_id: anketaData.anketa_answer_id,
                  question_id: question.id,
                  text: question.answer,
                  variants: question.variants
                    .filter(v => v.value === true)
                    .map(variant => variant.id)
                    .join(','),
                },
              });
              debug && consoleLog('success', 'Inquirer answer saved', data);
            });
            setSubmitting(false);
            setStatus({ success: 'Сохранено успешно. Вы будете перенаправлены на след. этап тестирования через 3с.' });
            setTimeout(() => {
              queryCache.invalidateQueries(['anketaQuestionsData', anketaID]);
              setStatus({ success: '' });
              anketaData.anketa.description ? history.push(`/client/anketa/${anketaID}/preview/`) : history.push(`/client/anketa/${anketaID}/questions/`);
            }, 2000);
          } catch (error) {
            setSubmitting(false);
            setStatus({ error: 'Упс. Что-то пошло не так.(' });
            setTimeout(() => {
              setStatus({ error: '' });
            }, 3000);
            debug && consoleLog('success', 'Inquirer answers saved', error);
          }
        }}
      >
        {props => (
          <Form>
            <FieldArray name='inquirer'>
              {() =>
                props.values.inquirer.map((_, index: number) => (
                  <div className='mb-[30rem] w-full'>
                    <SubTitle>{`Вопрос ${index + 1}`}</SubTitle>
                    <div className='my-[30rem]'>
                      <ContainerWYSIWYG data={props.values.inquirer[index].text} size='default' />
                    </div>
                    {props.values.inquirer[index].variants.length >= 1 ? (
                      <div className='mb-[30rem]'>
                        <FieldArray name={`inquirer.${index}.variants`}>
                          {() =>
                            props.values.inquirer[index].variants.map((variant, variantIndex) => (
                              <Checkbox
                                name={`inquirer.${index}.variants.${variantIndex}.value`}
                                checked={variant.value}
                                text={variant.text}
                                onClick={() => props.setFieldValue(`inquirer.${index}.variants.${variantIndex}.value`, !variant.value)}
                                containerClassName='mb-[10rem]'
                              />
                            ))
                          }
                        </FieldArray>
                      </div>
                    ) : (
                      <TextareaWYSIWYG
                        value={props.values.inquirer[index].answer}
                        onChange={value => props.setFieldValue(`inquirer[${index}].answer`, value.replace(/(<([^>]+)>)/gi, '') ? value : '')}
                        onBlur={() => props.setFieldTouched(`inquirer[${index}].answer`, true)}
                        placeholderType='alwaysVisible'
                        placeholder={`Ответ`}
                        // @ts-ignore
                        isValid={!(props.errors?.inquirer?.[index]?.answer && props.touched?.inquirer?.[index]?.answer)}
                        // @ts-ignore
                        validationError={
                          props.errors?.inquirer?.[index]?.answer && props.touched?.inquirer?.[index]?.answer ? props.errors?.inquirer?.[index]?.answer : undefined
                        }
                        modulesType='noModules'
                      />
                    )}
                  </div>
                ))
              }
            </FieldArray>

            <div className='flex w-full justify-center'>
              <InputError className={`${props.status?.success ? 'success' : ''}`}>{props.status?.success || props.status?.error || ''}</InputError>
            </div>

            <Button type='button' buttonType='submit' width='330rem' size='large' color='blue' text='Сохранить' />
          </Form>
        )}
      </Formik>
    </motion.main>
  );
};
