import __ from 'lodash';
import axios from 'axios';
import { backendURL } from '../App';
import { useQuery } from 'react-query';

const getReportHTMLData = async (_, type, id, streamID) => {
  const { data } = await axios({
    method: 'get',
    url: `${backendURL}reports/html/`,
    params: {
      type,
      id,
      f: 'new',
      streams: true,
      stream_id: __.isNil(streamID) ? '' : streamID,
    },
  });
  return data.data;
};

export default function useReportHTMLData(type, id, streamID) {
  return useQuery(['reportHTMLData', type, id, streamID], getReportHTMLData, { retry: false });
}
