import './ErrorInfiniteRetry.scss';

import { Offline, Online } from 'react-detect-offline';

import icon_loading from '../../assets/images/icon_loadingBlue.svg';
import { motion } from 'framer-motion';

interface Props {
  resetStateErrors?: () => void;
}

export const ErrorInfiniteRetry: React.FC<Props> = ({ resetStateErrors }) => {
  return (
    <div className='errorInfiniteRetry'>
      <motion.img src={icon_loading} initial={{ rotate: 0 }} animate={{ rotate: 360 }} transition={{ duration: 2, loop: Infinity, ease: 'linear' }} />
      <br />
      <Online>
        <div className='errorMessage'>
          На сервере технические проблемы. <br />
          Приносим извинения за неудобства. В ближайшее время мы восстановим работу системы. <br />
          <br />
          Попробуйте перезагрузить страницу через несколько минут. <br />
          Если ошибка остаётся - свяжитесь с администратором.
        </div>
      </Online>
      <Offline onChange={resetStateErrors}>
        <div className='errorMessage'>
          Проблемы с интернет соединением.
          <br /> Пытаемся переподключиться. Если ошибка остается - свяжитесь с администратором.
        </div>
      </Offline>
    </div>
  );
};
