import './InputSelect.scss';

import { AnimatePresence, motion } from 'framer-motion';

import { FormikErrors } from 'formik';
import InputError from '../InputError';
import Select from 'react-select';
import { useState } from 'react';

interface Props {
  name: string;
  options: Array<any>;
  defaultValue?: any;
  value: any;
  placeholder?: string;
  placeholderType?: 'alwaysVisible' | 'partlyVisible';
  isValid?: boolean;
  validationError?: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined;
  onChange?: any;
  onBlur?: Function;
  size?: 'small' | 'medium';
  refProp?: any;
}

export const InputSelect: React.FC<Props> = ({
  name,
  onChange,
  onBlur,
  options,
  value,
  defaultValue,
  isValid,
  validationError,
  placeholder,
  placeholderType,
  size,
  refProp,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const variants = {
    placeholderDown: { y: 0 },
    placeholderUp: { y: size === 'small' ? '-25rem' : '-30rem' },
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: 'none',
      height: size === 'small' ? '29rem' : '39rem',
      alignItems: 'flexStart',
      border: 'none',
      borderBottom: isValid === true || isValid === undefined ? '1px solid #00AEEF !important' : '1px solid rgba(239,0,74,1.0) !important',
      borderRadius: '0',
      boxShadow: state.isFocused ? 0 : 0,
      paddingBottom: '8rem',
    }),
    valueContainer: provided => ({
      ...provided,
      position: 'relative',
      zIndex: 10,
      height: size === 'small' ? '23rem' : '33rem',
      paddingLeft: '0',
      paddingTop: '0',
      paddingBottom: '0',
      cursor: 'pointer',
    }),
    input: provided => ({
      ...provided,
      fontSize: size === 'small' ? '16rem' : '24rem',
      lineHeight: size === 'small' ? '18rem' : '28rem',
      margin: '0',
    }),
    placeholder: provided => ({
      ...provided,
      fontSize: '14rem',
      lineHeight: '16rem',
      top: size === 'small' ? '25rem' : '20rem',
      marginLeft: '0',
      marginRight: '0',
    }),
    singleValue: provided => ({
      ...provided,
      fontSize: size === 'small' ? '16rem' : '24rem',
      lineHeight: size === 'small' ? '18rem' : '28rem',
      top: size === 'small' ? '12rem' : '15rem',
      marginLeft: '0',
    }),
    menuList: provided => ({
      ...provided,
      fontSize: '14rem',
      lineHeight: '16rem',
    }),
    menuPortal: provided => ({
      ...provided,
      zIndex: 20,
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none',
    }),
    dropdownIndicator: provided => ({
      ...provided,
      padding: '0',
    }),
  };

  return (
    <div className={`inputSelect ${size}`}>
      {placeholderType === 'alwaysVisible' && (
        <motion.div
          className='placeholder'
          animate={isFocused || value?.toString() ? 'placeholderUp' : 'placeholderDown'}
          variants={variants}
          transition={{ type: 'spring', velocity: 2 }}
        >
          {placeholder}
        </motion.div>
      )}
      <Select
        name={name}
        options={options}
        onChange={onChange}
        onBlur={() => {
          onBlur && onBlur();
          setIsFocused(false);
        }}
        onFocus={() => setIsFocused(true)}
        styles={customStyles}
        defaultValue={defaultValue}
        placeholder={placeholderType === 'partlyVisible' ? placeholder : ''}
        ref={refProp}
      />

      <AnimatePresence>
        {isValid !== undefined && (
          <motion.div initial={{ opacity: 0, y: -25 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -25 }} transition={{ duration: 0.2 }}>
            <InputError>{validationError}</InputError>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
