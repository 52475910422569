import __, { isNil } from 'lodash';
import { backendURL, backendURL_PDF } from '../App';

import axios from 'axios';
import { useQuery } from 'react-query';

const getReportPDFData = async (_, type, id, streamID, webRender) => {
  const { data } = await axios({
    method: 'get',
    // url: `${backendURL_PDF}reports/data/`,
    url: `${backendURL}reports/data/`,
    params: {
      type,
      id,
      f: 'new',
      streams: true,
      stream_id: __.isNil(streamID) ? '' : streamID,
    },
  });
  return data;
};

export default function useReportPDFData(type, id, streamID, webRender) {
  return useQuery(['reportPDFData', type, id, streamID, webRender], getReportPDFData, { retry: false });
}
