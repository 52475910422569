import './AssignInterviewModal.scss';

import { Dispatch, SetStateAction, useState } from 'react';

import Button from '../../../components/Button';
import Input from '../../../components/Input';
import Modal from '../../../components/Modal';
import Title from '../../../components/Title';
import { format } from 'date-fns';
import { queryCache } from 'react-query';
import useAssignInterviewMutation from '../../../hooks/useAssignInterviewMutation';
import useClientAnketaData from '../../../hooks/useClientAnketaData';
import { useParams } from 'react-router-dom';
import { utcToZonedTime } from 'date-fns-tz/esm';
import InputSelect from "../../../components/InputSelect";

interface Props {
  onClose: () => void;
  setIsInterviewAssignedModal: () => void;
  interviewTime: Date;
  setSelectedDateTime: Dispatch<SetStateAction<Date | undefined>>;
  setIsChangingDateTime: Dispatch<SetStateAction<boolean>>;
}

export const AssignInterviewModal: React.FC<Props> = ({ onClose, setIsInterviewAssignedModal, interviewTime, setSelectedDateTime, setIsChangingDateTime }) => {
  const { anketaID } = useParams<{ anketaID: string }>();
  const { data: anketaData } = useClientAnketaData(anketaID);

  const [skypeFieldValue, setSkypeFieldValue] = useState('');
  const [skypeFieldError, setSkypeFieldError] = useState('');
  const [selectedVC, setSelectedVC] = useState('');


  const [mutate, { status: interviewAssignMutationStatus }] = useAssignInterviewMutation({
    onSuccess: async () => {
      await queryCache.invalidateQueries('clientAnketaData');
      await queryCache.invalidateQueries('timeTableData');
      await setSelectedDateTime(undefined);
      setIsChangingDateTime(false);
      onClose();
      setIsInterviewAssignedModal();
    },
    onError: () => {
      setSkypeFieldError('Ошибка. Что-то пошло не так.( ');
      setTimeout(() => {
        setSkypeFieldError('');
      }, 3000);
    },
  });

  const onClick = target => {
    if (!skypeFieldValue && selectedVC == 'skype') {
      setSkypeFieldError('Укажите ваш ник Skype или выберите другой способ связи');
      setTimeout(() => {
        setSkypeFieldError('');
      }, 3000);
    } else {
      mutate({
        anketaAnswerID: anketaData?.anketa_answer_id,
        call_start: format(utcToZonedTime(new Date(interviewTime), 'Europe/Moscow'), 'yyyy-MM-dd HH:mm'),
        call_type: selectedVC,
        skype: target.classList.contains('zoomButton') ? undefined : skypeFieldValue,
      });
    }
  };

  let VCOptions=[
    { label: '', value: '' },
    { label: 'Dion', value: 'dion' },
    { label: 'Zoom', value: 'zoom' },
    { label: 'Skype', value: 'skype' },
  ];

  return (
    <Modal className='assignInterviewModal' onClose={onClose}>
      <Title>Укажите способ связи для интервью</Title>
      <div className='vcFieldWrapper'>
        <InputSelect
            name='vc'
            // refProp={streamsSelectRef}
            options={VCOptions}
            value=''
            placeholderType='alwaysVisible'
            placeholder='Способ связи'
            onChange={option => setSelectedVC(option.value)}
            onBlur={() => null}
            isValid={true}
            validationError={undefined}
        />
      </div>
      {selectedVC === 'skype' && (
        <div className='skypeFieldWrapper'>
          <Input
            name='skype'
            type='text'
            value={skypeFieldValue}
            onChange={event => setSkypeFieldValue(event.target.value)}
            placeholderType='partlyVisible'
            placeholder='Ваш никнейм в Skype'
            isValid={!skypeFieldError}
            validationError={skypeFieldError}
            autoComplete='off'
            size='small'
            spellcheck={false}
          />
        </div>
      )}
      {/*<div className='separationText'>или</div>*/}
      {/*<div className='zoomFieldWrapper'>*/}
      {/*  <button type='button' className='zoomButton link' onClick={event => onClick(event.target)}>*/}
      {/*    Сгенерировать ссылку на встречу в Zoom*/}
      {/*  </button>*/}
      {/*</div>*/}
      <Button
        type='button'
        onClick={event => onClick(event.target)}
        width='230rem'
        size='small'
        color='black'
        text='Готово'
        loading={interviewAssignMutationStatus === 'loading'}
      />
    </Modal>
  );
};
