import './Competension.scss';

import * as Yup from 'yup';

import { Dispatch, SetStateAction } from 'react';
import { FieldArray, Form, Formik } from 'formik';
import { backendURL, debug } from '../../../../App';

import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import InputSelect from '../../../../components/InputSelect';
import SubTitle from '../../SubTitle';
import TextareaWYSIWYG from '../../../../components/TextareaWYSIWYG';
import axios from 'axios';
import consoleLog from '../../../../helpers/consoleLog';
import icon_closeBlue from '../../../../assets/images/icon_closeBlue.svg';
import icon_plus from '../../../../assets/images/icon_plus.svg';
import { queryCache } from 'react-query';
import useDeleteCompetentionMutation from '../../../../hooks/useDeleteCompetensionMutation';
import { useParams } from 'react-router-dom';

interface Props {
  competensionData?: {
    anketa_id: number;
    description: string;
    id: number;
    rate_model: number;
    recomendation_bad: string; // * HTML as string, DEPRECATED
    recomendation_bad_article: string;
    recomendation_bad_book: string;
    recomendation_bad_video: string;
    recomendation_bad_podcasts: string;
    recomendation_bad_courses: string;
    recomendation_good: string; // * HTML as string, DEPRECATED
    recomendation_good_article: string;
    recomendation_good_book: string;
    recomendation_good_video: string;
    recomendation_good_podcasts: string;
    recomendation_good_courses: string;
    title: string;
    indicators: any;
  };
  isAddCompenetsionButton?: boolean;
  addCompenetsion: Dispatch<SetStateAction<boolean>>;
}

export const Competension: React.FC<Props> = ({ competensionData, isAddCompenetsionButton, addCompenetsion }) => {
  const { anketaID } = useParams<{ anketaID: string }>();

  const [mutate] = useDeleteCompetentionMutation({
    onSuccess: () => {
      queryCache.invalidateQueries(['competensionsData', anketaID]);
    },
  });

  const rateModelOptions = [
    { value: 0, label: '0' },
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
  ];

  return (
    <div className='compentesion'>
      <Formik
        initialValues={{
          title: competensionData?.title || '',
          rate_model: competensionData?.rate_model !== undefined ? competensionData?.rate_model : '',
          description: competensionData?.description || '',
          recomendation_good: competensionData?.recomendation_good || '',
          recomendation_good_article: competensionData?.recomendation_good_article || '',
          recomendation_good_book: competensionData?.recomendation_good_book || '',
          recomendation_good_video: competensionData?.recomendation_good_video || '',
          recomendation_good_podcasts: competensionData?.recomendation_good_podcasts || '',
          recomendation_good_courses: competensionData?.recomendation_good_courses || '',
          recomendation_bad: competensionData?.recomendation_bad || '',
          recomendation_bad_article: competensionData?.recomendation_bad_article || '',
          recomendation_bad_book: competensionData?.recomendation_bad_book || '',
          recomendation_bad_video: competensionData?.recomendation_bad_video || '',
          recomendation_bad_podcasts: competensionData?.recomendation_bad_podcasts || '',
          recomendation_bad_courses: competensionData?.recomendation_bad_courses || '',
          indicators: competensionData?.indicators?.length > 0 ? competensionData?.indicators : [{ id: '', title: '', description: '' }],
        }}
        validationSchema={Yup.object({
          title: Yup.string().required('поле должно быть заполнено'),
          rate_model: Yup.string().required('нужно выбрать уровень'),
        })}
        onSubmit={async (values, { setSubmitting, setStatus }) => {
          let clientFormData = new FormData();
          Object.entries(values).forEach(([key, value]) => {
            if (key === 'indicators') {
              clientFormData.append(key, JSON.stringify(value));
            } else {
              // @ts-ignore
              clientFormData.append(key, value);
            }
          });
          clientFormData.append('anketa_id', anketaID);

          try {
            const { data } = await axios({
              method: 'post',
              url: competensionData?.id ? `${backendURL}competention/${competensionData?.id}/` : `${backendURL}competentions/`,
              headers: {
                Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
              },
              data: clientFormData,
            });
            setSubmitting(false);
            setStatus({ success: true });
            addCompenetsion ? addCompenetsion(false) : console.log();
            queryCache.invalidateQueries(['competensionsData', anketaID]);
            debug && consoleLog('success', 'Comptension save', data);
          } catch (error) {
            setSubmitting(false);
            debug && consoleLog('error', 'Comptension save', error);
          }
        }}
      >
        {props => (
          <Form>
            <div className='competensionFieldsWrapper'>
              <SubTitle>Компетенция</SubTitle>
              <Input
                name='title'
                type='text'
                value={props.values.title}
                onChange={event => props.setFieldValue('title', event.target.value)}
                onBlur={() => props.setFieldTouched('title', true)}
                placeholderType='alwaysVisible'
                placeholder='Наименование компетенции'
                isDisabled={props.isSubmitting === true ? true : false}
                isValid={!(props.errors.title && props.touched.title)}
                validationError={props.errors.title && props.touched.title ? props.errors.title : undefined}
                autoComplete='off'
              />
              <InputSelect
                name='rate_model'
                options={rateModelOptions}
                defaultValue={rateModelOptions.find(item => item.value === props.values.rate_model)}
                value={props.values.rate_model}
                placeholderType='alwaysVisible'
                placeholder='Требуемый уровень компетенции'
                onChange={option => props.setFieldValue('rate_model', option.value)}
                onBlur={() => props.setFieldTouched('rate_model', true)}
                isValid={!(props.errors.rate_model && props.touched.rate_model)}
                validationError={props.errors.rate_model && props.touched.rate_model ? props.errors.rate_model : undefined}
              />
              <TextareaWYSIWYG
                value={props.values.description}
                onChange={value => props.setFieldValue('description', value.replace(/(<([^>]+)>)/gi, '') ? value : '')}
                onBlur={() => props.setFieldTouched('description', true)}
                placeholderType='alwaysVisible'
                placeholder='Расшифровка компетенции'
                modulesType='noModules'
              />
            </div>

            <div className='reccomendationsFieldsWrapper'>
              <SubTitle>Рекомендации</SubTitle>
              {/* // * Положительные реомендации  */}
              <TextareaWYSIWYG
                value={props.values.recomendation_good}
                onChange={value => props.setFieldValue('recomendation_good', value.replace(/(<([^>]+)>)/gi, '') ? value : '')}
                onBlur={() => props.setFieldTouched('recomendation_good', true)}
                placeholderType='alwaysVisible'
                placeholder='Положительная рекомендация для участника | общая'
                modulesType='defaultWithLink'
              />
              <TextareaWYSIWYG
                value={props.values.recomendation_good_article}
                onChange={value => props.setFieldValue('recomendation_good_article', value.replace(/(<([^>]+)>)/gi, '') ? value : '')}
                onBlur={() => props.setFieldTouched('recomendation_good_article', true)}
                placeholderType='alwaysVisible'
                placeholder='Положительная рекомендация для участника | статьи'
                modulesType='defaultWithLink'
              />
              <TextareaWYSIWYG
                value={props.values.recomendation_good_book}
                onChange={value => props.setFieldValue('recomendation_good_book', value.replace(/(<([^>]+)>)/gi, '') ? value : '')}
                onBlur={() => props.setFieldTouched('recomendation_good_book', true)}
                placeholderType='alwaysVisible'
                placeholder='Положительная рекомендация для участника | книги'
                modulesType='defaultWithLink'
              />
              <TextareaWYSIWYG
                value={props.values.recomendation_good_video}
                onChange={value => props.setFieldValue('recomendation_good_video', value.replace(/(<([^>]+)>)/gi, '') ? value : '')}
                onBlur={() => props.setFieldTouched('recomendation_good_video', true)}
                placeholderType='alwaysVisible'
                placeholder='Положительная рекомендация для участника | видео'
                modulesType='defaultWithLink'
              />
              <TextareaWYSIWYG
                value={props.values.recomendation_good_courses}
                onChange={value => props.setFieldValue('recomendation_good_courses', value.replace(/(<([^>]+)>)/gi, '') ? value : '')}
                onBlur={() => props.setFieldTouched('recomendation_good_courses', true)}
                placeholderType='alwaysVisible'
                placeholder='Положительная рекомендация для участника | курсы'
                modulesType='defaultWithLink'
              />
              <TextareaWYSIWYG
                value={props.values.recomendation_good_podcasts}
                onChange={value => props.setFieldValue('recomendation_good_podcasts', value.replace(/(<([^>]+)>)/gi, '') ? value : '')}
                onBlur={() => props.setFieldTouched('recomendation_good_podcasts', true)}
                placeholderType='alwaysVisible'
                placeholder='Положительная рекомендация для участника | подкасты'
                modulesType='defaultWithLink'
              />
              {/* // * Отрицательные реомендации  */}
              <TextareaWYSIWYG
                value={props.values.recomendation_bad}
                onChange={value => props.setFieldValue('recomendation_bad', value.replace(/(<([^>]+)>)/gi, '') ? value : '')}
                onBlur={() => props.setFieldTouched('recomendation_bad', true)}
                placeholderType='alwaysVisible'
                placeholder='Отрицательная рекомендация для участника | общая'
                modulesType='defaultWithLink'
              />
              <TextareaWYSIWYG
                value={props.values.recomendation_bad_article}
                onChange={value => props.setFieldValue('recomendation_bad_article', value.replace(/(<([^>]+)>)/gi, '') ? value : '')}
                onBlur={() => props.setFieldTouched('recomendation_bad_article', true)}
                placeholderType='alwaysVisible'
                placeholder='Отрицательная рекомендация для участника | статьи'
                modulesType='defaultWithLink'
              />
              <TextareaWYSIWYG
                value={props.values.recomendation_bad_book}
                onChange={value => props.setFieldValue('recomendation_bad_book', value.replace(/(<([^>]+)>)/gi, '') ? value : '')}
                onBlur={() => props.setFieldTouched('recomendation_bad_book', true)}
                placeholderType='alwaysVisible'
                placeholder='Отрицательная рекомендация для участника | книги'
                modulesType='defaultWithLink'
              />
              <TextareaWYSIWYG
                value={props.values.recomendation_bad_video}
                onChange={value => props.setFieldValue('recomendation_bad_video', value.replace(/(<([^>]+)>)/gi, '') ? value : '')}
                onBlur={() => props.setFieldTouched('recomendation_bad_video', true)}
                placeholderType='alwaysVisible'
                placeholder='Отрицательная рекомендация для участника | видео'
                modulesType='defaultWithLink'
              />
              <TextareaWYSIWYG
                value={props.values.recomendation_bad_courses}
                onChange={value => props.setFieldValue('recomendation_bad_courses', value.replace(/(<([^>]+)>)/gi, '') ? value : '')}
                onBlur={() => props.setFieldTouched('recomendation_bad_courses', true)}
                placeholderType='alwaysVisible'
                placeholder='Отрицательная рекомендация для участника | курсы'
                modulesType='defaultWithLink'
              />
              <TextareaWYSIWYG
                value={props.values.recomendation_bad_podcasts}
                onChange={value => props.setFieldValue('recomendation_bad_podcasts', value.replace(/(<([^>]+)>)/gi, '') ? value : '')}
                onBlur={() => props.setFieldTouched('recomendation_bad_podcasts', true)}
                placeholderType='alwaysVisible'
                placeholder='Отрицательная рекомендация для участника | подкасты'
                modulesType='defaultWithLink'
              />
            </div>

            <div className='indicatorsFieldsWrapper'>
              <SubTitle>Индикаторы</SubTitle>
              <FieldArray name='indicators'>
                {({ push, remove }) =>
                  props.values.indicators.map((_, index) => (
                    <div className='indicator'>
                      <div className='indicatorWrapper'>
                        <Input
                          name={`indicators[${index}].title`}
                          type='text'
                          value={props.values.indicators[index].title}
                          onChange={event => props.setFieldValue(`indicators[${index}].title`, event.target.value)}
                          onBlur={() => props.setFieldTouched(`indicators[${index}].title`, true)}
                          placeholderType='alwaysVisible'
                          placeholder='Название индикатора'
                          isDisabled={props.isSubmitting}
                          // @ts-ignore
                          isValid={!(props.errors?.indicators?.[index]?.title && props.touched?.indicators?.[index]?.title)}
                          // @ts-ignore
                          validationError={
                            props.errors?.indicators?.[index]?.title && props.touched?.indicators?.[index]?.title ? props.errors?.indicators?.[index]?.title : undefined
                          }
                          size='small'
                          autoComplete='off'
                        />
                        <TextareaWYSIWYG
                          value={props.values.indicators[index].description || ''}
                          onChange={value => props.setFieldValue(`indicators[${index}].description`, value.replace(/(<([^>]+)>)/gi, '') ? value : '')}
                          onBlur={() => props.setFieldTouched(`indicators[${index}].description`, true)}
                          placeholderType='alwaysVisible'
                          placeholder='Текст индикатора'
                          modulesType='noModules'
                          size='small'
                        />
                      </div>
                      <div className='addRemoveButtons'>
                        {!(props.values.indicators.length === 1 && index === 0) && (
                          <Button type='button' buttonType='button' width='50rem' size='medium' color='white' onClick={() => remove(index)} className='whiteButton'>
                            <img src={icon_closeBlue} alt='icon' className='iconRemove' />
                          </Button>
                        )}
                        {index + 1 === props.values.indicators.length && (
                          <Button type='button' buttonType='button' width='50rem' size='medium' color='blue' onClick={() => push({ title: '', key: '' })}>
                            <img src={icon_plus} alt='icon' className='iconAdd' />
                          </Button>
                        )}
                      </div>
                    </div>
                  ))
                }
              </FieldArray>
            </div>

            <Button
              type='button'
              width='330rem'
              size='medium'
              color='blue'
              text='Сохранить компетенцию'
              loading={props.isSubmitting === true ? true : false}
              className='submitButton'
            />
          </Form>
        )}
      </Formik>

      <div className='addRemoveButtons'>
        <Button
          type='button'
          size='medium'
          width='50rem'
          color='white'
          className='removeComptensionButton'
          onClick={() =>
            mutate({
              competentionID: competensionData!.id,
            })
          }
        />

        {isAddCompenetsionButton && (
          <Button type='button' onClick={() => addCompenetsion(true)} size='medium' width='50rem' color='bliue' className='addComptensionButton'></Button>
        )}
      </div>
    </div>
  );
};
