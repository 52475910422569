import './EmailConfirm.scss';

import { useHistory, useParams } from 'react-router-dom';

import { Button } from '../../components/Button/Button';
import { InputError } from '../../components/InputError/InputError';
import { Modal } from '../../components/Modal/Modal';
import { Title } from '../../components/Title/Title';
import axios from 'axios';
import { backendURL } from '../../App';
import consoleLog from '../../helpers/consoleLog';
import { queryCache } from 'react-query';
import { useState } from 'react';
import useUserData from '../../hooks/useUserData';

export const EmailConfirm: React.FC<{}> = () => {
  const history = useHistory();
  const { key } = useParams<{ key: string }>();
  const [emailVerifiedError, setEmailVerifiedError] = useState('');
  const { data: userData } = useUserData();

  const onConfirm = () => {
    axios({
      method: 'post',
      url: `${backendURL}auth/email-verification/`,
      data: {
        key,
      },
    })
      .then(result => {
        queryCache.invalidateQueries('userData');
        if (localStorage.getItem('skillsSurveyUserType')) {
          userData.user.is_member && history.push('/client/anketas/');
          userData.user.is_expert && history.push('/expert/schedule/');
        } else {
          history.push('/');
        }
        consoleLog('success', 'Email verified', result);
      })
      .catch(error => {
        history.push('/500/');
        consoleLog('error', 'Email verified', error);
      });
  };

  const onUnderstand = () => {
    axios({
      method: 'get',
      url: `${backendURL}user/${localStorage.getItem('skillsSurveyUserID')}/`,
      headers: {
        Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
      },
    })
      .then(result => {
        if (result.data.user.email_verified) {
          queryCache.setQueryData('userData', result.data);
          result.data.user.is_member && history.push('/client/anketas/');
          result.data.user.is_expert && history.push('/expert/schedule/');
          consoleLog('success', 'Email verified', result);
        } else {
          setEmailVerifiedError('Email не верифицирован.\n Ссылка для верификации отправлена на указанный email.');
          setTimeout(() => {
            setEmailVerifiedError('');
          }, 4000);
        }
      })
      .catch(error => {
        history.push('/500/');
        consoleLog('error', 'Email verified', error);
      });
  };

  return (
    <main className='emailConfirm'>
      {!key ? (
        <Modal className='emailConfirmModal'>
          <Title>Подтвердите e-mail</Title>
          <div className='text'>
            Ссылка для подтверждения отправлена <br /> на указанный e-mail. Чтобы завершить <br /> регистрацию, перейдите по этой ссылке.
          </div>
          <InputError>
            <span>{emailVerifiedError}</span>
          </InputError>
          <Button onClick={() => onUnderstand()} type='button' width='230rem' size='small' color='black' text='Понятно' className='emailConfirmedButton' />
        </Modal>
      ) : (
        <Modal className='emailConfirmModal noText'>
          <Title>Ваш e-mail подтвержден</Title>
          <Button onClick={() => onConfirm()} type='button' width='230rem' size='small' color='black' text='Продолжить' className='emailConfirmedButton' />
        </Modal>
      )}
    </main>
  );
};
