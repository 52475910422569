import './AdminClientTemplates.scss';

import { Link, useParams } from 'react-router-dom';

import AnimatedRedirect from '../../components/AnimatedRedirect';
import Button from '../../components/Button';
import PageLoader from '../../components/PageLoader';
import TemplateQuestionsTable from './TemplateQuestionsTable';
import Title from '../../components/Title';
import api_getAnketasData from '../../hooks/api_getAnketasData';
import icon_plus from '../../assets/images/icon_plus.svg';
import { motion } from 'framer-motion';
import { standardPageVariants } from '../../animationsVariants/StandardPageVariants';
import useAnketaQuetionsData from '../../hooks/useAnketaQuetionsData';
import { useQuery } from 'react-query';
import { useState } from 'react';

export const AdminClientTemplates: React.FC<{}> = () => {
  const { clientID, streamID } = useParams<{ clientID: string; streamID: string }>();
  const [isQuestionsTable, setIsQuestionsTable] = useState<number | undefined>(undefined);
  const { data: anketasData, status: anketasDataLoadingStatus } = useQuery(['anketasData', clientID, streamID], api_getAnketasData);
  const { data: anketaQuestionsData, status: anketaQuestionsDataLoadingStatus } = useAnketaQuetionsData(isQuestionsTable);

  const createDataForTable = () => {
    const data = anketaQuestionsData.map((question, index) => ({
      title: question.text,
      competentions: question.competentions.map(competention => competention.title),
      indicators: question.indicators.map(indicator => indicator.title),
      questionIndex: index,
      questionID: question.id,
      anketaID: question.anketa_id,
    }));
    return data;
  };

  if (anketasDataLoadingStatus === 'loading' || anketaQuestionsDataLoadingStatus === 'loading') {
    return <PageLoader />;
  }

  if (anketasDataLoadingStatus === 'error' || anketaQuestionsDataLoadingStatus === 'error') {
    return <AnimatedRedirect redirectTo='/500/' />;
  }

  return (
    <motion.main className='adminClientTemplates' variants={standardPageVariants} initial='initial' animate='animate' exit='exit'>
      <div className='buttonsContainer'>
        <Button type='link' href={`/admin/${clientID}/templates/copy/`} size='small' width='360rem' color='blue' className='addCopiedAnketaButton'>
          <img src={icon_plus} alt='icon' />
          <span>Добавить новую анкету из существующих</span>
        </Button>
        <Button type='link' href={`/admin/${clientID}/templates/new/`} size='small' width='230rem' color='blue' className='addNewAnketaButton'>
          <img src={icon_plus} alt='icon' />
          <span>Добавить новую анкету</span>
        </Button>
      </div>

      {anketasData.map(anketa => (
        <div className='anketaItem' key={anketa.id}>
          <div className='anketaItemHeader'>
            <Title>{anketa.title}</Title>
            <Link to={`/admin/${clientID}/templates/${anketa.id}/recommendations/`} className='recomendationsButton link'>
              Общие рекомендации к анкете
            </Link>
            <Link to={`/admin/${clientID}/templates/${anketa.id}/case/`} className='caseButton link'>
              Описание кейса
            </Link>
            <Button
              type='button'
              size='small'
              width='30rem'
              color='blue'
              className={`epxandAnketaButton ${isQuestionsTable === anketa.id ? 'expanded' : ''}`}
              onClick={() => (isQuestionsTable === anketa.id ? setIsQuestionsTable(undefined) : setIsQuestionsTable(anketa.id))}
            />
          </div>

          {isQuestionsTable === anketa.id && (
            <div className='anketaItemBody'>
              <Button
                type='link'
                href={`/admin/${clientID}/templates/${anketa.id}/competentions/`}
                size='small'
                width='410rem'
                color='blue'
                text='Редактировать список компетенций и индикаторов'
              />
              <Button type='button' disabled={true} size='small' width='280rem' color='black' text='Редактировать номера вопросов' />
              <Button type='link' href={`/admin/${clientID}/templates/${anketa.id}/questions/`} size='small' width='230rem' color='black' className='addQuestionButton'>
                <img src={icon_plus} />
                <span>Добавить вопрос</span>
              </Button>

              <TemplateQuestionsTable filteredData={createDataForTable()} />
            </div>
          )}
        </div>
      ))}
    </motion.main>
  );
};
