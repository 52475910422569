import './ExpertSchedule.scss';

import { endOfWeek, format, set, startOfWeek } from 'date-fns';

import AnimatedRedirect from '../../components/AnimatedRedirect';
import { AnketsDashboard } from '../../components/AnketsDashboard/AnketsDashboard';
import { ExpertTelegramModal } from '../../components/ExpertTelegramModal/ExpertTelegramModal';
import { Link } from 'react-router-dom';
import PageLoader from '../../components/PageLoader';
import { ScheduleTable } from '../../components/ScheduleTable/ScheduleTableExpert';
import { ScheduleTabs } from '../../components/ScheduleTabs/ScheduleTabsExpert';
import Title from '../../components/Title/';
import { motion } from 'framer-motion';
import { standardPageVariants } from '../../animationsVariants/StandardPageVariants';
import useAnketasAnswersData from '../../hooks/useAnketasAnswersData';
import { useState } from 'react';
import useTimeTableData from '../../hooks/useTimeTableData';
import useUserData from '../../hooks/useUserData';

export const ExpertSchedule: React.FC<{}> = () => {
  // * Фиксируем текущую неделю
  const currentDate = set(new Date(), { hours: 8, minutes: 0, seconds: 0 });
  const currentWeekStart = set(startOfWeek(currentDate, { weekStartsOn: 1 }), { hours: 8, minutes: 0, seconds: 0 });
  const currentWeekEnd = set(endOfWeek(currentDate, { weekStartsOn: 1 }), { hours: 21, minutes: 0, seconds: 0 });
  // * Обрабтаываем action-ы юзера по изменению/выбору расписания и интервью
  const [selectedWeekStart, setSelectedWeekStart] = useState<Date>(currentWeekStart);
  const [selectedWeekEnd, setSelectedWeekEnd] = useState<Date>(currentWeekEnd);
  // * Данные
  const { data: anketasAnswersData, status: anketasAnswersDataLoadingStatus } = useAnketasAnswersData() as any;
  const { data: timeTableData, status: timeTableLoadingStatus } = useTimeTableData(
    format(selectedWeekStart, 'yyyy-MM-dd HH:mm'),
    format(selectedWeekEnd, 'yyyy-MM-dd HH:mm')
  );
  const [isExpertTelegramModal, setIsExpertTelegramModal] = useState<true | false | undefined>(undefined);
  const { data: userData, status: userDataLoadingStatus } = useUserData();

  if (anketasAnswersDataLoadingStatus === 'loading' || timeTableLoadingStatus === 'loading' || userDataLoadingStatus === 'loading') {
    return <PageLoader />;
  }

  if (anketasAnswersDataLoadingStatus === 'error' || timeTableLoadingStatus === 'error' || userDataLoadingStatus === 'error') {
    return <AnimatedRedirect redirectTo='/500/' />;
  }

  return (
    <motion.main className='expertSchedule' variants={standardPageVariants} initial='initial' animate='animate' exit='exit'>
      <AnketsDashboard anketaAnswersData={anketasAnswersData} />
      <Title>Расписание</Title>
      <Link to='/expert/respondents/' className='subTitleLink'>
        Cписок участников
      </Link>
      <ScheduleTabs
        currentWeekStart={currentWeekStart}
        currentWeekEnd={currentWeekEnd}
        selectedWeekStart={selectedWeekStart}
        setSelectedWeekStart={setSelectedWeekStart}
        selectedWeekEnd={selectedWeekEnd}
        setSelectedWeekEnd={setSelectedWeekEnd}
      />
      <ScheduleTable timeTableData={timeTableData} selectedWeekStart={selectedWeekStart} selectedWeekEnd={selectedWeekEnd} />
      {console.log(userData)}
      {userData.user.tm_chat_id === '' && isExpertTelegramModal === undefined && (
        <ExpertTelegramModal user={userData.user} onClose={() => setIsExpertTelegramModal(true)} />
      )}
    </motion.main>
  );
};
