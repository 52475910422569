export const headerVariants = {
  initial: {
    y: '-100vh',
  },
  animate: {
    y: 0,

    transition: {
      duration: '1',
      ease: 'easeOut'
    }
  },
  exit: {
    y: '-100vh',

    transition: {
      duration: '1',
      ease: 'easeIn'
    }
  },
}