export const standardPageVariants = {
  initial: {
    opacity: 0,
    ease: 'ease-out',
  },
  animate: {
    opacity: 1,
    ease: 'ease-out',

    transition: {
      duration: 0.2,
    },
  },
  exit: {
    opacity: 1,
    ease: 'ease-in',

    transition: {
      duration: 0.2,
    },
  },
};
