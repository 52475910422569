import axios from 'axios';
import { backendURL } from '../App';
import { useQuery } from 'react-query';

const getClientData = async (_, clientID, token, streamID) => {
  if (clientID === null) {
    return;
  }

  console.log('streamID', streamID);

  if (token) {
    const { data } = await axios({
      method: 'get',
      url: `${backendURL}client/${clientID}/`,
      headers: {
        Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
      },
    });
    return data;
  }
  const { data } = await axios({
    method: 'get',
    url: `${backendURL}client/${clientID}/`,
    headers: {
      Authorization: '',
    },
    params: {
      stream_id: streamID,
    },
  });
  return data;
};

export default function useClientData(clientID, token, streamID) {
  return useQuery(['clientData', clientID, token, streamID], getClientData, { retry: false, refetchOnWindowFocus: false });
}
