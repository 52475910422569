import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import App, {sentryDSN, sentryEnvironment} from './App';
import {Route, Router, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import * as Sentry from "@sentry/react";
import * as serviceWorker from './serviceWorker';


console.log('Sentry Environment: ' + sentryEnvironment + ' , dsn: ' + sentryDSN);

if (sentryDSN !== undefined) {
    const SentryRoute = Sentry.withSentryRouting(Route);
    const history = createBrowserHistory();
    Sentry.init({
        dsn: sentryDSN,
        integrations: [
            Sentry.reactRouterV5BrowserTracingIntegration({history}),
        ],
        tracesSampleRate: 1.0,
        environment: sentryEnvironment,
        // tracePropagationTargets: [
        //     /^\//,
        //     /^http:\/\/127\.0\.0\.1:3000\/api/,
        //     /^https:\/\/survey\.dev\.skills\.iidf\.ru\/api/,
        //     /^https:\/\/survey\.skills\.iidf\.ru\/api/,
        //     /^https:\/\/survey\.product-cpt\.ru\/api/,
        // ],
    });
}

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
