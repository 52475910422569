import { useState } from 'react';

import axios from 'axios';
import * as Yup from 'yup';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import { queryCache } from 'react-query';
import { useParams, useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import { formVariants } from '../../animationsVariants/FormVariants';
import { backendURL } from '../../App';
import consoleLog from '../../helpers/consoleLog';
import { InputError } from '../../components/InputError/InputError';
import { Button } from '../../components/Button/Button';

export const RegistrationExpert: React.FC<{}> = () => {
  const [errorOnSubmit, setErrorOnSubmit] = useState('');
  const { key } = useParams<{ key: string }>();
  const history = useHistory();

  return (
    <Formik
      initialValues={{ key, member_type: 'expert', first_name: '', last_name: '', email: '', password: '' }}
      validationSchema={Yup.object({
        first_name: Yup.string().max(15, 'Must be 15 characters or less').required('поле должно быть заполнено'),
        last_name: Yup.string().max(20, 'Must be 20 characters or less').required('поле должно быть заполнено'),
        email: Yup.string().email('некорректный email адрес').required('поле должно быть заполнено'),
        password: Yup.string().min(8, 'пароль должен содержать минимум 8 символов').required('поле должно быть заполнено'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        axios({
          method: 'post',
          url: `${backendURL}auth/registration/`,
          data: values,
        })
          .then(result => {
            setSubmitting(false);
            setErrorOnSubmit('');
            localStorage.setItem('skillsSurveyUserToken', result.data.token);
            localStorage.setItem('skillsSurveyUserID', result.data.user.id);
            localStorage.setItem('skillsSurveyUserType', 'is_expert');
            queryCache.invalidateQueries('userData');
            history.push('/email_confirm/');
          })
          .catch(error => {
            setSubmitting(false);
            error.response.data.error.email === 'Пользователь уже зарегистрирован'
              ? setErrorOnSubmit('Пользователь с таким email-ом уже зарегистрирован.')
              : setErrorOnSubmit('Ошибка. Свяжитесь с администратором.');
            setTimeout(() => {
              setErrorOnSubmit('');
            }, 3000);
            consoleLog('error', 'Expert registration', error);
          });
      }}
    >
      {props => (
        <main className='registration'>
          <motion.div className='expertRegistrationForm formContainer' variants={formVariants} initial='initial' animate='animate' exit='exit'>
            <Form>
              <h1>Регистрация на сервисе</h1>

              <Field name='first_name' type='text' placeholder='Ваше имя' className={`${props.errors.first_name && props.touched.first_name ? 'invalid' : ''}`} />
              <InputError>
                <ErrorMessage name='first_name' />
              </InputError>

              <Field name='last_name' type='text' placeholder='Ваша фамилия' className={`${props.errors.last_name && props.touched.last_name ? 'invalid' : ''}`} />
              <InputError>
                <ErrorMessage name='last_name' />
              </InputError>

              <Field name='email' type='email' placeholder='Email' className={`${props.errors.email && props.touched.email ? 'invalid' : ''}`} />
              <InputError>
                <ErrorMessage name='email' />
              </InputError>

              <Field name='password' type='password' placeholder='Ваш пароль' className={`${props.errors.password && props.touched.password ? 'invalid' : ''}`} />
              <InputError>
                <ErrorMessage name='password' />
              </InputError>

              <InputError className='submitError'>{errorOnSubmit}</InputError>
              <Button type='button' width='330rem' size='medium' color='blue' text='Зарегистрироваться' />
              <Button type='link' href='/' width='280rem' size='small' color='black' text='Войти на сервис' className='loginButtonLink' />
            </Form>
          </motion.div>
        </main>
      )}
    </Formik>
  );
};
