import './AdminClients.scss';


import { motion } from 'framer-motion';

import useClientsData from '../../hooks/useClientsData';
import { standardPageVariants } from '../../animationsVariants/StandardPageVariants';

import Title from '../../components/Title';
import AdminClientMetric from './AdminClientMetric';
import PageLoader from '../../components/PageLoader';
import AnimatedRedirect from '../../components/AnimatedRedirect';

export const AdminClients: React.FC<{}> = () => {
  const { data: clientsData, status: clientsDataLoadingStatus } = useClientsData();

  if (clientsDataLoadingStatus === 'loading') {
    return <PageLoader />;
  }

  if (clientsDataLoadingStatus === 'error') {
    return <AnimatedRedirect redirectTo='/500/' />;
  }

  return (
    <motion.main
      className={`adminClients` + `${clientsData.length === 3 ? ' threeClients' : ''}`}
      variants={standardPageVariants}
      initial='initial'
      animate='animate'
      exit='exit'
    >
      <Title>Общая аналитика по клиентам</Title>
      {clientsData.map(client => (
        <AdminClientMetric client={client} key={client.id} />
      ))}
    </motion.main>
  );
};
