import './AdminClientMetricsSimplified.scss';
import 'react-circular-progressbar/dist/styles.css';

import { Link, useParams } from 'react-router-dom';
import { CircularProgressbar } from 'react-circular-progressbar';
import _ from 'lodash';

import shadeRGBColor from '../../../helpers/colorDL';

import Title from '../../../components/Title/';

interface Props {
  clientData: {
    id: number;
    hex_color: string;
  };

  clientMetricsData: {
    common_stats: {
      reg_complete: number;
      anketa_complete: number;
      expert_complete: number;
      admin_complete: number;
      all: number;
    };
  };

  streamData?: {
    title: string;
  };
}

export const AdminClientMetricsSimplified: React.FC<Props> = ({ clientMetricsData, clientData, streamData }) => {
  const { clientID, streamID } = useParams<{ clientID: string; streamID: string }>();

  return (
    <div className='adminClientMetricsSimplified'>
      <Title>Метрики</Title>

      <Link
        data-cy='anketa-list'
        to={_.isNaN(Number(streamID)) ? `/admin/${clientID}/anketa_list/` : `/admin/${clientID}/${streamID}/anketa_list/`}
        className='subTitleLink'
      >
        Cписок анкет
      </Link>

      <div className='metrics'>
        {_.isNil(streamData) && (
          <Link to={`/admin/${clientID}/edit/`} className='subTitleLink'>
            Редактировать клиента
          </Link>
        )}

        <div className='metric'>
          {!_.isNil(streamData) ? <div className='subTitle withMargin'>{streamData!.title}</div> : <div className='subTitle'>Общая метрика по клиенту</div>}
          <div className='metricDescription'>
            <div className='descriptionTitle'>Участники</div>
            <div className='descriptionDataRow'>
              <div className='rowBullet' style={{ backgroundColor: `${shadeRGBColor(`rgb(${clientData.hex_color})`, -0.9)}` }}></div>
              <div className='rowDesription'>зарегистрировались</div>
              <div className='rowValue'>{clientMetricsData.common_stats.reg_complete}</div>
            </div>
            <div className='descriptionDataRow'>
              <div className='rowBullet' style={{ backgroundColor: `${shadeRGBColor(`rgb(${clientData.hex_color})`, -0.5)}` }}></div>
              <div className='rowDesription'>прошли анкетирование</div>
              <div className='rowValue'>{clientMetricsData.common_stats.anketa_complete}</div>
            </div>
            <div className='descriptionDataRow'>
              <div className='rowBullet' style={{ backgroundColor: `${shadeRGBColor(`rgb(${clientData.hex_color})`, -0.25)}` }}></div>
              <div className='rowDesription'>опрошены экспертом</div>
              <div className='rowValue'>{clientMetricsData.common_stats.expert_complete}</div>
            </div>
            <div className='descriptionDataRow'>
              <div className='rowBullet' style={{ backgroundColor: `rgb(${clientData.hex_color})` }}></div>
              <div className='rowDesription'>проверены администратором</div>
              <div className='rowValue'>{clientMetricsData.common_stats.admin_complete}</div>
            </div>
            <div className='descriptionDataRow'>
              <div className='rowBullet colorOne'></div>
              <div className='rowDesription'>общее число</div>
              <div className='rowValue'>{clientMetricsData.common_stats.all}</div>
            </div>
          </div>
          <div className='metricCircles'>
            <div className='circleContainer'>
              <CircularProgressbar
                value={
                  (clientMetricsData.common_stats.reg_complete / clientMetricsData.common_stats.all) * 100 === 0
                    ? 1
                    : (clientMetricsData.common_stats.reg_complete / clientMetricsData.common_stats.all) * 100
                }
                strokeWidth={3.35}
                styles={{
                  path: {
                    stroke: `${shadeRGBColor(`rgb(${clientData.hex_color})`, -0.9)}`,
                    strokeLinecap: 'butt',
                    transition: 'stroke-dashoffset 0.5s ease 0s',
                    transform: 'rotate(0.020turn)',
                    transformOrigin: 'center center',
                  },
                  trail: {
                    stroke: 'rgba(242, 242, 242, 1)',
                    strokeLinecap: 'butt',
                    transform: 'rotate(0.20turn)',
                    transformOrigin: 'center center',
                  },
                }}
              />
            </div>
            <div className='circleContainer'>
              <CircularProgressbar
                value={
                  (clientMetricsData.common_stats.anketa_complete / clientMetricsData.common_stats.all) * 100 === 0
                    ? 1
                    : (clientMetricsData.common_stats.anketa_complete / clientMetricsData.common_stats.all) * 100
                }
                strokeWidth={4.05}
                styles={{
                  path: {
                    stroke: `${shadeRGBColor(`rgb(${clientData.hex_color})`, -0.5)}`,
                    strokeLinecap: 'butt',
                    transition: 'stroke-dashoffset 0.5s ease 0s',
                    transform: 'rotate(0.020turn)',
                    transformOrigin: 'center center',
                  },
                  trail: {
                    stroke: 'rgba(242, 242, 242, 1)',
                    strokeLinecap: 'butt',
                    transform: 'rotate(0.20turn)',
                    transformOrigin: 'center center',
                  },
                }}
              />
            </div>
            <div className='circleContainer'>
              <CircularProgressbar
                value={
                  (clientMetricsData.common_stats.expert_complete / clientMetricsData.common_stats.all) * 100 === 0
                    ? 1
                    : (clientMetricsData.common_stats.expert_complete / clientMetricsData.common_stats.all) * 100
                }
                strokeWidth={4.75}
                styles={{
                  path: {
                    stroke: `${shadeRGBColor(`rgb(${clientData.hex_color})`, -0.25)}`,
                    strokeLinecap: 'butt',
                    transition: 'stroke-dashoffset 0.5s ease 0s',
                    transform: 'rotate(0.020turn)',
                    transformOrigin: 'center center',
                  },
                  trail: {
                    stroke: 'rgba(242, 242, 242, 1)',
                    strokeLinecap: 'butt',
                    transform: 'rotate(0.20turn)',
                    transformOrigin: 'center center',
                  },
                }}
              />
            </div>
            <div className='circleContainer'>
              <CircularProgressbar
                value={
                  (clientMetricsData.common_stats.admin_complete / clientMetricsData.common_stats.all) * 100 === 0
                    ? 1
                    : (clientMetricsData.common_stats.admin_complete / clientMetricsData.common_stats.all) * 100
                }
                strokeWidth={6.45}
                styles={{
                  path: {
                    stroke: `rgb(${clientData.hex_color})`,
                    strokeLinecap: 'butt',
                    transition: 'stroke-dashoffset 0.5s ease 0s',
                    transform: 'rotate(0.020turn)',
                    transformOrigin: 'center center',
                  },
                  trail: {
                    stroke: 'rgba(242, 242, 242, 1)',
                    strokeLinecap: 'butt',
                    transform: 'rotate(0.20turn)',
                    transformOrigin: 'center center',
                  },
                }}
              />
            </div>
            <div className='circleText'>
              <span className='value'>{clientMetricsData.common_stats.all}</span>
              <span className='text'>Человек</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
