import './MemberAnketaDescription.scss';

import AnimatedRedirect from '../../components/AnimatedRedirect';
import Button from '../../components/Button';
import ContainerWYSIWYG from '../../components/ContainerWYSIWYG';
import MemberAnketaHeader from '../../components/AnketaSharedComponents/MemberAnketaHeader';
import PageLoader from '../../components/PageLoader';
import Title from '../../components/Title';
import { motion } from 'framer-motion';
import { standardPageVariants } from '../../animationsVariants/StandardPageVariants';
import useClientAnketaData from '../../hooks/useClientAnketaData';
import { useParams } from 'react-router-dom';
import useUserData from '../../hooks/useUserData';

export const MemberAnketaDescription: React.FC<{}> = () => {
  const { anketaID } = useParams<{ anketaID: string }>();
  const { data: anketaData, status: anketaDataLoadingStatus } = useClientAnketaData(anketaID);
  const { data: userData, status: userDataLoadingStatus } = useUserData();

  if (anketaDataLoadingStatus === 'loading' || userDataLoadingStatus === 'loading') {
    return <PageLoader />;
  }

  if (anketaDataLoadingStatus === 'error' || userDataLoadingStatus === 'error') {
    return <AnimatedRedirect redirectTo='/500/' />;
  }

  if (anketaData.status === 1 && !anketaData.questions_completed) {
    return <AnimatedRedirect redirectTo={`/client/anketa/${anketaID}/questions/`} />;
  }

  if (
    (anketaData.status === 1 && anketaData.questions_completed && !anketaData.anketa.interview_needless) ||
    (anketaData.status !== 0 && anketaData.status !== 1 && !anketaData.anketa.interview_needless)
  ) {
    return <AnimatedRedirect redirectTo={`/client/anketa/${anketaID}/call_schedulling/`} />;
  }

  if (
    (anketaData.status === 1 && anketaData.questions_completed && anketaData.anketa.interview_needless) ||
    (anketaData.status !== 0 && anketaData.status !== 1 && anketaData.anketa.interview_needless)
  ) {
    return <AnimatedRedirect redirectTo='/profile/' />;
  }

  return (
    <motion.main className='memberAnketaDescription' variants={standardPageVariants} initial='initial' animate='animate' exit='exit'>
      <MemberAnketaHeader userData={userData.user} anketaData={anketaData} />
      <Title>Описание кейса</Title>
      <ContainerWYSIWYG data={anketaData.anketa.description} size='default' />
      <Button type='link' href={`/client/anketa/${anketaID}/questions/`} width='330rem' size='large' color='blue' text='Начать' />
      <div className='hint'>
        Заполняя анкету, каждый раз проверяйте ваш ответ перед переходом к следующему вопросу. <br />
        Вы не сможете вернуться к ответам позже и отредактировать их после отправки.
      </div>
    </motion.main>
  );
};
