import './AdminTemplateForm.scss';
import 'react-quill/dist/quill.snow.css';

import StagesIndicator from './StagesIndicator';
import TemplateStage1Form from './TemplateStage1Form';
import TemplateStage2Form from './TemplateStage2Form';
import TemplateStage3Form from './TemplateStage3Form';
import TemplateStage4Form from './TemplateStage4Form';
import TemplateStage5Form from './TemplateStage5Form';
import { motion } from 'framer-motion';
import { standardPageVariants } from '../../animationsVariants/StandardPageVariants';

interface Props {
  stage: number;
}

export const AdminTemplateForm: React.FC<Props> = ({ stage }) => {
  return (
    <motion.main className='adminTemplateForm' variants={standardPageVariants} initial='initial' animate='animate' exit='exit'>
      <StagesIndicator stage={stage} />
      {(stage === 0 || stage === 1) && <TemplateStage1Form />}
      {stage === 2 && <TemplateStage2Form />}
      {stage === 3 && <TemplateStage3Form />}
      {stage === 4 && <TemplateStage4Form />}
      {stage === 5 && <TemplateStage5Form />}
    </motion.main>
  );
};
