import { combineReducers } from 'redux';

import HeaderReducer from './HeaderReducer.js';
import ScheduleReducer from './ScheduleReducer';
import ModalsReducer from './ModalsReducer';
import AnketaListReducer from './AnketaListReducer';
import AdminTableReducer from './AdminTableReducer';

const appReducers = combineReducers({
  headerReducer: HeaderReducer,
  scheduleReducer: ScheduleReducer,
  modalsReducer: ModalsReducer,
  anketaListReducer: AnketaListReducer,
  adminTableReducer: AdminTableReducer,
});

const rootReducer = (state, action) => {
  return appReducers(state, action);
};

export default rootReducer;
