export const isTesting3Streams = false;

export const reportColors = (color: string, streamsCount: number) => {
  if (streamsCount === 2) {
    return {
      firstStreamColor: `rgba(135, 205, 249, 1.0)`,
      firstStreamColorWithOpacity: `rgba(135, 205, 249, 0.1)`,
      secondStreamColor: `rgba(0, 121, 193, 1.0)`,
      secondStreamColorWithOpacity: 'rgba(0, 121, 193, 0.1)',
      thirdStreamColor: undefined,
      thirdStreamColorWithOpacity: undefined,
      firstSpiderChartColor: `rgba(117,77,168, 1.0)`,
      firstSpiderChartColorWithOpacity: `rgba(117,77,168, 0.3)`,
      secondSpiderChartColor: `rgba(0, 121, 193, 1.0)`,
      secondSpiderChartColorWithOpacity: `rgba(0, 121, 193, 0.3)`,
      iconsColor: 'rgba(0, 121, 193, 1.0)',
      linksColor: 'rgba(0, 121, 193, 1.0)',
    }
  }

  return {
    firstStreamColor: `rgba(${color}, 1)`,
    firstStreamColorWithOpacity: `rgba(${color}, 0.1)`,
    secondStreamColor: undefined,
    secondStreamColorWithOpacity: undefined,
    thirdStreamColor: undefined,
    thirdStreamColorWithOpacity: undefined,
    firstSpiderChartColor: `rgba(${color}, 1)`,
    firstSpiderChartColorWithOpacity: `rgba(${color}, 0.3)`,
    secondSpiderChartColor: undefined,
    secondSpiderChartColorWithOpacity: undefined,
    iconsColor: `rgba(${color}, 1)`,
    linksColor: `rgba(${color}, 1)`,
  }
}
