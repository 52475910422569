import { useMutation } from 'react-query';
import axios from 'axios';
import { backendURL, debug } from '../App';
import consoleLog from '../helpers/consoleLog';

const blockMember = async ({ userID, clientID, block }) => {
  try {
    const { data } = await axios({
      method: 'post',
      url: `${backendURL}admin/members/${clientID}/`,
      headers: {
        Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
      },
      data: {
        user_id: userID,
        client_id: clientID,
        block_user: block
      },
    });
    debug && consoleLog('success', 'Block member', data);
    return data;
  } catch (error) {
    debug && consoleLog('error', 'Block member', error);
  }
};

export default function useBlockMemberMutation({onSuccess}) {
  return useMutation(blockMember, {onSuccess});
}
