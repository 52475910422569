import { useMutation } from 'react-query';
import axios from 'axios';
import { backendURL, debug } from '../App';
import consoleLog from '../helpers/consoleLog';
import { format } from "date-fns";
import { utcToZonedTime } from 'date-fns-tz/esm';

const changeInterviewDate = async ({ selectedDateTime, callType, skype, anketaAnswersID }) => {
  try {
    const { data } = await axios({
      method: 'post',
      url: `${backendURL}anketa-answers/${anketaAnswersID}/`,
      headers: {
        Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
      },
      data: {
        call_start: format(utcToZonedTime(selectedDateTime, 'Europe/Moscow'), 'yyyy-MM-dd HH:mm'),
        call_type: callType,
        skype: skype,
        change_time: true,
      },
    })
    debug && consoleLog('success', 'Change interview date time', data);
    return data;
  } catch (error) {
    debug && consoleLog('error', 'Change interview date time', error);
  }
};

export default function useChangeInterviewDateMutation({onSuccess}) {
  return useMutation(changeInterviewDate, {onSuccess});
}