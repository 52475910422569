import { useQuery } from 'react-query';
import axios from 'axios';
import { backendURL, debug } from '../App';
import consoleLog from '../helpers/consoleLog';

const getAvalilableTimeData = async (_, timeSlotID, expertID) => {
  try {
    const { data } = await axios({
      method: 'get',
      url: `${backendURL}admin/timetable/available/${timeSlotID}/`,
      headers: {
        Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
      },
      params: {
        expert_id: expertID || ''
      }
    });
    debug && consoleLog('success', 'Get available slots', data);
    return data;
  } catch (error) {
    debug && consoleLog('error', 'Get available slots', error);
  }
};

export default function useAvalilableTimeData(timeSlotID, expertID) {
  return useQuery(['avalilableTimeData', timeSlotID, expertID], getAvalilableTimeData);
}
