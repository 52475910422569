import './ExpertMemberReportPDF.scss';

import { useLocation, useParams } from 'react-router-dom';

import AnimatedRedirect from '../../components/AnimatedRedirect';
import { CTP } from '../../App';
import CompetentionsCircles from '../../components/ReportsSharedComponents/CompetentionsCircles';
import CompetentionsIndicators from '../../components/ReportsSharedComponents/CompetentionsIndicators';
import CompetentionsSpider from '../../components/ReportsSharedComponents/CompetentionsSpider';
import CompetentionsTable from '../../components/ReportsSharedComponents/CompetentionsTable';
import PageLoader from '../../components/PageLoader';
import RecomendationsDetail from '../../components/ReportsSharedComponents/RecomendationsDetail';
import RecomendationsForIndicators from '../../components/ReportsSharedComponents/RecomendationsForIndicators';
import RecomendationsGeneral from '../../components/ReportsSharedComponents/RecomendationsGeneral';
import logo_blue from '../../assets/images/logo-blue.svg';
import logo_blue_ctp from '../../assets/images/logo-blue_ctp.svg';
import { useEffect } from 'react';
import useReportPDFData from '../../hooks/useReportPDFData';

export const ExpertMemberReportPDF: React.FC<{}> = () => {
  const { anketaAnswersID, streamID } = useParams<{ anketaAnswersID: string; streamID: string }>();
  const { data, status: reportDataLoadingStatus } = useReportPDFData('anketa', anketaAnswersID, streamID, false);
  const location = useLocation();

  const transformDataForRadarChart = () => {
    let chartColumns = {};
    let radarRequired = { class: 'required' };
    let radarClient = { class: 'client' };
    let radarClient2 = { class: 'client2' };

    if (data.streams_count === 1) {
      data.diagram_data.map((competention, index) => {
        chartColumns = { ...{ [index]: competention[0] }, ...chartColumns };
        radarRequired = { ...{ [index]: competention[2] / 100 }, ...radarRequired };
        radarClient = { ...{ [index]: competention[1] / 100 }, ...radarClient };
      });

      return [chartColumns, radarRequired, radarClient];
    } else if (data.streams_count === 2) {
      data.diagram_data[1].map((competention, index) => {
        chartColumns = { ...{ [index]: competention[0] }, ...chartColumns };
        radarRequired = { ...{ [index]: data.diagram_data[0][index]?.[2] / 100 }, ...radarRequired };
        radarClient = { ...{ [index]: data.diagram_data[0][index]?.[1] / 100 }, ...radarClient };
        radarClient2 = { ...{ [index]: competention[1] / 100 }, ...radarClient2 };
      });

      return [chartColumns, radarRequired, radarClient, radarClient2];
    }
  };

  useEffect(() => {
    document.getElementsByTagName('html')[0].classList.add('pdfPage');

    return () => {
      document.getElementsByTagName('html')[0].classList.remove('pdfPage');
    };
  }, []);

  if (reportDataLoadingStatus === 'loading') {
    return <PageLoader />;
  }

  if (reportDataLoadingStatus === 'error') {
    return <AnimatedRedirect redirectTo='/500/' />;
  }

  return (
    <main className='adminMemberReportHTML'>
      <div className='header'>
        <div className='topHeader'>
          <img src={CTP ? logo_blue_ctp : logo_blue} alt='logo' />
          <img src={data.client_logo_url} alt='clientLogo' />
        </div>
        <div className='bottomHeader'>
          <div className='bottomHeaderSection'>
            <span className='key'>Клиент</span>
            <span className='value'>{data.client_title}</span>
          </div>
          {data.show_tribe_title && (
            <div className='bottomHeaderSection'>
              <span className='key'>Трайб</span>
              <span className='value'>{data.tribe_title}</span>
            </div>
          )}
          <div className='bottomHeaderSection'>
            <span className='key'>Участник</span>
            <span className='value'>
              {data.member_first_name} {data.member_last_name} {data.member_id}
            </span>
          </div>
          <div className='bottomHeaderSection'>
            <span className='key'>Анкета</span>
            <span className='value'>{data.status}</span>
          </div>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <td>
              <div className='page-header-space'></div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <CompetentionsCircles data={data} type='member' />
              <CompetentionsTable data={data} />
              {data.show_spiders && <CompetentionsSpider radarData={transformDataForRadarChart()} radarInfo={data.diagram_data} data={data} type='member' />}
              <CompetentionsIndicators data={data} />
              {(data.comment_member ||
                (data.comment_hr && location.pathname.indexOf('/for_hr/')) ||
                (data.streams_count === 1 && typeof data.anketa_recomendation === 'string' && data.anketa_recomendation !== '') ||
                (data.streams_count === 2 && typeof data.anketa_recomendation === 'object' && data.anketa_recomendation[1] !== '')) && (
                <RecomendationsGeneral data={data} />
              )}
              {/* <RecomendationsDetail data={data} /> */}
              <RecomendationsForIndicators data={data} />
            </td>
          </tr>
        </tbody>
      </table>
      <footer></footer>
    </main>
  );
};
