import './AnketaCopyModal.scss';

import * as Yup from 'yup';

import { Form, Formik } from 'formik';
import { backendURL, debug } from '../../../App';
import { useHistory, useParams } from 'react-router-dom';

import Button from '../../../components/Button';
import Input from '../../../components/Input';
import InputError from '../../../components/InputError';
import InputSelect from '../../../components/InputSelect';
import Modal from '../../../components/Modal';
import Title from '../../../components/Title';
import axios from 'axios';
import consoleLog from '../../../helpers/consoleLog';
import icon_loading from '../../../assets/images/icon_loadingBlue.svg';
import { motion } from 'framer-motion';
import { queryCache } from 'react-query';
import useClientData from '../../../hooks/useClientData';

interface Props {
  anketaID: number;
  onClose: () => void;
}

export const AnketaCopyModal: React.FC<Props> = ({ anketaID, onClose }) => {
  const { clientID, streamID } = useParams<{ clientID: string; streamID: string }>();
  const history = useHistory();
  const { data: clientData, status: clientDataLoadingStatus } = useClientData(clientID, localStorage.getItem('skillsSurveyUserToken'), undefined);

  if (clientDataLoadingStatus === 'loading') {
    return (
      <Modal className='сhangeTribeModal' onClose={onClose}>
        <div className='isLoading'>
          <motion.img src={icon_loading} initial={{ rotate: 0 }} animate={{ rotate: 360 }} transition={{ duration: 2, loop: Infinity, ease: 'linear' }} />
        </div>
      </Modal>
    );
  }

  return (
    <Modal className='anketaCopyModal' onClose={onClose}>
      <Title>Параметры новой анкеты</Title>
      <Formik
        initialValues={{
          title: '',
          stream_id: '',
          segment_id: '',
          role_id: '',
        }}
        validationSchema={Yup.object({
          title: Yup.string().required('поле должно быть заполнено'),
          stream_id: Yup.string().required('нужно выбрать поток'),
          segment_id: Yup.string().required('нужно выбрать сегмент'),
          role_id: Yup.string().required('нужно выбрать роль'),
        })}
        onSubmit={async (values, { setSubmitting, setStatus }) => {
          try {
            const { data } = await axios({
              method: 'post',
              url: `${backendURL}anketa/copy/`,
              headers: {
                Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
              },
              data: {
                anketa_id: anketaID,
                client_id: clientID,
                role_id: values.role_id,
                segment_id: values.segment_id === 'null' ? null : values.segment_id,
                stream_id: values.stream_id,
                title: values.title,
              },
            });
            queryCache.invalidateQueries(['anketasData', clientID]);
            setStatus('Анкета успешно скопирована, через 3 сек. вы будете перенаправлены на страницу с анкетами.');
            setTimeout(() => {
              setStatus('');
              streamID ? history.push(`/admin/${clientID}/${streamID}/templates/`) : history.push(`/admin/${clientID}/templates/`);
            }, 3000);
            debug && consoleLog('success', 'Anketa copy', data);
          } catch (error) {
            setStatus('Произошла ошибка, свяжитесь с администратором.');
            setTimeout(() => {
              setStatus('');
            }, 5000);
            debug && consoleLog('error', 'Anketa copy', error);
          }
        }}
      >
        {props => (
          <Form>
            <InputSelect
              name='stream_id'
              options={clientData.streams.map(stream => ({ value: stream.id, label: stream.title }))}
              value={props.values.stream_id}
              placeholderType='alwaysVisible'
              placeholder='Поток'
              onChange={option => props.setFieldValue('stream_id', option.value)}
              onBlur={() => props.setFieldTouched('stream_id', true)}
              isValid={!(props.errors.stream_id && props.touched.stream_id)}
              validationError={props.errors.stream_id && props.touched.stream_id ? props.errors.stream_id : undefined}
            />
            <InputSelect
              name='role_id'
              options={clientData.roles.map(role => ({ value: role.id, label: role.title }))}
              value={props.values.role_id}
              placeholderType='alwaysVisible'
              placeholder='Роль'
              onChange={option => props.setFieldValue('role_id', option.value)}
              onBlur={() => props.setFieldTouched('role_id', true)}
              isValid={!(props.errors.role_id && props.touched.role_id)}
              validationError={props.errors.role_id && props.touched.role_id ? props.errors.role_id : undefined}
            />
            <InputSelect
              name='segment_id'
              options={[
                { value: 'null', label: 'Без сегмента' },
                { value: 0, label: 'B2B' },
                { value: 1, label: 'B2C' },
                { value: 2, label: 'Внутренний продукт' },
                { value: 3, label: 'B2X' },
              ]}
              value={props.values.segment_id}
              placeholderType='alwaysVisible'
              placeholder='Сегмент'
              onChange={option => props.setFieldValue('segment_id', option.value)}
              onBlur={() => props.setFieldTouched('segment_id', true)}
              isValid={!(props.errors.segment_id && props.touched.segment_id)}
              validationError={props.errors.segment_id && props.touched.segment_id ? props.errors.segment_id : undefined}
            />
            <Input
              name='title'
              type='text'
              value={props.values.title}
              onChange={event => props.setFieldValue('title', event.target.value)}
              onBlur={() => props.setFieldTouched('title', true)}
              placeholderType='alwaysVisible'
              placeholder='Название'
              isDisabled={props.isSubmitting === true ? true : false}
              isValid={!(props.errors.title && props.touched.title)}
              validationError={props.errors.title && props.touched.title ? props.errors.title : undefined}
              autoComplete='off'
            />
            <InputError
              className={props.status === 'Анкета успешно скопирована, через 3 сек. вы будете перенаправлены на страницу с анкетами.' ? 'success center' : 'center'}
            >
              {props.status}
            </InputError>
            <Button
              type='button'
              width='330rem'
              size='medium'
              color='blue'
              text='Скопировать'
              loading={props.isSubmitting === true ? true : false}
              disabled={props.isSubmitting === true ? true : false}
              className='submitButton'
            />
          </Form>
        )}
      </Formik>
      {/* <ReactSelect placeholder='Поток' options={clientData.streams.map(stream => ({ value: stream.id, label: stream.title }))} onChange={setStreamID} />
      <ReactSelect placeholder='Роль' options={clientData.roles.map(role => ({ value: role.id, label: role.title }))} onChange={setRoleID} />
      <ReactSelect
        placeholder='Сегмент'
        options={[
          { value: 0, label: 'B2B' },
          { value: 1, label: 'B2C' },
          { value: 2, label: 'Внутренний продукт' },
        ]}
        onChange={setSegmentID}
      />
      <Input type='text' name='title' value /> */}
      {/* <InputError className={submitResult === 'Анкета успешно скопирована.' ? 'success' : ''}>{submitResult}</InputError>
      <Button
        type='button'
        width='220rem'
        size='medium'
        color='blue'
        text='Скопировать'
        onClick={() => mutate({ anketaID, clientID: +clientID, streamID, roleID, segmentID })}
        loading={anketaCopyMutationStatus === 'loading'}
        disabled={streamID === null || roleID === null || segmentID === null || anketaCopyMutationStatus === 'loading'}
      /> */}
    </Modal>
  );
};
