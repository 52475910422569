import './BlockMemberModal.scss';


import { queryCache } from 'react-query';

import useBlockMemberMutation from '../../../../hooks/useBlockMemberMutation';

import Title from '../../../../components/Title';
import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';

interface Props {
  userID: number;
  clientID: number;
  block: boolean;
  onClose: () => void;
}

export const BlockMemberModal: React.FC<Props> = ({ userID, clientID, block, onClose }) => {
  const [mutate, { status: blockMemberMutationStatus }] = useBlockMemberMutation({
    onSuccess: () => {
      queryCache.invalidateQueries('adminMembersData');
      onClose();
    },
  });

  return (
    <Modal className='blockMemberModal' onClose={onClose}>
      <Title>{block ? 'Заблокировать' : 'Разблокировать'} участника</Title>
      <div className='text'>Вы уверены, что хотите {block ? 'заблокировать' : 'разблокировать'} участника?</div>
      <Button
        type='button'
        size='medium'
        width='330rem'
        color='blue'
        onClick={() => mutate({ userID, clientID, block })}
        text={block ? 'заблокировать' : 'разблокировать'}
        loading={blockMemberMutationStatus === 'loading' ? true : false}
      />
      <Button type='button' size='medium' width='330rem' color='white' text='Оставить' onClick={() => onClose()} />
    </Modal>
  );
};
