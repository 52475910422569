import './CompetentionsSpider.scss';
import 'react-circular-progressbar/dist/styles.css';

import { twoStreams_radarChart_Text1, twoStreams_radarChart_Text2 } from '../../../helpers/textVariablesForReports';

import Title from '../../Title/';
import radar from 'svg-radar-chart';
import stringify from 'virtual-dom-stringify';

interface Props {
  type: 'tribe' | 'member';
  data: {
    status: string;
    client_color: string;
    shifted_hex_color: string;
    rate: number | number[];
    rate_percent: number | number[];
    max_rate: number | number[];
    streams_count: number;
    report_percents: number | number[];
  };
  radarData: any;
  radarInfo?: any;
}

export const CompetentionsSpider: React.FC<Props> = ({ data, radarData, radarInfo, type }) => {
  // const { firstStreamColor, secondStreamColor, firstSpiderChartColor, firstSpiderChartColorWithOpacity, secondSpiderChartColor, secondSpiderChartColorWithOpacity } =
  //   reportColors(data.client_color, data.streams_count);

  const chartColumnsHtml = () => {
    let html = [] as any;
    Object.entries(radarData[0]).forEach(([key, value], index) => {
      html.push(
        <div className='radarText' key={`radaText${key}`}>
          {value}
          {data.streams_count === 2 && (
            <span style={{ color: `rgb(${data.shifted_hex_color})` }}>
              <br />
              {type === 'member' ? (
                <>
                  {radarInfo[0][index][1]} | <span style={{ color: `rgb(${data.client_color})` }}>{radarInfo[1][index][1]}</span>
                </>
              ) : (
                <>
                  {radarInfo[0].diagram_data[index][1]} | <span style={{ color: `rgb(${data.client_color})` }}>{radarInfo[1].diagram_data[index][1]}</span>
                </>
              )}
            </span>
          )}
        </div>
      );
    });
    return html;
  };

  let chart;

  if (data.streams_count === 2) {
    chart = radar(radarData[0], [radarData[1], radarData[2], radarData[3]], {
      shapeProps: data => ({ className: 'shape ' + data.class, fill: '#1364B4' }),
      axisProps: () => ({ className: 'axis' }),
      scaleProps: () => ({ className: 'scale', fill: '#1364B4' }),
      scales: 1,
      captions: false,
    });
  } else {
    chart = radar(radarData[0], [radarData[1], radarData[2]], {
      shapeProps: data => ({ className: 'shape ' + data.class, fill: '#1364B4' }),
      axisProps: () => ({ className: 'axis' }),
      scaleProps: () => ({ className: 'scale', fill: '#1364B4' }),
      scales: 1,
      captions: false,
      options: {
        dots: true,
        dotProps: () => ({
          className: 'dot',
        }),
      },
    });
  }

  const svg = `
    <svg version="1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <style>
      .axis {
        stroke: rgba(0, 0, 0, 0.3);
        stroke-width: .1;
      }
      .scale {
        fill: #fff;
        stroke: rgba(0, 0, 0, 0.3);
        stroke-width: .1;
      }
      .shape {
        fill-opacity: .3;
        stroke-width: .3;
      }
      .shape:hover { fill-opacity: .6; }
      .shape.required { fill: #BDBDBD; stroke: #BDBDBD; }
      .shape.client  { 
        fill: rgb(${data.streams_count === 2 ? data.shifted_hex_color : data.client_color});
        stroke: rgb(${data.streams_count === 2 ? data.shifted_hex_color : data.client_color}); }
      .shape.client2  { fill: rgb(${data.client_color}); stroke: rgb(${data.client_color}); }
    </style>
    ${stringify(chart)}
    </svg>
  `;

  return (
    <div className='competentionsSpider' data-cy='competentionsSpiderBlock'>
      {type === 'member' && <Title>Оценка компетенций</Title>}
      {type === 'tribe' && (
        <>
          {data.streams_count === 2 && (
            <>
              <Title>
                Оценка компетенций <br /> {radarInfo[1].role_title}
              </Title>
              {/* <div className='memberCount'>
                <div className='secondSteam'>
                  Прошло 2-е тестирование
                  <span>
                    — {radarInfo[1].test_passed} {radarInfo[1].test_passed_man}
                  </span>
                </div>
                <div className='firstSteam'>
                  Прошло 1-е тестирование
                  <span>
                    — {radarInfo[0].test_passed} {radarInfo[0].test_passed_man}
                  </span>
                </div>
              </div>
              <div className='points' style={{ backgroundColor: secondStreamColor }}>
                <div className='pointsHeader'>Набрано в 2-м тестировании</div>
                <div className='pointsHeader'>Навыки изменились на </div>
                <div className='pointsValue'>
                  {data.report_percents ? (
                    <>
                      <span className='value'>{data.rate_percent[1]} %</span>
                    </>
                  ) : (
                    <>
                      <span className='value'>{data.rate[1]}</span> балла из <span className='value'>{data.max_rate[1]}</span> возможных
                    </>
                  )}
                </div>
                <div className='pointsValue'>
                  <span className='value'>{(data.rate_percent[1] - data.rate_percent[0]).toFixed(1)} %</span>
                </div>
              </div>
              <div className='points' style={{ backgroundColor: firstStreamColor, marginBottom: '125rem' }}>
                <div className='pointsHeader'>Набрано в 1-м тестировании</div>
                <div className='pointsValue'>
                  {data.report_percents ? (
                    <>
                      <span className='value'>{data.rate_percent[0]} %</span>
                    </>
                  ) : (
                    <>
                      <span className='value'>{data.rate[0]}</span> балла из <span className='value'>{data.max_rate[0]}</span> возможных
                    </>
                  )}
                </div>
              </div> */}
            </>
          )}
          {data.streams_count === 1 && (
            <>
              <Title>
                Оценка компетенций <br />{' '}
                <span className='middle'>
                  {radarInfo.role_title} {radarInfo?.role_title && radarInfo?.segment_title && ' / '} {radarInfo?.segment_title && `${radarInfo?.segment_title}`}
                </span>
              </Title>
              {/* <div className='memberCount'>
                <div className='firstSteam'>
                  Прошло тестирование{' '}
                  <span>
                    — {radarInfo.test_passed} {radarInfo.test_passed_man}
                  </span>
                </div>
              </div>
              <div className='points' style={{ backgroundColor: firstStreamColor }}>
                <div className='pointsHeader'>Набрано</div>
                <div className='pointsValue'>
                  {data.report_percents ? (
                    <>
                      <span className='value'>{data.rate_percent} %</span>
                    </>
                  ) : (
                    <>
                      <span className='value'>{data.rate}</span> балла из <span className='value'>{data.max_rate}</span> возможных
                    </>
                  )}
                </div>
              </div> */}
            </>
          )}
        </>
      )}
      <div className='svgContainer'>
        <div className={`svgText items${chartColumnsHtml().length}` + `${data.streams_count === 2 ? ' twoStreams' : ''}`}>{chartColumnsHtml()}</div>
        <div
          className='svg'
          dangerouslySetInnerHTML={{
            __html: svg,
          }}
        />
      </div>
      <div className='radarExplanation'>
        <div className='pointer'>
          <span className='pointerCube'></span>
          <span className='pointerText'>Необходимая норма</span>
        </div>
        <div className='pointer'>
          <span
            className='pointerCube'
            style={{
              backgroundColor: `rgba(${data.streams_count === 2 ? data.shifted_hex_color : data.client_color}, 0.5)`,
              borderColor: `rgb(${data.streams_count === 2 ? data.shifted_hex_color : data.client_color})`,
            }}
          ></span>
          <span className='pointerText'>{data.streams_count < 2 ? 'Набрано' : twoStreams_radarChart_Text1}</span>
        </div>
        {data.streams_count === 2 && (
          <div className='pointer'>
            <span
              className='pointerCube'
              style={{
                backgroundColor: `rgba(${data.client_color}, 0.5)`,
                borderColor: `rgb(${data.client_color})`,
              }}
            ></span>
            <span className='pointerText'>{twoStreams_radarChart_Text2}</span>
          </div>
        )}
      </div>
    </div>
  );
};
