import './ExpertAnketaDescription.scss';


import { motion } from 'framer-motion';
import { useParams } from 'react-router-dom';

import useAnketaData from '../../hooks/useAnketaData';
import useAnketaAnswersData from '../../hooks/useAnketaAnswersData';
import { standardPageVariants } from '../../animationsVariants/StandardPageVariants';

import Title from '../../components/Title';
import Button from '../../components/Button';
import ContainerWYSIWYG from '../../components/ContainerWYSIWYG';
import ExpertAnketaHeader from '../../components/AnketaSharedComponents/ExpertAnketaHeader';
import AnimatedRedirect from '../../components/AnimatedRedirect';
import PageLoader from '../../components/PageLoader';

export const ExpertAnketaDescription: React.FC<{}> = () => {
  const { anketaID, anketaAnswersID } = useParams<{ anketaID: string; anketaAnswersID: string }>();
  const { data: anketaData, status: anketaDataLoadingStatus } = useAnketaData(anketaID);
  const { data: anketaAnswersData, status: anketaAnswersDataLoadingStatus } = useAnketaAnswersData(anketaAnswersID);

  if (anketaDataLoadingStatus === 'loading' || anketaAnswersDataLoadingStatus === 'loading') {
    return <PageLoader />;
  }

  if (anketaDataLoadingStatus === 'error' || anketaAnswersDataLoadingStatus === 'error') {
    return <AnimatedRedirect redirectTo='/500/' />;
  }

  if (!anketaData.description) {
    return <AnimatedRedirect redirectTo={`/expert/review/anketa/${anketaID}/${anketaAnswersID}/intro/`} />;
  }

  return (
    <motion.main className='expertAnketaDescription' variants={standardPageVariants} initial='initial' animate='animate' exit='exit'>
      <ExpertAnketaHeader userData={anketaAnswersData.user} anketaData={anketaData} clientData={anketaAnswersData.client} />
      <Title>Описание кейса</Title>
      <ContainerWYSIWYG data={anketaData.description} size='default' />
      <Button type='link' href={`/expert/review/anketa/${anketaID}/${anketaAnswersID}/intro/`} width='330rem' size='large' color='blue' text='Далее' />
    </motion.main>
  );
};
