
// * ------ Групповые отчёты | 2 потока ------

// * Текст под заголовоком "Общие результаты по клиенту"
export const twoStreams_competentionsCircles_Text1 = 'Прошли диагностику компетенций после обучения';
export const twoStreams_competentionsCircles_Text2 = 'Прошли диагностику компетенций до обучения';
// * Текст подзаголовка
export const twoStreams_competentionsCircles_SubTitle = 'Оценка компетенций';
// * Текст под подзаголовком
export const twoStreams_competentionsCircles_Text3 = 'Диагностика компетенций после обучения';
export const twoStreams_competentionsCircles_Text4 = 'Диагностика компетенций до обучения';
// * Цветные прямоугольники справа
export const twoStreams_competentionsCircles_Text5 = 'Набрано после обучения';
export const twoStreams_competentionsCircles_Text6 = 'Набрано до обучения';


// * Паутинки
export const twoStreams_radarChart_Text1 = 'Результат диагностики до обучения'
export const twoStreams_radarChart_Text2 = 'Результат диагностики после обучения'


// * Результаты всех участников
export const twoStreams_tribeResults_Text1 = 'Набрано после обучения'
export const twoStreams_tribeResults_Text2 = 'Набрано до обучения'
