import './AdminClient.scss';

import AdminClientLink from './AdminClientLink';
import AdminClientReport from './AdminClientReport';
import AdminMetrics from './AdminClientMetricsSimplified';
import AdminTribesReports from './AdminTribesReports';
import AnimatedRedirect from '../../components/AnimatedRedirect';
import PageLoader from '../../components/PageLoader';
import _ from 'lodash';
import { motion } from 'framer-motion';
import { standardPageVariants } from '../../animationsVariants/StandardPageVariants';
import useClientData from '../../hooks/useClientData';
import useClientMetrics from '../../hooks/useClientMetrics';
import { useParams } from 'react-router-dom';

export const AdminClient: React.FC<{}> = () => {
  const { clientID } = useParams<{ clientID: string }>();
  const { data: clientData, status: clientDataLoadingStatus } = useClientData(clientID, localStorage.getItem('skillsSurveyUserToken'), undefined);
  const { data: clientMetricsData, status: clientMetricsDataLoadingStatus } = useClientMetrics(clientID, undefined);

  if (clientDataLoadingStatus === 'loading' || clientMetricsDataLoadingStatus === 'loading') {
    return <PageLoader />;
  }

  if (clientDataLoadingStatus === 'error' || clientMetricsDataLoadingStatus === 'error') {
    return <AnimatedRedirect redirectTo='/500/' />;
  }

  return (
    <motion.main className='adminClient' variants={standardPageVariants} initial='initial' animate='animate' exit='exit'>
      <AdminClientLink clientData={clientData} />
      <AdminMetrics clientData={clientData} clientMetricsData={clientMetricsData} />
      <AdminClientReport />
      {_.size(clientMetricsData.tribes_data) > 0 && <AdminTribesReports tribesData={clientMetricsData.tribes_data} />}
    </motion.main>
  );
};
