import './AnketaZoomVideoModal.scss';

import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { Title } from '../Title/Title';
import { Modal } from '../Modal/Modal';
import { anketa_zoom_video_modal_display_toggle } from '../../actions';
import QuizQuestion_videoPreviewPoster from '../../assets/images/QuizQuestion_videoPreviewPoster.svg';
import axios from 'axios';
import { backendURL } from '../../App';
import useUserData from '../../hooks/useUserData';
import consoleLog from '../../helpers/consoleLog';

interface AnketaZoomVideoModalProps {
  zoomModalDataID: number;
}

export const AnketaZoomVideoModal: React.FC<AnketaZoomVideoModalProps> = ({ zoomModalDataID }) => {
  const dispatch = useDispatch();
  const { data: expertUserData, status: expertUserDataLoadingStatus } = useUserData();
  const [memberData, setMemberData] = useState({} as any);

  useEffect(() => {
    axios({
      method: 'get',
      url: `${backendURL}anketa-answers/${zoomModalDataID}/`,
      headers: {
        Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
      },
    })
      .then(result => {
        setMemberData(result.data);
        consoleLog('success', 'Anketa answer data', result);
      })
      .catch(error => {
        consoleLog('error', 'Anketa answer data', error);
      });
  }, [zoomModalDataID]);

  return (
    <Modal className='anketaZoomVideoModal' onClose={() => dispatch(anketa_zoom_video_modal_display_toggle(false))}>
      <Title>{memberData?.vc_service?.title} конференция</Title>
      <div className='titleRow'>
        <div className='expert'>Эксперт</div>
        <div className='name'>Имя участника или ID</div>
        <div className='tribe'>Трайб</div>
        <div className='role'>Роль</div>
        <div className='case'>Сегмент</div>
      </div>
      {expertUserDataLoadingStatus === 'success' && memberData.user && (
        <>
          <div className='row'>
            <div className='expert'>{`${memberData.expert.first_name} ${memberData.expert.last_name}`}</div>
            <div className='name'>
              {/* // * Либо ID пользователя выводим, либо Имя + Фамилия */}
              {`${memberData.user.member_id ? memberData.user.member_id : `${memberData.user.first_name} ${memberData?.user?.last_name}`}`}
            </div>
            <div className='tribe'>{`${memberData?.user?.tribe_str ? memberData.user.tribe_str : '-----------'}`}</div>
            <div className='role'>{memberData?.user?.status_str}</div>
            <div className='case'>{memberData?.user?.case_str}</div>
          </div>

          {memberData?.vc_service?.system_name === 'zoom' && (
            <video poster={QuizQuestion_videoPreviewPoster} controls>
              <source src={memberData?.vc_video_link} type='video/mp4'></source>
            </video>
          )}

          {memberData?.vc_service?.system_name === 'dion' && (
            <iframe
              width='560'
              height='315'
              src={memberData?.vc_video_link.replace(/\/video\//gi, "/video/embed/")}
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen'></iframe>
          )}

        </>
      )}
    </Modal>
  );
};
