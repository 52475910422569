import './styles.scss';

import Button from '../../Button';
import Modal from '../../Modal';
import Title from '../../Title';
import { queryCache } from 'react-query';
import useCancelInterviewMutation from '../../../hooks/useCancelInterviewMutation';

interface Props {
  timeSlotID: number | undefined;
  expertID: number;
  onClose: () => void;
}

export const CancelInterviewModal: React.FC<Props> = ({ timeSlotID, expertID, onClose }) => {
  const [mutate, { status: cancelInterviewMutationStatus }] = useCancelInterviewMutation({
    onSuccess: () => {
      queryCache.invalidateQueries('adminExpertsScheduleData');
      onClose();
    },
  });

  return (
    <Modal className='cancelInterviewModal' onClose={onClose}>
      <Title>Отменить интервью</Title>
      <div className='text'>Вы уверены, что хотите отменить интвервью?</div>
      <Button
        type='button'
        size='medium'
        width='330rem'
        color='blue'
        text='Отменить'
        loading={cancelInterviewMutationStatus === 'loading' ? true : false}
        onClick={() =>
          mutate({
            timeSlotID,
            expertID,
          })
        }
      />
      <Button type='button' size='medium' width='330rem' color='white' text='Оставить' onClick={() => onClose()} />
    </Modal>
  );
};
