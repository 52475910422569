export const hours_noun = (value:number) => {
  const words = ['час', 'часа', 'часов'];
  // value = Math.abs(value) % 100;
  const num = (Math.abs(value) % 100) % 10;
  if (value > 10 && value < 20) return words[2];
  if (num > 1 && num < 5) return words[1];
  if (num === 1) return words[0];
  return words[2];
};

export const minutes_noun = (value:number) => {
  const words = ['минута', 'минуты', 'минут'];
  // value = Math.abs(value) % 100;
  const num = (Math.abs(value) % 100) % 10;
  if (value > 10 && value < 20) return words[2];
  if (num > 1 && num < 5) return words[1];
  if (num === 1) return words[0];
  return words[2];
};
