import { useQuery } from 'react-query';
import axios from 'axios';
import { backendURL } from '../App';

const getIndicatorsData = async (_, anketaID, competensionID,) => {
    const { data } = await axios({
      method: 'get',
      url: `${backendURL}indicators/`,
      headers: {
        Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
      },
      params: {
        anketa_id: anketaID
      }
    });
    if ( competensionID ) {
      return data.filter((item) => item.competention_id === competensionID);
    }
    return data;
};

export default function useIndicatorsData(anketaID, competensionID) {
  return useQuery(['indicatorsData', anketaID, competensionID,], getIndicatorsData, { retry: false, refetchOnWindowFocus: false });
}
