import './AdminClientReportsTable.scss';

import { useFilters, useRowSelect, useSortBy, useTable } from 'react-table';

import CopyToClipboard from '../../../components/CopyToClipboard';
import api_reportCancel from '../../../hooks/api_reportCancel';
import { backendURL } from '../../../App';
import { format } from 'date-fns';
import icon_search from '../../../assets/images/icon_search.svg';
import icon_sortDown from '../../../assets/images/icon_sortDown.svg';
import { useMemo } from 'react';

interface Props {
  addoData: any;
}

export const AdminClientReportsTable: React.FC<Props> = ({ addoData }) => {
  // * Фильтры для таблица
  const DefaultColumnFilter = () => {
    return <img alt='icon' className='searchIcon tribeSearch' src={icon_search} />;
  };
  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );
  // * Стркутруа колнок для таблицы
  const columns = useMemo(
    () => [
      {
        Header: 'Дата и время',
        accessor: 'created',
        disableFilters: true,
        Cell: ({ cell }) => <>{format(new Date(cell.value), 'dd.MM.yyyy  hh:mm')}</>,
      },
      {
        Header: 'Трайб',
        accessor: 'tribe_title',
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ cell }) => <>{cell.value || '––'}</>,
      },
      {
        Header: 'Поток',
        accessor: 'stream_id',
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: 'Готовность %',
        accessor: 'percent',
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ cell }) => <>{`${cell.value} %`}</>,
      },
      {
        Header: 'ETA',
        accessor: 'ETA',
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ cell }) => <>{cell.value === '-' ? '––' : cell.value}</>,
      },
      {
        Header: 'PDF',
        accessor: 'pdf',
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ cell, row }) => (
          <>
            {row.original.status !== 'done' || row.original.error ? (
              '––'
            ) : (
              <a href={cell.value} target='_blank' rel='noreferrer' className='link' data-cy='reportInPdf'>
                PDF
              </a>
            )}
          </>
        ),
      },
      {
        Header: 'HTML',
        accessor: 'html',
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ row }) => (
          <>
            {row.original.status !== 'done' || row.original.error ? (
              '––'
            ) : (
              <a
                href={`/admin/${row.original.tribe_title ? 'tribe_report' : 'client_report'}/pdf_ard/${row.original.id}/${row.original.key}/`}
                target='_blank'
                rel='noreferrer'
                className='link'
                data-cy='reportInHtml'
              >
                HTML
              </a>
            )}
          </>
        ),
      },
      {
        Header: 'Error',
        accessor: 'error',
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ cell }) => (
          <>
            {!cell.value ? (
              '––'
            ) : (
              <div className='error'>
                <div className='link link-disabled-style'>Error</div>
                <CopyToClipboard value={`${backendURL.substring(0, backendURL.length - 4)}${cell.value.substring(1, cell.value.length)}`} />
              </div>
            )}
          </>
        ),
      },
      {
        Header: 'Cancel',
        accessor: 'cancel',
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ cell, row }) => (
          <>
            <button
              onClick={() => api_reportCancel({ reportID: row.original.id })}
              className='link text-left !text-red-500 disabled:!text-red-300'
              disabled={row.original.status === 'done'}
            >
              Отменить генрацию
            </button>
          </>
        ),
      },
    ],
    []
  );
  // * Данные для таблицы
  const data = useMemo(() => addoData, [addoData]);

  // * Инциализация таблицы
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      defaultColumn,
      disableSortRemove: true,
      autoResetSortBy: false,
      initialState: {
        // sortBy: [
        //   {
        //     id: 'date',
        //   },
        // ],
      },
    },
    useFilters,
    useSortBy,
    useRowSelect
  );

  return (
    <section className='reportsTable col-span-2'>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={`headerGroup-tr-${index}`}>
              {headerGroup.headers.map((column, index) => (
                <th {...column.getHeaderProps} key={`headerGroup-tr-th-${index}`}>
                  <div {...column.getSortByToggleProps()}>
                    {column.render('Header')}
                    {!column.disableSortBy &&
                      (column.isSortedDesc ? (
                        <img alt='icon' className='sortDownIcon' src={icon_sortDown} />
                      ) : (
                        <img alt='icon' className='sortUpIcon' src={icon_sortDown} />
                      ))}
                  </div>
                  {column.canFilter ? column.render('Filter') : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={`body-tr-${i}`}>
                {row.cells.map((cell, i: number) => {
                  return (
                    <td {...cell.getCellProps()} key={`body-tr-td-${i}`}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </section>
  );
};
