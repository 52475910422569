import './MemberAnketaProgression.scss';



interface AnketaProgressionProps {
  questionsCount: number;
  currentQuestionNumber: number;
}

export const MemberAnketaProgression: React.FC<AnketaProgressionProps> = ({ questionsCount, currentQuestionNumber }) => {
  const prograssionPoints = () => {
    let prograssionPointsArray: object[] = [];
    for (let i = 1; i <= questionsCount; i++) {
      i === currentQuestionNumber &&
        prograssionPointsArray.push(
          <div className='progressionPoint active' key={i}>
            {i}
          </div>
        );
      i < currentQuestionNumber &&
        prograssionPointsArray.push(
          <div className='progressionPoint answered' key={i}>
            {i}
          </div>
        );
      i > currentQuestionNumber &&
        prograssionPointsArray.push(
          <div className='progressionPoint' key={i}>
            {i}
          </div>
        );
    }
    return prograssionPointsArray;
  };

  return <div className='memberAnketaProgression anketaProgression'>{prograssionPoints()}</div>;
};
