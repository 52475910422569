
import { Route, RouteProps } from 'react-router';
import AnimatedRedirect from '../AnimatedRedirect';

export interface Props extends RouteProps {
  accessibleFor: 'admin' | 'expert' | 'member';
}

export const PrivateRoute: React.FC<Props> = props => {
  if (
    (localStorage.getItem('skillsSurveyUserType') === 'is_member' && props.accessibleFor === 'member') ||
    (localStorage.getItem('skillsSurveyUserType') === 'is_expert' && props.accessibleFor === 'expert') ||
    (localStorage.getItem('skillsSurveyUserType') === 'is_moderator' && props.accessibleFor === 'admin')
  ) {
    return <Route {...props} />;
  } else {
    return <AnimatedRedirect redirectTo='/404/' />;
  }
};

export default PrivateRoute;
