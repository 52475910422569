import {
  ADMIN_TABLE_EXPERT_FILTER_DISPLAY_TOGGLE,
  ADMIN_TABLE_MEMBER_ID_FILTER_DISPLAY_TOGGLE,
  ADMIN_TABLE_MEMBER_NAME_FILTER_DISPLAY_TOGGLE,
  ADMIN_TABLE_STATUS_FILTER_DISPLAY_TOGGLE,
  ADMIN_TABLE_TRIBE_FILTER_DISPLAY_TOGGLE,
} from '../actions/types';

const INITIAL_STATE = {
  admin_table_expert_filter_display_status: false,
  admin_table_member_id_filter_display_status: false,
  admin_table_member_name_filter_display_status: false,
  admin_table_status_filter_display_status: false,
  admin_table_tribe_filter_display_status: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADMIN_TABLE_EXPERT_FILTER_DISPLAY_TOGGLE:
      return { ...state, ...INITIAL_STATE, admin_table_expert_filter_display_status: action.payload };
    case ADMIN_TABLE_MEMBER_ID_FILTER_DISPLAY_TOGGLE:
      return { ...state, ...INITIAL_STATE, admin_table_member_id_filter_display_status: action.payload };
    case ADMIN_TABLE_MEMBER_NAME_FILTER_DISPLAY_TOGGLE:
      return { ...state, ...INITIAL_STATE, admin_table_member_name_filter_display_status: action.payload };
    case ADMIN_TABLE_STATUS_FILTER_DISPLAY_TOGGLE:
      return { ...state, ...INITIAL_STATE, admin_table_status_filter_display_status: action.payload };
    case ADMIN_TABLE_TRIBE_FILTER_DISPLAY_TOGGLE:
      return { ...state, ...INITIAL_STATE, admin_table_tribe_filter_display_status: action.payload };
    default:
      return { ...state };
  }
};
