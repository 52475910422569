import './MemberAnketaNoInterviewComplete.scss';

import { CTP, backendURL } from '../../App';
import { useEffect, useState } from 'react';

import Title from '../../components/Title';
import axios from 'axios';
import consoleLog from '../../helpers/consoleLog';
import { motion } from 'framer-motion';
import { standardPageVariants } from '../../animationsVariants/StandardPageVariants';
import { useHistory } from 'react-router-dom';

export const MemberAnketaNoInterviewComplete: React.FC<{}> = () => {
  const [counter, setCounter] = useState(5);
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      counter > 0 ? setCounter(counter - 1) : history.push('/client/anketas/');
    }, 1000);
  }, [counter, history]);

  return (
    <motion.main className='memberAnketaNoInterviewComplete' variants={standardPageVariants} initial='initial' animate='animate' exit='exit'>
      <div className='subTitle'>
        {CTP ? (
          <small>
            Спасибо за прохождение диагностики!
            <br />
            <br /> Вы получите индивидуальные отчеты на почту после прохождения диагностики всеми участниками.
            <br />
            <br />
            Переход на главную через {counter} секунд...
          </small>
        ) : (
          <small>
            Спасибо за уделенное время!
            <br />
            <br /> Ваш отчет будет отправлен на почту, после того, как все участники пройдут диагностику компетенций.
            <br />
            <br />
            Переход на главную через {counter} секунд...
          </small>
        )}
      </div>
    </motion.main>
  );
};
