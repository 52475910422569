import './Checkbox.scss';

import { FormikErrors } from 'formik';

interface Props {
  name: string;
  value?: string;
  checked?: boolean;
  text?: string;
  children?: any;
  onClick?: () => void;
  onBlur?: () => void;
  isDisabled?: boolean;
  isValid?: boolean;
  validationError?: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined;
  containerClassName?: string;
}

export const Checkbox: React.FC<Props> = ({ name, value, checked, onClick, onBlur, text, children, containerClassName }) => {
  return (
    <div className={`checkbox ${containerClassName}`}>
      <input type='checkbox' name={name} id={name} value={value} checked={checked} />
      <label htmlFor={name} className='checkboxVisibleLabel' onClick={onClick}>
        <span className={`checkboxIcon ${value || checked ? 'checked' : 'notChecked'}`}></span>
        <span className='checkboxText'>{children ? children : text}</span>
      </label>
    </div>
  );
};
