import './InterviewAssignedModal.scss';

import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz/esm';
import { ru } from 'date-fns/locale';

import Title from '../../../components/Title';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';

interface Props {
  onClose: () => void;
  selectedDateTime: Date;
}

export const InterviewAssignedModal: React.FC<Props> = ({ onClose, selectedDateTime }) => {
  return (
    <Modal className='interviewAssignedModal' onClose={onClose}>
      <Title>Вы выбрали способ и время связи</Title>
      <div className='text'>
        Спасибо за участие в программе по развитию продуктовых компетенций!
        <br /> <br />
        {format(utcToZonedTime(selectedDateTime, 'Europe/Moscow'), 'dd MMMM', { locale: ru })} в{' '}
        {format(utcToZonedTime(selectedDateTime, 'Europe/Moscow'), 'HH:mm', { locale: ru })} часов. Если вы не можете созвониться с экспертом в выбранное время, измените
        дату и время в календаре.
        <br /> <br />
        Перенести интервью можно только один раз.
      </div>
      <Button type='button' onClick={onClose} width='300rem' size='small' color='black' text='Понятно' className='anketaCallAssignedModalButton' />
    </Modal>
  );
};
