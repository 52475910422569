import { useQuery } from 'react-query';
import axios from 'axios';
import { backendURL } from '../App';
import __ from 'lodash';

const getAnketaQuetionsData = async (_, anketaId) => {
  console.log('anketaID', anketaId);
  if (!__.isNil(anketaId)) {
    const { data } = await axios({
      method: 'get',
      url: `${backendURL}questions/`,
      headers: {
        Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
      },
      params: {
        anketa_id: anketaId,
      },
    });
    return data;
  }
};

export default function useAnketaQuetionsData(anketaId) {
  return useQuery(['anketaQuestionsData', anketaId], getAnketaQuetionsData, { retry: false });
}
