import './ContainerWYSIWYG.scss';

interface Props {
  data: string; // * html-string
  size: 'large' | 'default' | 'small';
  className?: string;
  color?: string;
}

export const ContainerWYSIWYG: React.FC<Props> = ({ data, size, className, color }) => {
  const styles = `
    .containerWYSIWYG a {
        color: ${color} !important;
    }
  `;

  return (
    <>
      <div
        style={{ color: '#000' }}
        className={`containerWYSIWYG ${size} ${className || ''}`}
        dangerouslySetInnerHTML={{
          __html: data,
        }}
      />
      {/* <style>{styles}</style> */}
    </>
  );
};
