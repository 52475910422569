import * as Yup from 'yup';

import { Form, Formik } from 'formik';

import { Button } from '../../../components/Button/Button';
import Input from '../../../components/Input';
import { InputError } from '../../../components/InputError/InputError';
import axios from 'axios';
import { backendURL } from '../../../App';
import consoleLog from '../../../helpers/consoleLog';
import { useHistory } from 'react-router-dom';

export const PasswordUpdateForm: React.FC<{}> = () => {
  const history = useHistory();

  const logout = () => {
    axios({
      method: 'post',
      url: `${backendURL}auth/logout/`,
      headers: {
        Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
      },
    })
      .then(result => {
        history.push('/');
        localStorage.removeItem('skillsSurveyUserToken');
        localStorage.removeItem('skillsSurveyUserID');
        localStorage.removeItem('skillsSurveyUserType');
        consoleLog('success', 'Logout', result);
      })
      .catch(error => {
        consoleLog('error', 'Logout', error);
      });
  };

  return (
    <Formik
      initialValues={{ new_password: '', reenter_password: '' }}
      validationSchema={Yup.object({
        new_password: Yup.string()
          .required('поле должно быть заполнено')
          .matches(/^((?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9]).{6,})\S$/, 'минимум 6 символов, хотя бы 1 заглавная, 1 строчная, 1 цифра'),
        reenter_password: Yup.string()
          .required('поле должно быть заполнено')
          // @ts-ignore
          .oneOf([Yup.ref('new_password'), null], 'пароли не совпадают'),
      })}
      onSubmit={(values, { setSubmitting, setStatus }) => {
        axios({
          method: 'post',
          url: `${backendURL}user/password/`,
          data: values,
          headers: {
            Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
          },
        })
          .then(result => {
            setStatus('success');
            setTimeout(() => {
              setStatus('');
              logout();
            }, 3000);
            consoleLog('success', 'Password update', result);
          })
          .catch(error => {
            setSubmitting(false);
            setStatus('fail');
            setTimeout(() => {
              setStatus('');
            }, 5000);
            consoleLog('error', 'Password update', error);
          });
      }}
    >
      {props => (
        <div className='passwordUpdateForm'>
          <Form>
            <Input
              name='new_password'
              type='password'
              size='small'
              value={props.values.new_password}
              onChange={event => props.setFieldValue('new_password', event.target.value)}
              onBlur={() => props.setFieldTouched('new_password', true)}
              placeholderType='alwaysVisible'
              placeholder='Новый пароль'
              isDisabled={props.isSubmitting}
              isValid={!(props.errors.new_password && props.touched.new_password)}
              validationError={props.errors.new_password && props.touched.new_password ? props.errors.new_password : undefined}
              autoComplete='new-password'
            />

            <Input
              name='reenter_password'
              type='password'
              size='small'
              value={props.values.reenter_password}
              onChange={event => props.setFieldValue('reenter_password', event.target.value)}
              onBlur={() => props.setFieldTouched('reenter_password', true)}
              placeholderType='alwaysVisible'
              placeholder='Повторите новый пароль'
              isDisabled={props.isSubmitting}
              isValid={!(props.errors.reenter_password && props.touched.reenter_password)}
              validationError={props.errors.reenter_password && props.touched.reenter_password ? props.errors.reenter_password : undefined}
              autoComplete='new-password'
            />

            <Button type='button' buttonType='submit' width='230rem' size='small' color='black'>
              Сохранить
            </Button>

            <InputError className={`submitError ${props.status === 'success' ? 'success' : ''}`}>
              {props.status === 'fail' && 'Системная ошибка. Обратитесь к администратору.'}
              {props.status === 'success' && (
                <span>
                  Пароль успешно изменён!
                  <br />
                  Вы будете перенаправлены на стр. логина через 3 сек.
                </span>
              )}
            </InputError>
          </Form>
        </div>
      )}
    </Formik>
  );
};
