import { useMutation } from 'react-query';
import axios from 'axios';
import { backendURL, debug } from '../App';
import consoleLog from '../helpers/consoleLog';

const saveRateComment = async ({ answerID, expertComment, rate }) => {
  try {
    const { data } = await axios({
      method: 'post',
      url: `${backendURL}answer/${answerID}/`,
      headers: {
        Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
      },
      data: {
        expert_comment: expertComment,
        rate: rate
      },
    });
    debug && consoleLog('success', 'Save rate comment', data);
    return data;
  } catch (error) {
    debug && consoleLog('error', 'Save rate comment', error);
  }
};

export default function useExpertRateCommentMutation({onSuccess}) {
  return useMutation(saveRateComment, {onSuccess});
}
