import './AdminExpertsTable.scss';

import { backendURL, debug } from '../../../App';
import { useFilters, useSortBy, useTable } from 'react-table';
import { useMemo, useState } from 'react';

import AddClientForExpertModal from './AddClientForExpertModal';
import AdminExpertNoInterviewsTable from './AdminExpertNoInterviewsTable/idnex';
import AdminExpertsSchedule from './AdminExpertsSchedule';
import RemoveClientForExpertModal from './RemoveClientForExpertModal';
import axios from 'axios';
import consoleLog from '../../../helpers/consoleLog';
import icon_resetFilter from '../../../assets/images/icon_resetFilter.svg';
import icon_search from '../../../assets/images/icon_search.svg';
import icon_sortDown from '../../../assets/images/icon_sortDown.svg';
import { queryCache } from 'react-query';
import useDynamicRefs from 'use-dynamic-refs';

interface Props {
  tableData: any;
}

export const AdminExpertsTable: React.FC<Props> = ({ tableData }) => {
  const [getRef, setRef] = useDynamicRefs();

  // * Фильтры для таблицы
  const DefaultColumnFilter = () => {
    return <img alt='icon' className='searchIcon tribeSearch' src={icon_search} />;
  };
  const ExpertColumnFilter = ({ column: { filterValue, setFilter } }) => {
    return (
      <>
        <img
          alt='icon'
          className='searchIcon expertSearch'
          src={icon_search}
          // @ts-ignore
          onClick={() => getRef('expertSearch').current.classList.add('visible')}
        />
        <div
          className='searchPopup expertSearch'
          // @ts-ignore
          ref={setRef('expertSearch')}
        >
          <button
            type='button'
            className='close'
            // @ts-ignore
            onClick={() => getRef('expertSearch').current.classList.remove('visible')}
          />
          <input
            type='text'
            value={filterValue || ''}
            onChange={e => {
              setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
            placeholder='Начните вводить имя/фамилию эксперта'
          />
        </div>
        {filterValue && (
          <img
            alt='icon'
            className='resetFilterIcon'
            src={icon_resetFilter}
            onClick={() => {
              setFilter(undefined);
              // @ts-ignore
              getRef('expertSearch').current.classList.remove('visible');
            }}
          />
        )}
      </>
    );
  };
  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );
  // * Стркутруа колонок для таблицы
  const columns = useMemo(
    () => [
      {
        Header: 'Имя эксперта',
        accessor: 'full_name',
        Filter: ExpertColumnFilter,
      },
      {
        Header: 'Клиентов',
        accessor: 'clients_count',
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: 'Назначено интервью',
        accessor: 'future_interview_count',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Cвободно слотов',
        accessor: 'free_interview_slots',
        disableFilters: true,
      },
      {
        Header: 'Всего было интервью',
        accessor: 'interview_pass',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Общая загруженность',
        accessor: 'total_percent',
        disableFilters: true,
      },
      {
        Header: 'Расписание',
        accessor: 'schedule',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Кнопки',
        accessor: 'actions',
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    []
  );
  // * Данные для таблицы
  const data = useMemo(() => tableData, [tableData]);
  // * Инциализация таблица
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, sortBy } = useTable(
    { columns, data, defaultColumn, disableSortRemove: true, autoResetSortBy: false },
    useFilters,
    useSortBy
  );

  // * Кнопка действий с экспертом
  const closeAllOpened = current => {
    tableData.forEach(element => {
      if (current !== element.actions) {
        // @ts-ignore
        getRef(`${element.actions}toggleButton`).current.classList.remove('active');
        // @ts-ignore
        getRef(`${element.actions}buttons`).current.classList.remove('visible');
      }
    });
  };

  // * Блокировать эксперта
  const blockExpert = (expert_id, bool) => {
    axios({
      method: 'post',
      url: `${backendURL}admin/experts/`,
      headers: {
        Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
      },
      data: {
        expert_id,
        block_expert: bool,
      },
    })
      .then(result => {
        queryCache.invalidateQueries('adminExpertsData');
        debug && consoleLog('success', 'Expert block', result);
      })
      .catch(error => {
        error && consoleLog('error', 'Expert block', error);
      });
  };
  // * Блокировать запись к эсперту
  const blockScheduling = (expert_id, bool) => {
    axios({
      method: 'post',
      url: `${backendURL}admin/experts/`,
      headers: {
        Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
      },
      data: {
        expert_id,
        block_users: bool,
      },
    })
      .then(result => {
        queryCache.invalidateQueries('adminExpertsData');
        debug && consoleLog('success', 'Expert scheduling block', result);
      })
      .catch(error => {
        error && consoleLog('error', 'Expert scheduling block', error);
      });
  };

  // * Показать расписание
  const [scheduleToShow, setScheduleToShow] = useState<any>([]);

  // * Модальное окно для привязки эксперту нового клиента
  const [addClientForExpertModal, setAddClientForExpertModal] = useState<number | null>(null);
  // * Модальное окно для отвязки клиента эксперту
  const [removeClientForExpertModal, setRemoveClientForExpertModal] = useState<number | null>(null);

  return (
    <section className='adminExpertsTable'>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={`headerGroup-tr-${index}`}>
              {headerGroup.headers.map((column, index) => (
                <th {...column.getHeaderProps} key={`headerGroup-tr-th-${index}`}>
                  <div {...column.getSortByToggleProps()}>
                    {column.render('Header')}
                    {!column.disableSortBy &&
                      (column.isSortedDesc ? (
                        <img alt='icon' className='sortDownIcon' src={icon_sortDown} />
                      ) : (
                        <img alt='icon' className='sortUpIcon' src={icon_sortDown} />
                      ))}
                  </div>
                  {column.canFilter ? column.render('Filter') : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <>
                <tr {...row.getRowProps()} key={`body-tr-${i}`}>
                  {row.original.block_expert && <div className='expertRowBlocker'>Эксперт заблокирован</div>}
                  {row.original.block_users && !row.original.block_expert && <div className='usersRowBlocker'>Запись к эксперту закрыта</div>}
                  {row.cells.map((cell, i: number) => {
                    return (
                      <>
                        <td {...cell.getCellProps()} key={`body-tr-td-${i}`}>
                          {/* // * Общая загруженность */}
                          {cell.column.Header === 'Общая загруженность' && (
                            <>
                              <div
                                className={
                                  'workloadContainer ' +
                                  `${100 - cell.value < 20 ? 'smallLoadFont ' : ''}` +
                                  `${100 - cell.value <= 33 ? 'smallLoad' : ''}` +
                                  `${100 - cell.value > 33 && 100 - cell.value <= 66 ? 'mediumLoad' : ''}` +
                                  `${100 - cell.value > 66 ? 'bigLoad' : ''}`
                                }
                              >
                                <div className='workloadValue' style={{ width: `${100 - cell.value}%` }}></div>
                                <div className='workloadText'>{100 - cell.value} %</div>
                              </div>
                              <div className='workloadLeft'>{cell.value} %</div>
                            </>
                          )}
                          {/* // * Расписание */}
                          {cell.column.Header === 'Расписание' &&
                            (!scheduleToShow.find(item => item === cell.value) ? (
                              <>
                                <button type='button' className='link isPaddingLeft' onClick={() => setScheduleToShow([...scheduleToShow, cell.value])}>
                                  Расписание
                                </button>
                              </>
                            ) : (
                              <button type='button' className='link' onClick={() => setScheduleToShow(scheduleToShow.filter(item => item !== cell.value))}>
                                Закрыть расписание
                              </button>
                            ))}
                          {/* // * Кнопки */}
                          {cell.column.Header === 'Кнопки' && (
                            <>
                              <div className='expertActionsContainer'>
                                <button
                                  type='button'
                                  className='expertActionsToggleButton'
                                  // @ts-ignore
                                  ref={setRef(`${cell.value}toggleButton`)}
                                  onClick={() => {
                                    closeAllOpened(cell.value);
                                    // @ts-ignore
                                    getRef(`${cell.value}toggleButton`).current.classList.toggle('active');
                                    // @ts-ignore
                                    getRef(`${cell.value}buttons`).current.classList.toggle('visible');
                                  }}
                                />

                                <div
                                  className='expertActions'
                                  // @ts-ignore
                                  ref={setRef(`${cell.value}buttons`)}
                                >
                                  <button
                                    type='button'
                                    className='link'
                                    onClick={() => {
                                      setRemoveClientForExpertModal(null);
                                      setAddClientForExpertModal(cell.value);
                                    }}
                                  >
                                    Привязать клиента
                                  </button>
                                  <button
                                    type='button'
                                    className='link'
                                    onClick={() => {
                                      setAddClientForExpertModal(null);
                                      setRemoveClientForExpertModal(cell.value);
                                    }}
                                  >
                                    Отвязать клиента
                                  </button>
                                  <button type='button' className='link' onClick={() => blockExpert(cell.value, row.original.block_expert ? false : true)}>
                                    {row.original.block_expert ? 'Разблокировать' : 'Заблокировать'}
                                  </button>
                                  <button type='button' className='link' onClick={() => blockScheduling(cell.value, row.original.block_users ? false : true)}>
                                    {row.original.block_users ? 'Разрешить записываться участникам' : 'Запретить записываться участникам'}
                                  </button>
                                </div>
                              </div>
                            </>
                          )}
                          {/* // * Обычная ячейка */}
                          {cell.column.Header !== 'Общая загруженность' && cell.column.Header !== 'Расписание' && cell.column.Header !== 'Кнопки' && cell.render('Cell')}
                        </td>
                      </>
                    );
                  })}
                </tr>
                {scheduleToShow.find(item => item === row.original.schedule) && (
                  <>
                    <AdminExpertsSchedule expertID={row.original.schedule} />
                    <AdminExpertNoInterviewsTable expertID={row.original.schedule} />
                  </>
                )}
              </>
            );
          })}
        </tbody>
      </table>
      {addClientForExpertModal && <AddClientForExpertModal expertID={addClientForExpertModal} onClose={() => setAddClientForExpertModal(null)} />}
      {removeClientForExpertModal && (
        <RemoveClientForExpertModal
          expertID={removeClientForExpertModal}
          onClose={() => setRemoveClientForExpertModal(null)}
          onSwitch={() => {
            setRemoveClientForExpertModal(null);
            setAddClientForExpertModal(removeClientForExpertModal);
          }}
        />
      )}
    </section>
  );
};
