import { useState } from 'react';

import { motion } from 'framer-motion';
import { useParams } from 'react-router-dom';

import useAnketaQuetionsData from '../../hooks/useAnketaQuetionsData';
import useAnketaQestionsAnswersData from '../../hooks/useAnketaQestionsAnswersData';
import useAnketaData from '../../hooks/useAnketaData';
import { standardPageVariants } from '../../animationsVariants/StandardPageVariants';
import useAnketaAnswersData from '../../hooks/useAnketaAnswersData';
import consoleLog from '../../helpers/consoleLog';

import ExpertAnketaHeader from '../../components/AnketaSharedComponents/ExpertAnketaHeader';
import { AnketaProgression } from '../../components/AnketaProgression/AnketaProgressionExpert';
import { AnketaExpertComment } from '../../components/AnketaExpertComment/AnketaExpertComment';
import AnketaDescriptionModal from '../../components/AnketaSharedComponents/AnketaDescriptionModal';
import PageLoader from '../../components/PageLoader';
import AnimatedRedirect from '../../components/AnimatedRedirect';

export const ExpertAnketaGeneralEvaluation: React.FC<{}> = () => {
  const { anketaID, anketaAnswersID, questionIndex } = useParams<{
    anketaID: string;
    anketaAnswersID: string;
    questionIndex: string;
  }>();
  const questionIdxMinus = Number(questionIndex) - 1;

  const { data: anketaData, status: anketaDataLoadingStatus } = useAnketaData(anketaID);
  const { data: anketaQuestionsData, status: anketaQuestionsDataLoadingStatus } = useAnketaQuetionsData(anketaID);
  const { data: anketaQuestionsAnswersData, status: anketaQuestionsAnswersDataLoadingStatus } = useAnketaQestionsAnswersData(anketaAnswersID);
  const { data: anketaAnswersData, status: anketaAnswersDataLoadingStatus } = useAnketaAnswersData(anketaAnswersID);

  const [isAnketaDescriptionModal, setIsAketaDescriptionModal] = useState<boolean>(false);

  if (
    anketaDataLoadingStatus === 'loading' ||
    anketaQuestionsDataLoadingStatus === 'loading' ||
    anketaQuestionsAnswersDataLoadingStatus === 'loading' ||
    anketaAnswersDataLoadingStatus === 'loading'
  ) {
    return <PageLoader />;
  }

  if (
    anketaDataLoadingStatus === 'error' ||
    anketaQuestionsDataLoadingStatus === 'error' ||
    anketaQuestionsAnswersDataLoadingStatus === 'error' ||
    anketaAnswersDataLoadingStatus === 'error'
  ) {
    return <AnimatedRedirect redirectTo='/500/' />;
  }

  if (anketaAnswersData.process_status === 4 || anketaAnswersData.process_status === 5) {
    return <AnimatedRedirect redirectTo='/expert/respondents/' />;
  }

  return (
    <motion.main className='expertAnketaRate' variants={standardPageVariants} initial='initial' animate='animate' exit='exit'>
      <ExpertAnketaHeader userData={anketaAnswersData.user} anketaData={anketaData} clientData={anketaAnswersData.client} />
      <AnketaProgression questionsCount={anketaQuestionsData.length} currentQuestionNumber={questionIdxMinus} anketaQuestionsAnswers={anketaQuestionsAnswersData} />
      {isAnketaDescriptionModal && <AnketaDescriptionModal onClose={() => setIsAketaDescriptionModal(false)} description={anketaData.description} />}
      <AnketaExpertComment anketaAnswersData={anketaAnswersData} anketaQestionsAnswersData={anketaQuestionsAnswersData} />
    </motion.main>
  );
};
