import './AnketaDescriptionModal.scss';



import Title from '../../Title';
import Modal from '../../Modal';
import ContainerWYSIWYG from '../../ContainerWYSIWYG';

interface Props {
  description: string;
  onClose: () => void;
}

export const AnketaDescriptionModal: React.FC<Props> = ({ description, onClose }) => {
  return (
    <Modal className='anketaDescriptionModal' onClose={onClose}>
      <Title>Описание кейса</Title>
      <ContainerWYSIWYG data={description} size='default' />
    </Modal>
  );
};
