import './NoTimeLeftModal.scss';

import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import Title from '../../../components/Title';

interface Props {
  onClose: () => void;
}

export const NoTimeLeftModal: React.FC<Props> = ({ onClose }) => {
  return (
    <Modal className='noTimeLeftModal' onClose={onClose}>
      <Title>Время на прохождение анкеты вышло</Title>
      <div className='text'>
        Ваша анкета будет просмотрена экспертом. <br /> После ее проверки результат анкетирования будет отправлен координирующему лицу.
      </div>
      <Button
        type='button'
        onClick={() => {
          onClose();
        }}
        width='260rem'
        size='small'
        color='black'
        text='Понятно'
      />
    </Modal>
  );
};
