import './InquirerForm.scss';
import 'react-quill/dist/quill.snow.css';

import * as FormData from 'form-data';
import * as Yup from 'yup';

import { FieldArray, Form, Formik } from 'formik';
import { backendURL, debug } from '../../../../App';
import { useHistory, useParams } from 'react-router-dom';

import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import InputError from '../../../../components/InputError';
import TextareaWYSIWYG from '../../../../components/TextareaWYSIWYG';
import __ from 'lodash';
import axios from 'axios';
import consoleLog from '../../../../helpers/consoleLog';
import icon_closeBlue from '../../../../assets/images/icon_closeBlue.svg';
import icon_plus from '../../../../assets/images/icon_plus.svg';
import { queryCache } from 'react-query';
import useAnketaData from '../../../../hooks/useAnketaData';

interface Props {
  questionData: {
    id: number;
    type: number;
    text: string;
    description: string;
    question_text: string;
    help_text: string;
    help_text_0: string;
    help_text_1: string;
    help_text_2: string;
    help_text_3: string;
    file: string;
    image: string;
    video: string;
    order: number;
    uploadingFile: File;
    indicators: any;
    subquestions: any;
  };
  questionsCount: number;
}

export const InquirerForm: React.FC<Props> = ({ questionData }) => {
  const history = useHistory();
  const { clientID, anketaID } = useParams<{ clientID: string; anketaID: string; questionIndex: string }>();
  const { data: anketaData, status: anketaDataLoadingStatus } = useAnketaData(anketaID);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        inquirer: anketaData?.inquirer?.questions?.length > 0 ? anketaData?.inquirer.questions : [{ text: '', variants: [] }],
      }}
      validationSchema={Yup.object({
        inquirer: Yup.array().of(
          Yup.object().shape({
            text: Yup.string().required('Поле обязательно для заполнения'),
          })
        ),
      })}
      onSubmit={async (values, { setSubmitting, setStatus }) => {
        let formData = new FormData();
        let inquirer = JSON.stringify({
          id: anketaData.inquirer?.id || '',
          questions: values.inquirer,
        });
        formData.append('inquirer', JSON.stringify(inquirer));

        try {
          const { data } = await axios({
            method: 'post',
            url: `${backendURL}anketa/${anketaData?.id}/`,
            headers: {
              Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
              // 'Content-Type': 'multipart/form-data',
            },
            data: {
              inquirer,
            },
          });
          setSubmitting(false);
          setStatus({ success: 'Сохранено успешно.' });
          setTimeout(() => {
            queryCache.invalidateQueries(['anketaQuestionsData', anketaID]);
            setStatus({ success: '' });
          }, 2000);
          debug && consoleLog('success', 'Question save', data);
        } catch (error) {
          setSubmitting(false);
          setStatus({ error: 'Упс. Что-то пошло не так.(' });
          setTimeout(() => {
            setStatus({ error: '' });
          }, 3000);
          debug && consoleLog('error', 'Question save', error);
        }
      }}
    >
      {props => (
        <Form>
          <div className='inquirerFieldsWrapper'>
            <FieldArray name='inquirer'>
              {({ push, remove }) =>
                props.values.inquirer.map((inquirer, index) => (
                  <>
                    <div className='inquirer'>
                      <div className='inquirerFieldWrapper'>
                        <TextareaWYSIWYG
                          value={props.values.inquirer[index].text}
                          onChange={value => props.setFieldValue(`inquirer[${index}].text`, value.replace(/(<([^>]+)>)/gi, '') ? value : '')}
                          onBlur={() => props.setFieldTouched(`inquirer[${index}].text`, true)}
                          placeholderType='alwaysVisible'
                          placeholder={`Вопрос для опросника ${index + 1}`}
                          // @ts-ignore
                          isValid={!(props.errors?.inquirer?.[index]?.text && props.touched?.inquirer?.[index]?.text)}
                          // @ts-ignore
                          validationError={
                            props.errors?.inquirer?.[index]?.text && props.touched?.inquirer?.[index]?.text ? props.errors?.inquirer?.[index]?.text : undefined
                          }
                          isDisabled={props.values.inquirer[index].id}
                          modulesType='defaultWithLink'
                        />
                      </div>
                      <div className='addRemoveButtons'>
                        {!(props.values.inquirer.length === 1 && index === 0) && (
                          <Button
                            type='button'
                            buttonType='button'
                            width='50rem'
                            size='medium'
                            color='white'
                            onClick={() => remove(index)}
                            disabled={!__.isNil(props.values.inquirer[index].id)}
                            className='whiteButton'
                          >
                            <img src={icon_closeBlue} alt='icon' className='iconRemove' />
                          </Button>
                        )}
                        {index + 1 === props.values.inquirer.length && (
                          <Button type='button' buttonType='button' width='50rem' size='medium' color='blue' onClick={() => push({ text: '', variants: [] })}>
                            <img src={icon_plus} alt='icon' className='iconAdd' />
                          </Button>
                        )}
                      </div>
                    </div>
                    <div className='inquirerQuestionType'>
                      <Button
                        type='button'
                        buttonType='button'
                        onClick={() => props.setFieldValue(`inquirer[${index}].variants`, [{ text: '' }])}
                        width='250rem'
                        size='medium'
                        color='blue'
                        className='next'
                        text='С вариантами ответа'
                        disabled={!__.isNil(props.values.inquirer[index].id)}
                      />
                      <Button
                        type='button'
                        buttonType='button'
                        onClick={() => props.setFieldValue(`inquirer[${index}].variants`, '')}
                        width='250rem'
                        size='medium'
                        color='black'
                        className='next'
                        text='Без вариантов ответа'
                        disabled={!__.isNil(props.values.inquirer[index].id)}
                      />
                    </div>
                    {inquirer.variants && (
                      <div className='inquirerVariantsWrapper'>
                        <FieldArray name={`inquirer.${index}.variants`}>
                          {({ push: pushVariant, remove: removeVariant }) =>
                            props.values.inquirer[index].variants.map((_, variantIndex) => (
                              <>
                                <Input
                                  name={`inquirer.${index}.variants.${variantIndex}.text`}
                                  type='text'
                                  value={props.values.inquirer[index].variants[variantIndex].text}
                                  onChange={event => props.setFieldValue(`inquirer.${index}.variants.${variantIndex}.text`, event.target.value)}
                                  onBlur={() => props.setFieldTouched(`inquirer.${index}.variants.${variantIndex}.text`, true)}
                                  placeholderType='alwaysVisible'
                                  placeholder={`Вариант ответа ${variantIndex + 1}`}
                                  isDisabled={props.values.inquirer[index].variants[variantIndex].id}
                                  isValid={true}
                                  size='small'
                                />
                                <div className='addRemoveButtons'>
                                  {!(props.values.inquirer[index].variants.length === 1 && variantIndex === 0) && (
                                    <Button
                                      type='button'
                                      buttonType='button'
                                      width='50rem'
                                      size='medium'
                                      color='white'
                                      onClick={() => removeVariant(variantIndex)}
                                      className='whiteButton'
                                      disabled={!__.isNil(props.values.inquirer[index].variants[variantIndex].id)}
                                    >
                                      <img src={icon_closeBlue} alt='icon' className='iconRemove' />
                                    </Button>
                                  )}
                                  {variantIndex + 1 === props.values.inquirer[index].variants.length && (
                                    <Button type='button' buttonType='button' width='50rem' size='medium' color='blue' onClick={() => pushVariant({ text: '' })}>
                                      <img src={icon_plus} alt='icon' className='iconAdd' />
                                    </Button>
                                  )}
                                </div>
                              </>
                            ))
                          }
                        </FieldArray>
                      </div>
                    )}
                  </>
                ))
              }
            </FieldArray>
          </div>

          <InputError className={`${props.status?.success ? 'success' : ''}`}>{props.status?.success || props.status?.error || ''}</InputError>

          <Button
            type='button'
            buttonType='submit'
            width='330rem'
            size='medium'
            color='blue'
            className='submitButton'
            text='Сохранить'
            loading={props.isSubmitting === true}
          />

          <Button
            type='link'
            href={`/admin/${clientID}/templates/${anketaID}/questions/`}
            width='330rem'
            size='medium'
            color='blue'
            className='next'
            text='К списку вопросов'
          />
        </Form>
      )}
    </Formik>
  );
};
