import './SupportModal.scss';
import icon_closeGray from '../../assets/images/icon_closeGray.svg';

import axios from 'axios';
import * as Yup from 'yup';
import _ from 'lodash';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import { backendURL } from '../../App';
import { Modal } from '../Modal/Modal';
import { Title } from '../Title/Title';
import { Button } from '../Button/Button';
import { InputError } from '../InputError/InputError';
import consoleLog from '../../helpers/consoleLog';
import { useHistory, useLocation } from 'react-router';

export const SupportModal: React.FC<{}> = () => {
  const history = useHistory();
  const location = useLocation();

  return (
    <Formik
      initialValues={{ title: '', text: '', file: {} as File }}
      validationSchema={Yup.object({
        title: Yup.string().required('поле должно быть заполнено'),
        text: Yup.string().required('поле должно быть заполнено'),
      })}
      onSubmit={(values, { setSubmitting, setStatus }) => {
        const formData = new FormData();
        formData.append('title', values.title);
        formData.append('text', values.text);
        values.file?.name && formData.append('file', values.file);

        axios({
          method: 'post',
          url: `${backendURL}support/`,
          headers: {
            Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
            'Content-Type': 'multipart/form-data',
          },
          data: formData,
        })
          .then(result => {
            setSubmitting(false);
            setStatus('success');
            consoleLog('success', 'Support message send', result);
          })
          .catch(error => {
            setSubmitting(false);
            setStatus('fail');
            consoleLog('error', 'Support message send', error);
          });
      }}
    >
      {props => (
        <Modal className='supportModal' onClose={() => history.push(location.pathname)}>
          <Form>
            <Title>Опишите свою проблему</Title>

            <div className='subTitle'>Опишите, с какой проблемой вы столкнулись.</div>

            <Field
              name='title'
              type='text'
              placeholder='Тема сообщения'
              className={`${props.errors.title && props.touched.title ? 'invalid' : ''}`}
              disabled={props.status === 'success'}
              autoComplete='off'
            />
            <InputError className='titleFieldError'>
              <ErrorMessage name='title' />
            </InputError>

            <Field
              name='text'
              type='text'
              placeholder='Описание проблемы'
              className={`${props.errors.text && props.touched.text ? 'invalid' : ''}`}
              disabled={props.status === 'success'}
              autoComplete='off'
            />
            <InputError className='textFieldError'>
              <ErrorMessage name='text' />
            </InputError>

            <div className='fileFieldContainer'>
              {props.values.file?.name && (
                <img src={icon_closeGray} className='icon' onClick={props.status !== 'success' ? () => props.setFieldValue('file', {} as File) : () => console.log()} />
              )}
              <div className='fileFieldInfo'>{props.values.file?.name || 'Вы можете загрузить файл или скриншот'}</div>
              <input type='file' name='file' id='file' onChange={event => props.setFieldValue('file', event.target.files![0])} accept='image/*' />
              <Button type='button' buttonType='button' width='230rem' size='small' color='black' disabled={props.status === 'success'}>
                {props.status === 'success' ? <span className='labelReplacement'>Загрузить файл</span> : <label htmlFor='file'>Загрузить файл</label>}
              </Button>
            </div>

            <InputError className={`submit ${props.status === 'success' ? 'success' : ''}`}>
              {props.status === 'success' && 'Обращение отправлено.'}
              {props.status === 'fail' && 'Что-то пошло не так. Свяжитесь с администратором.'}
              {props.values.file?.size >= 5248000 && 'Превышен допустимый размер файла в 5мб.'}
            </InputError>

            <Button
              type='button'
              width='330rem'
              size='large'
              color='blue'
              text='Отправить'
              loading={props.isSubmitting}
              disabled={props.status === 'success' || (props.values.file?.size >= 5248000 && true)}
            />
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
