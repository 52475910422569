import { useQuery } from 'react-query';
import axios from 'axios';
import { backendURL } from '../App';

const getAnketaData = async (_, id) => {
  if (id) {
    const { data } = await axios({
      method: 'get',
      url: `${backendURL}anketa/${id}/`,
      headers: {
        Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
      },
    });
    return data;
  }
  return undefined;
};

export default function useAnketaData(id) {
  return useQuery(['anketaData', id], getAnketaData, { retry: false, refetchOnWindowFocus: false });
}
