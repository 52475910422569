const statusNumToString = (number: number) => {
  switch (number) {
      case 0:
        return 'Новая';
      case 1:
        return 'Заполняется';
      case 2:
        return 'Без оценки';
      case 3:
        return 'Оценивается';
      case 4:
        return 'Для проверки';
      case 5:
        return 'Проверена';
      case 6:
        return 'Опубликована';
      default:
        break;
    }
}

export default statusNumToString;