import './DeleteQuestionModal.scss';


import { queryCache } from 'react-query';

import useDeleteQuestionMutation from '../../../../hooks/useDeleteQuestionMutation';
import Title from '../../../../components/Title';
import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';

interface Props {
  questionID: number;
  anketaID: number;
  onClose: () => void;
}

export const DeleteQuestionModal: React.FC<Props> = ({ questionID, anketaID, onClose }) => {
  const [mutate, { status: deleteQuestionMutationStatus }] = useDeleteQuestionMutation({
    onSuccess: () => {
      queryCache.invalidateQueries(['anketaQuestionsData', anketaID]);
      onClose();
    },
  });

  return (
    <Modal className='deleteQuestionModal' onClose={onClose}>
      <Title>Удалить вопрос</Title>
      <div className='text'>Вы уверены, что хотите удалить этот вопрос?</div>
      <Button
        type='button'
        size='medium'
        width='330rem'
        color='blue'
        onClick={() => mutate({ questionID })}
        text='Удалить'
        loading={deleteQuestionMutationStatus === 'loading' ? true : false}
      />
      <Button type='button' size='medium' width='330rem' color='white' text='Оставить' onClick={() => onClose()} />
    </Modal>
  );
};
