import log from 'log-beautify';
import { debug } from '../App';

log.useSymbols = true;
log.useLabels = true;
log.setSymbols({
  success: "🦄",
  info: "👀",
  error: "💩"
});
log.setColors({
  success: '#FF00FF',
  success_: '#00AEEF',
  error: '#EF004A',
  info: '#158467',
  info_: '#197163	'
});

export default function consoleLog(type: 'success' | 'error' | 'info', action, data) {
  if (debug) {
    switch (type) {
      case 'error':
        log.show('_________________________________\n\n');
        log.error(`${action} fail`);
        data.response && log.show('Error responce: \n\n', data.response);
        data.message && log.show('Error message: ', data.message);
        log.show('_________________________________\n');
        break;
      case 'success':
        log.show('\n_________________________________');
        log.success(`${action} success`);
        log.show('Data: \n\n', data);
        data.responce && log.show('Responce: \n\n', data.response);
        // data.data && log.show('Data: ', data.data);        
        log.show('_________________________________\n');
        break;
      case 'info':
        log.show('\n_________________________________');
        log.info(`${action} data`);
        data && log.show('\n', data);
        log.show('_________________________________\n');
        break;
      default:
        break;
    }
  }
}
