import './RecomendationResult.scss';

import ContainerWYSIWYG from '../../ContainerWYSIWYG';
import Title from '../../Title';
import {reportColors} from '../../../helpers/colorPalete';
import {useLocation} from 'react-router-dom';

interface Props {
    data: {
        member_first_name: string;
        member_last_name: string;
        anketa_free_comment: boolean;
        comment_member: string;
        comment_hr: string;
        anketa_recomendation: string | string[];
        streams_count: number;
        client_color: string;
        answers: any;
        diagram_data: any;
    };
}

export const RecomendationResult: React.FC<Props> = ({data}) => {
    const location = useLocation();
    const {linksColor} = reportColors(data.client_color, data.streams_count);

    const indicatorPercent = (indicator) => {
        let result: object[] = [];
        data.diagram_data.forEach(diagram => {
            if (diagram[0] == indicator.title) {
                result.push(diagram[1]);
            }
        });

        return result;
    }

    const indicators = (answer) => {
        let indicatorsArray: object[] = [];

        answer.question.indicators.forEach(indicator => {
            indicatorsArray.push(
                <>
                    <p className='answerTitle'>{indicator.title}</p>
                    <p className='answerTitle'>Набранный процент: {indicatorPercent(indicator)}%</p>
                </>
            );
        });

        return indicatorsArray;
    }

    const answers = () => {
        let answersArray: object[] = [];
        data.answers.forEach(answer => {
            if (answer.expert_comment != "") {
                answersArray.push(
                    <div className='answer'>
                        <>
                            {indicators(answer)}
                        </>
                        <p className='answerTitle'>Текст вопроса:</p>
                        <ContainerWYSIWYG size='default' data={answer.question.question_text} />
                        <p></p>
                        <p className='answerTitle'>Ваш ответ:</p>
                        <ContainerWYSIWYG size='default' data={answer.text} />
                        {answer.expert_comment !== "" ? (
                            <>
                                <p className='answerTitle'>Комментарий эксперта:</p>
                                <ContainerWYSIWYG size='default' data={answer.expert_comment} />
                            </>
                        ) : null}
                    </div>
                );
            }
        });

        return answersArray;
    }

    return (
        <div className='recomendationResult'>
            <div className='comment'>
                <p>Добрый день, {data.member_first_name} {data.member_last_name}.</p>
                <p>Благодарим Вас за участие в оценке, проведенной Центром Продуктовой Трансформации. Мы ценим Ваше время и вклад в этот процесс.</p>
                <p>На основании полученных результатов, мы подготовили подробные комментарии и рекомендации, которые помогут Вам определить ключевые точки роста и развития. Мы уверены, что данная информация будет полезной для дальнейшего профессионального совершенствования и достижения новых высот.</p>
                <p>Важно отметить, что результаты диагностики не всегда полностью отражают ваш реальный вклад на рабочем месте, поскольку успех в работе зависит не только от Hard Skills, но и от Soft Skills, а также вашего опыта и экспертизы. Таким образом, текущие результаты указывают на значительный потенциал, который еще предстоит полностью реализовать. Обучение поможет вам выявить области для профессионального и карьерного роста, а также повысить эффективность в решении рабочих задач.</p>
            </div>
            <div className='answers'>
                {answers()}
            </div>
        </div>
    )
}
