import { useQuery } from 'react-query';
import axios from 'axios';
import { backendURL } from '../App';
import __ from 'lodash';

const getClientMetricsData = async (_, clientID, streamID) => {
  const { data } = await axios({
    method: 'get',
    url: `${backendURL}metrics/${clientID}/`,
    headers: {
      Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
    },
    params: {
      stream_id: !__.isNil(streamID) ? streamID : '',
    },
  });

  return data;
};

export default function useClientMetricsData(clientID, streamID) {
  return useQuery(['clientMetricsData', clientID, streamID], getClientMetricsData, { retry: false });
}
