import './TemplateStage2Form.scss';
import 'react-quill/dist/quill.snow.css';

import Button from '../../../components/Button';
import Competension from './Competension';
import Title from '../../../components/Title';
import icon_loading from '../../../assets/images/icon_loadingBlue.svg';
import { motion } from 'framer-motion';
import useCompetensionsData from '../../../hooks/useCompetensionsData';
import { useParams } from 'react-router-dom';
import { useState } from 'react';

export const TemplateStage2Form: React.FC<{}> = () => {
  const { clientID, anketaID } = useParams<{ clientID: string; anketaID: string }>();
  const { data: competensionsData, status: competensionsDataLoadingStatus } = useCompetensionsData(anketaID);
  const [isNewComptension, setIsNewComptension] = useState(false);

  if (competensionsDataLoadingStatus === 'loading') {
    return (
      <div className='isLoading'>
        <motion.img src={icon_loading} initial={{ rotate: 0 }} animate={{ rotate: 360 }} transition={{ duration: 2, loop: Infinity, ease: 'linear' }} />
      </div>
    );
  }

  return (
    <section className='templateStage2Form'>
      <Title>Создание списка компетенций</Title>
      {competensionsData.map((competension, index) => (
        <Competension
          competensionData={competension}
          isAddCompenetsionButton={competensionsData.length === index + 1 && !isNewComptension ? true : false}
          addCompenetsion={setIsNewComptension}
        />
      ))}
      {(competensionsData.length === 0 || isNewComptension) && <Competension addCompenetsion={setIsNewComptension} />}
      <Button
        type='link'
        href={`/admin/${clientID}/templates/${anketaID}/inquirer/`}
        width='330rem'
        size='medium'
        color='blue'
        className='addComptensionButton'
        text='К опроснику'
      />
    </section>
  );
};
