import './RegistrationMember.scss';

import * as Yup from 'yup';

import { CTP, backendURL, debug } from '../../App';
import { ErrorMessage, Field, Form, Formik } from 'formik';

import Button from '../../components/Button';
import InputError from '../../components/InputError/';
import axios from 'axios';
import consoleLog from '../../helpers/consoleLog';
import { formVariants } from '../../animationsVariants/FormVariants';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';

export const RegistrationMember: React.FC<{}> = () => {
  const history = useHistory();
  const [errorOnSubmit, setErrorOnSubmit] = useState('');

  return (
    <Formik
      initialValues={{
        member_type: 'member',
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        phone: '',
      }}
      validationSchema={Yup.object({
        first_name: Yup.string().max(15, 'Должно быть не более 15 символов').required('поле должно быть заполнено'),
        last_name: Yup.string().max(20, 'Должно быть не более 20 символов').required('поле должно быть заполнено'),
        email: Yup.string().email('некорректный email адрес').required('поле должно быть заполнено'),
        password: Yup.string().min(8, 'пароль должен содержать минимум 8 символов').required('поле должно быть заполнено'),
        phone: Yup.string().matches(/^((\+7|7|)+([0-9]){10})$/, 'тел. номер должен быть вида 79991112233 или +79991112233'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        axios({
          method: 'post',
          url: `${backendURL}auth/registration/`,
          data: values,
        })
          .then(result => {
            setSubmitting(false);
            setErrorOnSubmit('');
            localStorage.setItem('skillsSurveyUserToken', result.data.token);
            localStorage.setItem('skillsSurveyUserID', result.data.user.id);
            localStorage.setItem('skillsSurveyUserType', 'is_member');
            history.push('/email_confirm/');
            debug && consoleLog('success', 'Member registration', result);
          })
          .catch(error => {
            setSubmitting(false);
            if (error.response.data?.error?.email === 'Пользователь уже зарегистрирован') {
              setErrorOnSubmit('Пользователь с таким email-ом уже зарегистрирован.');
              setTimeout(() => {
                setErrorOnSubmit('');
              }, 3000);
            } else {
              setErrorOnSubmit('Ошибка. Свяжитесь с администратором.');
              setTimeout(() => {
                setErrorOnSubmit('');
              }, 3000);
            }
            debug && consoleLog('error', 'Member registration', error);
          });
      }}
    >
      {props => (
        <main className='registration'>
          <motion.div className='clientRegistrationForm formContainer' variants={formVariants} initial='initial' animate='animate' exit='exit'>
            <Form>
              <h1>Регистрация на сервисе</h1>

              <Field name='first_name' type='text' placeholder={'Ваше имя'} className={`${props.errors.first_name && props.touched.first_name ? 'invalid' : ''}`} />
              <InputError>
                <ErrorMessage name='first_name' />
              </InputError>

              <Field name='last_name' type='text' placeholder={`Ваша фамилия`} className={`${props.errors.last_name && props.touched.last_name ? 'invalid' : ''}`} />
              <InputError>
                <ErrorMessage name='last_name' />
              </InputError>

              <Field name='email' type='email' placeholder='Ваш e-mail' className={`${props.errors.email && props.touched.email ? 'invalid' : ''}`} />
              <InputError>
                <ErrorMessage name='email' />
              </InputError>

              <Field
                name='password'
                type='password'
                placeholder='Ваш пароль'
                className={`${props.errors.password && props.touched.password ? 'invalid' : ''}`}
                autocomplete='new-password'
              />
              <InputError>
                <ErrorMessage name='password' />
              </InputError>

              <Field
                name='phone'
                type='tel'
                placeholder='Номер вашего телефона (необязательное поле)'
                className={`${props.errors.phone && props.touched.phone ? 'invalid' : ''}`}
              />
              <InputError>
                <ErrorMessage name='phone' />
              </InputError>

              <InputError className='submitError'>{errorOnSubmit}</InputError>

              <Button type='button' width='330rem' size='medium' color='blue' text='Зарегистрироваться' loading={props.isSubmitting} disabled={props.isSubmitting} />

              <div className='policyAgreementText'>
                Нажимая на кнопку «Зарегистрироваться»,
                <br /> вы даете{' '}
                <a
                  className='link'
                  href={
                    CTP
                      ? 'https://storage.yandexcloud.net/cpt-survey-production-storage/pdf_docs/%D0%A1%D0%BE%D0%B3%D0%BB%D0%B0%D1%81%D0%B8%D0%B5%20%D0%B4%D0%BB%D1%8F%20%D1%81%D0%B0%D0%B8%CC%86%D1%82%D0%B0%20Survey.pdf'
                      : 'https://storage.yandexcloud.net/skills-production-static/policy/Soglasie-na-obrabotku-SKILLS.pdf'
                  }
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  согласие
                </a>{' '}
                на обработку персональных данных.
                <br />
                <br />
                <a
                  className='link'
                  href={
                    CTP
                      ? 'https://storage.yandexcloud.net/cpt-survey-production-storage/pdf_docs/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0%20%D0%B2%20%D0%BE%D1%82%D0%BD%D0%BE%D1%88%D0%B5%D0%BD%D0%B8%D0%B8%20%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B8%20%D0%9F%D0%B5%D1%80%D1%81%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85%20%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85.pdf'
                      : 'https://storage.yandexcloud.net/skills-production-static/policy/Politika_obrabotki_personalnih_dannih.pdf'
                  }
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Политика обработки персональных данных
                </a>
              </div>

              <Button type='link' href='/' width='280rem' size='small' color='black' text='Войти на сервис' className='loginButtonLink' />
            </Form>
          </motion.div>
        </main>
      )}
    </Formik>
  );
};
