import './ExpertAnketaAnswerText.scss';
import 'react-quill/dist/quill.snow.css';


import ContainerWYSIWYG from '../../../components/ContainerWYSIWYG';

interface AnketaAnswerProps {
  answer: string;
}

export const ExpertAnketaAnswerText: React.FC<AnketaAnswerProps> = ({ answer }) => {
  return (
    <div className='expertAnketaAnswerText'>
      <div className='answerSubTitle'>Ответ</div>
      <ContainerWYSIWYG data={answer} size='default' />
    </div>
  );
};
