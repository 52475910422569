import './ProfileAnketasTable.scss';

import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFilters, useRowSelect, useSortBy, useTable } from 'react-table';
import { useMemo, useState } from 'react';

import { COLUMNS } from './ProfileAnketasTableColumns';
import _ from 'lodash';
import icon_resetFilter from '../../../assets/images/icon_resetFilter.svg';
import icon_search from '../../../assets/images/icon_search.svg';
import icon_sortDown from '../../../assets/images/icon_sortDown.svg';
import icon_zoomVideo from '../../../assets/images/icon_zoomVideo.svg';
import useDynamicRefs from 'use-dynamic-refs';

interface Props {
  filteredData?: any;
  memberID?: number | null | undefined;
  clientID?: number;
}

export const ProfileAnketasTable: React.FC<Props> = ({ filteredData }) => {
  const dispatch = useDispatch();
  const [getRef, setRef] = useDynamicRefs();
  const { streamID } = useParams<{ streamID: string }>();
  // * Фильтры для таблица
  const DefaultColumnFilter = () => {
    return <img alt='icon' className='searchIcon tribeSearch' src={icon_search} />;
  };
  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );
  // * Стркутруа колнок для таблицы
  const columns = useMemo(() => COLUMNS, []);
  // * Данные для таблицы
  const data = useMemo(() => filteredData, [filteredData]);

  const closeAllOpened = (current: number) => {
    filteredData.forEach(element => {
      if (current !== element.anketaAnswersID) {
        // @ts-ignore
        getRef(`${element.anketaAnswersID}toggleButton`)?.current?.classList.remove('active');
        // @ts-ignore
        getRef(`${element.anketaAnswersID}buttons`)?.current?.classList.remove('visible');
      }
    });
  };

  // * Инциализация таблицы
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      defaultColumn,
      disableSortRemove: true,
      autoResetSortBy: false,
      initialState: {
        sortBy: [
          {
            id: 'date',
            desc: true,
          },
        ],
      },
    },
    useFilters,
    useSortBy,
    useRowSelect
  );

  return (
    <section className='profileAnketasTable'>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={`headerGroup-tr-${index}`}>
              {headerGroup.headers.map((column, index) => (
                <th {...column.getHeaderProps} key={`headerGroup-tr-th-${index}`}>
                  <div {...column.getSortByToggleProps()}>
                    {column.render('Header')}
                    {!column.disableSortBy &&
                      (column.isSortedDesc ? (
                        <img alt='icon' className='sortDownIcon' src={icon_sortDown} />
                      ) : (
                        <img alt='icon' className='sortUpIcon' src={icon_sortDown} />
                      ))}
                  </div>
                  {column.canFilter ? column.render('Filter') : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={`body-tr-${i}`}>
                {row.cells.map((cell, i: number) => {
                  return (
                    <td {...cell.getCellProps()} key={`body-tr-td-${i}`}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </section>
  );
};
