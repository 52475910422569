import './Error500.scss';


import { motion } from 'framer-motion';
import { formVariants } from '../../animationsVariants/FormVariants';
import { Title } from '../../components/Title/Title';

export const Error500: React.FC<{}> = () => (
  <main className='error500'>
    <motion.div className='formContainer' variants={formVariants} initial='initial' animate='animate' exit='exit'>
      <Title>Страница 500</Title>
      <div className='text'>
        Сервис временно недоступен. <br />
        Попробуйте вернуться назад и перезагрузить страницу <br /> или воспользуйтесь сервисом позднее.
      </div>
    </motion.div>
  </main>
);
