import { backendURL, debug } from '../../../../App';
import { useRef, useState } from 'react';

import Button from '../../../../components/Button';
import ButtonLoader from '../../../../components/ButtonLoader';
import _ from 'lodash';
import axios from 'axios';
import consoleLog from '../../../../helpers/consoleLog';
import icon_expand from '../../../../assets/images/icon_expand.svg';
import useInterval from '../../../../helpers/useInterval';
import useOnClickOutside from '../../../../helpers/useOnClickOutside';
import { useParams } from 'react-router-dom';

export const AdminClientAnketasDownload = ({ selectedAnketas, setIsChangeTrbieModal }) => {
  const { streamID } = useParams<{ streamID: string }>();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const handleClickOutside = () => {
    setIsDropdownOpen(false);
  };
  useOnClickOutside(dropdownRef, handleClickOutside);

  const [archiveID, setArchiveID] = useState();
  const [isDownloading, setIsDownloading] = useState('not_started');
  const [downloadingProgress, setDownloadingProgress] = useState({ total: undefined as undefined | number, count: undefined as undefined | number });
  // * Начать скачивания / подготовку архива с PDF-ками
  const downloadPDF = ({ withEmail }) => {
    let selectedCompletedAnketas: Object[] = [];
    selectedAnketas.forEach(row => {
      (row.original.status === 'Проверена' || row.original.status === 'Опубликована') && selectedCompletedAnketas.push(row.original.anketaAnswersID);
    });
    setDownloadingProgress({ total: selectedCompletedAnketas.length, count: 0 });

    axios({
      method: 'post',
      url: `${backendURL}reports/archive/`,
      headers: {
        Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
      },
      data: {
        anketa_answers_ids: selectedCompletedAnketas.join(','),
        client_url: _.isNil(streamID)
          ? `PDF_FRONTEND_URL/admin/CLIENT_ID/anketa_questions/ANKETA_ID/AA_ID/pdf/`
          : `PDF_FRONTEND_URL/admin/CLIENT_ID/${streamID}/anketa_questions/ANKETA_ID/AA_ID/pdf/`,
        hr_url: _.isNil(streamID)
          ? `PDF_FRONTEND_URL/admin/CLIENT_ID/anketa_questions/ANKETA_ID/AA_ID/pdf/for_hr/`
          : `PDF_FRONTEND_URL/admin/CLIENT_ID/${streamID}/anketa_questions/ANKETA_ID/AA_ID/pdf/for_hr/`,
        wait: 'footer&scrollPage=true&ignoreHttpsErrors=true&emulateScreenMedia=false',
        with_email: withEmail,
      },
    })
      .then(result => {
        setArchiveID(result.data.archive_id);
        setIsDownloading(result.data.status);
        debug && consoleLog('success', 'archiveCreateRequest', result);
      })
      .catch(error => {
        error && consoleLog('error', 'archiveCreateRequest', error);
      });
  };
  // * Проверяем готов ли наш архив с pdf-ками
  const checkDownloadingPDF = () => {
    axios({
      method: 'get',
      url: `${backendURL}reports/archive/`,
      headers: {
        Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
      },
      params: {
        archive_id: archiveID,
      },
    })
      .then(result => {
        if (result.data.status === 'ready') {
          setIsDownloading('not_started');
          setDownloadingProgress({ total: undefined, count: undefined });
          const link = document.createElement('a');
          link.href = result.data.link;
          link.setAttribute('download', 'clientReport.pdf');
          document.body.appendChild(link);
          link.click();
          link.remove();
        } else {
          setDownloadingProgress({ total: result.data.total, count: result.data.count });
        }
        debug && consoleLog('success', 'checkDownloadingPDF', result);
      })
      .catch(error => {
        setIsDownloading('error');
        setDownloadingProgress({ total: undefined, count: undefined });
        error && consoleLog('error', 'checkDownloadingPDF', error);
      });
  };
  // * Проверяем раз в 3 секунды
  useInterval(
    () => {
      checkDownloadingPDF();
    },
    isDownloading === 'in_process' ? 3000 : null
  );

  const downloadXLSX = (type: 'admin-ball' | 'admin-tribe' | 'admin-status') => {
    setIsDownloading('in_process_xlsx');

    let selectedCompletedAnketas: Object[] = [];
    selectedAnketas.forEach(row => {
      (row.original.status === 'Проверена' || row.original.status === 'Опубликована') && selectedCompletedAnketas.push(row.original.anketaAnswersID);
    });

    axios({
      method: 'get',
      url: `${backendURL}${type}/`,
      headers: {
        Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
      },
      params: {
        anketa_answers_id: selectedCompletedAnketas.join(','),
      },
    })
      .then(result => {
        setIsDownloading('not_started');
        const link = document.createElement('a');
        link.href = result.data.link;
        link.setAttribute('download', 'clientReport.pdf');
        document.body.appendChild(link);
        link.click();
        link.remove();
        debug && consoleLog('success', 'Download xlsx', result);
      })
      .catch(error => {
        error && consoleLog('error', 'Download xlsx', error);
      });
  };

  return (
    <div className='buttonsContainer'>
      {isDownloading === 'not_started' && (
        <>
          <div ref={dropdownRef}>
            <Button
              type='button'
              buttonType='button'
              width='150rem'
              size='medium'
              color='blue'
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className='dropdownButton'
            >
              <span>XLSX выгрузки</span> <img src={icon_expand} alt='expand' />
            </Button>

            {!isDropdownOpen ? null : (
              <div className='dropdownMenu'>
                <Button type='button' buttonType='button' width='350rem' size='medium' color='white--blackText' onClick={() => downloadXLSX('admin-tribe')}>
                  Выгрузить по анкете
                </Button>
                <Button type='button' buttonType='button' width='350rem' size='medium' color='white--blackText' onClick={() => downloadXLSX('admin-status')}>
                  Выгрузить по статусу прохождения
                </Button>
                <Button type='button' buttonType='button' width='350rem' size='medium' color='white--blackText' onClick={() => downloadXLSX('admin-ball')}>
                  Выгрузить по комптенциям и индикаторам
                </Button>
              </div>
            )}
          </div>

          <Button type='button' buttonType='button' width='260rem' size='medium' color='gray' onClick={() => downloadPDF({ withEmail: false })}>
            Выгрузить все анкеты в PDF
          </Button>
          <Button type='button' buttonType='button' width='420rem' size='medium' color='gray' onClick={() => downloadPDF({ withEmail: true })}>
            Выгрузить все анкеты в PDF и разослать участникам
          </Button>

          <Button type='button' buttonType='button' width='260rem' size='medium' color='gray' onClick={setIsChangeTrbieModal}>
            Изменить трайб
          </Button>
        </>
      )}

      {(isDownloading === 'in_process' || isDownloading === 'in_process_xlsx') && (
        <Button type='button' width='300rem ' size='medium' color='black' className='pdfButton'>
          <ButtonLoader />
          <span style={{ paddingLeft: '10rem' }}>
            Файлы формируются{' '}
            {downloadingProgress.total ? (
              <>
                {downloadingProgress.count} из {downloadingProgress.total}
              </>
            ) : null}
          </span>
        </Button>
      )}

      {isDownloading === 'error' && (
        <div className='errorMessage'>
          Произошла ошибка при формировании файлов, попробуйте обновить страницу и повторить действие.
          <br />
          Если ошибка повторилась, свяжитесь с администратором проекта.
        </div>
      )}
    </div>
  );
};
