import axios from 'axios';
import { backendURL } from '../App';
import { useQuery } from 'react-query';

const getClientAnketaData = async (_, anketaID) => {
  const { data } = await axios({
    method: 'get',
    url: `${backendURL}user/anketa/`,
    headers: {
      Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
    },
    params: {
      anketa_id: anketaID,
    },
  });
  return data;
};

export default function useClientAnketaData(anketaID) {
  return useQuery(['clientAnketaData', anketaID], getClientAnketaData, { retry: true, refetchOnWindowFocus: false });
}
