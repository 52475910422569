import './Header.scss';

import { CTP, backendURL } from '../../App';
import { Link, matchPath, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { Button } from '../Button/Button';
import _ from 'lodash';
import axios from 'axios';
import consoleLog from '../../helpers/consoleLog';
import { headerVariants } from '../../animationsVariants/HeaderVariants';
import { header_display_type_toggle } from '../../actions';
import icon_UpWhite from '../../assets/images/icon_UpWhite.svg';
import icon_plus from '../../assets/images/icon_plus.svg';
import logoBlue from '../../assets/images/logo-blue.svg';
import logoBlueCTP from '../../assets/images/logo-blue_ctp.svg';
import logoWhite from '../../assets/images/logo-white.svg';
import logoWhiteCTP from '../../assets/images/logo-white_ctp.svg';
import { motion } from 'framer-motion';
import { queryCache } from 'react-query';
import useClientsData from '../../hooks/useClientsData';
import useUserData from '../../hooks/useUserData';

interface Props {
  params?: any;
}

export const Header: React.FC<Props> = ({ params }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  // @ts-ignore
  const streamID = Number(matchPath(location.pathname, { path: '/admin/:clientID/:streamID/' })?.params?.streamID);
  // @ts-ignore
  const clientID = Number(matchPath(location.pathname, { path: '/admin/:clientID/' })?.params?.clientID);

  // const clientID = parseInt(location.pathname.slice(7, 9)) ? parseInt(location.pathname.slice(7, 9)) : null;
  const header_display_type = useSelector((state: any) => state.headerReducer.header_display_type);
  const [clientsIsExpanded, setClientsIsExpanded] = useState(false);
  const [streamsIsExanded, setStreamsIsExanded] = useState(false);

  const { data: userData, status: userDataLoadingStatus } = useUserData();
  const { data: clientsData, status: clientsDataLoadingStatus } = useClientsData();

  useEffect(() => {
    clientsIsExpanded && setClientsIsExpanded(false);
    streamsIsExanded && setStreamsIsExanded(false);
    location.pathname === '/' && dispatch(header_display_type_toggle('basic'));
    location.pathname === '/404/' && dispatch(header_display_type_toggle('basic'));
    location.pathname === '/500/' && dispatch(header_display_type_toggle('basic'));
    location.pathname === '/email_confirm/' && dispatch(header_display_type_toggle('basic'));
    location.pathname === '/profile/' && dispatch(header_display_type_toggle('userIsLogged'));
    location.pathname === '/client/anketas/' && dispatch(header_display_type_toggle('userIsLogged'));
    _.includes(location.pathname, '/client/anketa/') && dispatch(header_display_type_toggle('userIsLogged'));
    location.pathname.indexOf('/expert/') !== -1 && dispatch(header_display_type_toggle('userIsLogged'));
    location.pathname.indexOf('/registration/') !== -1 && dispatch(header_display_type_toggle('basic'));
    location.pathname.indexOf('/login/') !== -1 && dispatch(header_display_type_toggle('basic'));
    location.pathname.indexOf('/admin/') !== -1 && dispatch(header_display_type_toggle('admin'));
  }, [location, dispatch]);

  const onLogoClick = () => {
    if (localStorage.getItem('skillsSurveyUserType') === 'is_member') {
      location.pathname === '/404/' && history.push('/client/anketas/');
      location.pathname === '/500/' && history.push('/client/anketas/');
      location.pathname === '/profile/' && history.push('/client/anketas/');
      location.pathname.indexOf('/client/') !== -1 && history.push('/client/anketas/');
    } else if (localStorage.getItem('skillsSurveyUserType') === 'is_expert') {
      location.pathname === '/404/' && history.push('/expert/schedule/');
      location.pathname === '/500/' && history.push('/expert/schedule/');
      location.pathname === '/profile/' && history.push('/expert/schedule/');
      location.pathname.indexOf('/expert/') !== -1 && history.push('/expert/schedule/');
    } else if (localStorage.getItem('skillsSurveyUserType') === 'is_moderator') {
      location.pathname === '/404/' && history.push('/expert/schedule/');
      location.pathname === '/500/' && history.push('/expert/schedule/');
      location.pathname === '/profile/' && history.push('/admin/');
      location.pathname.indexOf('/admin/') !== -1 && history.push('/admin/');
    }
  };

  const logout = () => {
    axios({
      method: 'post',
      url: `${backendURL}auth/logout/`,
      headers: {
        Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
      },
    })
      .then(result => {
        history.push('/');
        localStorage.removeItem('skillsSurveyUserToken');
        localStorage.removeItem('skillsSurveyUserID');
        localStorage.removeItem('skillsSurveyUserType');
        consoleLog('success', 'Logout', result);
      })
      .catch(error => {
        consoleLog('error', 'Logout', error);
      });
  };

  useEffect(() => {
    if (location.pathname === '/' && userDataLoadingStatus === 'success' && localStorage.getItem('skillsSurveyUserToken')) {
      if (userData?.user?.is_member) {
        history.push('/client/anketas/');
      }
      if (userData?.user?.is_expert) {
        history.push('/expert/schedule/');
      }
      if (userData?.user?.is_moderator) {
        history.push('/admin/');
      }
    }
  }, [userData, userDataLoadingStatus, history, location.pathname]);

  return (
    userDataLoadingStatus === 'success' &&
    header_display_type && (
      <>
        <motion.header
          className={`header ${header_display_type === 'userIsLogged' || header_display_type === 'admin' ? 'userIsLogged' : ''}`}
          variants={headerVariants}
          initial='initial'
          animate='animate'
          exit='exit'
        >
          <div className='userHeader'>
            <div className='contentContainer'>
              {header_display_type === 'userIsLogged' || header_display_type === 'admin' ? (
                <img src={CTP ? logoBlueCTP : logoBlue} className={'logo'} alt={'logo'} onClick={() => onLogoClick()} />
              ) : (
                <img src={CTP ? logoWhiteCTP : logoWhite} className={'logo'} alt={'logo'} onClick={() => onLogoClick()} />
              )}

              {(header_display_type === 'userIsLogged' || header_display_type === 'admin') && (
                <nav>
                  {userData?.user?.is_member && (
                    <Link to={`${location.pathname}?support/`} className='link link-white-style'>
                      Служба поддержки
                    </Link>
                  )}
                  <Link to='/profile/' className='link link-white-style'>
                    {`${userData?.user?.first_name} ${userData?.user?.last_name}`}
                  </Link>
                  <button onClick={() => logout()} className='link link-pale-style'>
                    Выйти
                  </button>
                </nav>
              )}
            </div>
          </div>

          {(_.includes(location.pathname, 'profile') || _.includes(location.pathname, '/client/anketas/')) && userData.user.is_member ? (
            <div className='adminBottomHeader'>
              <div className='contentContainer'>
                <nav>
                  <Link to='/client/anketas/' className={`link link-button ${_.includes(location.pathname, 'anketas') ? 'active' : ''}`}>
                    Анкеты
                  </Link>
                  <Link to='/profile' className={`link link-button ${_.includes(location.pathname, 'profile') ? 'active' : ''}`}>
                    Ваши данные
                  </Link>
                </nav>
              </div>
            </div>
          ) : null}

          {header_display_type === 'admin' && location.pathname.indexOf('/pdf/') === -1 && clientsDataLoadingStatus === 'success' && (
            <>
              <div className='adminTopHeader'>
                <div className='contentContainer'>
                  <nav>
                    <Button
                      type='button'
                      buttonType='button'
                      color='black'
                      size='small'
                      width='350rem'
                      className='clientsExpandButton'
                      onClick={() => setClientsIsExpanded(!clientsIsExpanded)}
                      dataCy='clientListButton'
                    >
                      <span>
                        Список клиентов
                        {_.isNaN(clientID) ? '' : ': '}
                        {_.find(clientsData, { id: clientID })?.short_title}
                      </span>
                      <img src={icon_UpWhite} alt='icon' className='clientsExpandIcon' />
                    </Button>

                    {!clientID ? null : _.find(clientsData, { id: clientID })?.streams.length > 1 ? (
                      <Button
                        type='button'
                        buttonType='button'
                        color='black'
                        size='small'
                        width='350rem'
                        className='streamsExpandButton'
                        onClick={() => setStreamsIsExanded(!streamsIsExanded)}
                        dataCy='streamListButton'
                      >
                        <span>
                          Список потоков
                          {_.isNaN(streamID) ? '' : ': '}
                          {
                            _.chain(clientsData)
                              .find(client => client.id === clientID)
                              .value()
                              .streams.find(stream => stream.id === streamID)?.title
                          }
                        </span>
                        <img src={icon_UpWhite} alt='icon' className='clientsExpandIcon' />
                      </Button>
                    ) : (
                      <Button
                        type='button'
                        buttonType='button'
                        color='black'
                        size='small'
                        width='350rem'
                        className='streamsExpandButton'
                        onClick={() => null}
                        disabled={true}
                      >
                        <span>Первый поток</span>
                      </Button>
                    )}

                    <Button type='link' href='/admin/new_client/' color='blue' size='small' width='230rem' className='addClientButton' dataCy='addClientBtn'>
                      <img src={icon_plus} alt='icon' className='addClientIcon' />
                      <span>Добавить клиента</span>
                    </Button>

                    <div className={`clients ${clientsIsExpanded ? 'expanded' : ''}`} data-cy='clientListExpanded'>
                      <Link to='/admin/' className={`link link-button ${location.pathname === '/admin/' ? 'active' : ''}`}>
                        Все клиенты
                      </Link>
                      {_.map(clientsData, client => (
                        <Link
                          to={`/admin/${client.id}/${location.pathname.indexOf('anketa_list') !== -1 ? 'anketa_list/' : ''}`}
                          key={client.id}
                          className={`link link-button ${clientID === client.id ? 'active' : ''}`}
                        >
                          {client.short_title}
                        </Link>
                      ))}
                    </div>

                    <div className={`streams ${streamsIsExanded ? 'expanded' : ''}`} data-cy='streamListExpanded'>
                      {_.find(clientsData, { id: clientID })?.streams.map(stream => (
                        <Link
                          key={stream.id}
                          to={`/admin/${clientID}/${stream.id}/`}
                          className={`link link-button ${stream.id === streamID ? 'active' : ''}`}
                          data-cy={`stream`}
                        >
                          {stream.title}
                        </Link>
                      ))}
                    </div>
                  </nav>
                </div>
              </div>

              {_.isNaN(clientID) ? null : (
                <div className='adminBottomHeader'>
                  <div className='contentContainer'>
                    <nav>
                      <Link
                        to={_.isNaN(streamID) ? `/admin/${clientID}/members/` : `/admin/${clientID}/${streamID}/members/`}
                        className={`link link-button ${_.includes(location.pathname, 'members') ? 'active' : ''}`}
                      >
                        Участники
                      </Link>
                      <Link
                        to={_.isNaN(streamID) ? `/admin/${clientID}/templates/` : `/admin/${clientID}/${streamID}/templates/`}
                        className={`link link-button ${_.includes(location.pathname, 'templates') ? 'active' : ''}`}
                      >
                        Шаблоны
                      </Link>
                      <Link
                        to={_.isNaN(streamID) ? `/admin/${clientID}/experts/` : `/admin/${clientID}/${streamID}/experts/`}
                        className={`link link-button ${_.includes(location.pathname, 'experts') ? 'active' : ''}`}
                      >
                        Эксперты
                      </Link>
                      <Link to={`/admin/${clientID}/reports/`} className={`link link-button ${_.includes(location.pathname, 'reports') ? 'active' : ''}`}>
                        Отчёты
                      </Link>
                    </nav>
                  </div>
                </div>
              )}
            </>
          )}
        </motion.header>
        {userData.user.is_member && localStorage.getItem('skillsSurveyUserPolicyAgreement') !== 'true' && (
          <div className='policy-alert'>
            <span>
              Используя данную платформу, пользователь дает свое{' '}
              <a
                href={
                  CTP
                    ? 'https://storage.yandexcloud.net/cpt-survey-production-storage/pdf_docs/consent_to_the_processing_of_personal_data.pdf'
                    : 'https://storage.yandexcloud.net/skills-production-static/policy/Soglasie-na-obrabotku-SKILLS.pdf'
                }
                rel='noopener noreferrer'
                target='_blank'
                className='link'
              >
                согласие{' '}
              </a>
              на обработку персональных данных.{' '}
            </span>
            <button
              className='link'
              onClick={() => {
                localStorage.setItem('skillsSurveyUserPolicyAgreement', 'true');
                queryCache.invalidateQueries('userData');
              }}
            >
              {'  '}Согласен!
            </button>
          </div>
        )}
      </>
    )
  );
};
