import './SubTitle.scss';

interface Props {
  children: any;
  className?: string;
}

export const SubTitle: React.FC<Props> = ({ children, className }) => {
  return <div className={`subTitle ${className}`}>{children}</div>;
};
