import './TextareaWYSIWYG.scss';
import 'react-quill/dist/quill.snow.css';

import { AnimatePresence, motion } from 'framer-motion';

import { FormikErrors } from 'formik';
import InputError from '../InputError';
import ReactQuill from 'react-quill';
import { useState } from 'react';

interface Props {
  value: string;
  onChange?: any;
  onBlur?: () => void;
  placeholder?: string;
  placeholderType?: 'alwaysVisible' | 'partlyVisible';
  modulesType: 'default' | 'defaultWithLink' | 'emailTemplating' | 'noModules';
  isDisabled?: boolean;
  isValid?: boolean;
  validationError?: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined;
  autoComplete?: 'on' | 'off';
  size?: 'medium' | 'small';
  defaultValue?: string;
}

export const TextareaWYSIWYG: React.FC<Props> = ({
  value,
  placeholder,
  placeholderType,
  onChange,
  onBlur,
  isDisabled,
  isValid,
  validationError,
  autoComplete,
  modulesType,
  size,
  defaultValue,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const variants = {
    placeholderDown: { y: 0 },
    placeholderUp: { y: modulesType === 'noModules' ? -25 : -105 },
  };

  const modules = () => {
    switch (modulesType) {
      case 'default':
        return {
          toolbar: [['bold'], [{ list: 'bullet' }, { list: 'ordered' }]],
          clipboard: {
            matchVisual: false,
          },
        };
      case 'defaultWithLink':
        return {
          toolbar: [['bold'], [{ list: 'bullet' }, { list: 'ordered' }], ['link']],
          clipboard: {
            matchVisual: false,
          },
        };
      case 'emailTemplating':
        return {
          toolbar: [[{ header: 1 }, { header: 2 }], ['bold'], [{ list: 'bullet' }, { list: 'ordered' }], ['link']],
          clipboard: {
            matchVisual: false,
          },
        };
      case 'noModules':
        return {
          clipboard: {
            matchVisual: false,
          },
        };
    }
  };

  const formats = () => {
    switch (modulesType) {
      case 'default':
        return ['bold', 'list'];
      case 'defaultWithLink':
        return ['bold', 'list', 'link'];
      case 'emailTemplating':
        return ['header', 'bold', 'list', 'link'];
      case 'noModules':
        return [];
    }
  };

  return (
    <div className={`textareaWYSIWYG ${modulesType === 'noModules' ? ' noToolbar' : ''} ${size}`}>
      {placeholderType === 'alwaysVisible' && modulesType === 'noModules' ? (
        <motion.div
          className={`placeholder ${isDisabled ? 'disabled' : ''}`}
          animate={!isFocused && !value ? 'placeholderDown' : 'placeholderUp'}
          variants={variants}
          transition={{ type: 'spring', velocity: 2 }}
        >
          {placeholder}
        </motion.div>
      ) : (
        <div className={`placeholder ${isDisabled ? 'disabled' : ''}`}>{placeholder}</div>
      )}

      <ReactQuill
        theme='snow'
        value={value}
        defaultValue={defaultValue}
        placeholder={placeholderType === 'partlyVisible' ? placeholder : undefined}
        onChange={onChange}
        onBlur={() => {
          onBlur && onBlur();
          setIsFocused(false);
        }}
        onFocus={() => setIsFocused(true)}
        modules={modules()}
        formats={formats()}
        className={`quillEditor ${isValid === false ? ' invalid' : ''} ${isDisabled ? ' disabled' : ''}`}
        readOnly={isDisabled}
      />

      <AnimatePresence>
        {isValid !== undefined && (
          <motion.div initial={{ opacity: 0, y: -25 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -25 }} transition={{ duration: 0.2 }}>
            <InputError>{validationError}</InputError>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
