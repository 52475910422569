import { useQuery } from 'react-query';
import axios from 'axios';
import { backendURL } from '../App';

const getAdminExpertsData = async _ => {
  const { data } = await axios({
    method: 'get',
    url: `${backendURL}admin/experts/`,
    headers: {
      Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
    },
  });

  return data;
};

export default function useAdminExpertsData() {
  return useQuery(['adminExpertsData'], getAdminExpertsData, { retry: false, refetchOnWindowFocus: false });
}
