import './TemplateQuestionsTable.scss';
import icon_search from '../../../assets/images/icon_search.svg';
import icon_resetFilter from '../../../assets/images/icon_resetFilter.svg';
import icon_questionDelete from '../../../assets/images/icon_questionDelete.svg';
import icon_questionView from '../../../assets/images/icon_questionView.svg';
import icon_questionEdit from '../../../assets/images/icon_questionEdit.svg';

import { useState, useMemo } from 'react';

import useDynamicRefs from 'use-dynamic-refs';
import { useHistory, useParams } from 'react-router-dom';
import { useTable, useFilters, autoResetSortBy } from 'react-table';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz/esm';
import DeleteQuestionModal from './DeleteQuestionModal';

interface Props {
  filteredData?: any;
}

export const TemplateQuestionsTable: React.FC<Props> = ({ filteredData }) => {
  const history = useHistory();
  const { clientID } = useParams<{ clientID: string }>();
  const [getRef, setRef] = useDynamicRefs();
  const [isDeleteQuestionModal, setIsDeleteQuestionModal] = useState<{ questionID: number; anketaID: number } | undefined>(undefined);

  // * Фильтры для таблицы
  const DefaultColumnFilter = () => {
    return <img alt='icon' className='searchIcon' src={icon_search} />;
  };
  const TitleColumnFilter = ({ column: { filterValue, setFilter } }) => {
    return (
      <>
        <img
          alt='icon'
          className='searchIcon titleSearch'
          src={icon_search}
          // @ts-ignore
          onClick={() => getRef('titleSearch').current.classList.add('visible')}
        />
        <div
          className='searchPopup titleSearch'
          // @ts-ignore
          ref={setRef('titleSearch')}
        >
          <button
            type='button'
            className='close'
            // @ts-ignore
            onClick={() => getRef('titleSearch').current.classList.remove('visible')}
          />
          <input
            type='text'
            value={filterValue || ''}
            onChange={e => {
              setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
            placeholder='Начните вводить название вопроса'
          />
        </div>
        {filterValue && (
          <img
            alt='icon'
            className='resetFilterIcon'
            src={icon_resetFilter}
            onClick={() => {
              setFilter(undefined);
              // @ts-ignore
              getRef('titleSearch').current.classList.remove('visible');
            }}
          />
        )}
      </>
    );
  };
  const CompetentionsColumnFilter = ({ column: { filterValue, setFilter } }) => {
    return (
      <>
        <img
          alt='icon'
          className='searchIcon competentionsSearch'
          src={icon_search}
          // @ts-ignore
          onClick={() => getRef('competentionsSearch').current.classList.add('visible')}
        />
        <div
          className='searchPopup competentionsSearch'
          // @ts-ignore
          ref={setRef('competentionsSearch')}
        >
          <button
            type='button'
            className='close'
            // @ts-ignore
            onClick={() => getRef('competentionsSearch').current.classList.remove('visible')}
          />
          <input
            type='text'
            value={filterValue || ''}
            onChange={e => {
              setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
            placeholder='Начните вводить название вопроса'
          />
        </div>
        {filterValue && (
          <img
            alt='icon'
            className='resetFilterIcon'
            src={icon_resetFilter}
            onClick={() => {
              setFilter(undefined);
              // @ts-ignore
              getRef('competentionsSearch').current.classList.remove('visible');
            }}
          />
        )}
      </>
    );
  };
  const IndicatorsColumnFilter = ({ column: { filterValue, setFilter } }) => {
    return (
      <>
        <img
          alt='icon'
          className='searchIcon inidcatorsSearch'
          src={icon_search}
          // @ts-ignore
          onClick={() => getRef('inidcatorsSearch').current.classList.add('visible')}
        />
        <div
          className='searchPopup inidcatorsSearch'
          // @ts-ignore
          ref={setRef('inidcatorsSearch')}
        >
          <button
            type='button'
            className='close'
            // @ts-ignore
            onClick={() => getRef('inidcatorsSearch').current.classList.remove('visible')}
          />
          <input
            type='text'
            value={filterValue || ''}
            onChange={e => {
              setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
            placeholder='Начните вводить название вопроса'
          />
        </div>
        {filterValue && (
          <img
            alt='icon'
            className='resetFilterIcon'
            src={icon_resetFilter}
            onClick={() => {
              setFilter(undefined);
              // @ts-ignore
              getRef('inidcatorsSearch').current.classList.remove('visible');
            }}
          />
        )}
      </>
    );
  };
  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );
  // * Стркутруа колонок для таблицы
  const columns = useMemo(
    () => [
      {
        Header: 'Название вопроса',
        accessor: 'title',
        Filter: TitleColumnFilter,
      },
      {
        Header: 'Компетенции',
        accessor: 'competentions',
        Filter: CompetentionsColumnFilter,
      },
      {
        Header: 'Индикаторы',
        accessor: 'indicators',
        Filter: IndicatorsColumnFilter,
      },
      {
        Header: 'Кнопки',
        accessor: 'questionIndex',
        disableFilters: true,
      },
    ],
    []
  );
  // * Данные для таблицы
  const data = useMemo(() => filteredData, [filteredData]);
  // * Инциализация таблица
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    { columns, data, defaultColumn, disableSortRemove: true, autoResetSortBy: false },
    useFilters
  );

  // * Кнопка действий с экспертом
  const closeAllOpened = current => {
    filteredData.forEach(element => {
      if (current !== element.actions) {
        // @ts-ignore
        getRef(`${element.actions}toggleButton`).current.classList.remove('active');
        // @ts-ignore
        getRef(`${element.actions}buttons`).current.classList.remove('visible');
      }
    });
  };

  return (
    <section className='templateQuestionsTable'>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={`headerGroup-tr-${index}`}>
              {headerGroup.headers.map((column, index) => (
                <th {...column.getHeaderProps} key={`headerGroup-tr-th-${index}`}>
                  <div>{column.render('Header')}</div>
                  {column.canFilter && column.render('Filter')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <>
                <tr {...row.getRowProps()} key={`body-tr-${index}`} className={row.original.status === 'Неявка' ? 'missedCall' : ''}>
                  {row.cells.map((cell, i: number) => {
                    return (
                      <>
                        <td {...cell.getCellProps()} key={`body-tr-td-${i}`}>
                          {cell.column.Header === 'Название вопроса' && cell.render('Cell')}
                          {cell.column.Header === 'Компетенции' && cell.value.map(comptention => <div>{comptention}</div>)}
                          {cell.column.Header === 'Индикаторы' && cell.value.map(indicator => <div>{indicator}</div>)}
                          {cell.column.Header === 'Кнопки' && (
                            <>
                              <img
                                src={icon_questionEdit}
                                onClick={() =>
                                  history.push({
                                    pathname: `/admin/${clientID}/templates/${row.original.anketaID}/questions/${index + 1}/`,
                                    state: { questionID: row.original.questionID },
                                  })
                                }
                              />
                              <img src={icon_questionView} />
                              <img
                                src={icon_questionDelete}
                                onClick={() => setIsDeleteQuestionModal({ questionID: row.original.questionID, anketaID: row.original.anketaID })}
                              />
                            </>
                          )}
                        </td>
                      </>
                    );
                  })}
                </tr>
              </>
            );
          })}
        </tbody>
      </table>

      {isDeleteQuestionModal && (
        <DeleteQuestionModal
          questionID={isDeleteQuestionModal.questionID}
          anketaID={isDeleteQuestionModal.anketaID}
          onClose={() => setIsDeleteQuestionModal(undefined)}
        />
      )}
    </section>
  );
};
