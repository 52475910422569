import './CompetentionsTable.scss';

import { isTesting3Streams, reportColors } from '../../../helpers/colorPalete';

import Title from '../../Title';

interface Competention {
  title: string;
  score: number;
  recomended_score: number;
  percent: number;
  real_percent: number;
  get_norm_percent: number;
}
interface Props {
  data: {
    client_color: string;
    shifted_hex_color: string;
    rate: number | number[];
    rate_percent: number | number[];
    max_rate: number | number[];
    streams_count: number;
    competentions_data: any[];
  };
}

export const CompetentionsTable: React.FC<Props> = ({ data }) => {
  const {
    firstStreamColor,
    secondStreamColor,
    thirdStreamColor,
    thirdStreamColorWithOpacity,
    firstSpiderChartColor,
    firstSpiderChartColorWithOpacity,
    secondSpiderChartColor,
    secondSpiderChartColorWithOpacity,
    iconsColor,
    linksColor,
  } = reportColors(data.client_color, data.streams_count);

  if (data.streams_count === 3 || isTesting3Streams) {
    return (
      <div className='competentionsTable twoStreams'>
        <Title>Общая оценка компетенций</Title>
        <div className='subTitle'>норма</div>
        <div className='competentions'>
          <div className='scoringBorder'>
            <span>низкий (0 баллов)</span>
          </div>
          <div className='scoringBorder'>
            <span>базовый (1 балл)</span>
          </div>
          <div className='scoringBorder'>
            <span>средний (2 балла)</span>
          </div>
          <div className='scoringBorder' style={{ backgroundColor: thirdStreamColor }}>
            <span>
              продвинутый
              <br />
              (3 балла)
            </span>
          </div>

          {data.competentions_data[1].map((competention, index) => (
            <div className='competention'>
              <div className='key'>{competention.title}</div>
              <div className='valuesContainer'>
                <div className='streamThree'>
                  <div className='value' style={{ width: 100 + '%', backgroundColor: thirdStreamColor }} />
                  <div className='valueRec' style={{ left: `${competention.get_norm_percent}%` }} />
                </div>
                <div className='streamTwo'>
                  <div className='value' style={{ width: competention.real_percent + '%', backgroundColor: secondStreamColor }} />
                  <div className='valueRec' style={{ left: `${competention.get_norm_percent}%` }} />
                </div>
                <div className='streamOne'>
                  <div className='value' style={{ width: data.competentions_data[0][index].real_percent + '%', backgroundColor: firstStreamColor }} />
                  <div className='valueRec' style={{ left: `${data.competentions_data[0][index].get_norm_percent}%` }} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (data.streams_count === 2) {
    return (
      <div className='competentionsTable twoStreams'>
        <Title>Общая оценка компетенций</Title>
        <div className='subTitle'>норма</div>
        <div className='competentions'>
          <div className='scoringBorder'>
            <span>низкий (0 баллов)</span>
          </div>
          <div className='scoringBorder'>
            <span>базовый (1 балл)</span>
          </div>
          <div className='scoringBorder'>
            <span>средний (2 балла)</span>
          </div>
          <div className='scoringBorder' style={{ backgroundColor: `rgb(${data.client_color})` }}>
            <span>
              продвинутый
              <br />
              (3 балла)
            </span>
          </div>

          {data.competentions_data[1].map((competention, index) => (
            <div className='competention'>
              <div className='key'>{competention.title}</div>
              <div className='valuesContainer'>
                <div className='streamTwo'>
                  <div className='value' style={{ width: competention.real_percent + '%', backgroundColor: `rgb(${data.client_color})` }} />
                  <div
                    className='valueRec'
                    style={{ left: competention.get_norm_percent === 100 ? `${competention.get_norm_percent}%` : `calc(${competention.get_norm_percent}% - 4rem)` }}
                  />
                </div>
                <div className='streamOne'>
                  <div className='value' style={{ width: data.competentions_data[0][index].real_percent + '%', backgroundColor: `rgb(${data.shifted_hex_color})` }} />
                  <div
                    className='valueRec'
                    style={{
                      left:
                        data.competentions_data[0][index].get_norm_percent === 100
                          ? `${data.competentions_data[0][index].get_norm_percent}%`
                          : `calc(${data.competentions_data[0][index].get_norm_percent}% - 4rem)`,
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className='competentionsTable oneStream'>
      <Title>Общая оценка компетенций</Title>
      <div className='subTitle'>норма</div>
      <div className='competentions'>
        <div className='scoringBorder'>
          <span>низкий (0 баллов)</span>
        </div>
        <div className='scoringBorder'>
          <span>базовый (1 балл)</span>
        </div>
        <div className='scoringBorder'>
          <span>средний (2 балла)</span>
        </div>
        <div className='scoringBorder' style={{ backgroundColor: `rgb(${data.client_color})` }}>
          <span>
            продвинутый
            <br />
            (3 балла)
          </span>
        </div>

        {data.competentions_data.map(competention => (
          <div className='competention'>
            <div className='key'>{competention.title}</div>
            <div className='valuesContainer'>
              <div className='streamOne'>
                <div className='value' style={{ width: competention.real_percent + '%', backgroundColor: `rgb(${data.client_color})` }} />
                <div
                  className='valueRec'
                  style={{ left: competention.get_norm_percent === 100 ? `${competention.get_norm_percent}%` : `calc(${competention.get_norm_percent}% - 4rem)` }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
