import './ChangeTribeModal.scss';

import { backendURL, debug } from '../../../../App';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';
import { ReactSelect } from '../../../../components/ReactSelect/ReactSelect';
import Title from '../../../../components/Title';
import axios from 'axios';
import consoleLog from '../../../../helpers/consoleLog';
import icon_loading from '../../../../assets/images/icon_loadingBlue.svg';
import { motion } from 'framer-motion';
import { queryCache } from 'react-query';
import useClientData from '../../../../hooks/useClientData';

interface Props {
  selectedAnketas: any;
  onClose: () => void;
}

export const ChangeTribeModal: React.FC<Props> = ({ selectedAnketas, onClose }) => {
  const history = useHistory();
  const { clientID } = useParams<{ clientID: string }>();
  const { data: clientData, status: clientDataLoadingStatus } = useClientData(clientID, localStorage.getItem('skillsSurveyUserToken'), undefined);

  const tribesOptions = () => {
    let options = clientData.tribes.map(tribe => ({ value: tribe.id, label: tribe.title }));
    options.unshift({ value: undefined, label: 'Без трайба' });
    return options;
  };

  // * Привязать эксперту нового клиентаа
  const [tribeID, setTribeID] = useState<number | null>(null);
  const [isUpdatingTribe, setIsUpdatingTribe] = useState(false);

  const changeTribe = async () => {
    setIsUpdatingTribe(true);
    let anketaAnswersID = selectedAnketas.map(row => row.original.anketaAnswersID);

    try {
      const { data } = await axios({
        method: 'post',
        url: `${backendURL}change-tribe/`,
        headers: {
          Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
        },
        data: {
          tribe_id: tribeID,
          action: tribeID ? '' : 'delete',
          anketa_answers_id: anketaAnswersID.join(', '),
        },
      });
      await queryCache.invalidateQueries(['clientAnketaAnswersData', clientID]);
      setIsUpdatingTribe(false);
      onClose();
      debug && consoleLog('success', 'Tribe change', data);
    } catch (error) {
      error && consoleLog('error', 'Tribe change', error);
      setIsUpdatingTribe(false);
      history.push('/500/');
    }
  };

  if (clientDataLoadingStatus === 'loading') {
    return (
      <Modal className='сhangeTribeModal' onClose={onClose}>
        <div className='isLoading'>
          <motion.img src={icon_loading} initial={{ rotate: 0 }} animate={{ rotate: 360 }} transition={{ duration: 2, loop: Infinity, ease: 'linear' }} />
        </div>
      </Modal>
    );
  }

  return (
    <Modal className='сhangeTribeModal' onClose={isUpdatingTribe ? () => null : onClose}>
      <Title>Изменить трайб</Title>
      <div className='subTitle'>Выберите трайб из списка:</div>
      <ReactSelect placeholder='Трайб' options={tribesOptions()} onChange={setTribeID} />
      <Button
        type='button'
        width='220rem'
        size='medium'
        color='blue'
        text='Изменить'
        onClick={() => changeTribe()}
        loading={isUpdatingTribe}
        disabled={isUpdatingTribe}
      />
    </Modal>
  );
};
