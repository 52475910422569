import './AdminClientAnketas.scss';

import { Link, useParams } from 'react-router-dom';

import AdminClientAnketasTable from './AdminClientAnketasTable';
import AnimatedRedirect from '../../components/AnimatedRedirect';
import PageLoader from '../../components/PageLoader';
import Title from '../../components/Title/';
import { format } from 'date-fns';
import { motion } from 'framer-motion';
import { ru } from 'date-fns/locale';
import { standardPageVariants } from '../../animationsVariants/StandardPageVariants';
import statusNumToString from '../../helpers/statusNumToString';
import useClientAnketaAnswersData from '../../hooks/useClientAnketaAnswersData';
import { utcToZonedTime } from 'date-fns-tz/esm';

export const AdminClientAnketas: React.FC<{}> = () => {
  const { clientID, streamID } = useParams<{ clientID: string; streamID: string }>();
  const { data: clientAnketaAnswersData, status: clientAnketaAnswersDataLoadingStatus } = useClientAnketaAnswersData(clientID, streamID);

  const createFilterdData = () => {
    const filteredData = clientAnketaAnswersData.map(anketa => ({
      checkbox: false,
      date: anketa.call_start ? format(utcToZonedTime(new Date(anketa.call_start), 'Europe/Moscow'), 'yyyy.MM.dd', { locale: ru }) : '----',
      expertName: anketa.expert ? `${anketa.expert.first_name} ${anketa.expert.last_name}` : '-------------',
      memberID: anketa.user.member_id || '----',
      memberName: `${anketa.user.first_name} ${anketa.user?.last_name}`,
      tribe: anketa.user.tribe_str,
      role: anketa.user.status_str,
      case: anketa.user.case_str,
      status: statusNumToString(anketa.process_status),
      anketaID: anketa.anketa_id,
      anketaAnswersID: anketa.id,
      zoom_meeting_video_link: anketa.zoom_meeting_video_link || undefined,
      vc_service: anketa.vc_service || undefined,
      vc_video_link: anketa.vc_video_link || undefined,
    }));

    return filteredData;
  };

  if (clientAnketaAnswersDataLoadingStatus === 'loading') {
    return <PageLoader />;
  }

  if (clientAnketaAnswersDataLoadingStatus === 'error') {
    return <AnimatedRedirect redirectTo='/500/' />;
  }

  return (
    <motion.main className='adminClientAnketas' variants={standardPageVariants} initial='initial' animate='animate' exit='exit'>
      <Title>Анкеты</Title>
      <Link to={`/admin/${clientID}/`} className='subTitleLink'>
        Метрики
      </Link>
      {clientAnketaAnswersData.length > 0 && (
        <AdminClientAnketasTable filteredData={createFilterdData()} memberID={clientAnketaAnswersData[0].user.member_id} clientID={Number(clientID)} />
      )}
    </motion.main>
  );
};
