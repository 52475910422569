import './AdminTemplateCopy.scss';
import 'react-quill/dist/quill.snow.css';

import { useMutation, useQuery } from 'react-query';
import { useRef, useState } from 'react';

import AnimatedRedirect from '../../components/AnimatedRedirect';
import AnketaCopyModal from './AnketaCopyModal';
import Button from '../../components/Button';
import InputSelect from '../../components/InputSelect';
import PageLoader from '../../components/PageLoader';
import api_getAnketasData from '../../hooks/api_getAnketasData';
import icon_plus from '../../assets/images/icon_plus.svg';
import { motion } from 'framer-motion';
import { standardPageVariants } from '../../animationsVariants/StandardPageVariants';
import useClientsData from '../../hooks/useClientsData';
import { useParams } from 'react-router-dom';

export const AdminTemplateCopy: React.FC<{}> = () => {
  const { clientID } = useParams<{ clientID: string }>();
  // * Выбираем значения в селектах
  const [selectedClient, setSelectedClient] = useState<undefined | number>(undefined);
  const [selectedStream, setSelectedStream] = useState<undefined | number>(undefined);
  const streamsSelectRef = useRef();
  const [selectedConfirm, setSelectedConfirm] = useState<boolean>(false);

  const [selectedAnketa, setSelectedAnketa] = useState<undefined | number>(undefined);
  const [isModal, setIsModal] = useState(false);

  const { data: clientsData, status: clientsDataLoadingStatus } = useClientsData();
  const { data: anketasData, status: anketasDataLoadingStatus } = useQuery(['anketasData', selectedClient, selectedStream], api_getAnketasData, {
    enabled: selectedClient !== undefined && selectedStream !== undefined && selectedConfirm,
    onSuccess: () => setSelectedConfirm(false),
  });

  if (clientsDataLoadingStatus === 'loading') {
    return <PageLoader />;
  }

  if (clientsDataLoadingStatus === 'error' || anketasDataLoadingStatus === 'error') {
    return <AnimatedRedirect redirectTo='/500/' />;
  }

  return (
    <motion.main className='adminTemplateCopy' variants={standardPageVariants} initial='initial' animate='animate' exit='exit'>
      <div className='buttonsContainer'>
        <Button type='link' href={`/admin/${clientID}/templates/new/`} size='small' width='360rem' color='blue' className='addCopiedAnketaButton'>
          <img src={icon_plus} alt='icon' />
          <span>Добавить новую анкету из существующих</span>
        </Button>
        <Button type='link' href={`/admin/${clientID}/templates/copy/`} size='small' width='230rem' color='blue' className='addNewAnketaButton'>
          <img src={icon_plus} alt='icon' />
          <span>Добавить новую анкету</span>
        </Button>
      </div>

      <div className='subTitle'>Укажите данные для поиска</div>

      <div className='selectsContainer'>
        <InputSelect
          name='client'
          options={clientsData.map(client => ({ value: client.id, label: client.title }))}
          value={selectedClient}
          placeholderType='alwaysVisible'
          placeholder='Клиент'
          onChange={option => {
            setSelectedClient(option.value);
            setSelectedStream(undefined);
            // @ts-ignore
            streamsSelectRef.current.select.setValue('');
          }}
          onBlur={() => null}
          isValid={true}
          validationError={undefined}
        />
        <InputSelect
          name='stream'
          refProp={streamsSelectRef}
          options={clientsData?.find(client => +client.id === selectedClient)?.streams?.map(stream => ({ value: stream.id, label: stream.title }))}
          value={selectedStream}
          placeholderType='alwaysVisible'
          placeholder='Поток'
          onChange={option => setSelectedStream(option.value)}
          onBlur={() => null}
          isValid={true}
          validationError={undefined}
        />
      </div>

      <Button
        type='button'
        buttonType='button'
        size='medium'
        width='330rem'
        color='blue'
        className='findAnketasButton'
        text='Найти'
        onClick={() => setSelectedConfirm(true)}
        disabled={selectedClient === undefined || selectedStream === undefined}
      />

      {anketasDataLoadingStatus === 'success' && (
        <>
          <div className='anketasList'>
            <div className='subTitle'>Список анкет</div>
            {anketasData.map(anketa => (
              <div
                className={'anketa ' + (anketa.id === selectedAnketa ? 'active' : '')}
                key={anketa.id}
                onClick={selectedAnketa === anketa.id ? () => setSelectedAnketa(undefined) : () => setSelectedAnketa(anketa.id)}
              >
                {anketa.title}
              </div>
            ))}
            <Button
              type='button'
              buttonType='button'
              size='medium'
              width='330rem'
              color='blue'
              className='copyAnketaButton'
              text='Скопировать'
              onClick={() => setIsModal(true)}
              disabled={!selectedAnketa}
            />
            {isModal && selectedAnketa ? <AnketaCopyModal anketaID={selectedAnketa} onClose={() => setIsModal(false)} /> : null}
          </div>
        </>
      )}
    </motion.main>
  );
};
