import './CompetentionsCircles.scss';
import 'react-circular-progressbar/dist/styles.css';

import { isTesting3Streams, reportColors } from '../../../helpers/colorPalete';
import {
  twoStreams_competentionsCircles_SubTitle,
  twoStreams_competentionsCircles_Text1,
  twoStreams_competentionsCircles_Text2,
  twoStreams_competentionsCircles_Text3,
  twoStreams_competentionsCircles_Text4,
  twoStreams_competentionsCircles_Text5,
  twoStreams_competentionsCircles_Text6,
} from '../../../helpers/textVariablesForReports';

import { CircularProgressbar } from 'react-circular-progressbar';
import _ from 'lodash';

interface Competention {
  title: string;
  score: number;
  recomended_score: number;
  percent: number;
  real_percent: number;
  get_norm_percent: number;
}
interface Props {
  type: 'tribe' | 'member' | 'client';
  data: {
    anketa_answers_count: number | number[];
    anketa_answers_count_man: string | string[];
    status: string;
    client_color: string;
    shifted_hex_color: string;
    rate: number | number[];
    rate_percent: number | number[];
    max_rate: number | number[];
    streams_count: number;
    competentions_data: any[];
    report_percents: boolean;
  };
}

export const CompetentionsCircles: React.FC<Props> = ({ data, type }) => {
  const scoreSuffix = score => {
    if (score === 0) {
      return (
        <>
          <span className='value'>0</span>
          <span className='suffix'> баллов</span>
        </>
      );
    }
    if (score === 1) {
      return (
        <>
          <span className='value'>1</span>
          <span className='suffix'> балл</span>
        </>
      );
    } else {
      return (
        <>
          <span className='value'>{score}</span>
          <span className='suffix'> балла</span>
        </>
      );
    }
  };

  const { firstStreamColor, secondStreamColor, thirdStreamColor } = reportColors(data.client_color, data.streams_count);

  if (data.streams_count === 3 || isTesting3Streams) {
    return (
      <div className='competentionsCircles threeStreams' data-cy='competentionsCirclesBlock threeStreams'>
        <h1>
          {type === 'member' && `Оценка продуктовых компетенций\n ${data.status}`}
          {type === 'tribe' && 'Общие результаты по трайбу'}
          {type === 'client' && 'Общие результаты по клиенту'}
        </h1>
        <div className='points' style={{ backgroundColor: thirdStreamColor }}>
          <div className='pointsHeader'>Набрано в 3-м тестировании</div>
          <div className='pointsHeader'>Навыки изменились на </div>
          <div className='pointsValue'>
            {data.report_percents ? (
              <>
                <span className='value'>100 %</span>
              </>
            ) : (
              <>
                <span className='value'>100</span> баллов из <span className='value'>{data.max_rate[1]}</span> возможных
              </>
            )}
          </div>
          <div className='pointsValue'>
            <span className='value'>{100 - data.rate_percent[1]} %</span>
          </div>
        </div>
        <div className='points' style={{ backgroundColor: secondStreamColor }}>
          <div className='pointsHeader'>Набрано на диагностике из 100%</div>
          <div className='pointsHeader'>Навыки изменились на </div>
          <div className='pointsValue'>
            {data.report_percents ? (
              <>
                <span className='value'>{data.rate_percent[1]} %</span>
              </>
            ) : (
              <>
                <span className='value'>{data.rate[1]}</span> балла из <span className='value'>{data.max_rate[1]}</span> возможных
              </>
            )}
          </div>
          <div className='pointsValue'>
            <span className='value'>{(data.rate_percent[1] - data.rate_percent[0]).toFixed(1)} %</span>
          </div>
        </div>
        <div className='points' style={{ backgroundColor: firstStreamColor }}>
          <div className='pointsHeader'>Набрано на самооценке из 100%</div>
          <div className='pointsValue'>
            {data.report_percents ? (
              <>
                <span className='value'>{data.rate_percent[0]} %</span>
              </>
            ) : (
              <>
                <span className='value'>{data.rate[0]}</span> балла из <span className='value'>{data.max_rate[0]}</span> возможных
              </>
            )}
          </div>
        </div>
        <div className='circleResultExplanation'>
          <div className='explanationContainer'>
            <div className='color' style={{ backgroundColor: firstStreamColor }}></div>
            <div className='text'>Набрано на самооценке из 100%</div>
          </div>
          <div className='explanationContainer'>
            <div className='color' style={{ backgroundColor: secondStreamColor }}></div>
            <div className='text'>Набрано на диагностике из 100%</div>
          </div>
          <div className='explanationContainer'>
            <div className='color' style={{ backgroundColor: thirdStreamColor }}></div>
            <div className='text'>Набрано в 3-м тестировании</div>
          </div>
        </div>
        <div className='circleResult'>
          {data.competentions_data[1].map((competention, index) => (
            <div className='circleRate' key={_.uniqueId()}>
              <div className='circleContainer'>
                <CircularProgressbar
                  value={100}
                  strokeWidth={4}
                  styles={{
                    path: {
                      stroke: thirdStreamColor,
                      strokeLinecap: 'butt',
                    },
                    trail: {
                      stroke: 'rgba(242, 242, 242, 1)',
                      strokeLinecap: 'butt',
                    },
                  }}
                />
              </div>
              <div className='circleContainer'>
                <CircularProgressbar
                  value={data.competentions_data[1][index].real_percent}
                  strokeWidth={5}
                  styles={{
                    path: {
                      stroke: secondStreamColor,
                      strokeLinecap: 'butt',
                    },
                    trail: {
                      stroke: 'rgba(242, 242, 242, 1)',
                      strokeLinecap: 'butt',
                    },
                  }}
                />
              </div>
              <div className='circleContainer'>
                <CircularProgressbar
                  value={data.competentions_data[0][index].real_percent}
                  strokeWidth={6.5}
                  styles={{
                    path: {
                      stroke: firstStreamColor,
                      strokeLinecap: 'butt',
                    },
                    trail: {
                      stroke: 'rgba(242, 242, 242, 1)',
                      strokeLinecap: 'butt',
                    },
                  }}
                />
              </div>
              <div className={`circleTextInside` + `${data.report_percents ? ' percentText' : ' pointsText'}`}>
                {data.report_percents ? (
                  <>
                    <span className='thirdStream'>
                      <span className='value'>100%</span>
                    </span>
                    <span className='secondStream'>
                      <span className='value'>{competention.real_percent}%</span>
                    </span>
                    <span className='firstStream'>
                      <span className='value'>{data.competentions_data[0][index].real_percent}%</span>
                    </span>
                  </>
                ) : (
                  <>
                    <span className='thirdStream'>{scoreSuffix(3)}</span>
                    <span className='secondStream'>{scoreSuffix(data.competentions_data[1][index].score)}</span>
                    <span className='firstStream'>{scoreSuffix(data.competentions_data[0][index].score)}</span>
                  </>
                )}
              </div>
              <div className='circleTextOutside'>{competention.title}</div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (data.streams_count === 2) {
    return (
      <div className='competentionsCircles twoStreams' data-cy='competentionsCirclesBlock twoStreams'>
        <h1>
          {type === 'member' && `Оценка продуктовых компетенций\n ${data.status}`}
          {type === 'tribe' && 'Общие результаты по трайбу'}
          {type === 'client' && 'Общие результаты по клиенту'}
        </h1>
        {type !== 'member' && (
          <div className='membersCount'>
            {twoStreams_competentionsCircles_Text1}{' '}
            <b>
              — {data.anketa_answers_count[1]} {data.anketa_answers_count_man[1]}
            </b>
            <br />
            {twoStreams_competentionsCircles_Text2}{' '}
            <b>
              — {data.anketa_answers_count[0]} {data.anketa_answers_count_man[0]}
            </b>
          </div>
        )}
        <div className='points' style={{ backgroundColor: `rgb(${data.client_color})` }}>
          <div className='pointsHeader'>{twoStreams_competentionsCircles_Text5}</div>
          <div className='pointsHeader'>Навыки изменились на </div>
          <div className='pointsValue'>
            {data.report_percents ? (
              <>
                <span className='value'>{data.rate_percent[1]} %</span>
              </>
            ) : (
              <>
                <span className='value'>{data.rate[1]}</span> балла из <span className='value'>{data.max_rate[1]}</span> возможных
              </>
            )}
          </div>
          <div className='pointsValue'>
            <span className='value'>{(data.rate_percent[1] - data.rate_percent[0]).toFixed(1)} %</span>
          </div>
        </div>
        <div className='points secondStream' style={{ backgroundColor: `rgb(${data.shifted_hex_color})` }}>
          <div className='pointsHeader'>{twoStreams_competentionsCircles_Text6}</div>
          <div className='pointsValue'>
            {data.report_percents ? (
              <>
                <span className='value'>{data.rate_percent[0]} %</span>
              </>
            ) : (
              <>
                <span className='value'>{data.rate[0]}</span> балла из <span className='value'>{data.max_rate[0]}</span> возможных
              </>
            )}
          </div>
        </div>
        <div className={`circleResultExplanation ${type !== 'member' ? '-mb-60' : ''}`}>
          {type !== 'member' && <div className='circleResultTitle'>{twoStreams_competentionsCircles_SubTitle}</div>}
          <div className='explanationContainer'>
            <div className='color' style={{ backgroundColor: `rgb(${data.client_color})` }}></div>
            <div className='text'>{twoStreams_competentionsCircles_Text3}</div>
          </div>
          <div className='explanationContainer'>
            <div className='color' style={{ backgroundColor: `rgb(${data.shifted_hex_color})` }}></div>
            <div className='text'>{twoStreams_competentionsCircles_Text4}</div>
          </div>
        </div>
        <div className='circleResult'>
          {data.competentions_data[1].map((competention, index) => (
            <div className='circleRate' key={_.uniqueId()}>
              <div className='circleContainer'>
                <CircularProgressbar
                  value={competention.real_percent}
                  strokeWidth={5}
                  styles={{
                    path: {
                      stroke: `rgb(${data.client_color})`,
                      strokeLinecap: 'butt',
                    },
                    trail: {
                      stroke: 'rgba(242, 242, 242, 1)',
                      strokeLinecap: 'butt',
                    },
                  }}
                />
              </div>
              <div className='circleContainer'>
                <CircularProgressbar
                  value={data.competentions_data[0][index].real_percent}
                  strokeWidth={7}
                  styles={{
                    path: {
                      stroke: `rgb(${data.shifted_hex_color})`,
                      strokeLinecap: 'butt',
                    },
                    trail: {
                      stroke: 'rgba(242, 242, 242, 1)',
                      strokeLinecap: 'butt',
                    },
                  }}
                />
              </div>
              <div className='circleTextInside'>
                <div className={`circleTextInside` + `${data.report_percents ? ' percentText' : ' pointsText'}`}>
                  {data.report_percents ? (
                    <>
                      <span className='secondStream'>
                        <span className='value'>{competention.real_percent}%</span>
                      </span>
                      <span className='firstStream'>
                        <span className='value'>{data.competentions_data[0][index].real_percent}%</span>
                      </span>
                    </>
                  ) : (
                    <>
                      <span className='secondStream'>{scoreSuffix(competention.score)}</span>
                      <span className='firstStream'>{scoreSuffix(data.competentions_data[0][index].score)}</span>
                    </>
                  )}
                </div>
              </div>
              <div className='circleTextOutside'>{competention.title}</div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className='competentionsCircles oneStream' data-cy='competentionsCirclesBlock oneStream'>
      <h1>
        {type === 'member' && `Оценка продуктовых компетенций\n ${data.status}`}
        {type === 'tribe' && 'Общие результаты по трайбу'}
        {type === 'client' && 'Общие результаты по клиенту'}
      </h1>
      {type !== 'member' && (
        <div className='membersCount'>
          Прошли тестирование
          <b>
            — {data.anketa_answers_count} {data.anketa_answers_count_man}
          </b>
          <br />
        </div>
      )}
      <div className='points' style={{ backgroundColor: `rgb(${data.client_color})` }}>
        <div className='pointsHeader'>Набрано из 100%</div>
        <div className='pointsValue'>
          {data.report_percents ? (
            <>
              <span className='value'>
                {/* @ts-ignore */}
                {data.rate_percent} %{type === 'member' && data.rate_percent <= 49 && ''}
                {/* @ts-ignore */}
                {type === 'member' && data.rate_percent >= 50 && data.rate_percent <= 79 && ''}
                {/* @ts-ignore */}
                {type === 'member' && data.rate_percent >= 80 && ''}
              </span>
            </>
          ) : (
            <>
              <span className='value'>{data.rate}</span> балла из <span className='value'>{data.max_rate}</span> возможных
            </>
          )}
        </div>
      </div>
      <div className='circleResult'>
        {data.competentions_data.map(competention => (
          <div className='circleRate' key={_.uniqueId()}>
            <div className='circleContainer'>
              <CircularProgressbar
                value={0}
                strokeWidth={5}
                styles={{
                  path: {
                    stroke: `rgba(7, 94, 127, 1.0)`,
                    strokeLinecap: 'butt',
                  },
                  trail: {
                    stroke: 'rgba(242, 242, 242, 1)',
                    strokeLinecap: 'butt',
                  },
                }}
              />
            </div>
            <div className='circleContainer'>
              <CircularProgressbar
                value={competention.real_percent}
                strokeWidth={7}
                styles={{
                  path: {
                    stroke: `rgb(${data.client_color})`,
                    strokeLinecap: 'butt',
                  },
                  trail: {
                    stroke: 'rgba(242, 242, 242, 1)',
                    strokeLinecap: 'butt',
                  },
                }}
              />
            </div>
            <div className={`circleTextInside` + `${data.report_percents ? ' percentText' : ' pointsText'}`}>
              {data.report_percents ? (
                <span className='firstStream'>
                  <span className='value'>{competention.real_percent}%</span>
                </span>
              ) : (
                <span className='firstStream'>{scoreSuffix(competention.score)}</span>
              )}
            </div>
            <div className='circleTextOutside'>{competention.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
