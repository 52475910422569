import './RespondentsDasboard.scss';

import { format, isBefore } from 'date-fns';

import { Link } from 'react-router-dom';
import { anketa_zoom_video_modal_display_toggle } from '../../actions/';
import { ru } from 'date-fns/locale';
import { useDispatch } from 'react-redux';
import { utcToZonedTime } from 'date-fns-tz/esm';

interface RespondentsDasboardStatusProps {
  anketaAnswersData: any;
  setZoomModalDataID: any;
}

export const RespondentsDasboardStatus: React.FC<RespondentsDasboardStatusProps> = ({ anketaAnswersData: data, setZoomModalDataID }) => {
  const dispatch = useDispatch();

  return (
    <div className='respondentsDasboard status'>
      <div className='row titleRow'>
        <div className='time'>Дата</div>
        <div className='inteviewStatus'>Статус звонка</div>
        <div className='fio'>Имя участника</div>
        <div className='client'>Клиент</div>
        <div className='id'>ID</div>
        <div className='phone'>Телефон</div>
        <div className='role'>Роль</div>
        <div className='case'>Сегмент</div>
        <div className='anketLink'></div>
        <div className='zoomVideoLink'></div>
      </div>
      {data?.withInterview?.map(element => (
        <div className='row' key={Math.random()}>
          <div className='time'>
            {element.call_start && <span className='overflowFix'>{format(utcToZonedTime(new Date(element.call_start), 'Europe/Moscow'), 'dd MMM', { locale: ru })}</span>}
          </div>
          <div className='inteviewStatus'>
            {element.call_start && (
              <>
                {element.call_failed === true
                  ? 'Не состоялся'
                  : isBefore(new Date(), utcToZonedTime(new Date(element.call_start), 'Europe/Moscow'))
                  ? 'Будет'
                  : 'Состоялся'}
              </>
            )}
          </div>
          <div className='fio'>
            {element.user.first_name} {element.user?.last_name}
          </div>
          <div className='client'>
            <span>{element.client.short_title}</span>
            <img src={element.client.small_logo} />
          </div>
          <div className='id'>{element.user.member_id || '------'}</div>
          <div className='phone'>{element.user.phone || '------'}</div>
          <div className='role'>{element.user.status_str}</div>
          <div className='case'>{element.user.case_str}</div>
          <div className='anketLink'>
            {element.process_status === 4 || element.process_status === 5 ? (
              'Анкета'
            ) : (
              <Link className='link' to={`/expert/review/anketa/${element?.anketa_id}/${element?.id}/preview/`}>
                Анкета
              </Link>
            )}
          </div>
          {element?.zoom_meeting_video_link ? (
            <div
              className='zoomVideoLink'
              onClick={() => {
                setZoomModalDataID(element.id);
                dispatch(anketa_zoom_video_modal_display_toggle(true));
              }}
            ></div>
          ) : (
            <div className='zoomVideoLink empty'></div>
          )}
        </div>
      ))}
      {data?.withoutInterview?.length > 0 && <div className='withoutInterviewTitleRowDummy'>Анкеты без интервью</div>}
      {data?.withoutInterview?.map(element => (
        <div className='row' key={Math.random()}>
          <div className='time' />
          <div className='inteviewStatus' />
          <div className='fio'>
            {element.user.first_name} {element.user?.last_name}
          </div>
          <div className='client'>
            <span>{element.client.short_title}</span>
            <img src={element.client.small_logo} />
          </div>
          <div className='id'>{element.user.member_id || '------'}</div>
          <div className='phone'>{element.user.phone || '------'}</div>
          <div className='role'>{element.user.status_str}</div>
          <div className='case'>{element.user.case_str}</div>
          <div className='anketLink'>
            {element.process_status === 4 || element.process_status === 5 ? (
              'Анкета'
            ) : (
              <Link className='link' to={`/expert/review/anketa/${element.anketa_id}/${element.id}/preview/`}>
                Анкета
              </Link>
            )}
          </div>
          <div className='zoomVideoLink empty'></div>
        </div>
      ))}
    </div>
  );
};
