// import './ExpertAnketaTableOfContents.scss';


import { motion } from 'framer-motion';
import { useParams } from 'react-router-dom';

import useAnketaData from '../../hooks/useAnketaData';
import useAnketaAnswersData from '../../hooks/useAnketaAnswersData';
import useAnketaQuetionsData from '../../hooks/useAnketaQuetionsData';
import { standardPageVariants } from '../../animationsVariants/StandardPageVariants';

import Button from '../../components/Button';
import ExpertAnketaHeader from '../../components/AnketaSharedComponents/ExpertAnketaHeader';
import AnimatedRedirect from '../../components/AnimatedRedirect';
import PageLoader from '../../components/PageLoader';
import AnketaTableOfContents from './AnketaTableOfContents';

export const ExpertAnketaTableOfContents: React.FC<{}> = () => {
  const { anketaID, anketaAnswersID } = useParams<{ anketaID: string; anketaAnswersID: string }>();
  const { data: anketaData, status: anketaDataLoadingStatus } = useAnketaData(anketaID);
  const { data: anketaAnswersData, status: anketaAnswersDataLoadingStatus } = useAnketaAnswersData(anketaAnswersID);
  const { data: anketaQuestionsData, status: anketaQuestionsDataLoadingStatus } = useAnketaQuetionsData(anketaID);

  if (anketaDataLoadingStatus === 'loading' || anketaAnswersDataLoadingStatus === 'loading' || anketaQuestionsDataLoadingStatus === 'loading') {
    return <PageLoader />;
  }

  if (anketaDataLoadingStatus === 'error' || anketaAnswersDataLoadingStatus === 'error' || anketaQuestionsDataLoadingStatus === 'error') {
    return <AnimatedRedirect redirectTo='/500/' />;
  }

  return (
    <motion.main className='expertAnketaDescription' variants={standardPageVariants} initial='initial' animate='animate' exit='exit'>
      <ExpertAnketaHeader userData={anketaAnswersData.user} anketaData={anketaData} clientData={anketaAnswersData.client} />
      <AnketaTableOfContents questionList={anketaQuestionsData} />
      <Button
        type='link'
        href={`/expert/review/anketa/${anketaID}/${anketaAnswersID}/questions/1/`}
        width='330rem'
        size='large'
        color='blue'
        text='Начать оценку анкеты'
      />
    </motion.main>
  );
};
