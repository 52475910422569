import './AdminAnketaHeader.scss';

import { Link, useParams } from 'react-router-dom';

import Title from '../../Title';
import _ from 'lodash';

interface Props {
  userData: {
    first_name: string;
    last_name?: string;
    member_id?: string;
    status_str?: string;
    case_str?: string;
  };
  anketaData: {
    role: string;
    case: string;
  };
  clientData: {
    title: string;
    logo: string;
  };
}

export const AdminAnketaHeader: React.FC<Props> = ({ userData, anketaData, clientData }) => {
  const { clientID, streamID } = useParams<{ clientID: string; streamID: string }>();

  return (
    <div className='expertAnketaHeader'>
      <Link to={_.isNaN(Number(streamID)) ? `/admin/${clientID}/anketa_list/` : `/admin/${clientID}/${streamID}/anketa_list/`} className='link'>
        Вернуться к списку анкет
      </Link>
      <Title>Анкета</Title>
      <div className='info'>
        <div className='keys'>
          <div className='key'>Имя участника или ID</div>
          <div className='key'>Клиент</div>
          <div className='key'>Роль</div>
          <div className='key'>Сегмент</div>
        </div>
        <div className='values'>
          <div className='value'>
            {userData.first_name} {userData.last_name} {userData.member_id}
          </div>
          <div className='value'>
            <img src={clientData.logo} alt='logo' />
            {clientData.title}
          </div>
          <div className='value'>{userData.status_str || anketaData.role}</div>
          <div className='value'>{userData.case_str || anketaData.case}</div>
        </div>
      </div>
    </div>
  );
};
