import './AdminClientLink.scss';

import icon_clipboard from '../../../assets/images/icon_clipboard.svg';
import { motion } from 'framer-motion';
import { useState } from 'react';

interface Stream {
  id: number;
  title: string;
  description: string;
  key: string;
}
interface Props {
  clientData: {
    link: string;
    id: number;
    streams_count: number;
    streams: Stream[];
  };
  streamData?: Stream;
}

export const AdminClientLink: React.FC<Props> = ({ clientData, streamData }) => {
  const [copiedToClipboard, setCopiedToClipboard] = useState<undefined | number>(undefined);

  const copiedAnimationVariants = {
    coppied: { scale: 1.3 },
    default: { scale: 1 },
  };

  const copyToClipboard = (str, id) => {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setCopiedToClipboard(id);
    setTimeout(() => {
      setCopiedToClipboard(undefined);
    }, 500);
  };

  return (
    <div className='adminClientLink'>
      {/* // * Если конректный поток не указан, показать все ссылки */}
      {!streamData ? (
        clientData.streams.map((stream, index) => (
          <div className='linkContainer' key={stream.id}>
            <div className='linkTitle'>Пароль для клиента, {stream.title}:</div>
            <a href={`/registration/`} target='_blank' rel='noopener noreferrer' className='link'>
              {`${stream.key}`}
            </a>
            <motion.img
              src={icon_clipboard}
              onClick={() => copyToClipboard(`${stream.key}`, stream.id)}
              className='toClipboardIcon'
              animate={copiedToClipboard === stream.id ? 'coppied' : 'default'}
              variants={copiedAnimationVariants}
            />
            {copiedToClipboard === stream.id && <div className='copiedToClipboard'>Скопировано</div>}
          </div>
        ))
      ) : (
        // * Если указан поток, показать ссылку для потока */}
        <div className='linkContainer'>
          <div className='linkTitle'>Пароль для клиента, {streamData!.title}:</div>
          <a href={`/registration/`} target='_blank' rel='noopener noreferrer' className='link'>
            {`${streamData!.key}`}
          </a>
          <motion.img
            src={icon_clipboard}
            onClick={() => copyToClipboard(`${streamData!.key}`, streamData!.id)}
            className='toClipboardIcon'
            animate={copiedToClipboard === streamData!.id ? 'coppied' : 'default'}
            variants={copiedAnimationVariants}
          />
          {copiedToClipboard === streamData!.id && <div className='copiedToClipboard'>Скопировано</div>}
        </div>
      )}
    </div>
  );
};
