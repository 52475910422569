import './AdminClientAnketasTable.scss';

import { Link, useParams } from 'react-router-dom';
import {
  admin_table_expert_filter_display_toggle,
  admin_table_member_id_filter_display_toggle,
  admin_table_member_name_filter_display_toggle,
  admin_table_status_filter_display_toggle,
  admin_table_tribe_filter_display_toggle,
} from '../../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { useFilters, useRowSelect, useSortBy, useTable } from 'react-table';
import { useMemo, useState } from 'react';

import AdminClientAnketasDownload from './AdminClientAnketasDownload';
import { AnketaZoomVideoModal } from '../../../components/AnketaZoomVideoModal/AnketaZoomVideoModal';
import ChangeTribeModal from './ChangeTribeModal';
import IndeterminateCheckbox from './AdminTableCheckbox/AdinTableCheckbox';
import _ from 'lodash';
import { anketa_zoom_video_modal_display_toggle } from '../../../actions';
import icon_resetFilter from '../../../assets/images/icon_resetFilter.svg';
import icon_search from '../../../assets/images/icon_search.svg';
import icon_sortDown from '../../../assets/images/icon_sortDown.svg';
import icon_zoomVideo from '../../../assets/images/icon_zoomVideo.svg';

interface Props {
  filteredData?: any;
  memberID: number | null | undefined;
  clientID: number;
}

export const AdminClientAnketasTable: React.FC<Props> = ({ filteredData, memberID, clientID }) => {
  const dispatch = useDispatch();
  const [zoomModalDataID, setZoomModalDataID] = useState(0);
  const { anketa_zoom_video_modal_display_status } = useSelector((state: any) => state.modalsReducer);
  const { streamID } = useParams<{ streamID: string }>();
  const [isChangeTrbieModal, setIsChangeTrbieModal] = useState(false);
  // * Фильтры для таблица
  const DefaultColumnFilter = () => {
    return <img alt='icon' className='searchIcon tribeSearch' src={icon_search} />;
  };
  const StatusColumnFilter = ({ column: { filterValue, setFilter } }) => {
    const { admin_table_status_filter_display_status } = useSelector((state: any) => state.adminTableReducer);

    return (
      <>
        <img
          alt='icon'
          className='searchIcon expertSearch'
          src={icon_search}
          onClick={() => dispatch(admin_table_status_filter_display_toggle(!admin_table_status_filter_display_status))}
        />
        {admin_table_status_filter_display_status && (
          <div className='searchPopup statusSearch'>
            <button type='button' className='close' onClick={() => dispatch(admin_table_status_filter_display_toggle(false))} />
            <input
              type='text'
              value={filterValue || ''}
              onChange={e => {
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
              }}
              placeholder='Начните вводить статус анкеты'
            />
          </div>
        )}
        {filterValue && (
          <img
            alt='icon'
            className='resetFilterIcon'
            src={icon_resetFilter}
            onClick={() => {
              setFilter(undefined);
              dispatch(admin_table_status_filter_display_toggle(false));
            }}
          />
        )}
      </>
    );
  };
  const MemberIDColumnFilter = ({ column: { filterValue, setFilter } }) => {
    const { admin_table_member_id_filter_display_status } = useSelector((state: any) => state.adminTableReducer);

    return (
      <>
        <img
          alt='icon'
          className='searchIcon memberIDSearch'
          src={icon_search}
          onClick={() => dispatch(admin_table_member_id_filter_display_toggle(!admin_table_member_id_filter_display_status))}
        />
        {admin_table_member_id_filter_display_status && (
          <div className='searchPopup memberIDSearch'>
            <button type='button' className='close' onClick={() => dispatch(admin_table_member_id_filter_display_toggle(false))} />
            <input
              type='text'
              value={filterValue || ''}
              onChange={e => {
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
              }}
              placeholder='Введите ID'
            />
          </div>
        )}
      </>
    );
  };
  const ExpertColumnFilter = ({ column: { filterValue, setFilter } }) => {
    const { admin_table_expert_filter_display_status } = useSelector((state: any) => state.adminTableReducer);

    return (
      <>
        <img
          alt='icon'
          className='searchIcon expertSearch'
          src={icon_search}
          onClick={() => dispatch(admin_table_expert_filter_display_toggle(!admin_table_expert_filter_display_status))}
        />
        {admin_table_expert_filter_display_status && (
          <div className='searchPopup expertSearch'>
            <button type='button' className='close' onClick={() => dispatch(admin_table_expert_filter_display_toggle(false))} />
            <input
              type='text'
              value={filterValue || ''}
              onChange={e => {
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
              }}
              placeholder='Начните вводить имя/фамилию эксперта'
            />
          </div>
        )}
        {filterValue && (
          <img
            alt='icon'
            className='resetFilterIcon'
            src={icon_resetFilter}
            onClick={() => {
              setFilter(undefined);
              dispatch(admin_table_expert_filter_display_toggle(false));
            }}
          />
        )}
      </>
    );
  };
  const MemberNameColumnFilter = ({ column: { filterValue, setFilter } }) => {
    const { admin_table_member_name_filter_display_status } = useSelector((state: any) => state.adminTableReducer);

    return (
      <>
        <img
          alt='icon'
          className='searchIcon expertSearch'
          src={icon_search}
          onClick={() => dispatch(admin_table_member_name_filter_display_toggle(!admin_table_member_name_filter_display_status))}
        />
        {admin_table_member_name_filter_display_status && (
          <div className='searchPopup memberNameSearch'>
            <button type='button' className='close' onClick={() => dispatch(admin_table_member_name_filter_display_toggle(false))} />
            <input
              type='text'
              value={filterValue || ''}
              onChange={e => {
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
              }}
              placeholder='Начните вводить имя/фамилию учатника'
            />
          </div>
        )}
        {filterValue && (
          <img
            alt='icon'
            className='resetFilterIcon'
            src={icon_resetFilter}
            onClick={() => {
              setFilter(undefined);
              dispatch(admin_table_member_name_filter_display_toggle(false));
            }}
          />
        )}
      </>
    );
  };
  const TribeColumnFilter = ({ column: { filterValue, setFilter } }) => {
    const { admin_table_tribe_filter_display_status } = useSelector((state: any) => state.adminTableReducer);

    return (
      <>
        <img
          alt='icon'
          className='searchIcon tribeSearch'
          src={icon_search}
          onClick={() => dispatch(admin_table_tribe_filter_display_toggle(!admin_table_tribe_filter_display_status))}
        />
        {admin_table_tribe_filter_display_status && (
          <div className='searchPopup tribeSearch'>
            <button type='button' className='close' onClick={() => dispatch(admin_table_tribe_filter_display_toggle(false))} />
            <input
              type='text'
              value={filterValue || ''}
              onChange={e => {
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
              }}
              placeholder='Начните вводить название трайба'
            />
          </div>
        )}
        {filterValue && (
          <img
            alt='icon'
            className='resetFilterIcon'
            src={icon_resetFilter}
            onClick={() => {
              setFilter(undefined);
              dispatch(admin_table_tribe_filter_display_toggle(false));
            }}
          />
        )}
      </>
    );
  };
  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );
  // * Стркутруа колнок для таблицы
  const columns = useMemo(
    () => [
      {
        Header: 'Дата',
        accessor: 'date',
        disableFilters: true,
      },
      {
        Header: 'Эксперт',
        accessor: 'expertName',
        Filter: ExpertColumnFilter,
      },
      {
        Header: 'ID',
        accessor: 'memberID',
        disableSortBy: true,
        Filter: MemberIDColumnFilter,
      },
      {
        Header: 'Имя участника',
        accessor: 'memberName',
        disableSortBy: true,
        Filter: MemberNameColumnFilter,
      },
      {
        Header: 'Трайб',
        accessor: 'tribe',
        Filter: TribeColumnFilter,
      },
      {
        Header: 'Роль',
        accessor: 'role',
        disableFilters: true,
      },
      {
        Header: 'Сегмент',
        accessor: 'case',
        disableFilters: true,
      },
      {
        Header: 'Статус',
        accessor: 'status',
        Filter: StatusColumnFilter,
      },
      {
        Header: 'PDF',
        accessor: 'anketaID',
        disableFilters: true,
        Cell: ({ cell, row }) =>
          row.original.status === 'Для проверки' || row.original.status === 'Проверена' || row.original.status === 'Опубликована' ? (
            <Link
              className='link'
              to={
                _.isNaN(Number(streamID))
                  ? `/admin/${clientID}/anketa_questions/${cell.row.original.anketaID}/${cell.row.original.anketaAnswersID}/pdf/`
                  : `/admin/${clientID}/${streamID}/anketa_questions/${cell.row.original.anketaID}/${cell.row.original.anketaAnswersID}/pdf/`
              }
            >
              PDF
            </Link>
          ) : (
            '––'
          ),
      },
    ],
    []
  );
  // * Данные для таблицы
  const data = useMemo(() => filteredData, [filteredData]);

  // * Инциализация таблицы
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, selectedFlatRows } = useTable(
    {
      columns,
      data,
      defaultColumn,
      disableSortRemove: true,
      autoResetSortBy: false,
      initialState: {
        sortBy: [
          {
            id: 'date',
            desc: true,
          },
        ],
      },
    },
    useFilters,
    useSortBy,
    useRowSelect,

    hooks => {
      hooks.visibleColumns.push(columns => [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />,
          Cell: ({ row }) =>
            row.original.status === 'Проверена' || row.original.status === 'Опубликована' ? (
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            ) : (
              <div className='checkbox disabled'></div>
            ),
        },
        ...columns,
      ]);
    }
  );

  return (
    <section className='anketaTable'>
      <AdminClientAnketasDownload selectedAnketas={selectedFlatRows} setIsChangeTrbieModal={() => setIsChangeTrbieModal(true)} />

      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={`headerGroup-tr-${index}`}>
              {headerGroup.headers.map((column, index) => (
                <th {...column.getHeaderProps} key={`headerGroup-tr-th-${index}`} className={`${!memberID ? 'noMemberIDTable' : ''}`}>
                  <div {...column.getSortByToggleProps()}>
                    {column.render('Header')}
                    {!column.disableSortBy &&
                      (column.isSortedDesc ? (
                        <img alt='icon' className='sortDownIcon' src={icon_sortDown} />
                      ) : (
                        <img alt='icon' className='sortUpIcon' src={icon_sortDown} />
                      ))}
                  </div>
                  {column.canFilter ? column.render('Filter') : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={`body-tr-${i}`}>
                {row.cells.map((cell, i: number) => {
                  return (
                    <td {...cell.getCellProps()} key={`body-tr-td-${i}`} className={`${!memberID ? 'noMemberIDTable' : ''}`}>
                      {cell.column.Header === 'Сегмент' &&
                      (cell.row.values.status === 'Для проверки' || cell.row.values.status === 'Проверена' || cell.row.values.status === 'Опубликована') ? (
                        <>
                          <Link
                            className='link'
                            to={
                              _.isNaN(Number(streamID))
                                ? `/admin/${clientID}/anketa_questions/${cell.row.original.anketaID}/${cell.row.original.anketaAnswersID}/`
                                : `/admin/${clientID}/${streamID}/anketa_questions/${cell.row.original.anketaID}/${cell.row.original.anketaAnswersID}/`
                            }
                          >
                            {cell.render('Cell')}
                          </Link>
                          {(cell.row.original.vc_video_link && cell.row.original?.vc_service && (
                              cell.row.original.vc_service?.system_name === 'zoom' ||  cell.row.original.vc_service?.system_name === 'dion'
                          )) && (
                            <img
                              alt='icon'
                              src={icon_zoomVideo}
                              className='zoomVideoIcon'
                              onClick={() => {
                                setZoomModalDataID(cell.row.original.anketaAnswersID);
                                dispatch(anketa_zoom_video_modal_display_toggle(true));
                              }}
                            />
                          )}
                        </>
                      ) : (
                        cell.render('Cell')
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      {anketa_zoom_video_modal_display_status && <AnketaZoomVideoModal zoomModalDataID={zoomModalDataID} />}
      {isChangeTrbieModal && <ChangeTribeModal selectedAnketas={selectedFlatRows} onClose={() => setIsChangeTrbieModal(false)} />}
    </section>
  );
};
