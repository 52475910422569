import './AnketaAnswerDraggable.scss';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Form, Formik } from 'formik';
import { backendURL, debug } from '../../../App';
import { useEffect, useState } from 'react';

import Button from '../../../components/Button';
import _ from 'lodash';
import axios from 'axios';
import consoleLog from '../../../helpers/consoleLog';
import { queryCache } from 'react-query';
import { useHistory } from 'react-router-dom';

interface Props {
  anketaData: {
    anketa_answer_id: number;
    anketa: {
      interview_needless: boolean;
    };
    question: {
      subquestions: [];
    };
    subquestion: {
      id: number;
      variants: [{ id: number; text: string }];
    };
    answer_id: number;
    questions_count: number;
    question_number: number;
    subquestion_number: number;
  };
  setIsNoInterviewModal: () => void;
  setErrorDuringSubmit: () => void;
}

export const AnketaAnswerDraggable: React.FC<Props> = ({ anketaData, setErrorDuringSubmit }) => {
  const history = useHistory();
  // * Варианты ответы записаны в стейт, чтобы можно было drag&drop делать
  const [variants, setVariants] = useState(anketaData.subquestion.variants);
  useEffect(() => {
    setVariants(anketaData.subquestion.variants);
  }, [anketaData]);

  const initialValues = () => {
    let values = {};
    anketaData.subquestion.variants.forEach(variant => (values[`${variant.id}`] = false));
    return values;
  };

  const valuesToSubmit = (values: typeof variants) => {
    const valuesToSubmit = values.map(value => value.id);
    return valuesToSubmit.join(',');
  };

  const reorder = (list: typeof variants, startIndex: number, endIndex: number) => {
    // @ts-ignore
    const result: typeof variants = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    const reorderedVariants = reorder(variants, result.source.index, result.destination.index);

    setVariants(reorderedVariants);
  };

  return (
    <div className='anketaAnswerDraggable'>
      <Formik
        enableReinitialize
        initialValues={initialValues()}
        onSubmit={async (values, { setSubmitting, setStatus, resetForm }) => {
          try {
            const { data } = await axios({
              method: 'post',
              url: `${backendURL}answers/subs/`,
              headers: {
                Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
              },
              data: {
                anketa_answers_id: anketaData.anketa_answer_id,
                subquestion_id: anketaData.subquestion.id,
                answer_id: anketaData.answer_id || '',
                variant_id: valuesToSubmit(variants),
              },
            });
            // * Если это последний вопрос, последний подвопрос и анкета без интервью - вначале показать модалку
            if (
              !(
                anketaData.questions_count === anketaData.question_number &&
                anketaData.subquestion_number === anketaData.question.subquestions.length &&
                anketaData.anketa.interview_needless
              )
            ) {
              await queryCache.invalidateQueries('clientAnketaData');
              resetForm();
              setSubmitting(false);
              setStatus({ success: true });
            } else {
              history.push('/client/anketa/complete/');
            }
            debug && consoleLog('success', 'Subquestion answer submit', data);
          } catch (error) {
            setSubmitting(false);
            setErrorDuringSubmit();
            debug && consoleLog('error', 'Subquestion answer submit', error);
          }
        }}
      >
        {props => (
          <Form>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId='droppable'>
                {provided => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {variants.map((variant, index) => (
                      <Draggable key={variant.id} draggableId={String(variant.id)} index={index}>
                        {(provided, snapshot) => (
                          <div
                            className={'draggableVariant ' + (snapshot.isDragging ? 'draggableVariant--Dragging' : '')}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div className='draggableVariantImage'></div>
                            <div className='draggableVariantText'>{variant.text}</div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <Button
              type='button'
              buttonType='submit'
              width='330rem'
              size='medium'
              color='blue'
              className='submitButton'
              text='Cледующий вопрос'
              loading={props.isSubmitting}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};
