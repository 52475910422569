import { HEADER_DISPLAY_TYPE_TOGGLE } from '../actions/types';

const INITIAL_STATE = {
  header_display_type: 'basic'
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HEADER_DISPLAY_TYPE_TOGGLE:
      return { ...state, header_display_type: action.payload };   
    default:
      return { ...state };
  }
};
