import { useQuery } from 'react-query';
import axios from 'axios';
import { backendURL } from '../App';

const getAnketasAnswersData = async _ => {
  const { data } = await axios({
    method: 'get',
    url: `${backendURL}anketa-answers/`,
    headers: {
      Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
    },
    params: {
      order_by: 'call_start',
      type: 'all',
    },
  });
  let filtredData = {
    withInterview: {
      all: [] as any[],
      reviewNotStated: [] as any[],
      reviewInProcess: [] as any[],
      reviewCompleted: [] as any[],
      closed: [] as any[],
    },
    withoutInterview: {
      all: [] as any[],
      reviewNotStated: [] as any[],
      reviewInProcess: [] as any[],
      reviewCompleted: [] as any[],
      closed: [] as any[],
    },
  };
  data.forEach(anketa => {
    if (anketa.call_start) {
      filtredData.withInterview.all.push(anketa);
      anketa.process_status === 2 && filtredData.withInterview.reviewNotStated.push(anketa);
      anketa.process_status === 3 && filtredData.withInterview.reviewInProcess.push(anketa);
      anketa.process_status === 4 && filtredData.withInterview.reviewCompleted.push(anketa);
      anketa.process_status === 5 && filtredData.withInterview.closed.push(anketa);
    } else {
      filtredData.withoutInterview.all.push(anketa);
      anketa.process_status === 2 && filtredData.withoutInterview.reviewNotStated.push(anketa);
      anketa.process_status === 3 && filtredData.withoutInterview.reviewInProcess.push(anketa);
      anketa.process_status === 4 && filtredData.withoutInterview.reviewCompleted.push(anketa);
      anketa.process_status === 5 && filtredData.withoutInterview.closed.push(anketa);
    }
  });
  return filtredData;
};

export default function useAnketasAnswersData() {
  return useQuery(['anketasAnswersData'], getAnketasAnswersData, { retry: false });
}
