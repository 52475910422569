import './InputError.scss';



interface InputErrorProps {
  children: any,
  className?: string
}

export const InputError: React.FC<InputErrorProps> = ({children, className}) => (
  <div className={`inputError ${className || ''}`}>{children}</div>
)
