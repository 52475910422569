import './CopyToClipboard.scss';

import icon_clipboard from '../../assets/images/icon_clipboard.svg';
import { motion } from 'framer-motion';
import { useState } from 'react';

interface Props {
  value: string;
}

export const CopyToClipboard = ({ value }: Props) => {
  const [copiedToClipboard, setCopiedToClipboard] = useState<undefined | string>(undefined);

  const copiedAnimationVariants = {
    coppied: { scale: 1.3 },
    default: { scale: 1 },
  };

  const copyToClipboard = value => {
    const el = document.createElement('textarea');
    el.value = value;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setCopiedToClipboard(value);
    setTimeout(() => {
      setCopiedToClipboard(undefined);
    }, 500);
  };

  return (
    <>
      <motion.img
        src={icon_clipboard}
        onClick={() => copyToClipboard(value)}
        className='clipboardIcon'
        animate={copiedToClipboard === value ? 'coppied' : 'default'}
        variants={copiedAnimationVariants}
      />
      {copiedToClipboard === value && <div className='copiedToClipboardText'>Скопировано</div>}
    </>
  );
};
