import './ExpertAnketaHeader.scss';

import { Link } from 'react-router-dom';
import Title from '../../Title';
import consoleLog from '../../../helpers/consoleLog';

interface Props {
  userData: {
    first_name: string;
    last_name?: string;
    member_id?: string;
    status_str?: string;
    case_str?: string;
  };
  anketaData: {
    role: string;
    case: string;
  };
  clientData: {
    title: string;
    logo: string;
  };
}

export const ExpertAnketaHeader: React.FC<Props> = ({ userData, anketaData, clientData }) => (
  <div className='expertAnketaHeader'>
    <Link to='/expert/respondents/' className='link'>
      Вернуться к списку анкет
    </Link>
    <Title>Анкета</Title>
    <div className='info'>
      <div className='keys'>
        <div className='key'>Имя участника или ID</div>
        <div className='key'>Клиент</div>
        <div className='key'>Роль</div>
        <div className='key'>Сегмент</div>
      </div>
      <div className='values'>
        <div className='value'>
          {userData.first_name} {userData.last_name} {userData.member_id}
        </div>
        <div className='value'>
          <img src={clientData.logo} alt='client logo' />
          {clientData.title}
        </div>
        <div className='value'>{userData.status_str || anketaData.role}</div>
        <div className='value'>{userData.case_str || anketaData.case}</div>
      </div>
    </div>
  </div>
);
