import './AdminClientReportHTML.scss';
import 'react-circular-progressbar/dist/styles.css';

import AnimatedRedirect from '../../components/AnimatedRedirect';
import { CTP } from '../../App';
import CompetentionsCircles from '../../components/ReportsSharedComponents/CompetentionsCircles';
import CompetentionsLevels from '../../components/ReportsSharedComponents/CompetentionsLevels';
import CompetentionsSpider from '../../components/ReportsSharedComponents/CompetentionsSpider';
import PageLoader from '../../components/PageLoader';
import RecomendationsForIndicators from '../../components/ReportsSharedComponents/RecomendationsForIndicators';
import { TribeResultsHTML } from '../../components/ReportsSharedComponents/TribeResults';
import _ from 'lodash';
import api_getReportHtmlData from '../../hooks/api_getReportHtmlData';
import logo_blue from '../../assets/images/logo-blue.svg';
import logo_blue_ctp from '../../assets/images/logo-blue_ctp.svg';
import { motion } from 'framer-motion';
import { standardPageVariants } from '../../animationsVariants/StandardPageVariants';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
// import { data } from '../../helpers/fakereportdata';
// 21 <=> 26 61-67 коменнтим однр, раскоменнчиваем другое

export const AdminClientReportHTML: React.FC<{}> = () => {
  const { addoID, key } = useParams<{ addoID: string; key: string }>();
  const { data, status: reportDataLoadingStatus } = useQuery('reportData', () => api_getReportHtmlData({ addo_id: +addoID, key }));
  const transformDataForRadarChart = spider => {
    console.log(spider.length);
    let chartColumns = {};
    let radarRequired = { class: 'required' };
    let radarClient = { class: 'client' };
    let radarClient2 = { class: 'client2' };

    if (spider.length === 1) {
      spider[0].diagram_data.map((competention, index) => {
        chartColumns = { ...{ [index]: competention[0] }, ...chartColumns };
        radarRequired = { ...{ [index]: competention[2] / 100 }, ...radarRequired };
        radarClient = { ...{ [index]: competention[1] / 100 }, ...radarClient };
      });

      return [chartColumns, radarRequired, radarClient];
    } else if (spider.length === 2) {
      spider[1].diagram_data.map((competention, index) => {
        chartColumns = { ...{ [index]: competention[0] }, ...chartColumns };
        radarRequired = { ...{ [index]: spider[0].diagram_data[index]?.[2] / 100 }, ...radarRequired };
        radarClient = { ...{ [index]: spider[0].diagram_data[index]?.[1] / 100 }, ...radarClient };
        radarClient2 = { ...{ [index]: competention[1] / 100 }, ...radarClient2 };
      });

      return [chartColumns, radarRequired, radarClient, radarClient2];
    }
  };

  useEffect(() => {
    document.getElementsByTagName('html')[0].classList.add('pdfPage');

    return () => {
      document.getElementsByTagName('html')[0].classList.remove('pdfPage');
    };
  }, []);

  if (reportDataLoadingStatus === 'loading') {
    return <PageLoader />;
  }

  if (reportDataLoadingStatus === 'error') {
    return <AnimatedRedirect redirectTo='/500/' />;
  }

  return (
    <motion.main className='adminClientReportHTML' variants={standardPageVariants} initial='initial' animate='animate' exit='exit'>
      <>
        <div className='header' data-cy='header'>
          <div className='topHeader'>
            <img src={CTP ? logo_blue_ctp : logo_blue} alt='logo' />
            <img src={data.client_logo_url} alt='clientLogo' />
          </div>
          <div className='bottomHeader'>
            <div className='bottomHeaderSection'>
              <span className='key'>Клиент</span>
              <span className='value' data-cy='clientTitle'>
                {data.client_title}
              </span>
            </div>
            <div className='bottomHeaderSection'>
              <span className='key'></span>
              <span className='value'>Групповой отчёт</span>
            </div>
            {/* <div className='bottomHeaderSection'>
              <span className='key'>Всего</span>
              <span className='value'>
                {data.anketa_answers_count} {data.anketa_answers_count_man}
              </span>
            </div> */}
            {/* <div className='bottomHeaderSection'>
              <span className='key'>Набрано из 100%</span>
              <span className='value'>{`${data.rate_percent}%`}</span>
            </div> */}
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <td>
                <div className='page-header-space'></div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {/* @ts-ignore */}
                <CompetentionsCircles data={data} type='client' />
                {/* // * Временный костыль, работает только для отп-банка */}
                {/* <CompetentionsCirclesByRole data={data} type='client' /> */}
                {/* @ts-ignore */}
                <CompetentionsLevels data={data} />

                {data.spiders.map((spider, index) => (
                  <>
                    {/* @ts-ignore */}
                    <CompetentionsSpider radarData={transformDataForRadarChart(spider)} radarInfo={spider} data={data} type='tribe' key={index} />
                  </>
                ))}
                {/* @ts-ignore */}
                {!_.isEmpty(data.cases) && <TribeResultsHTML data={data} withHeader={false} />}
                <RecomendationsForIndicators data={data} />
                <footer></footer>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    </motion.main>
  );
};
