import './TemplateStage1Form.scss';
import 'react-quill/dist/quill.snow.css';

import * as Yup from 'yup';

import { Form, Formik } from 'formik';
import { backendURL, debug } from '../../../App';
import { useHistory, useParams } from 'react-router-dom';

import Button from '../../../components/Button';
import Input from '../../../components/Input';
import InputSelect from '../../../components/InputSelect';
import SubTitle from '../SubTitle';
import TextareaWYSIWYG from '../../../components/TextareaWYSIWYG';
import Title from '../../../components/Title';
import _ from 'lodash';
import axios from 'axios';
import consoleLog from '../../../helpers/consoleLog';
import icon_checkedLongWhite from '../../../assets/images/icon_checkedLongWhite.svg';
import icon_loading from '../../../assets/images/icon_loadingBlue.svg';
import { motion } from 'framer-motion';
import { queryCache } from 'react-query';
import useAnketaData from '../../../hooks/useAnketaData';
import useClientData from '../../../hooks/useClientData';

export const TemplateStage1Form: React.FC<{}> = () => {
  const { clientID, anketaID } = useParams<{ clientID: string; anketaID: string }>();
  const { data: clientData, status: clientDalaLoadingStatus } = useClientData(clientID, localStorage.getItem('skillsSurveyUserToken'), undefined);
  const { data: anketaData, status: anketaDataLoadingStatus } = useAnketaData(anketaID);
  const history = useHistory();

  const roleOptions = () => {
    let options: object[] = [];

    _.forEach(clientData.roles, role => {
      options.push({ value: role.id, label: role.title });
    });

    return options;
  };

  const streamOptions = () => {
    let options: object[] = [];

    _.forEach(clientData.streams, stream => {
      options.push({ value: stream.id, label: stream.title });
    });

    return options;
  };

  const caseOptions = () => {
    let options: object[] = [];

    options.push({ value: 0, label: 'B2B' });
    options.push({ value: 1, label: 'B2C' });
    options.push({ value: 2, label: 'Внутренний продукт' });
    options.push({ value: 3, label: 'B2X' });
    options.push({ value: 4, label: 'MMБ' });
    options.push({ value: 5, label: 'Розничный Бизнес' });
    options.push({ value: 6, label: 'HR' });
    options.push({ value: 7, label: 'SFA' });

    return options;
  };

  if (clientDalaLoadingStatus === 'loading' || anketaDataLoadingStatus === 'loading') {
    return (
      <div className='isLoading'>
        <motion.img src={icon_loading} initial={{ rotate: 0 }} animate={{ rotate: 360 }} transition={{ duration: 2, loop: Infinity, ease: 'linear' }} />
      </div>
    );
  }

  return (
    <section className='templateStage1Form'>
      <Title>Создание шаблона анкеты</Title>
      <Formik
        initialValues={{
          title: anketaData?.title || '',
          role: anketaData?.role_value !== '' ? anketaData?.role_value : '',
          case: anketaData?.case_value !== '' ? anketaData?.case_value : '',
          description: anketaData?.description || '',
          interview_needless: anketaData?.interview_needless || false,
          auto_validation: anketaData?.auto_validation || false,
          auto_email: anketaData?.auto_email || false,
          stream_id: anketaData?.stream_id || '',
          intro: anketaData?.intro || '',
          time_for_anketa: anketaData?.time_for_anketa || null,
        }}
        validationSchema={Yup.object({
          title: Yup.string().required('поле должно быть заполнено'),
          role: Yup.string().required('нужно выбрать роль'),
          // case: Yup.string().required('нужно выбрать сегмент'),
          stream_id: Yup.string().required('нужно выбрать поток'),
          description: Yup.string().required('поле должно быть заполнено'),
        })}
        onSubmit={async (values, { setSubmitting, setStatus }) => {
          try {
            const { data } = await axios({
              method: 'post',
              url: anketaData?.id ? `${backendURL}anketa/${anketaData?.id}/` : `${backendURL}anketas/`,
              headers: {
                Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
              },
              data: {
                id: anketaData?.id ? anketaData?.id : undefined,
                client_id: clientData.id,
                ...values,
              },
            });
            setSubmitting(false);
            setStatus({ success: true });
            history.push(`/admin/${clientID}/templates/${data.id}/competentions/`);
            queryCache.setQueryData(['anketaData', anketaID], data);
            debug && consoleLog('success', 'Create anketa template stage 1', data);
          } catch (error) {
            setSubmitting(false);
            debug && consoleLog('error', 'Create anketa template stage 1', error);
          }
        }}
      >
        {props => (
          <Form>
            <SubTitle>Описание кейса</SubTitle>
            <div className='checkboxes-container'>
              <Button
                type='button'
                buttonType='button'
                width='310rem'
                size='small'
                color='red'
                className='checkbox'
                onClick={() => {
                  props.setFieldValue('interview_needless', !props.values.interview_needless);
                  props.setFieldValue('auto_validation', false);
                  props.setFieldValue('auto_email', false);
                }}
              >
                <span className='checkboxContainer'>{props.values.interview_needless && <img src={icon_checkedLongWhite} alt='icon' />}</span>
                <span>Анкетирование без интервью</span>
              </Button>
              <Button
                type='button'
                buttonType='button'
                width='310rem'
                size='small'
                color='red'
                className='checkbox'
                disabled={props.values.interview_needless === false}
                onClick={() => props.setFieldValue('auto_validation', !props.values.auto_validation)}
              >
                <span className='checkboxContainer'>{props.values.auto_validation && <img src={icon_checkedLongWhite} alt='icon' />}</span>
                <span>Автовалидация</span>
              </Button>
              <Button
                type='button'
                buttonType='button'
                width='310rem'
                size='small'
                color='red'
                className='checkbox'
                disabled={props.values.interview_needless === false}
                onClick={() => props.setFieldValue('auto_email', !props.values.auto_email)}
              >
                <span className='checkboxContainer'>{props.values.auto_email && <img src={icon_checkedLongWhite} alt='icon' />}</span>
                <span>Автоматическая отправка email</span>
              </Button>
            </div>

            <Input
              name='text'
              type='text'
              value={props.values.title}
              onChange={event => props.setFieldValue('title', event.target.value)}
              onBlur={() => props.setFieldTouched('title', true)}
              placeholderType='alwaysVisible'
              placeholder='Название'
              isDisabled={props.isSubmitting === true ? true : false}
              isValid={!(props.errors.title && props.touched.title)}
              validationError={props.errors.title && props.touched.title ? props.errors.title : undefined}
              autoComplete='off'
            />
            <InputSelect
              name='stream'
              options={streamOptions()}
              // @ts-ignore
              defaultValue={streamOptions().find(item => item.value === props.values.stream_id)}
              value={props.values.stream_id}
              placeholderType='alwaysVisible'
              placeholder='Поток'
              onChange={option => props.setFieldValue('stream_id', option.value)}
              onBlur={() => props.setFieldTouched('stream_id', true)}
              isValid={!(props.errors.stream_id && props.touched.stream_id)}
              validationError={props.errors.stream_id && props.touched.stream_id ? props.errors.stream_id : undefined}
            />
            <InputSelect
              name='role'
              options={roleOptions()}
              // @ts-ignore
              defaultValue={roleOptions().find(item => item.value === props.values.role)}
              value={props.values.role}
              placeholderType='alwaysVisible'
              placeholder='Роль'
              onChange={option => props.setFieldValue('role', option.value)}
              onBlur={() => props.setFieldTouched('role', true)}
              isValid={!(props.errors.role && props.touched.role)}
              validationError={props.errors.role && props.touched.role ? props.errors.role : undefined}
            />
            <InputSelect
              name='case'
              options={caseOptions()}
              // @ts-ignore
              defaultValue={caseOptions().find(item => item.value === props.values.case)}
              value={props.values.case}
              placeholderType='alwaysVisible'
              placeholder='Сегмент'
              onChange={option => props.setFieldValue('case', option.value)}
              onBlur={() => props.setFieldTouched('case', true)}
              isValid={!(props.errors.case && props.touched.case)}
              validationError={props.errors.case && props.touched.case ? props.errors.case : undefined}
            />
            <Input
              name='time_for_anketa'
              type='text'
              value={props.values.time_for_anketa}
              onChange={event => props.setFieldValue('time_for_anketa', event.target.value === '' ? null : event.target.value)}
              onBlur={() => props.setFieldTouched('time_for_anketa', true)}
              placeholderType='alwaysVisible'
              placeholder='Доступное кол. минут на прохожение анкеты'
              isDisabled={props.isSubmitting === true ? true : false}
              isValid={!(props.errors.time_for_anketa && props.touched.time_for_anketa)}
              validationError={props.errors.time_for_anketa && props.touched.time_for_anketa ? props.errors.time_for_anketa : undefined}
              autoComplete='off'
            />
            <TextareaWYSIWYG
              value={props.values.intro}
              onChange={value => props.setFieldValue('intro', value.replace(/(<([^>]+)>)/gi, '') ? value : '')}
              onBlur={() => props.setFieldTouched('intro', true)}
              placeholderType='alwaysVisible'
              placeholder='Интро анкеты'
              modulesType='default'
              isValid={!(props.errors.intro && props.touched.intro)}
              validationError={props.errors.intro && props.touched.intro ? props.errors.intro : undefined}
            />
            <TextareaWYSIWYG
              value={props.values.description}
              onChange={value => props.setFieldValue('description', value.replace(/(<([^>]+)>)/gi, '') ? value : '')}
              onBlur={() => props.setFieldTouched('description', true)}
              placeholderType='alwaysVisible'
              placeholder='Описание кейса'
              modulesType='default'
              isValid={!(props.errors.description && props.touched.description)}
              validationError={props.errors.description && props.touched.description ? props.errors.description : undefined}
            />
            <Button
              type='button'
              width='330rem'
              size='medium'
              color='blue'
              text='Перейти к созданию компетенций'
              loading={props.isSubmitting === true ? true : false}
              className='submitButton'
            />
          </Form>
        )}
      </Formik>
    </section>
  );
};
