import './ExpertAnketaAnswerSelect.scss';
import 'react-quill/dist/quill.snow.css';


import consoleLog from '../../../helpers/consoleLog';

interface AnketaAnswerProps {
  answerVariants: any;
  selectedAnswerVariants?: any;
}

export const ExpertAnketaAnswerSelect: React.FC<AnketaAnswerProps> = ({ answerVariants, selectedAnswerVariants }) => {
  const isUserSelected = id => {
    if (selectedAnswerVariants.find(variant => variant.id === id)) {
      return true;
    }
    return false;
  };

  return (
    <div className='expertAnketaAnswerSelect'>
      <div className='answerSubTitle'>Ответ</div>
      <div className='answerColorsExpanation'>
        <span>ответ участника</span>
        <span>правильный ответ</span>
      </div>
      <div className='answerVariants'>
        {answerVariants.map(variant => (
          <div className={'answerVariant' + `${variant.weight > 0 ? ' correct' : ''}` + `${isUserSelected(variant.id) ? ' userSelected' : ''}`} key={variant.id}>
            {variant.text}
            {isUserSelected(variant.id)}
          </div>
        ))}
      </div>
    </div>
  );
};
