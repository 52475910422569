import { useMutation } from 'react-query';
import axios from 'axios';
import { backendURL, debug } from '../App';
import consoleLog from '../helpers/consoleLog';

const deleteCompetention = async ({ competentionID }) => {
  try {
    const { data } = await axios({
      method: 'post',
      url: `${backendURL}competention/${competentionID}/`,
      headers: {
        Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
      },
      data: {
        id: competentionID,
        delete: true
      },
    });
    debug && consoleLog('success', 'Delete competention', data);
    return data;
  } catch (error) {
    debug && consoleLog('error', 'Delete competention', error);
  }
};

export default function useDeleteCompetentionMutation({onSuccess}) {
  return useMutation(deleteCompetention, {onSuccess});
}
