import './MemberInterview.scss';

import { endOfWeek, format, set, startOfWeek } from 'date-fns';

import AnimatedRedirect from '../../components/AnimatedRedirect';
import AssignInterviewModal from './AssignInterviewModal';
import Button from '../../components/Button';
import InterviewAssignedModal from './InterviewAssignedModal';
import NoAvailableTimeModal from './NoAvailableTimeModal';
import PageLoader from '../../components/PageLoader';
import { ScheduleTable } from '../../components/ScheduleTable/ScheduleTableMember';
import { ScheduleTabs } from '../../components/ScheduleTabs/ScheduleTabsMember';
import Title from '../../components/Title';
import { motion } from 'framer-motion';
import { queryCache } from 'react-query';
import { standardPageVariants } from '../../animationsVariants/StandardPageVariants';
import useChangeInterviewDateMutation from '../../hooks/useChangeInterviewDateMutation';
import useClientAnketaData from '../../hooks/useClientAnketaData';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import useTimeTableAvailableData from '../../hooks/useTimeTableAvailableData';
import useTimeTableData from '../../hooks/useTimeTableData';
import useUserData from '../../hooks/useUserData';
import { utcToZonedTime } from 'date-fns-tz/esm';

export const MemberInterview: React.FC<{}> = () => {
  const { anketaID } = useParams<{ anketaID: string }>();
  // * Фиксируем текущую неделю
  const currentDate = set(new Date(), { hours: 9, minutes: 0, seconds: 0 });
  const currentWeekStart = set(startOfWeek(currentDate, { weekStartsOn: 1 }), { hours: 8, minutes: 0, seconds: 0 });
  const currentWeekEnd = set(endOfWeek(currentDate, { weekStartsOn: 1 }), { hours: 21, minutes: 0, seconds: 0 });
  // * Обрабтаываем action-ы юзера по изменению/выбору расписания и интервью
  const [selectedWeekStart, setSelectedWeekStart] = useState<Date>(currentWeekStart);
  const [selectedWeekEnd, setSelectedWeekEnd] = useState<Date>(currentWeekEnd);
  const [selectedDateTime, setSelectedDateTime] = useState<undefined | Date>(undefined);
  const [isChangingDateTime, setIsChangingDateTime] = useState<boolean>(false);
  // * Модалки
  const [isNoAvailableTimeModal, setIsNoAvailableTimeModal] = useState<boolean>(true);
  const [isAssignInterviewModal, setIsAssignInterviewModal] = useState<boolean>(false);
  const [isInterviewAssignedModal, setIsInterviewAssignedModal] = useState<boolean>(false);
  // * Данные
  const { data: userData, status: userDataLoadingStatus } = useUserData();
  const { data: anketaData, status: anketaDataLoadingStatus } = useClientAnketaData(anketaID);
  const { data: timeTableAvailableData, status: timeTableAvailableDataLoadingStatus } = useTimeTableAvailableData();
  const { data: timeTableData, status: timeTableLoadingStatus } = useTimeTableData(
    format(selectedWeekStart, 'yyyy-MM-dd HH:mm'),
    format(selectedWeekEnd, 'yyyy-MM-dd HH:mm')
  );

  const [mutate, { status: changeInterviewDateMutationStatus }] = useChangeInterviewDateMutation({
    onSuccess: async () => {
      await queryCache.invalidateQueries('clientAnketaData');
      await queryCache.invalidateQueries('timeTableData');
      await setSelectedDateTime(undefined);
      await setIsChangingDateTime(false);
    },
  });

  if (
    timeTableLoadingStatus === 'loading' ||
    timeTableAvailableDataLoadingStatus === 'loading' ||
    anketaDataLoadingStatus === 'loading' ||
    userDataLoadingStatus === 'loading'
  ) {
    return <PageLoader />;
  }

  if (timeTableLoadingStatus === 'error' || timeTableAvailableDataLoadingStatus === 'error' || anketaDataLoadingStatus === 'error' || userDataLoadingStatus === 'error') {
    return <AnimatedRedirect redirectTo='/500/' />;
  }

  return (
    <motion.main className='memberInterview' variants={standardPageVariants} initial='initial' animate='animate' exit='exit'>
      {anketaData.status !== 1 ? (
        <>
          <Title>Вы выбрали способ связи и время</Title>
          <div className='callInfo'>
            <div className='subTitle callTypeSubTitle'>Способ связи для интервью с экспертом</div>
            {anketaData.vc_service && anketaData.vc_service.system_name == 'skype' && <div className='callLink skype'>{anketaData.skype}</div>}
            {anketaData.vc_service && anketaData.vc_service.system_name == 'zoom' && (
              <div className='callLink zoom'>
                <a href={anketaData.vc_link} target='_blank' rel='noopener noreferrer'>
                  Ссылка на звонок в Zoom
                </a>
              </div>
            )}
            {anketaData.vc_service && anketaData.vc_service.system_name == 'dion' && (
              <div className='callLink dion'>
                <a href={anketaData.vc_link} target='_blank' rel='noopener noreferrer'>
                  Ссылка на звонок в Dion
                </a>
              </div>
            )}
          </div>
        </>
      ) : (
        <Title>Вы закончили заполнение анкеты</Title>
      )}

      <div className='subTitle'>Укажите время для интервью</div>

      <ScheduleTabs
        currentWeekStart={currentWeekStart}
        currentWeekEnd={currentWeekEnd}
        selectedWeekStart={selectedWeekStart}
        setSelectedWeekStart={setSelectedWeekStart}
        selectedWeekEnd={selectedWeekEnd}
        setSelectedWeekEnd={setSelectedWeekEnd}
      />

      <ScheduleTable
        timeTableData={timeTableData}
        selectedWeekStart={selectedWeekStart}
        selectedWeekEnd={selectedWeekEnd}
        selectedDateTime={selectedDateTime}
        setSelectedDateTime={setSelectedDateTime}
        isChangingDateTime={isChangingDateTime}
        isAlreadyHasInterview={Boolean(anketaData.call_start)}
      />

      {anketaData.status === 1 && (
        <Button
          type='button'
          buttonType='button'
          onClick={() => setIsAssignInterviewModal(true)}
          width='330rem'
          size='large'
          color='blue'
          text='Сохранить время'
          disabled={selectedDateTime === undefined}
        />
      )}

      {anketaData.status !== 1 && anketaData.call_start && !anketaData.call_start_changed && !isChangingDateTime && (
        <Button type='button' buttonType='button' onClick={() => setIsChangingDateTime(true)} width='330rem' size='large' color='blue' text='Изменить время интервью' />
      )}

      {anketaData.status !== 1 && isChangingDateTime && selectedDateTime && (
        <Button
          type='button'
          buttonType='button'
          onClick={() =>
            mutate({
              selectedDateTime,
              callType: anketaData.zoom ? 'zoom' : 'skype',
              skype: anketaData.skype || '',
              anketaAnswersID: anketaData.anketa_answer_id,
            })
          }
          width='330rem'
          size='large'
          color='blue'
          text='Сохранить'
          loading={changeInterviewDateMutationStatus === 'loading'}
        />
      )}

      {anketaData.status !== 1 && isChangingDateTime && (
        <Button
          type='button'
          buttonType='button'
          onClick={() => {
            setIsChangingDateTime(false);
            setSelectedDateTime(undefined);
          }}
          width='330rem'
          size='large'
          color='blue'
          text='Отмена'
        />
      )}

      {anketaData.status !== 1 && anketaData.call_start_changed && (
        <Button type='button' buttonType='button' width='550rem' size='large' color='blue' text='Изменить время интервью допускается только 1 раз' disabled={true} />
      )}

      {!timeTableAvailableData.available_slots && isNoAvailableTimeModal && <NoAvailableTimeModal onClose={() => setIsNoAvailableTimeModal(false)} />}

      {isAssignInterviewModal && selectedDateTime && (
        <AssignInterviewModal
          onClose={() => setIsAssignInterviewModal(false)}
          interviewTime={selectedDateTime}
          setIsInterviewAssignedModal={() => setIsInterviewAssignedModal(true)}
          setSelectedDateTime={setSelectedDateTime}
          setIsChangingDateTime={setIsChangingDateTime}
        />
      )}

      {isInterviewAssignedModal && <InterviewAssignedModal onClose={() => setIsInterviewAssignedModal(false)} selectedDateTime={new Date(anketaData.call_start)} />}
    </motion.main>
  );
};
