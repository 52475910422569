import './ButtonLoader.scss';
import icon_loadingButton from '../../assets/images/icon_loadingButton.svg';
import icon_loadingBlue from '../../assets/images/icon_loadingBlue.svg';


import { motion } from 'framer-motion';

interface Props {
  color?: 'white' | 'blue';
}

export const ButtonLoader: React.FC<Props> = ({ color }) => (
  <motion.img
    initial={{ rotate: 0 }}
    animate={{ rotate: 360 }}
    transition={{ duration: 2, loop: Infinity, ease: 'linear' }}
    src={color === 'blue' ? icon_loadingBlue : icon_loadingButton}
    className='buttonLoader'
  />
);
