import './Radiobutton.scss';



interface Props {
  name: string;
  value?: string | number;
  checked?: boolean;
  text?: string;
  children?: any;
  onClick?: () => void;
}

export const Radiobutton: React.FC<Props> = ({ name, value, checked, onClick, text, children }) => {
  return (
    <div className='radiobutton'>
      <input type='radio' name={name} id={`${name}_${value}`} value={value} checked={checked} />
      <label id={`${name}_${value}`} className='radiobuttonVisibleLabel' onClick={onClick}>
        <span className={`radiobuttonIcon ${checked ? 'checked' : 'notChecked'}`}/>
        <span className='radiobuttonText'>{children ? children : text}</span>
      </label>
    </div>
  );
};
