import { ANKETA_LIST_ACTIVE_QUESTION_CHANGE } from '../actions/types';

const INITIAL_STATE = {
  anketaListActiveQuestion: 0
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ANKETA_LIST_ACTIVE_QUESTION_CHANGE:
      return { ...state, anketaListActiveQuestion: action.payload };   
    default:
      return { ...state };
  }
};
