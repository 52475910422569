import './AnketaQuestionRateHelpModal.scss';



import Title from '../Title';
import Modal from '../Modal';

interface AnketaQuestionRateHelpModal {
  currentQuestionNumber: number;
  helpText: string;
  onClose: () => void;
}

export const AnketaQuestionRateHelpModal: React.FC<AnketaQuestionRateHelpModal> = ({ currentQuestionNumber, helpText, onClose }) => {
  return (
    <Modal className='anketaQuestionRateHelpModal' onClose={onClose}>
      <Title>Инструкция для оценки вопроса {currentQuestionNumber}</Title>
      <div
        className='questionDescriptionContainer'
        dangerouslySetInnerHTML={{
          __html: helpText,
        }}
      ></div>
    </Modal>
  );
};
