import './ProfileAnketas.scss';

import AnimatedRedirect from '../../components/AnimatedRedirect';
import NewAnketa from './NewAnketa';
import PageLoader from '../../components/PageLoader';
import ProfileAnketasTable from './ProfileAnketasTable';
import Title from '../../components/Title';
import { motion } from 'framer-motion';
import { queryCache } from 'react-query';
import { standardPageVariants } from '../../animationsVariants/StandardPageVariants';
import { useEffect } from 'react';
import useUserData from '../../hooks/useUserData';

export const ProfileAnketas: React.FC<{}> = () => {
  useEffect(() => {
    queryCache.invalidateQueries('userData');
  }, []);

  const { data: userData, status: userDataLoadingStatus } = useUserData();

  if (userDataLoadingStatus === 'loading' || !userData.anketa_answers) {
    return <PageLoader />;
  }

  if (userDataLoadingStatus === 'error') {
    return <AnimatedRedirect redirectTo='/500/' />;
  }

  return (
    <motion.main className='profileAnketas' variants={standardPageVariants} initial='initial' animate='animate' exit='exit'>
      <Title>Анкеты</Title>
      <NewAnketa userID={userData.user.id} />
      <ProfileAnketasTable filteredData={userData.anketa_answers} />
    </motion.main>
  );
};
