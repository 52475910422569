import {
  ANKETA_NO_TIME_AVAILABLE_MODAL_DISPLAY_TOGGLE,
  ANKETA_CALL_TYPE_MODAL_DISPLAY_TOGGLE,
  ANKETA_CALL_ASSIGNED_MODAL_DISPLAY_TOGGLE,
  ANKETA_DESCRIPTION_MODAL_DISPLAY_TOGGLE,
  ANKETA_ZOOM_VIDEO_MODAL_DISPLAY_TOGGLE,
  SUPPORT_MODAL_DISPLAY_TOGGLE,
  ANKETA_QUESTION_RATE_HELP_MODAL_TOGGLE,
} from '../actions/types';

const INITIAL_STATE = {
  anketa_no_time_available_modal_display_status: false,
  anketa_call_type_modal_display_status: false,
  anketa_call_assigned_display_status: false,
  anketa_description_modal_display_status: false,
  anketa_zoom_video_modal_display_status: false,
  anketa_question_rate_help_modal_status: false,
  support_modal_display_status: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ANKETA_NO_TIME_AVAILABLE_MODAL_DISPLAY_TOGGLE:
      return { ...state, anketa_no_time_available_modal_display_status: action.payload };
    case ANKETA_CALL_TYPE_MODAL_DISPLAY_TOGGLE:
      return { ...state, anketa_call_type_modal_display_status: action.payload };
    case ANKETA_CALL_ASSIGNED_MODAL_DISPLAY_TOGGLE:
      return { ...state, anketa_call_assigned_display_status: action.payload };
    case ANKETA_DESCRIPTION_MODAL_DISPLAY_TOGGLE:
      return { ...state, anketa_description_modal_display_status: action.payload };
    case ANKETA_ZOOM_VIDEO_MODAL_DISPLAY_TOGGLE:
      return { ...state, anketa_zoom_video_modal_display_status: action.payload };
    case SUPPORT_MODAL_DISPLAY_TOGGLE:
      return { ...state, support_modal_display_status: action.payload };
    case ANKETA_QUESTION_RATE_HELP_MODAL_TOGGLE:
      return { ...state, anketa_question_rate_help_modal_status: action.payload };
    default:
      return { ...state };
  }
};
