import './RemoveMemberAbsenceModal.scss';


import { queryCache } from 'react-query';

import useRemoveMemberAbsenceMutation from '../../../../hooks/useRemoveMemberAbsenceMutation';
import Title from '../../../../components/Title';
import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';

interface Props {
  userID: number;
  clientID: number;
  onClose: () => void;
}

export const RemoveMemberAbsenceModal: React.FC<Props> = ({ userID, clientID, onClose }) => {
  const [mutate, { status: blockMemberMutationStatus }] = useRemoveMemberAbsenceMutation({
    onSuccess: () => {
      queryCache.invalidateQueries('adminMembersData');
      onClose();
    },
  });

  return (
    <Modal className='removeMemberAbsenceModal' onClose={onClose}>
      <Title>Убрать неявку участника</Title>
      <div className='text'>
        Если вы уберете неявку участника - это позволит ему назначить новое интервью.
        <br /> Максимальное количество неявок - 2.
      </div>
      <Button
        type='button'
        size='medium'
        width='330rem'
        color='blue'
        onClick={() => mutate({ userID, clientID })}
        text='Убрать'
        loading={blockMemberMutationStatus === 'loading' ? true : false}
      />
      <Button type='button' size='medium' width='330rem' color='white' text='Оставить' onClick={() => onClose()} />
    </Modal>
  );
};
