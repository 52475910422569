import * as Yup from 'yup';

import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Link, useHistory } from 'react-router-dom';

import { Button } from '../../components/Button/Button';
import { InputError } from '../../components/InputError/InputError';
import axios from 'axios';
import { backendURL } from '../../App';
import consoleLog from '../../helpers/consoleLog';
import { formVariants } from '../../animationsVariants/FormVariants';
import { header_display_type_toggle } from '../../actions';
import { motion } from 'framer-motion';
import { queryCache } from 'react-query';
import { useDispatch } from 'react-redux';

export const Login: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={Yup.object({
        email: Yup.string().email('некорректный email адрес').required('поле должно быть заполнено'),
        password: Yup.string().required('поле должно быть заполнено'),
      })}
      onSubmit={(values, { setSubmitting, setStatus, setFieldError }) => {
        axios({
          method: 'post',
          url: `${backendURL}auth/login/`,
          data: values,
        })
          .then(result => {
            localStorage.setItem('skillsSurveyUserToken', result.data.token);
            localStorage.setItem('skillsSurveyUserID', result.data.user.id);
            result.data.user.is_expert && localStorage.setItem('skillsSurveyUserType', 'is_expert');
            result.data.user.is_expert && history.push('/expert/schedule/');
            result.data.user.is_member && localStorage.setItem('skillsSurveyUserType', 'is_member');
            result.data.user.is_moderator && localStorage.setItem('skillsSurveyUserType', 'is_moderator');
            console.log(result.data);
            queryCache.setQueryData('userData', result.data);
            result.data.user.is_member && result.data.user.email_verified && history.push('/client/anketas/');
            result.data.user.is_member && !result.data.user.email_verified && history.push('/email_confirm/');
            result.data.user.is_moderator && queryCache.invalidateQueries('clientsData');
            result.data.user.is_moderator && dispatch(header_display_type_toggle('admin'));
            result.data.user.is_moderator && history.push('/admin/');
            consoleLog('success', 'Login attempt', result);
          })
          .catch(error => {
            setSubmitting(false);
            if (error?.response?.data?.error?.email) {
              setFieldError('password', error.response.data.error.email);
              setFieldError('email', ' ');
            } else {
              setStatus('fail');
              setTimeout(() => {
                setStatus('');
              }, 5000);
            }
            consoleLog('error', 'Login attempt', error);
          });
      }}
    >
      {props => (
        <main className='login'>
          <motion.div className='loginForm formContainer' variants={formVariants} initial='initial' animate='animate' exit='exit'>
            <Form>
              <h1>Войти на сервис</h1>

              <Field
                name='email'
                type='email'
                placeholder={'Ваш e-mail (логин)'}
                className={`${props.errors.email && props.touched.email ? 'invalid' : ''}`}
                data-cy='authLogin'
              />
              <InputError>
                <ErrorMessage name='email' />
              </InputError>

              <Field
                name='password'
                type='password'
                placeholder={'Ваш пароль'}
                className={`${props.errors.password && props.touched.password ? 'invalid' : ''}`}
                data-cy='authPassword'
              />
              <InputError className='password'>
                <ErrorMessage name='password' />
              </InputError>
              <Link to='/password_recover/' className='link forgotPasswordLink'>
                Забыли пароль?
              </Link>

              <InputError className='submitError'>{props.status === 'fail' && 'Системная ошибка. Обратитесь к администратору.'}</InputError>
              <Button type='button' width='330rem' size='large' color='blue' text='Войти' loading={props.isSubmitting === true ? true : false} dataCy='authButton' />
              <Button type='link' href='/registration/' width='280rem' size='small' color='black' text='Регистрация на сервисе' className='loginButtonLink' />
            </Form>
          </motion.div>
        </main>
      )}
    </Formik>
  );
};
