import { useMutation } from 'react-query';
import axios from 'axios';
import { backendURL, debug } from '../App';
import consoleLog from '../helpers/consoleLog';

const changeExpert = async ({timeSlotID, expertID, dateTime}) => {
  try {
    const { data } = await axios({
      method: 'post',
      url: `${backendURL}admin/timetable/${expertID}/`,
      headers: {
        Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
      },
      data: {
        change_interview_slot_id: timeSlotID,
        change_interview_expert_id: expertID,
        change_interview_datetime: dateTime
      },
    });
    debug && consoleLog('success', 'Change expert', data);
    return data;
  } catch (error) {
    debug && consoleLog('error', 'Change expert', error);
  }
};

export default function useChangeExpertMutation({onSuccess}) {
  return useMutation(changeExpert, {onSuccess});
}
