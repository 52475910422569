import { useMutation } from 'react-query';
import axios from 'axios';
import { backendURL, debug } from '../App';
import consoleLog from '../helpers/consoleLog';

const assignInterview = async ({ anketaAnswerID, call_type, call_start, skype }) => {
  try {
    const { data } = await axios({
      method: 'post',
      url: `${backendURL}anketa-answers/${anketaAnswerID}/`,
      headers: {
        Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
      },
      data: {
        call_type,
        call_start,
        skype
      },
    });
    debug && consoleLog('success', 'Assign interview', data);
    return data;
  } catch (error) {
    debug && consoleLog('error', 'Assign interview', error);
    throw new Error();    
  }
};

export default function useAssignInterviewMutation({onSuccess, onError}) {
  return useMutation(assignInterview, {onSuccess, onError});
}
