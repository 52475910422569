import './AdminReports.scss';

import { Link, useParams } from 'react-router-dom';
import { useInfiniteQuery, useQuery } from 'react-query';

import AdminClientReportsTable from './AdminClientReportsTable';
import AnimatedRedirect from '../../components/AnimatedRedirect';
import Button from '../../components/Button';
import PageLoader from '../../components/PageLoader';
import React from 'react';
import Title from '../../components/Title';
import _ from 'lodash';
import api_reports from '../../hooks/api_reports';
import { motion } from 'framer-motion';
import { standardPageVariants } from '../../animationsVariants/StandardPageVariants';

export const AdminReports: React.FC<{}> = () => {
  const { clientID } = useParams<{ clientID: string }>();

  const {
    data: reportsData,
    status: reportsStatus,
    isFetching,
    fetchMore,
    canFetchMore,
  } = useInfiniteQuery(['addos', clientID], api_reports, {
    getFetchMore: lastPage => (lastPage.current_page < lastPage.total_pages ? lastPage.current_page + 1 : false),
    refetchInterval: 3000,
  });

  const mergeAddosPages = data => {
    let mergedAddos: any[] = [];

    _.forEach(data, page => {
      mergedAddos = [...mergedAddos, ...page.addos];
    });

    return mergedAddos;
  };

  if (reportsStatus === 'loading') {
    return <PageLoader />;
  }

  if (reportsStatus === 'error') {
    return <AnimatedRedirect redirectTo='/500/' />;
  }

  return (
    <motion.main className='adminClientAnketas' variants={standardPageVariants} initial='initial' animate='animate' exit='exit'>
      <div className='pageTabs'>
        <Title>Отчёты</Title>
        <Link to={`/admin/${clientID}/`} className='subTitleLink'>
          Клиент
        </Link>
      </div>

      {reportsStatus === 'success' && (
        <>
          <AdminClientReportsTable addoData={mergeAddosPages(reportsData)} />

          <Button
            type='button'
            buttonType='button'
            onClick={() => fetchMore()}
            width='300rem'
            size='large'
            color='blue'
            text='Загрузить ещё'
            loading={isFetching}
            disabled={!canFetchMore || isFetching}
            className='col-span-2 ml-[30rem] mr-auto mt-[20rem]'
          />
        </>
      )}
    </motion.main>
  );
};
