import { useQuery } from 'react-query';
import axios from 'axios';
import { backendURL } from '../App';

const getTimeTableData = async (_, start, end) => {
  const { data } = await axios({
    method: 'get', 
    url: `${backendURL}timetables/`,
    headers: {
      Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`
    },
    params: {
      start: start,
      end: end
    }      
  });
  return data;
};

export default function useTimeTableData(start, end) {
  return useQuery(['timeTableData', start, end], getTimeTableData, { retry: false });
}
