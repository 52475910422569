import './NotFound404.scss';


import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { formVariants } from '../../animationsVariants/FormVariants';
import { Title } from '../../components/Title/Title';

export const NotFound404: React.FC<{}> = () => (
  <main className='notFound404'>
    <motion.div className='formContainer' variants={formVariants} initial='initial' animate='animate' exit='exit'>
      <Title>Страница 404</Title>
      <div className='text'>
        Страница не существует или была перемещена.
        <br />
        Перейдите на <Link to='/'>главную страницу.</Link>
      </div>
    </motion.div>
  </main>
);
