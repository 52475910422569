import './AnketaAnswerForm.scss';

import * as Yup from 'yup';

import { Form, Formik } from 'formik';
import { backendURL, debug } from '../../../App';

import Button from '../../../components/Button';
import InputError from '../../../components/InputError';
import TextareaWYSIWYG from '../../../components/TextareaWYSIWYG';
import _ from 'lodash';
import axios from 'axios';
import consoleLog from '../../../helpers/consoleLog';
import icon_error from '../../../assets/images/icon_error.svg';
import { queryCache } from 'react-query';
import { useHistory } from 'react-router-dom';
import useInterval from '../../../helpers/useInterval';
import useSaveAnswerMutation from '../../../hooks/useSaveAnswerMutration';
import { useState } from 'react';

interface Props {
  anketaData: {
    anketa: {
      interview_needless: boolean;
    };
    anketa_answer_id: number;
    answer_text?: string;
    answer_id?: number;
    question: {
      id: number;
    };
    questions_count: number;
    question_number: number;
  };
  setIsNoInterviewModal: () => void;
}

// ? Remove NoInterviewModal because deprecated
export const AnketaAnswerForm: React.FC<Props> = ({ anketaData, setIsNoInterviewModal }) => {
  const history = useHistory();
  const [valueForAutoSave, setValueForAutoSave] = useState<string>('');

  const [autoSaveIndicator, setSaveAutoSaveIndicator] = useState<boolean>(false);
  const [answerSavingError, setAnswerSavingError] = useState<boolean>(false);

  // const [mutate] = useSaveAnswerMutation({
  //   onSuccess: () => {
  //     setSaveAutoSaveIndicator(true);
  //     setTimeout(() => setSaveAutoSaveIndicator(false), 2000);
  //     _.isNil(anketaData.answer_id) && queryCache.invalidateQueries('clientAnketaData');
  //   },
  //   onError: () => {
  //     setAnswerSavingError(true);
  //   },
  // });

  // useInterval(() => {
  //   !answerSavingError &&
  //     mutate({
  //       text: valueForAutoSave,
  //       questionsID: anketaData.question.id,
  //       anketaAnswersID: anketaData.anketa_answer_id,
  //       answerID: anketaData.answer_id,
  //     });
  // }, 30000);

  return (
    <div className='anketaAnswerForm'>
      {answerSavingError && (
        <div className='answerSavingError'>
          <div className='wrapper'>
            {icon_error && <img src={icon_error} alt='errorIcon' />}
            <span>
              На сервере технические проблемы, на данный момент времени, мы не можем сохранить ваш ответ. Пожалуйста, скопируйте его себе на компьютер. <br />
              Приносим извинения за неудобства. В ближайшее время мы восстановим работу системы.
            </span>
          </div>
        </div>
      )}
      <Formik
        enableReinitialize={true}
        initialValues={{
          text: anketaData.answer_text || '',
        }}
        validationSchema={Yup.object({
          text: Yup.string()
            .required('поле должно быть заполнено')
            // @ts-ignore
            .test('lengthWithooutHTML', 'Должно быть не менее 30 символов', text => text?.replace(/(<([^>]+)>)/gi, '')?.replace(/\s+/g, '')?.length >= 30),
        })}
        onSubmit={async (values, { setSubmitting, setStatus, resetForm }) => {
          try {
            const { data } = await axios({
              method: 'post',
              url: anketaData.answer_id ? `${backendURL}answer/${anketaData.answer_id}/` : `${backendURL}answers/`,
              headers: {
                Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
              },
              data: {
                text: values.text,
                anketa_answers_id: anketaData.anketa_answer_id,
                question_id: anketaData.question.id,
                completed: true,
              },
            });
            setValueForAutoSave('');
            setSubmitting(false);
            setStatus({ success: true });
            if (!(anketaData.questions_count === anketaData.question_number && anketaData.anketa.interview_needless)) {
              resetForm();
              queryCache.invalidateQueries('clientAnketaData');
            } else {
              // setIsNoInterviewModal();
              history.push('/client/anketa/complete/');
            }
            debug && consoleLog('success', 'Answer submit', data);
          } catch (error) {
            setSubmitting(false);
            setAnswerSavingError(true);
            debug && consoleLog('error', 'Answer submit', error);
          }
        }}
      >
        {props => (
          <Form>
            <TextareaWYSIWYG
              value={props.values.text}
              onChange={value => {
                props.setFieldValue('text', value.replace(/(<([^>]+)>)/gi, '') ? value : '');
                setValueForAutoSave(value);
              }}
              placeholderType='alwaysVisible'
              placeholder='Ваш ответ'
              isValid={!(props.errors.text && props.touched.text)}
              validationError={props.errors.text && props.touched.text ? props.errors.text : undefined}
              modulesType='default'
            />
            <InputError className='success'>{autoSaveIndicator && !(props.errors.text && props.touched.text) && 'Автосохранение...'}</InputError>
            <Button
              type='button'
              width='330rem'
              size='medium'
              color='blue'
              className='submitButton'
              text='Cледующий вопрос'
              loading={props.isSubmitting}
              disabled={answerSavingError}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};
