import Input from '../../Input';
import InputSelect from '../../InputSelect';
import './styles.scss';
import { backendURL, debug } from '../../../App';

import { format, utcToZonedTime } from 'date-fns-tz';

import Button from '../../Button';
import Modal from '../../Modal';
import Title from '../../Title';
import { queryCache, useQuery } from 'react-query';

import axios from 'axios';
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import _ from 'lodash';
import PageLoader from '../../PageLoader';
import AnimatedRedirect from '../../AnimatedRedirect';
import useClientsData from '../../../hooks/useClientsData';
import { Ref, useEffect, useRef, useState } from 'react';
// import { api_getClientAnketaAnswersData } from '../../../hooks/api_getClientAnketaAnswersData';
import ButtonLoader from '../../ButtonLoader';
import Radiobutton from '../../Radiobutton';
import consoleLog from '../../../helpers/consoleLog';
import InputError from '../../InputError';

interface Props {
  timeSlotID: number | undefined;
  timeSlot: Date;
  expertID: number;
  expertData: {
    clients: [
      {
        title: string;
        id: number;
      }
    ];
    clients_count: number;
    free_interview_slots: number;
    full_name: string;
    future_interview_count: number;
    interview_pass: number;
    time_slots: [
      {
        status: string;
        time: Date;
      }
    ];
  };
  onClose: () => void;
  onAssigneInterviewSuccess: React.Dispatch<
    React.SetStateAction<{
      expertName: string;
      timeSlot: Date;
      interviewType: string;
      zoom?: string | undefined;
      skype?: string | undefined;
    } | null>
  >;
}

export const AssignNewInterviewModal: React.FC<Props> = ({ timeSlotID, timeSlot, expertID, expertData, onClose, onAssigneInterviewSuccess }) => {
  const streamsSelectRef = useRef();
  const anketasSelectRef = useRef();

  const { data: clientsData, status: clientsDataLoadingStatus } = useClientsData();

  const [clientID, setClientID] = useState<number | undefined>(undefined);
  const [streamID, setStreamID] = useState<number | undefined>(undefined);
  const [anketaAnswers, setAnketaAnswers] = useState<any>();
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    async function api_getClientAnketaAnswersData() {
      setIsFetching(true);
      const { data } = await axios({
        method: 'get',
        url: `${backendURL}anketa-answers/client/${clientID}/`,
        headers: {
          Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
        },
        params: {
          order_by: 'call_start',
          stream_id: _.isNil(streamID) ? '' : streamID,
        },
      });
      setAnketaAnswers(data);
      setIsFetching(false);
    }

    if (clientID !== undefined && streamID !== undefined) {
      api_getClientAnketaAnswersData();
    }
  }, [clientID, streamID]);

  const clientsOptions = () => _.map(expertData.clients, client => ({ value: client.id, label: client.title }));

  const streamOptions = (clientID: number) => {
    let streams = _.find(clientsData, client => client.id === clientID)?.streams;
    let streamsOptions = _.map(streams, stream => ({ value: stream.id, label: stream.title }));
    return streamsOptions;
  };

  const anketaAnswersOptions = () => {
    let anketaAnswersOptions = _.chain(anketaAnswers)
      .filter(anketa => anketa.process_status === 2 || anketa.process_status === 3)
      .map(anketa => ({ value: anketa.id, label: `${anketa.user.first_name} ${anketa.user.last_name}` }))
      .value();
    return anketaAnswersOptions;
  };

  if (clientsDataLoadingStatus === 'loading') {
    return <PageLoader />;
  }

  if (clientsDataLoadingStatus === 'error') {
    return <AnimatedRedirect redirectTo='/500/' />;
  }

  return (
    <Modal className='assignNewInterviewModal' onClose={onClose}>
      <Title>Назначить интервью на {format(utcToZonedTime(new Date(timeSlot), 'Europe/Moscow'), 'yyyy-MM-dd в HH:mm')}</Title>
      <Formik
        initialValues={{
          client_id: expertData.clients.length === 1 ? clientsOptions()[0].value : '',
          stream_id: '',
          anketa_id: '',
          call_type: 'dion',
          skype: '',
        }}
        validationSchema={Yup.object({
          client_id: Yup.string().required('нужно выбрать клиента'),
          stream_id: Yup.string().required('нужно выбрать поток'),
          anketa_id: Yup.string().required('нужно выбрать участника'),
        })}
        onSubmit={async (values, { setSubmitting, setStatus }) => {
          try {
            if (timeSlotID === undefined) {
              const { data: timeSlotData } = await axios({
                method: 'post',
                url: `${backendURL}timetables/`,
                headers: {
                  Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
                },
                data: {
                  expert_id: expertID,
                  start: format(utcToZonedTime(new Date(timeSlot), 'Europe/Moscow'), 'yyyy-MM-dd HH:mm'),
                },
              });
              debug && consoleLog('success', 'TimeSlot created', timeSlotData);

              const { data } = await axios({
                method: 'post',
                url: `${backendURL}admin/interview/`,
                headers: {
                  Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
                },
                data: {
                  availabletime_id: timeSlotData[0].id,
                  expert_id: expertID,
                  anketaanswer_id: values.anketa_id,
                  interview_type: values.call_type,
                  skype_name: values.call_type === 'skype' ? values.skype : undefined,
                },
              });
              setSubmitting(false);
              setStatus({ success: true });
              queryCache.invalidateQueries('adminExpertsScheduleData');
              queryCache.invalidateQueries('anketasAnswewrsForSelect');
              onClose();
              onAssigneInterviewSuccess({
                expertName: expertData.full_name,
                timeSlot,
                interviewType: values.call_type,
                zoom: values.call_type === 'zoom' ? undefined : data.zoom,
                skype: values.call_type === 'skype' ? values.skype : undefined,
              });
              debug && consoleLog('success', 'Interview assigned', data);
            } else {
              const { data } = await axios({
                method: 'post',
                url: `${backendURL}admin/interview/`,
                headers: {
                  Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
                },
                data: {
                  availabletime_id: timeSlotID,
                  expert_id: expertID,
                  anketaanswer_id: values.anketa_id,
                  interview_type: values.call_type,
                  skype_name: values.call_type === 'skype' ? values.skype : undefined,
                },
              });
              setSubmitting(false);
              setStatus({ success: true });
              queryCache.invalidateQueries('adminExpertsScheduleData');
              queryCache.invalidateQueries('anketasAnswewrsForSelect');
              onClose();
              onAssigneInterviewSuccess({
                expertName: expertData.full_name,
                timeSlot,
                interviewType: values.call_type,
                zoom: values.call_type === 'zoom' ? undefined : data.zoom,
                skype: values.call_type === 'skype' ? values.skype : undefined,
              });
              debug && consoleLog('success', 'Interview assigned', data);
            }
          } catch (error) {
            setSubmitting(false);
            debug && consoleLog('error', 'Interview assigned', error);
          }
        }}
      >
        {props => (
          <Form>
            <InputSelect
              name='client_id'
              options={clientsOptions()}
              defaultValue={expertData.clients.length === 1 ? clientsOptions()[0] : null}
              value={props.values.client_id}
              placeholderType='alwaysVisible'
              placeholder='Клиент'
              onChange={async option => {
                props.setFieldValue('client_id', option.value);
                props.setFieldValue('anketa_id', '');
                props.setFieldValue('stream_id', '');
                props.setFieldError('anketa_id', '');
                props.setFieldError('stream_id', '');
                props.setFieldTouched('anketa_id', false);
                props.setFieldTouched('stream_id', false);
                // @ts-ignore
                streamsSelectRef?.current?.select?.setValue('');
                // @ts-ignore
                anketasSelectRef?.current?.select?.setValue('');
                setClientID(option.value);
                setStreamID(undefined);
              }}
              onBlur={() => props.setFieldTouched('client_id', true)}
              isValid={!(props.errors.client_id && props.touched.client_id)}
              validationError={props.errors.client_id && props.touched.client_id ? props.errors.client_id : undefined}
            />
            {console.log(streamID, clientID)}
            {props.values.client_id === '' || props.values.client_id === undefined ? null : (
              <>
                <InputSelect
                  refProp={streamsSelectRef}
                  name='stream_id'
                  options={streamOptions(+props.values.client_id)}
                  // defaultValue={streamOptions(props.values.client_id).length === 1 ? streamOptions(props.values.client_id)[0].value : null}
                  value={props.values.stream_id}
                  placeholderType='alwaysVisible'
                  placeholder='Поток'
                  onChange={option => {
                    props.setFieldValue('stream_id', option.value);
                    props.setFieldValue('anketa_id', '');
                    props.setFieldError('anketa_id', '');
                    props.setFieldTouched('anketa_id', false);
                    // @ts-ignore
                    anketasSelectRef?.current?.select?.setValue('');
                    setStreamID(option.value);
                  }}
                  onBlur={() => props.setFieldTouched('stream_id', true)}
                  isValid={!(props.errors.stream_id && props.touched.stream_id)}
                  validationError={props.errors.stream_id && props.touched.stream_id ? props.errors.stream_id : undefined}
                />
              </>
            )}

            {props.values.stream_id === '' || props.values.stream_id === undefined ? null : (
              <>
                {isFetching === true ? (
                  <div className='anketasSelectLoader'>
                    <div className='anketasSelectLoaderText'>Анкеты загружаются</div>
                    <ButtonLoader color='blue' />
                  </div>
                ) : (
                  <InputSelect
                    refProp={anketasSelectRef}
                    name='stream_id'
                    options={anketaAnswersOptions()}
                    value={props.values.anketa_id}
                    placeholderType='alwaysVisible'
                    placeholder='Выберите участника'
                    onChange={option => props.setFieldValue('anketa_id', option.value)}
                    onBlur={() => props.setFieldTouched('anketa_id', true)}
                    isValid={!(props.errors.anketa_id && props.touched.anketa_id)}
                    validationError={props.errors.anketa_id && props.touched.anketa_id ? props.errors.anketa_id : undefined}
                  />
                )}
              </>
            )}

            {props.values.anketa_id === '' || props.values.anketa_id === undefined ? null : (
              <>
                {!_.find(anketaAnswers, { id: props.values.anketa_id }).call_start ? null : (
                  <InputError className='success existingInterviewAlert'>
                    Внимание! У данного участника уже есть интервью с экспертом {_.find(anketaAnswers, { id: props.values.anketa_id }).expert.first_name}{' '}
                    {_.find(anketaAnswers, { id: props.values.anketa_id }).expert.last_name}{' '}
                    {format(utcToZonedTime(new Date(_.find(anketaAnswers, { id: props.values.anketa_id }).call_start), 'Europe/Moscow'), 'yyyy-MM-dd в HH:mm')}. При
                    создании нового интервью, это будет удалено.
                  </InputError>
                )}

                <div className='radiobuttonsWrapper'>
                  <div className='radiobuttonPlaceholder'>Выберите тип интервью</div>
                  <Radiobutton name='call_type' checked={props.values.call_type === 'dion'} value='dion' onClick={() => props.setFieldValue('call_type', 'dion')}>
                    <span className='key'>Dion</span>
                  </Radiobutton>
                  <Radiobutton name='call_type' checked={props.values.call_type === 'zoom'} value='zoom' onClick={() => props.setFieldValue('call_type', 'zoom')}>
                    <span className='key'>Zoom</span>
                  </Radiobutton>
                  <Radiobutton name='call_type' checked={props.values.call_type === 'skype'} value='skype' onClick={() => props.setFieldValue('call_type', 'skype')}>
                    <span className='key'>Skype</span>
                  </Radiobutton>
                </div>

                {props.values.call_type === 'skype' ? (
                  <Input
                    name={`skype`}
                    type='text'
                    size='medium'
                    value={props.values.skype}
                    onChange={event => props.setFieldValue('skype', event.target.value)}
                    onBlur={() => props.setFieldTouched('skype', true)}
                    placeholderType='alwaysVisible'
                    placeholder='Skype'
                    isDisabled={props.isSubmitting}
                    isValid={!(props.errors.skype && props.touched.skype)}
                    validationError={props.errors.skype && props.touched.skype ? props.errors.skype : undefined}
                    autoComplete='off'
                  />
                ) : null}
              </>
            )}

            <Button
              type='button'
              buttonType='submit'
              width='330rem'
              size='medium'
              color='blue'
              text='Создать интервью'
              loading={props.isSubmitting}
              disabled={props.isSubmitting}
              className='submitButton'
            />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
