import './AdminAnketaEvaluation.scss';

import AdminAnketaHeader from '../../components/AnketaSharedComponents/AdminAnketaHeader';
import AnimatedRedirect from '../../components/AnimatedRedirect';
import { AnketaExpertComment } from '../../components/AnketaExpertComment/AnketaExpertComment_Admin';
import AnketaQuestion from '../../components/AnketaSharedComponents/AnketaQuestion';
import AnketaQuestionRateHelp from './AnketaQuestionRateHelp';
import { AnketaQuestionsList } from '../../components/AnketaQuestionsList/AnketaQuestionList_Admin';
import ContainerWYSIWYG from '../../components/ContainerWYSIWYG';
import ExpertAnketaAnswerSelect from '../../pages/P_4.5_Expert_AnketaQuestionsEvaluation/ExpertAnketaAnswerSelect';
import ExpertAnketaAnswerText from '../../pages/P_4.5_Expert_AnketaQuestionsEvaluation/ExpertAnketaAnswerText';
import PageLoader from '../../components/PageLoader';
import Title from '../../components/Title';
import { motion } from 'framer-motion';
import { standardPageVariants } from '../../animationsVariants/StandardPageVariants';
import useAnketaAnswersData from '../../hooks/useAnketaAnswersData';
import useAnketaQestionsAnswersData from '../../hooks/useAnketaQestionsAnswersData';
import useAnketaQuetionsData from '../../hooks/useAnketaQuetionsData';
import { useParams } from 'react-router-dom';

export const AdminAnketaEvaluation: React.FC<{}> = () => {
  const { clientID, anketaID, anketaAnswersID } = useParams<{ clientID: string; anketaID: string; anketaAnswersID: string }>();
  const { data: anketaQuestionsData, status: anketaQuestionsDataLoadingStatus } = useAnketaQuetionsData(anketaID);
  const { data: anketaQestionsAnswersData, status: anketaQestionsAnswersDataLoadingStatus } = useAnketaQestionsAnswersData(anketaAnswersID);
  const { data: anketaAnswersData, status: anketaAnswersDataLoadingStatus } = useAnketaAnswersData(anketaAnswersID);

  if (anketaQuestionsDataLoadingStatus === 'loading' || anketaQestionsAnswersDataLoadingStatus === 'loading' || anketaAnswersDataLoadingStatus === 'loading') {
    return <PageLoader />;
  }

  if (anketaQuestionsDataLoadingStatus === 'error' || anketaQestionsAnswersDataLoadingStatus === 'error' || anketaAnswersDataLoadingStatus === 'error') {
    return <AnimatedRedirect redirectTo='/500/' />;
  }

  return (
    <motion.main className='adminAnketaEvaluation' variants={standardPageVariants} initial='initial' animate='animate' exit='exit'>
      <AdminAnketaHeader userData={anketaAnswersData.user} anketaData={anketaAnswersData.anketa} clientData={anketaAnswersData.client} />
      {anketaQuestionsData.map((question, index) => (
        <div className='anketaQuestionAnswerWrapper' key={index} id={`q${index}`}>
          <Title>Вопрос {index + 1}</Title>
          {anketaQuestionsData[index].subquestions.length === 0 ? (
            <>
              <AnketaQuestion questionData={question} toggleModal={undefined} />
              <AnketaQuestionRateHelp helpText={anketaQuestionsData[index].help_text} />
              <ExpertAnketaAnswerText answer={anketaQestionsAnswersData[index].text} />
            </>
          ) : (
            anketaQuestionsData[index].subquestions.map((subquiestion, subquestionIdx) => (
              <>
                <AnketaQuestion
                  questionData={{
                    text: subquestionIdx === 0 ? question.text : '',
                    question_text: subquiestion.text,
                  }}
                  toggleModal={undefined}
                />
                <ExpertAnketaAnswerSelect
                  answerVariants={subquiestion.variants}
                  selectedAnswerVariants={anketaQestionsAnswersData[index].answer_subs[subquestionIdx].variants}
                />
                {anketaQuestionsData[index].subquestions.length === subquestionIdx + 1 && anketaQuestionsData[index].help_text && (
                  <AnketaQuestionRateHelp helpText={anketaQuestionsData[index].help_text} />
                )}
              </>
            ))
          )}
          <div className='anketaQuestionRateAdmin'>
            <div className='subTitle'>Баллы за ответ</div>
            <div className='rate'>
              <div className={`rateValue${anketaQestionsAnswersData[index].rate === 0 ? ' active' : ''}`}>0</div>
              <div className={`rateValue${anketaQestionsAnswersData[index].rate === 1 ? ' active' : ''}`}>1</div>
              <div className={`rateValue${anketaQestionsAnswersData[index].rate === 2 ? ' active' : ''}`}>2</div>
              <div className={`rateValue${anketaQestionsAnswersData[index].rate === 3 ? ' active' : ''}`}>3</div>
            </div>
            {anketaQestionsAnswersData[index].expert_comment && (
              <>
                <div className='subTitle'>Комментарий эксперта</div>
                <ContainerWYSIWYG data={anketaQestionsAnswersData[index].expert_comment} size='default' />
              </>
            )}
          </div>
        </div>
      ))}
      <AnketaExpertComment anketaAnswersData={anketaAnswersData} clientID={Number(clientID)} />
      <AnketaQuestionsList questionList={anketaQuestionsData} />
    </motion.main>
  );
};
