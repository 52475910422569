import './ExpertAnketaQuestionsEvaluation.scss';

import AnimatedRedirect from '../../components/AnimatedRedirect';
import AnketaDescriptionModal from '../../components/AnketaSharedComponents/AnketaDescriptionModal';
import { AnketaProgression } from '../../components/AnketaProgression/AnketaProgressionExpert';
import AnketaQuestion from '../../components/AnketaSharedComponents/AnketaQuestion';
import { AnketaQuestionRateHelpModal } from '../../components/AnketaQuestionRateHelpModal/AnketaQuestionRateHelpModal';
import ExpertAnketaAnswerSelect from './ExpertAnketaAnswerSelect';
import ExpertAnketaAnswerText from './ExpertAnketaAnswerText';
import ExpertAnketaHeader from '../../components/AnketaSharedComponents/ExpertAnketaHeader';
import ExpertAnketaQuestionAutoRate from './ExpertAnketaQuestionAutoRate';
import ExpertAnketaQuestionRate from './ExpertAnketaQuestionRate';
import ExpertAnketaSubquestionsProgression from '../../components/AnketaSharedComponents/ExpertAnketaSubquestionsProgression';
import PageLoader from '../../components/PageLoader';
import consoleLog from '../../helpers/consoleLog';
import { motion } from 'framer-motion';
import { standardPageVariants } from '../../animationsVariants/StandardPageVariants';
import useAnketaAnswersData from '../../hooks/useAnketaAnswersData';
import useAnketaData from '../../hooks/useAnketaData';
import useAnketaQestionsAnswersData from '../../hooks/useAnketaQestionsAnswersData';
import useAnketaQuetionsData from '../../hooks/useAnketaQuetionsData';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useState } from 'react';

export const ExpertAnketaQuestionsEvaluation: React.FC<{}> = () => {
  const { anketaID, anketaAnswersID, questionIndex, subquestionIndex } = useParams<{
    anketaID: string;
    anketaAnswersID: string;
    questionIndex: string;
    subquestionIndex: string;
  }>();
  const questionIdx = Number(questionIndex);
  const questionIdxMinus = Number(questionIndex) - 1;
  const subquestionIdx = Number(subquestionIndex);
  const subquestionIdxMinus = Number(subquestionIndex) - 1;
  const { data: anketaData, status: anketaDataLoadingStatus } = useAnketaData(anketaID);
  const { data: anketaQuestionsData, status: anketaQuestionsDataLoadingStatus } = useAnketaQuetionsData(anketaID);
  const { data: anketaQuestionsAnswersData, status: anketaQuestionsAnswersDataLoadingStatus } = useAnketaQestionsAnswersData(anketaAnswersID);
  const { data: anketaAnswersData, status: anketaAnswersDataLoadingStatus } = useAnketaAnswersData(anketaAnswersID);

  const [isAnketaDescriptionModal, setIsAketaDescriptionModal] = useState<boolean>(false);
  const [isAnketaQuestionRateHelpModal, setIsAnketaQuestionRateHelpModal] = useState<boolean>(false);

  if (
    anketaDataLoadingStatus === 'loading' ||
    anketaQuestionsDataLoadingStatus === 'loading' ||
    anketaQuestionsAnswersDataLoadingStatus === 'loading' ||
    anketaAnswersDataLoadingStatus === 'loading'
  ) {
    return <PageLoader />;
  }

  if (
    anketaDataLoadingStatus === 'error' ||
    anketaQuestionsDataLoadingStatus === 'error' ||
    anketaQuestionsAnswersDataLoadingStatus === 'error' ||
    anketaAnswersDataLoadingStatus === 'error'
  ) {
    return <AnimatedRedirect redirectTo='/500/' />;
  }

  if (anketaAnswersData.process_status === 4 || anketaAnswersData.process_status === 5) {
    return <AnimatedRedirect redirectTo='/expert/respondents/' />;
  }

  return (
    <motion.main className='expertAnketaQuestionsEvaluation' variants={standardPageVariants} initial='initial' animate='animate' exit='exit'>
      <ExpertAnketaHeader userData={anketaAnswersData.user} anketaData={anketaData} clientData={anketaAnswersData.client} />
      <AnketaProgression questionsCount={anketaQuestionsData.length} currentQuestionNumber={questionIdxMinus} anketaQuestionsAnswers={anketaQuestionsAnswersData} />
      {/* // * Есть / нет подвопросы */}
      {anketaQuestionsData[questionIdxMinus].subquestions.length > 0 ? (
        <>
          <ExpertAnketaSubquestionsProgression
            subquestionsCount={anketaQuestionsData[questionIdxMinus].subquestions.length}
            currentSubquestionNumber={subquestionIdx || 1}
          />
          <AnketaQuestion
            questionData={{
              text: anketaQuestionsData[questionIdxMinus].text,
              question_text: anketaQuestionsData[questionIdxMinus].subquestions[subquestionIdxMinus || 0].text,
            }}
            toggleModal={anketaData.description ? () => setIsAketaDescriptionModal(true) : undefined}
          />
          {/* // ? Deprecated после того как закрытые вопросы стали true/false, без баллов */}
          <ExpertAnketaQuestionAutoRate
            rate={anketaQuestionsAnswersData[questionIdxMinus].rate}
            questionsCount={anketaQuestionsData.length}
            currentQuestionNumber={questionIdx}
            subquestionsCount={anketaQuestionsData[questionIdxMinus].subquestions.length}
            currentSubquestionNumber={subquestionIdx || 1}
          />
          <ExpertAnketaAnswerSelect
            answerVariants={anketaQuestionsData[questionIdxMinus].subquestions[subquestionIdxMinus || 0].variants}
            selectedAnswerVariants={anketaQuestionsAnswersData[questionIdxMinus].answer_subs[subquestionIdxMinus || 0].variants}
          />
        </>
      ) : (
        <>
          <AnketaQuestion
            questionData={anketaQuestionsData[questionIdxMinus]}
            toggleModal={anketaData.description ? () => setIsAketaDescriptionModal(true) : undefined}
          />
          <ExpertAnketaQuestionRate
            anketaQestionsAnswerData={anketaQuestionsAnswersData[questionIdxMinus]}
            questionData={anketaQuestionsData[questionIdxMinus]}
            questionsCount={anketaQuestionsData.length}
            currentQuestionNumber={questionIdx}
            setIsAnketaQuestionRateHelpModal={() => setIsAnketaQuestionRateHelpModal(true)}
            anketaAnswersData={anketaAnswersData}
          />
          <ExpertAnketaAnswerText answer={anketaQuestionsAnswersData[questionIdxMinus].text} />
        </>
      )}
      {isAnketaDescriptionModal && <AnketaDescriptionModal description={anketaData.description} onClose={() => setIsAketaDescriptionModal(false)} />}
      {isAnketaQuestionRateHelpModal && (
        <AnketaQuestionRateHelpModal
          currentQuestionNumber={questionIdx}
          helpText={anketaQuestionsData[questionIdxMinus].help_text}
          onClose={() => setIsAnketaQuestionRateHelpModal(false)}
        />
      )}
    </motion.main>
  );
};
