import './AdminMembersTable.scss';
import icon_sortDown from '../../../assets/images/icon_sortDown.svg';
import icon_search from '../../../assets/images/icon_search.svg';
import icon_resetFilter from '../../../assets/images/icon_resetFilter.svg';

import { useState, useMemo } from 'react';
import useDynamicRefs from 'use-dynamic-refs';
import { useParams } from 'react-router-dom';
import { useTable, useSortBy, useFilters } from 'react-table';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz/esm';

import consoleLog from '../../../helpers/consoleLog';

import ChangeSkypeModal from './ChangeSkypeModal';
import BlockMemberModal from './BlockMemberModal';
import RemoveMemberAbsenceModal from './RemoveMemberAbsenceModal';
import ChangeExpertModal from '../../../components/ScheduleTable/ChangeExpertModal';

interface Props {
  filteredData?: any;
}

export const AdminMembersTable: React.FC<Props> = ({ filteredData }) => {
  const [getRef, setRef] = useDynamicRefs();
  const { clientID } = useParams<{ clientID: string }>();

  // * Фильтры для таблицы
  const DefaultColumnFilter = () => {
    return <img alt='icon' className='searchIcon tribeSearch' src={icon_search} />;
  };
  const NameColumnFilter = ({ column: { filterValue, setFilter } }) => {
    return (
      <>
        <img
          alt='icon'
          className='searchIcon nameSearch'
          src={icon_search}
          // @ts-ignore
          onClick={() => getRef('nameSearch').current.classList.add('visible')}
        />
        <div
          className='searchPopup nameSearch'
          // @ts-ignore
          ref={setRef('nameSearch')}
        >
          <button
            type='button'
            className='close'
            // @ts-ignore
            onClick={() => getRef('nameSearch').current.classList.remove('visible')}
          />
          <input
            type='text'
            value={filterValue || ''}
            onChange={e => {
              setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
            placeholder='Начните вводить имя/фамилию участника'
          />
        </div>
        {filterValue && (
          <img
            alt='icon'
            className='resetFilterIcon'
            src={icon_resetFilter}
            onClick={() => {
              setFilter(undefined);
              // @ts-ignore
              getRef('nameSearch').current.classList.remove('visible');
            }}
          />
        )}
      </>
    );
  };
  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );
  // * Стркутруа колонок для таблицы
  const columns = useMemo(
    () => [
      {
        Header: 'Время',
        accessor: 'time',
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: 'Дата',
        accessor: 'date',
        disableFilters: true,
      },
      {
        Header: 'Статус интервью',
        accessor: 'status',
        disableFilters: true,
      },
      {
        Header: 'Имя участника',
        accessor: 'full_name',
        Filter: NameColumnFilter,
      },
      {
        Header: 'Трайб',
        accessor: 'tribe',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Роль',
        accessor: 'role',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Сегмент',
        accessor: 'case',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Способ связи',
        accessor: 'call_type',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Кнопки',
        accessor: 'actions',
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    []
  );
  // * Данные для таблицы
  const data = useMemo(() => filteredData, [filteredData]);
  // * Инциализация таблица
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      defaultColumn,
      disableSortRemove: true,
      autoResetSortBy: false,
      initialState: {
        sortBy: [
          {
            id: 'date',
            desc: true,
          },
        ],
      },
    },
    useFilters,
    useSortBy
  );

  // * Кнопка действий с экспертом
  const closeAllOpened = current => {
    filteredData.forEach(element => {
      if (current !== element.actions) {
        // @ts-ignore
        getRef(`${element.actions}toggleButton`).current.classList.remove('active');
        // @ts-ignore
        getRef(`${element.actions}buttons`).current.classList.remove('visible');
      }
    });
  };

  // * Изменить skype участника
  const [isChangeSkypeModal, setIsChangeSkypeModal] = useState<{ userID: number; skype: string } | undefined>(undefined);

  // * Заблокировать/разблокировать участника
  const [isBlockMemberModal, setIsBlockMemberModal] = useState<{ userID: number; block: boolean } | undefined>(undefined);

  // * Заблокировать/разблокировать участника
  const [isRemoveMemberAbsenceModal, setIsRemoveMemberAbsenceModal] = useState<{ userID: number } | undefined>(undefined);

  // * Заблокировать/разблокировать участника
  const [isChangeExpertModal, setIsChangeExpertModal] = useState<{ timeSlotID: number } | undefined>(undefined);

  return (
    <section className='adminMembersTable'>
      {consoleLog('info', 'filteredData', filteredData)}
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={`headerGroup-tr-${index}`}>
              {headerGroup.headers.map((column, index) => (
                <th {...column.getHeaderProps} key={`headerGroup-tr-th-${index}`}>
                  <div {...column.getSortByToggleProps()}>
                    {column.render('Header')}
                    {!column.disableSortBy &&
                      (column.isSortedDesc ? (
                        <img alt='icon' className='sortDownIcon' src={icon_sortDown} />
                      ) : (
                        <img alt='icon' className='sortUpIcon' src={icon_sortDown} />
                      ))}
                  </div>
                  {column.canFilter ? column.render('Filter') : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={`body-tr-${i}`} className={row.original.status === 'Неявка' ? 'missedCall' : ''}>
                {row.original.block_user && <div className='memberRowBlocker'>Участник заблокирован</div>}
                {row.cells.map((cell, i: number) => {
                  return (
                    <td {...cell.getCellProps()} key={`body-tr-td-${i}`}>
                      {/* // * Время */}
                      {cell.column.Header === 'Время' && (cell.value ? format(utcToZonedTime(new Date(`${cell.value}`), 'Europe/Moscow'), 'HH:mm') : '')}
                      {/* // * Дата */}
                      {cell.column.Header === 'Дата' && (cell.value ? format(utcToZonedTime(new Date(`${cell.value}`), 'Europe/Moscow'), 'yyyy.MM.dd') : '')}
                      {/* // * Статус интервью */}
                      {cell.column.Header === 'Статус интервью' && (
                        <>
                          {cell.value && cell.value === 'Без интервью' ? <div className='noInterview'>{cell.value}</div> : cell.value}
                          {!cell.value && 'Будет'}
                        </>
                      )}
                      {/* // * Способ связи */}
                      {cell.column.Header === 'Способ связи' && (
                        <>
                          {cell.value === 'zoom' && row.original.call_link && (
                            <div className='zoom'>
                              <span>zoom</span>
                            </div>
                          )}

                          {cell.value === 'skype' && row.original.call_link && (
                            <div className='skype'>
                              <span>{row.original.call_link}</span>
                            </div>
                          )}
                        </>
                      )}
                      {/* // * Кнопки */}
                      {cell.column.Header === 'Кнопки' && (
                        <div className='memberActionsContainer'>
                          <button
                            type='button'
                            className='memberActionsToggleButton'
                            // @ts-ignore
                            ref={setRef(`${cell.value}toggleButton`)}
                            onClick={() => {
                              closeAllOpened(cell.value);
                              // @ts-ignore
                              getRef(`${cell.value}toggleButton`).current.classList.toggle('active');
                              // @ts-ignore
                              getRef(`${cell.value}buttons`).current.classList.toggle('visible');
                            }}
                          />

                          <div
                            className='memberActions'
                            // @ts-ignore
                            ref={setRef(`${cell.value}buttons`)}
                          >
                            <button
                              type='button'
                              className='link'
                              onClick={() => setIsBlockMemberModal({ userID: cell.value, block: row.original.block_user ? false : true })}
                            >
                              {row.original.block_user ? 'Разблокировать' : 'Заблокировать'} участника
                            </button>
                            {row.original.call_type === 'skype' && (
                              <button type='button' className='link' onClick={() => setIsChangeSkypeModal({ userID: cell.value, skype: row.original.call_link })}>
                                Изменить skype
                              </button>
                            )}
                            {row.original.status === 'Назначено' && (
                              <button type='button' className='link' onClick={() => setIsChangeExpertModal({ timeSlotID: row.original.interview_slot_id })}>
                                Перенести интервью
                              </button>
                            )}
                            {/* {row.original.status === 'Неявка' && (
                                    <button type='button' className='link' onClick={() => setIsRemoveMemberAbsenceModal({ userID: cell.value })}>
                                      Убрать неявку
                                    </button>
                                  )} */}
                          </div>
                        </div>
                      )}
                      {/* // * Обычная ячейка */}
                      {cell.column.Header !== 'Время' &&
                        cell.column.Header !== 'Дата' &&
                        cell.column.Header !== 'Статус интервью' &&
                        cell.column.Header !== 'Кнопки' &&
                        cell.column.Header != 'Способ связи' &&
                        cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {isChangeSkypeModal && (
        <ChangeSkypeModal userID={isChangeSkypeModal.userID} clientID={Number(clientID)} skype={isChangeSkypeModal.skype} onClose={() => setIsChangeSkypeModal(undefined)} />
      )}
      {isBlockMemberModal && (
        <BlockMemberModal userID={isBlockMemberModal.userID} clientID={Number(clientID)} block={isBlockMemberModal.block} onClose={() => setIsBlockMemberModal(undefined)} />
      )}
      {isRemoveMemberAbsenceModal && (
        <RemoveMemberAbsenceModal userID={isRemoveMemberAbsenceModal.userID} clientID={Number(clientID)} onClose={() => setIsRemoveMemberAbsenceModal(undefined)} />
      )}
      {isChangeExpertModal && <ChangeExpertModal timeSlotID={isChangeExpertModal.timeSlotID} onClose={() => setIsChangeExpertModal(undefined)} />}
    </section>
  );
};
