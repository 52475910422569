import './styles.scss';
import icon_loading from '../../../assets/images/icon_loadingBlue.svg';

import { useState } from 'react';

import { motion } from 'framer-motion';
import { format, parse } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz/esm';
import { queryCache } from 'react-query';

import Title from '../../Title';
import Modal from '../../Modal';
import { Button } from '../../Button/Button';
import useAvalilableTimeData from '../../../hooks/useAvailableTimeData';
import { ReactSelect } from '../../ReactSelect/ReactSelect';
import useChangeExpertMutation from '../../../hooks/useChangeExpertMutation';
import { InputError } from '../../InputError/InputError';

interface Props {
  timeSlotID: number;
  expertID: number;
  onClose: () => void;
}

export const ChangeTimeModal: React.FC<Props> = ({ timeSlotID, expertID, onClose }) => {
  const { data: availableSlotsData, status: eavailableSlotsDataLoadingStatus } = useAvalilableTimeData(timeSlotID, expertID);
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [selectedTime, setSelectedTime] = useState<string>('');
  const [mutate, { status: changeExpertMutationStatus }] = useChangeExpertMutation({
    onSuccess: () => {
      queryCache.invalidateQueries('adminExpertsScheduleData');
      queryCache.invalidateQueries('avalilableTImeData');
      setSelectedDate('');
      setSelectedTime('');
      setTimeout(() => {
        onClose();
      }, 3000);
    },
  });

  // * Готовим съедобный массив данных для селекта даты
  const datesForSelect = () => {
    if (availableSlotsData) {
      const dates = availableSlotsData.slots.map(slot => ({ value: slot.date, label: slot.date }));
      console.log('dates');
      console.log(dates);
      return dates;
    }
  };

  // * Готовим съедобный массив данных для селекта времени
  const timesForSelect = () => {
    if (availableSlotsData && selectedDate) {
      const dates = availableSlotsData.slots.find(slot => slot.date === selectedDate);
      const times = dates.time_slots.map(timeSlot => ({ value: timeSlot, label: timeSlot }));
      return times;
    }
  };

  if (eavailableSlotsDataLoadingStatus === 'loading') {
    return (
      <Modal className='changeTimeModal' onClose={onClose}>
        <div className='isLoading'>
          <motion.img src={icon_loading} initial={{ rotate: 0 }} animate={{ rotate: 360 }} transition={{ duration: 2, loop: Infinity, ease: 'linear' }} />
        </div>
      </Modal>
    );
  }

  return (
    <Modal className='changeTimeModal' onClose={onClose}>
      <Title>Перенести время интервью</Title>
      {console.log(availableSlotsData)}
      <ReactSelect placeholder='Дата' onChange={setSelectedDate} options={datesForSelect()} />
      <ReactSelect placeholder='Время' onChange={setSelectedTime} isDisabled={!selectedDate} options={selectedDate ? timesForSelect() : undefined} />
      <Button
        type='button'
        size='medium'
        width='330rem'
        color='blue'
        text='Применить'
        disabled={!selectedDate || !selectedTime}
        loading={changeExpertMutationStatus === 'loading' ? true : false}
        onClick={() =>
          mutate({
            timeSlotID,
            expertID,
            dateTime: `${format(utcToZonedTime(parse(selectedDate, 'dd.MM.yyyy', new Date()), 'Europe/Moscow'), 'yyyy-MM-dd')} ${selectedTime}`,
          })
        }
      />

      <InputError className='success'>
        {changeExpertMutationStatus === 'success' ? (
          <>
            Расписание успешно изменено. <br /> Через 3 секунды это окно будет закрыто
          </>
        ) : (
          <>
            <br />
          </>
        )}
      </InputError>
    </Modal>
  );
};
