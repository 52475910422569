import './TribeResults.scss';

import { isTesting3Streams, reportColors } from '../../../helpers/colorPalete';

import React from 'react';
import Title from '../../Title';
import _ from 'lodash';

interface Props {
  data: {
    cases: any;
    streams_count: number;
    tribe_title: string;
    client_color: string;
    report_percents: number | number[];
    rate_percent: number | number[];
    rate: number | number[];
    max_rate: number | number[];
  };
  withHeader?: true | false;
}

export const TribeResultsHTML: React.FC<Props> = ({ data, withHeader }) => {
  const { firstStreamColor, firstStreamColorWithOpacity, secondStreamColor, secondStreamColorWithOpacity } = reportColors(data.client_color, data.streams_count);

  if (data.streams_count === 2) {
    return (
      <div className='tribeResults' data-cy='tribeResultsBlock'>
        {data.cases.cases.map((segment, index) => (
          <>
            {index === 0 &&
              segment[0].competensions_chunks.map((competension, index) => (
                <div className='tribeStats' key={_.uniqueId()}>
                  <div className='statsSection twoStreams'>
                    {index === 0 && (
                      <>
                        <div className='resultsHeader'>
                          <Title>Cравнительная оценка участников диагностики</Title>
                          <div className='resultColorsExpanation'>
                            <div className='after'>
                              <span className='square' style={{ backgroundColor: secondStreamColor }}></span>
                              <span className='text'>Набрано на диагностике</span>
                            </div>
                            <div className='before'>
                              <span className='square' style={{ backgroundColor: firstStreamColor }}></span>
                              <span className='text'>Набрано на самооценке</span>
                            </div>
                            <div className='norm'>
                              <span className='triangle'></span>
                              <span className='text'>Необходимая норма, % от возможных 100%</span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    <div className='names single'>
                      <div className='title'>
                        <b>Имя участника</b>
                      </div>
                      <div className='namesWrapper'>{competension.chunks.map(chunk => chunk.names.map(element => <div className='name'>{element}</div>))}</div>
                    </div>

                    <div className='roles single'>
                      <div className='title'>
                        <b>Роль участника</b>
                      </div>
                      <div className='rolesWrapper'>{competension.chunks.map(chunk => chunk.roles.map(element => <div className='role'>{element}</div>))}</div>
                    </div>

                    <div className='numbers'>
                      <div className='title'>
                        <b>{competension.competension_1_title === 'all' ? 'Общий результат' : competension.competension_1_title},</b>
                        <br />
                        <span className='small'>% из 100 возможных</span>
                      </div>
                      <div className='numbersWrapper'>
                        <div className='guideLinesWrapper'>
                          <div className='guideLines'>
                            {/* {competension.competension_1_title === 'all' ? (
                              <>
                                <div className='guideLine' style={{ left: '79%', borderColor: secondStreamColor }}>
                                  <div className='guideLineTriangle' style={{ borderBottomColor: secondStreamColor }}></div>
                                  <div className='guideLineDescription' style={{ color: secondStreamColor }}>
                                    Senior
                                  </div>
                                </div>
                                <div className='guideLine gray' style={{ left: '49%' }}>
                                  <div className='guideLineTriangle'></div>
                                  <div className='guideLineDescription'>Middle</div>
                                </div>
                              </>
                            ) : (
                            )} */}
                            {competension.competension_1_recomended && (
                              <div className='guideLine gray' style={{ left: `${competension.competension_1_recomended}%` }}>
                                <div className='guideLineTriangle'></div>
                                <div className='guideLineDescription'>{competension.competension_1_recomended}%</div>
                              </div>
                            )}
                          </div>
                        </div>
                        {competension.chunks.map(chunk =>
                          chunk.competension_1_data.map(element => (
                            <div className='number'>
                              <div className='lineContainer' style={{ backgroundColor: secondStreamColorWithOpacity }}>
                                <div
                                  className='line'
                                  style={{
                                    width: `${element[1] ? element[1] : '0'}%`,
                                    backgroundColor: secondStreamColor,
                                  }}
                                ></div>
                              </div>
                              <div className='value'>{element[1] === '' || element[1] === undefined ? '—' : element[1]}</div>
                              <div className='lineContainer' style={{ backgroundColor: firstStreamColorWithOpacity }}>
                                <div
                                  className='line'
                                  style={{
                                    width: `${element[0] ? element[0] : '0'}%`,
                                    backgroundColor: firstStreamColor,
                                  }}
                                ></div>
                              </div>
                              <div className='value'>{element[0] === '' || element[0] === undefined ? '—' : element[0]}</div>
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                    {competension.competension_2_title && (
                      <div className='numbers'>
                        <div className='title'>
                          <b>{competension.competension_2_title},</b>
                          <br />
                          <span className='small'>% из 100 возможных</span>
                        </div>
                        <div className='numbersWrapper'>
                          <div className='guideLinesWrapper'>
                            <div className='guideLines'>
                              <div className='guideLine gray' style={{ left: `${competension.competension_2_recomended}%` }}>
                                <div className='guideLineTriangle'></div>
                                <div className='guideLineDescription'>{competension.competension_2_recomended}%</div>
                              </div>
                            </div>
                          </div>
                          {competension.chunks.map(chunk =>
                            chunk.competension_2_data.map((element, index) => (
                              <div className='number'>
                                <div className='lineContainer' style={{ backgroundColor: secondStreamColorWithOpacity }}>
                                  <div
                                    className='line'
                                    style={{
                                      width: `${element[1] ? element[1] : '0'}%`,
                                      backgroundColor: secondStreamColor,
                                    }}
                                  ></div>
                                </div>
                                <div className='value'>{element[1] === '' || element[1] === undefined ? '—' : element[1]}</div>
                                <div className='lineContainer' style={{ backgroundColor: firstStreamColorWithOpacity }}>
                                  <div
                                    className='line'
                                    style={{
                                      width: `${element[0] ? element[0] : '0'}%`,
                                      backgroundColor: firstStreamColor,
                                    }}
                                  ></div>
                                </div>
                                <div className='value'>{element[0] === '' || element[0] === undefined ? '—' : element[0]}</div>
                              </div>
                            ))
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            {index > 0 &&
              segment[0].data.map((item, index) => (
                <>
                  {item.data[0].competensions_chunks.map((competension, index) => (
                    <div className='tribeStats' key={_.uniqueId()}>
                      <div className='statsSection twoStreams'>
                        {index === 0 && (
                          <div className='resultsHeader'>
                            <div className='subTitle'>{`Оценка по компетенции «${item.data[0].title}»`}</div>
                          </div>
                        )}
                        <div className='names single'>
                          <div className='title'>
                            <b>Имя участника</b>
                          </div>
                          <div className='namesWrapper'>{competension.chunks.map(chunk => chunk.names.map(element => <div className='name'>{element}</div>))}</div>
                        </div>

                        <div className='roles single'>
                          <div className='title'>
                            <b>Роль участника</b>
                          </div>
                          <div className='rolesWrapper'>{competension.chunks.map(chunk => chunk.roles.map(element => <div className='role'>{element}</div>))}</div>
                        </div>

                        <div className='numbers'>
                          <div className='title'>
                            {/* <b>{competension.competension_1_title === 'all' ? 'Общий результат' : competension.competension_1_title},</b> */}
                            <br />
                            <span className='small'>% из 100 возможных</span>
                          </div>
                          <div className='numbersWrapper'>
                            <div className='guideLinesWrapper'>
                              <div className='guideLines'>
                                {/* {competension.competension_1_title === 'all' ? (
                                  <>
                                    <div className='guideLine' style={{ left: '79%', borderColor: secondStreamColor }}>
                                      <div className='guideLineTriangle' style={{ borderBottomColor: secondStreamColor }}></div>
                                      <div className='guideLineDescription' style={{ color: secondStreamColor }}>
                                        Senior
                                      </div>
                                    </div>
                                    <div className='guideLine gray' style={{ left: '49%' }}>
                                      <div className='guideLineTriangle'></div>
                                      <div className='guideLineDescription'>Middle</div>
                                    </div>
                                  </>
                                ) : (


                                )} */}
                                <div className='guideLine gray' style={{ left: `${competension.competension_1_recomended}%` }}>
                                  <div className='guideLineTriangle'></div>
                                  <div className='guideLineDescription'>{competension.competension_1_recomended}%</div>
                                </div>
                              </div>
                            </div>
                            {competension.chunks.map(chunk =>
                              chunk.competension_1_data.map(element => (
                                <div className='number'>
                                  <div className='lineContainer' style={{ backgroundColor: secondStreamColorWithOpacity }}>
                                    <div
                                      className='line'
                                      style={{
                                        width: `${element[1] ? element[1] : '0'}%`,
                                        backgroundColor: secondStreamColor,
                                      }}
                                    ></div>
                                  </div>
                                  <div className='value'>{element[1] === '' || element[1] === undefined ? '—' : element[1]}</div>
                                  <div className='lineContainer' style={{ backgroundColor: firstStreamColorWithOpacity }}>
                                    <div
                                      className='line'
                                      style={{
                                        width: `${element[0] ? element[0] : '0'}%`,
                                        backgroundColor: firstStreamColor,
                                      }}
                                    ></div>
                                  </div>
                                  <div className='value'>{element[0] === '' || element[0] === undefined ? '—' : element[0]}</div>
                                </div>
                              ))
                            )}
                          </div>
                        </div>
                        {competension.competension_2_title && (
                          <div className='numbers'>
                            <div className='title'>
                              <b>{competension.competension_2_title},</b>
                              <br />
                              <span className='small'>% из 100 возможных</span>
                            </div>
                            <div className='numbersWrapper'>
                              <div className='guideLinesWrapper'>
                                <div className='guideLines'>
                                  <div className='guideLine gray' style={{ left: `${competension.competension_2_recomended}%` }}>
                                    <div className='guideLineTriangle'></div>
                                    <div className='guideLineDescription'>{competension.competension_2_recomended}%</div>
                                  </div>
                                </div>
                              </div>
                              {competension.chunks.map(chunk =>
                                chunk.competension_2_data.map((element, index) => (
                                  <div className='number'>
                                    <div className='lineContainer' style={{ backgroundColor: secondStreamColorWithOpacity }}>
                                      <div
                                        className='line'
                                        style={{
                                          width: `${element[1] ? element[1] : '0'}%`,
                                          backgroundColor: secondStreamColor,
                                        }}
                                      ></div>
                                    </div>
                                    <div className='value'>{element[1] === '' || element[1] === undefined ? '—' : element[1]}</div>
                                    <div className='lineContainer' style={{ backgroundColor: firstStreamColorWithOpacity }}>
                                      <div
                                        className='line'
                                        style={{
                                          width: `${element[0] ? element[0] : '0'}%`,
                                          backgroundColor: firstStreamColor,
                                        }}
                                      ></div>
                                    </div>
                                    <div className='value'>{element[0] === '' || element[0] === undefined ? '—' : element[0]}</div>
                                  </div>
                                ))
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                  {item.indicators.map((indicator, index) =>
                    indicator[0].competensions_chunks?.map((competension, index) => (
                      <div className='tribeStats' key={_.uniqueId()}>
                        <div className='statsSection twoStreams'>
                          {index === 0 && (
                            <div className='resultsHeader'>
                              <div className='subTitle'>{`Оценка по индикаторам компетенции «${item.data[0].title}»`}</div>
                            </div>
                          )}
                          <div className='names single'>
                            <div className='title'>
                              <b>Имя участника</b>
                            </div>
                            <div className='namesWrapper'>{competension.chunks.map(chunk => chunk.names.map(element => <div className='name'>{element}</div>))}</div>
                          </div>

                          <div className='roles single'>
                            <div className='title'>
                              <b>Роль участника</b>
                            </div>
                            <div className='rolesWrapper'>{competension.chunks.map(chunk => chunk.roles.map(element => <div className='role'>{element}</div>))}</div>
                          </div>

                          <div className='numbers'>
                            <div className='title'>
                              <b>{competension.competension_1_title === 'all' ? 'Общий результат' : competension.competension_1_title},</b>
                              <br />
                              <span className='small'>% из 100 возможных</span>
                            </div>
                            <div className='numbersWrapper'>
                              <div className='guideLinesWrapper'>
                                <div className='guideLines'>
                                  {/* {competension.competension_1_title === 'all' ? (
                                    <>
                                      <div className='guideLine' style={{ left: '79%', borderColor: secondStreamColor }}>
                                        <div className='guideLineTriangle' style={{ borderBottomColor: secondStreamColor }}></div>
                                        <div className='guideLineDescription' style={{ color: secondStreamColor }}>
                                          Senior
                                        </div>
                                      </div>
                                      <div className='guideLine gray' style={{ left: '49%' }}>
                                        <div className='guideLineTriangle'></div>
                                        <div className='guideLineDescription'>Middle</div>
                                      </div>
                                    </>
                                  ) : (
                                  )} */}
                                  <div className='guideLine gray' style={{ left: `${competension.competension_1_recomended}%` }}>
                                    <div className='guideLineTriangle'></div>
                                    <div className='guideLineDescription'>{competension.competension_1_recomended}%</div>
                                  </div>
                                </div>
                              </div>
                              {competension.chunks.map(chunk =>
                                chunk.competension_1_data.map(element => (
                                  <div className='number'>
                                    <div className='lineContainer' style={{ backgroundColor: secondStreamColorWithOpacity }}>
                                      <div
                                        className='line'
                                        style={{
                                          width: `${element[1] ? element[1] : '0'}%`,
                                          backgroundColor: secondStreamColor,
                                        }}
                                      ></div>
                                    </div>
                                    <div className='value'>{element[1] === '' || element[1] === undefined ? '—' : element[1]}</div>
                                    <div className='lineContainer' style={{ backgroundColor: firstStreamColorWithOpacity }}>
                                      <div
                                        className='line'
                                        style={{
                                          width: `${element[0] ? element[0] : '0'}%`,
                                          backgroundColor: firstStreamColor,
                                        }}
                                      ></div>
                                    </div>
                                    <div className='value'>{element[0] === '' || element[0] === undefined ? '—' : element[0]}</div>
                                  </div>
                                ))
                              )}
                            </div>
                          </div>
                          {competension.competension_2_title && (
                            <div className='numbers'>
                              <div className='title'>
                                <b>{competension.competension_2_title},</b>
                                <br />
                                <span className='small'>% из 100 возможных</span>
                              </div>
                              <div className='numbersWrapper'>
                                <div className='guideLinesWrapper'>
                                  <div className='guideLines'>
                                    <div className='guideLine gray' style={{ left: `${competension.competension_2_recomended}%` }}>
                                      <div className='guideLineTriangle'></div>
                                      <div className='guideLineDescription'>{competension.competension_2_recomended}%</div>
                                    </div>
                                  </div>
                                </div>
                                {competension.chunks.map(chunk =>
                                  chunk.competension_2_data.map((element, index) => (
                                    <div className='number'>
                                      <div className='lineContainer' style={{ backgroundColor: secondStreamColorWithOpacity }}>
                                        <div
                                          className='line'
                                          style={{
                                            width: `${element[1] ? element[1] : '0'}%`,
                                            backgroundColor: secondStreamColor,
                                          }}
                                        ></div>
                                      </div>
                                      <div className='value'>{element[1] === '' || element[1] === undefined ? '—' : element[1]}</div>
                                      <div className='lineContainer' style={{ backgroundColor: firstStreamColorWithOpacity }}>
                                        <div
                                          className='line'
                                          style={{
                                            width: `${element[0] ? element[0] : '0'}%`,
                                            backgroundColor: firstStreamColor,
                                          }}
                                        ></div>
                                      </div>
                                      <div className='value'>{element[0] === '' || element[0] === undefined ? '—' : element[0]}</div>
                                    </div>
                                  ))
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ))
                  )}
                </>
              ))}
          </>
        ))}
      </div>
    );
  }

  return (
    <div className='tribeResults' data-cy='tribeResultsBlock'>
      {data.cases.map((segment, index) => (
        <>
          {index === 0 &&
            segment[0].competensions_chunks.map((competension, index) => (
              <div className='tribeStats' key={_.uniqueId()}>
                <div className='statsSection'>
                  {index === 0 && (
                    <>
                      <div className='resultsHeader'>
                        <Title>Cравнительная оценка участников диагностики</Title>
                        <div className='resultColorsExpanation'>
                          <div className='before'>
                            <span className='square' style={{ backgroundColor: firstStreamColor }}></span>
                            <span className='text'>Набрано</span>
                          </div>
                          <div className='norm'>
                            <span className='triangle'></span>
                            <span className='text'>Необходимая норма, % от возможных 100%</span>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  <div className='names single'>
                    <div className='title'>
                      <b>Имя участника</b>
                    </div>
                    <div className='namesWrapper'>{competension.chunks.map(chunk => chunk.names.map(element => <div className='name'>{element}</div>))}</div>
                  </div>
                  <div className='roles single'>
                    <div className='title'>
                      <b>Роль участника</b>
                    </div>
                    <div className='rolesWrapper'>{competension.chunks.map(chunk => chunk.roles.map(element => <div className='role'>{element}</div>))}</div>
                  </div>
                  <div className='numbers'>
                    <div className='title'>
                      <b>{competension.competension_1_title === 'all' ? 'Общий результат' : competension.competension_1_title},</b>
                      <br />
                      <span className='small'>% из 100 возможных</span>
                    </div>
                    <div className='numbersWrapper'>
                      <div className='guideLinesWrapper'>
                        <div className='guideLines'>
                          {/* {competension.competension_1_title === 'all' ? (
                            <>
                              <div className='guideLine' style={{ left: '79%', borderColor: firstStreamColor }}>
                                <div className='guideLineTriangle' style={{ borderBottomColor: firstStreamColor }}></div>
                                <div className='guideLineDescription' style={{ color: firstStreamColor }}>
                                  Senior
                                </div>
                              </div>
                              <div className='guideLine gray' style={{ left: '49%' }}>
                                <div className='guideLineTriangle'></div>
                                <div className='guideLineDescription'>Middle</div>
                              </div>
                            </>
                          ) : (


                          )} */}
                          {competension.competension_1_recomended && (
                            <div className='guideLine gray' style={{ left: `${competension.competension_1_recomended}%` }}>
                              <div className='guideLineTriangle'></div>
                              <div className='guideLineDescription'>{competension.competension_1_recomended}%</div>
                            </div>
                          )}
                        </div>
                      </div>
                      {competension.chunks.map(chunk =>
                        chunk.competension_1_data.map(element => (
                          <div className='number'>
                            <div className='lineContainer' style={{ backgroundColor: firstStreamColorWithOpacity }}>
                              <div
                                className='line'
                                style={{
                                  width: `${element ? element : '0'}%`,
                                  backgroundColor: firstStreamColor,
                                }}
                              ></div>
                            </div>
                            <div className='value'>{element !== '' ? element : '—'}</div>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                  {competension.competension_2_title && (
                    <div className='numbers'>
                      <div className='title'>
                        <b>{competension.competension_2_title},</b>
                        <br />
                        <span className='small'>% из 100 возможных</span>
                      </div>
                      <div className='numbersWrapper'>
                        <div className='guideLinesWrapper'>
                          <div className='guideLines'>
                            <div className='guideLine gray' style={{ left: `${competension.competension_2_recomended}%` }}>
                              <div className='guideLineTriangle'></div>
                              <div className='guideLineDescription'>{competension.competension_2_recomended}%</div>
                            </div>
                          </div>
                        </div>
                        {competension.chunks.map(chunk =>
                          chunk.competension_2_data.map(element => (
                            <div className='number' key={_.uniqueId()}>
                              <div className='lineContainer' style={{ backgroundColor: firstStreamColorWithOpacity }}>
                                <div className='line' style={{ width: `${element ? element : '0'}%`, backgroundColor: firstStreamColor }}></div>
                              </div>
                              <div className='value'>{element === '' || element === undefined ? '—' : element}</div>
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          {index > 0 &&
            segment[0].data.map((item, index) => (
              <>
                {item.data[0].competensions_chunks.map((competension, index) => (
                  <div className='tribeStats' key={_.uniqueId()}>
                    <div className='statsSection'>
                      {index === 0 && (
                        <>
                          <div className='resultsHeader'>
                            <div className='subTitle'>{`Оценка по компетенции «${item.data[0].title}»`}</div>
                          </div>
                        </>
                      )}

                      <div className='names single'>
                        <div className='title'>
                          <b>Имя участника</b>
                        </div>
                        <div className='namesWrapper'>{competension.chunks.map(chunk => chunk.names.map(element => <div className='name'>{element}</div>))}</div>
                      </div>
                      <div className='roles single'>
                        <div className='title'>
                          <b>Роль участника</b>
                        </div>
                        <div className='rolesWrapper'>{competension.chunks.map(chunk => chunk.roles.map(element => <div className='role'>{element}</div>))}</div>
                      </div>
                      <div className='numbers'>
                        <div className='title'>
                          {/* <b>{competension.competension_1_title === 'all' ? 'Общий результат' : competension.competension_1_title},</b> */}
                          <br />
                          <span className='small'>% из 100 возможных</span>
                        </div>
                        <div className='numbersWrapper'>
                          <div className='guideLinesWrapper'>
                            <div className='guideLines'>
                              {/* {competension.competension_1_title === 'all' ? (
                                <>
                                  <div className='guideLine' style={{ left: '79%', borderColor: firstStreamColor }}>
                                    <div className='guideLineTriangle' style={{ borderBottomColor: firstStreamColor }}></div>
                                    <div className='guideLineDescription' style={{ color: firstStreamColor }}>
                                      Senior
                                    </div>
                                  </div>
                                  <div className='guideLine gray' style={{ left: '49%' }}>
                                    <div className='guideLineTriangle'></div>
                                    <div className='guideLineDescription'>Middle</div>
                                  </div>
                                </>
                              ) : (
                              )} */}
                              <div className='guideLine gray' style={{ left: `${competension.competension_1_recomended}%` }}>
                                <div className='guideLineTriangle'></div>
                                <div className='guideLineDescription'>{competension.competension_1_recomended}%</div>
                              </div>
                            </div>
                          </div>
                          {competension.chunks.map(chunk =>
                            chunk.competension_1_data.map(element => (
                              <div className='number'>
                                <div className='lineContainer' style={{ backgroundColor: firstStreamColorWithOpacity }}>
                                  <div
                                    className='line'
                                    style={{
                                      width: `${element ? element : '0'}%`,
                                      backgroundColor: firstStreamColor,
                                    }}
                                  ></div>
                                </div>
                                <div className='value'>{element !== '' ? element : '—'}</div>
                              </div>
                            ))
                          )}
                        </div>
                      </div>
                      {competension.competension_2_title && (
                        <div className='numbers'>
                          <div className='title'>
                            <b>{competension.competension_2_title},</b>
                            <br />
                            <span className='small'>% из 100 возможных</span>
                          </div>
                          <div className='numbersWrapper'>
                            <div className='guideLinesWrapper'>
                              <div className='guideLines'>
                                <div className='guideLine gray' style={{ left: `${competension.competension_2_recomended}%` }}>
                                  <div className='guideLineTriangle'></div>
                                  <div className='guideLineDescription'>{competension.competension_2_recomended}%</div>
                                </div>
                              </div>
                            </div>
                            {competension.chunks.map(chunk =>
                              chunk.competension_2_data.map(element => (
                                <div className='number' key={_.uniqueId()}>
                                  <div className='lineContainer' style={{ backgroundColor: firstStreamColorWithOpacity }}>
                                    <div className='line' style={{ width: `${element ? element : '0'}%`, backgroundColor: firstStreamColor }}></div>
                                  </div>
                                  <div className='value'>{element === '' || element === undefined ? '—' : element}</div>
                                </div>
                              ))
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
                {item.indicators.map((indicator, index) =>
                  indicator[0].competensions_chunks?.map((competension, index) => (
                    <div className='tribeStats' key={_.uniqueId()}>
                      <div className='statsSection'>
                        {index === 0 && (
                          <>
                            <div className='resultsHeader'>
                              <div className='subTitle'>{`Оценка по индикаторам компетенции «${item.data[0].title}»`}</div>
                            </div>
                          </>
                        )}

                        <div className='names single'>
                          <div className='title'>
                            <b>Имя участника</b>
                          </div>
                          <div className='namesWrapper'>{competension.chunks.map(chunk => chunk.names.map(element => <div className='name'>{element}</div>))}</div>
                        </div>
                        <div className='roles single'>
                          <div className='title'>
                            <b>Роль участника</b>
                          </div>
                          <div className='rolesWrapper'>{competension.chunks.map(chunk => chunk.roles.map(element => <div className='role'>{element}</div>))}</div>
                        </div>
                        <div className='numbers'>
                          <div className='title'>
                            <b>{competension.competension_1_title === 'all' ? 'Общий результат' : competension.competension_1_title},</b>
                            <br />
                            <span className='small'>% из 100 возможных</span>
                          </div>
                          <div className='numbersWrapper'>
                            <div className='guideLinesWrapper'>
                              <div className='guideLines'>
                                {/* {competension.competension_1_title === 'all' ? (
                                  <>
                                    <div className='guideLine' style={{ left: '79%', borderColor: firstStreamColor }}>
                                      <div className='guideLineTriangle' style={{ borderBottomColor: firstStreamColor }}></div>
                                      <div className='guideLineDescription' style={{ color: firstStreamColor }}>
                                        Senior
                                      </div>
                                    </div>
                                    <div className='guideLine gray' style={{ left: '49%' }}>
                                      <div className='guideLineTriangle'></div>
                                      <div className='guideLineDescription'>Middle</div>
                                    </div>
                                  </>
                                ) : (


                                )} */}
                                <div className='guideLine gray' style={{ left: `${competension.competension_1_recomended}%` }}>
                                  <div className='guideLineTriangle'></div>
                                  <div className='guideLineDescription'>{competension.competension_1_recomended}%</div>
                                </div>
                              </div>
                            </div>
                            {competension.chunks.map(chunk =>
                              chunk.competension_1_data.map(element => (
                                <div className='number'>
                                  <div className='lineContainer' style={{ backgroundColor: firstStreamColorWithOpacity }}>
                                    <div
                                      className='line'
                                      style={{
                                        width: `${element ? element : '0'}%`,
                                        backgroundColor: firstStreamColor,
                                      }}
                                    ></div>
                                  </div>
                                  <div className='value'>{element !== '' ? element : '—'}</div>
                                </div>
                              ))
                            )}
                          </div>
                        </div>
                        {competension.competension_2_title && (
                          <div className='numbers'>
                            <div className='title'>
                              <b>{competension.competension_2_title},</b>
                              <br />
                              <span className='small'>% из 100 возможных</span>
                            </div>
                            <div className='numbersWrapper'>
                              <div className='guideLinesWrapper'>
                                <div className='guideLines'>
                                  <div className='guideLine gray' style={{ left: `${competension.competension_2_recomended}%` }}>
                                    <div className='guideLineTriangle'></div>
                                    <div className='guideLineDescription'>{competension.competension_2_recomended}%</div>
                                  </div>
                                </div>
                              </div>
                              {competension.chunks.map(chunk =>
                                chunk.competension_2_data.map(element => (
                                  <div className='number' key={_.uniqueId()}>
                                    <div className='lineContainer' style={{ backgroundColor: firstStreamColorWithOpacity }}>
                                      <div className='line' style={{ width: `${element ? element : '0'}%`, backgroundColor: firstStreamColor }}></div>
                                    </div>
                                    <div className='value'>{element === '' || element === undefined ? '—' : element}</div>
                                  </div>
                                ))
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))
                )}
              </>
            ))}
        </>
      ))}
    </div>
  );
};
