import './Profile.scss';

import AnimatedRedirect from '../../components/AnimatedRedirect';
import PageLoader from '../../components/PageLoader';
import PasswordUpdateForm from './PasswordUpdateForm';
import Title from '../../components/Title';
import { motion } from 'framer-motion';
import { standardPageVariants } from '../../animationsVariants/StandardPageVariants';
import useUserData from '../../hooks/useUserData';

export const Profile: React.FC<{}> = () => {
  const { data: userData, status: userDataLoadingStatus } = useUserData();

  if (userDataLoadingStatus === 'loading') {
    return <PageLoader />;
  }

  if (userDataLoadingStatus === 'error') {
    return <AnimatedRedirect redirectTo='/500/' />;
  }

  return (
    <motion.main className='profile' variants={standardPageVariants} initial='initial' animate='animate' exit='exit'>
      <Title>Ваши данные</Title>
      <div className='fieldset firstName'>
        <label>Имя </label>
        <input type='text' name='firstName' value={userData.user.first_name} disabled />
      </div>
      <div className='fieldset lastName'>
        <label>Фамилия</label>
        <input type='text' name='lastName' value={userData.user.last_name} disabled />
      </div>
      <div className='fieldset email'>
        <label>Email</label>
        <input type='email' name='email' value={userData.user.email} disabled />
      </div>
      {userData.user.tm_link && (
        <>
          <a href={userData.user.tm_link} target='_blank' rel='noopener noreferrer' className='tgButton button blue medium'>
            Получать уведомления в телеграм
          </a>
          <div className='hint'>
            Подпишитесь на уведомления в Telegram о <br /> предстоящих интервью с участниками.
          </div>
        </>
      )}
      <PasswordUpdateForm />
    </motion.main>
  );
};
