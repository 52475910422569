import './PasswordRecovery.scss';

import axios from 'axios';
import * as Yup from 'yup';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import { useHistory, Link, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { formVariants } from '../../animationsVariants/FormVariants';
import { backendURL } from '../../App';
import { Button } from '../../components/Button/Button';
import { InputError } from '../../components/InputError/InputError';
import consoleLog from '../../helpers/consoleLog';
import { Title } from '../../components/Title/Title';

export const PasswordRecovery: React.FC<{}> = () => {
  const { key } = useParams<{ key: string }>();
  const history = useHistory();

  return !key ? (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={Yup.object({
        email: Yup.string().email('некорректный email адрес').required('поле должно быть заполнено'),
      })}
      onSubmit={(values, { setSubmitting, setStatus, setFieldError }) => {
        axios({
          method: 'get',
          url: `${backendURL}recover/`,
          params: values,
        })
          .then(result => {
            setSubmitting(false);
            setStatus('Проверьте ваш email для дальнейших инструкций');
            consoleLog('success', 'Password reset attempt', result);
          })
          .catch(error => {
            console.log(error.response);
            setSubmitting(false);
            if (error?.response?.data?.error?.email) {
              setFieldError('email', error.response.data.error.email);
              setTimeout(() => {
                setFieldError('email', '');
              }, 3000);
            }
            consoleLog('error', 'Password reset attempt', error);
          });
      }}
    >
      {props => (
        <main className='passwordRecovery'>
          <motion.div className='passwordRecoverForm formContainer' variants={formVariants} initial='initial' animate='animate' exit='exit'>
            <Form>
              <Title>Забыли пароль?</Title>

              <Field
                name='email'
                type='email'
                placeholder='Ваш e-mail (логин)'
                className={`${props.errors.email && props.touched.email ? 'invalid' : ''}`}
                disabled={props.status}
              />
              <div className='hint'>
                Мы отправим вам письмо на указанный e-mail <br /> для восстановления доступа
              </div>
              <InputError className='email'>
                <ErrorMessage name='email' />
              </InputError>
              <InputError className='submit'>{props.status}</InputError>
              <Button type='button' width='330rem' size='medium' color='blue' text='Отправить' loading={props.isSubmitting} disabled={props.status && true} />
            </Form>
          </motion.div>
        </main>
      )}
    </Formik>
  ) : (
    <Formik
      initialValues={{ key: key, new_password: '', reenter_password: '' }}
      validationSchema={Yup.object({
        new_password: Yup.string().min(8, 'пароль должен содержать минимум 8 символов').required('поле должно быть заполнено'),
        reenter_password: Yup.string()
          .min(8, 'пароль должен содержать минимум 8 символов')
          .required('поле должно быть заполнено')
          .oneOf([Yup.ref('new_password')], 'Пароли не совпадают'),
      })}
      onSubmit={(values, { setSubmitting, setStatus }) => {
        axios({
          method: 'post',
          url: `${backendURL}new-password/`,
          data: values,
        })
          .then(result => {
            setSubmitting(false);
            setStatus('Пароль изменён. Вы будете перенаправлены на страиницу логина через 5с.');
            setTimeout(() => {
              history.push('/');
            }, 5000);
            consoleLog('success', 'Password update', result);
          })
          .catch(error => {
            setSubmitting(false);
            history.push('/500/');
            consoleLog('error', 'Password update', error);
          });
      }}
    >
      {props => (
        <main className='newPassword'>
          <motion.div className='newPasswordForm formContainer' variants={formVariants} initial='initial' animate='animate' exit='exit'>
            <Form>
              <Title>Восстановление пароля</Title>

              <Field
                name='new_password'
                type='password'
                placeholder='Пароль'
                className={`${props.errors.new_password && props.touched.new_password ? 'invalid' : ''}`}
                disabled={props.status}
              />
              <InputError>
                <ErrorMessage name='new_password' />
              </InputError>

              <Field
                name='reenter_password'
                type='password'
                placeholder='Повторите пароль'
                className={`${props.errors.reenter_password && props.touched.reenter_password ? 'invalid' : ''}`}
                disabled={props.status}
              />
              <InputError className='reenter_password'>
                <ErrorMessage name='reenter_password' />
              </InputError>
              <InputError className='submit'>{props.status}</InputError>
              <Button type='button' width='330rem' size='medium' color='blue' text='Готово' loading={props.isSubmitting} disabled={props.status} />
            </Form>
          </motion.div>
        </main>
      )}
    </Formik>
  );
};
