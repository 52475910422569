import './Button.scss';

import { ButtonLoader } from '../ButtonLoader/ButtonLoader';
import { Link } from 'react-router-dom';

interface ButtonProps {
  type: 'button' | 'link';
  buttonType?: 'button' | 'submit' | 'reset' | undefined;
  width: string;
  size?: 'medium' | 'large' | 'small' | 'default';
  color: string;
  text?: string;
  onClick?: any;
  href?: string;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  children?: any;
  dataCy?: string;
}

export const Button: React.FC<ButtonProps> = ({ type, buttonType, onClick, href, size, color, width, text, className, disabled, loading, children, dataCy }) => (
  <>
    {type === 'button' && (
      <button
        type={buttonType}
        className={`button ${size} ${color} ${className || ''}`}
        style={{ width }}
        onClick={onClick}
        disabled={disabled === true || loading === true}
        data-cy={dataCy}
      >
        {loading ? <ButtonLoader /> : text}
        {children}
      </button>
    )}

    {type === 'link' && (
      <Link className={`button ${size} ${color} ${className || ''}`} style={{ width }} to={href || '/'}>
        {text}
        {children}
      </Link>
    )}
  </>
);
