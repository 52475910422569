
import { Redirect } from 'react-router-dom';
import { motion } from 'framer-motion';

interface Props {
  redirectTo: string;
}

export const AnimatedRedirect: React.FC<Props> = ({ redirectTo }) => (
  <motion.div exit='undefined'>
    <Redirect to={redirectTo} />
  </motion.div>
);
