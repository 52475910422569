import './TemplateStage5Form.scss';
import 'react-quill/dist/quill.snow.css';

import * as Yup from 'yup';

import { Form, Formik } from 'formik';
import { backendURL, debug } from '../../../App';

import Button from '../../../components/Button';
import SubTitle from '../SubTitle';
import TextareaWYSIWYG from '../../../components/TextareaWYSIWYG';
import Title from '../../../components/Title';
import axios from 'axios';
import consoleLog from '../../../helpers/consoleLog';
import icon_loading from '../../../assets/images/icon_loadingBlue.svg';
import { motion } from 'framer-motion';
import { queryCache } from 'react-query';
import useAnketaData from '../../../hooks/useAnketaData';
import { useParams } from 'react-router-dom';

export const TemplateStage5Form: React.FC<{}> = () => {
  const { anketaID } = useParams<{ clientID: string; anketaID: string }>();
  const { data: anketaData, status: anketaDataLoadingStatus } = useAnketaData(anketaID);

  if (anketaDataLoadingStatus === 'loading') {
    return (
      <div className='isLoading'>
        <motion.img src={icon_loading} initial={{ rotate: 0 }} animate={{ rotate: 360 }} transition={{ duration: 2, loop: Infinity, ease: 'linear' }} />
      </div>
    );
  }

  return (
    <section className='templateStage4Form'>
      <Title>Создание общих рекомендаций анкеты</Title>
      <Formik
        initialValues={{
          recomendation_low: anketaData?.recomendation_low || '',
          recomendation_jun_mid: anketaData?.recomendation_jun_mid || '',
          recomendation_mid: anketaData?.recomendation_mid || '',
          recomendation_high: anketaData?.recomendation_high || '',
        }}
        validationSchema={Yup.object({
          recomendation_low: Yup.string().required('поле должно быть заполнено'),
          recomendation_jun_mid: Yup.string().required('поле должно быть заполнено'),
          recomendation_mid: Yup.string().required('поле должно быть заполнено'),
          recomendation_high: Yup.string().required('поле должно быть заполнено'),
        })}
        onSubmit={async (values, { setSubmitting, setStatus }) => {
          try {
            const { data } = await axios({
              method: 'post',
              url: `${backendURL}anketa/${anketaID}/`,
              headers: {
                Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
              },
              data: {
                id: anketaID,
                ...values,
              },
            });
            setSubmitting(false);
            setStatus({ success: true });
            queryCache.invalidateQueries(['AnketaData', anketaID]);
            debug && consoleLog('success', 'Recomendations save', data);
          } catch (error) {
            setSubmitting(false);
            debug && consoleLog('error', 'Recomendations save', error);
          }
        }}
      >
        {props => (
          <Form>
            <SubTitle>Рекомендация для анкеты</SubTitle>
            <TextareaWYSIWYG
              value={props.values.recomendation_low}
              onChange={value => props.setFieldValue('recomendation_low', value.replace(/(<([^>]+)>)/gi, '') ? value : '')}
              onBlur={() => props.setFieldTouched('recomendation_low', true)}
              placeholderType='alwaysVisible'
              placeholder='При наборе до 40% баллов (Junior)'
              isValid={!(props.errors.recomendation_low && props.touched.recomendation_low)}
              validationError={props.errors.recomendation_low && props.touched.recomendation_low ? props.errors.recomendation_low : undefined}
              modulesType='defaultWithLink'
            />
            <TextareaWYSIWYG
              value={props.values.recomendation_jun_mid}
              onChange={value => props.setFieldValue('recomendation_jun_mid', value.replace(/(<([^>]+)>)/gi, '') ? value : '')}
              onBlur={() => props.setFieldTouched('recomendation_jun_mid', true)}
              placeholderType='alwaysVisible'
              placeholder='При наборе от 40% до 50% баллов (Junior - Middle)'
              isValid={!(props.errors.recomendation_jun_mid && props.touched.recomendation_jun_mid)}
              validationError={props.errors.recomendation_jun_mid && props.touched.recomendation_jun_mid ? props.errors.recomendation_jun_mid : undefined}
              modulesType='defaultWithLink'
            />
            <TextareaWYSIWYG
              value={props.values.recomendation_mid}
              onChange={value => props.setFieldValue('recomendation_mid', value.replace(/(<([^>]+)>)/gi, '') ? value : '')}
              onBlur={() => props.setFieldTouched('recomendation_mid', true)}
              placeholderType='alwaysVisible'
              placeholder='При наборе от 50% до 70% баллов (Middle)'
              isValid={!(props.errors.recomendation_mid && props.touched.recomendation_mid)}
              validationError={props.errors.recomendation_mid && props.touched.recomendation_mid ? props.errors.recomendation_mid : undefined}
              modulesType='defaultWithLink'
            />
            <TextareaWYSIWYG
              value={props.values.recomendation_high}
              onChange={value => props.setFieldValue('recomendation_high', value.replace(/(<([^>]+)>)/gi, '') ? value : '')}
              onBlur={() => props.setFieldTouched('recomendation_high', true)}
              placeholderType='alwaysVisible'
              placeholder='При наборе от 70% баллов (Senior)'
              isValid={!(props.errors.recomendation_high && props.touched.recomendation_high)}
              validationError={props.errors.recomendation_high && props.touched.recomendation_high ? props.errors.recomendation_high : undefined}
              modulesType='defaultWithLink'
            />
            <Button type='button' width='330rem' size='medium' color='blue' className='submitButton' text='Сохранить' />
          </Form>
        )}
      </Formik>
    </section>
  );
};
