import './MemberAnketaSubquestionsProgression.scss';



interface Props {
  subquestionsCount: number;
  currentSubquestionNumber: number;
}

export const MemberAnketaSubquestionsProgression: React.FC<Props> = ({ subquestionsCount, currentSubquestionNumber }) => {
  const prograssionPoints = () => {
    let prograssionPointsArray: object[] = [];
    for (let i = 1; i <= subquestionsCount; i++) {
      i === currentSubquestionNumber &&
        prograssionPointsArray.push(
          <div className='progressionPoint active' key={i}>
            {i}
          </div>
        );
      i < currentSubquestionNumber &&
        prograssionPointsArray.push(
          <div className='progressionPoint answered' key={i}>
            {i}
          </div>
        );
      i > currentSubquestionNumber &&
        prograssionPointsArray.push(
          <div className='progressionPoint' key={i}>
            {i}
          </div>
        );
    }
    return prograssionPointsArray;
  };

  return (
    <div className='memberAnketaSubquestionsProgression'>
      <div className='memberAnketaSubquestionsProgressionTitle'>Список вопросов закрытого анкетирования</div>
      <div className='progressionPoints'>{prograssionPoints()}</div>
    </div>
  );
};
