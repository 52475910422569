import './AdminExpertsSchedule.scss';
import icon_loading from '../../../../assets/images/icon_loadingBlue.svg';

import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { format } from 'date-fns';

import useAdminExpertsScheduleData from '../../../../hooks/useAdminExpertsScheduleData';
import { ScheduleTableAdminExperts } from '../../../../components/ScheduleTable/ScheduleTableAdminExperts';
import { ScheduleTabs } from '../../../../components/ScheduleTabs/ScheduleTabs';
import { Title } from '../../../../components/Title/Title';

interface Props {
  expertID: number;
}

export const AdminExpertsSchedule: React.FC<Props> = ({ expertID }) => {
  const { firstWeekStartDate, firstWeekEndDate, selectedStartDate, selectedEndDate } = useSelector((state: any) => state.scheduleReducer);
  const { data: expertsScheduleData, status: expertsScheduleDataLoadingStatus } = useAdminExpertsScheduleData(
    expertID,
    format(selectedStartDate, 'yyyy-MM-dd HH:mm'),
    format(selectedEndDate, 'yyyy-MM-dd HH:mm')
  );

  if (expertsScheduleDataLoadingStatus === 'loading') {
    return (
      <section className='adminExpertSchedule'>
        <div className='isLoading'>
          <motion.img src={icon_loading} initial={{ rotate: 0 }} animate={{ rotate: 360 }} transition={{ duration: 2, loop: Infinity, ease: 'linear' }} />
        </div>
      </section>
    );
  }

  return (
    <section className='adminExpertSchedule'>
      <Title>Расписание эксперта</Title>
      <ScheduleTabs start={firstWeekStartDate} end={firstWeekEndDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} />
      <ScheduleTableAdminExperts expertData={expertsScheduleData.expert_data} start={selectedStartDate} expertID={expertID} />
    </section>
  );
};
