import './ExpertAnketaSubquestionsProgression.scss';


import { Link, useParams } from 'react-router-dom';

interface Props {
  subquestionsCount: number;
  currentSubquestionNumber: number;
}

export const ExpertAnketaSubquestionsProgression: React.FC<Props> = ({ subquestionsCount, currentSubquestionNumber }) => {
  const { anketaID, anketaAnswersID, questionIndex } = useParams<{
    anketaID: string;
    anketaAnswersID: string;
    questionIndex: string;
  }>();

  const prograssionPoints = () => {
    let prograssionPointsArray: object[] = [];
    for (let i = 1; i <= subquestionsCount; i++) {
      i === currentSubquestionNumber &&
        prograssionPointsArray.push(
          <div className='progressionPoint active' key={i}>
            {i}
          </div>
        );
      i < currentSubquestionNumber &&
        prograssionPointsArray.push(
          <Link to={`/expert/review/anketa/${anketaID}/${anketaAnswersID}/questions/${questionIndex}/subquestions/${i}/`} className='progressionPoint' key={i}>
            {i}
          </Link>
        );
      i > currentSubquestionNumber &&
        prograssionPointsArray.push(
          <Link to={`/expert/review/anketa/${anketaID}/${anketaAnswersID}/questions/${questionIndex}/subquestions/${i}/`} className='progressionPoint' key={i}>
            {i}
          </Link>
        );
    }
    return prograssionPointsArray;
  };

  return (
    <div className='expertAnketaSubquestionsProgression'>
      <div className='expertAnketaSubquestionsProgressionTitle'>Список вопросов закрытого анкетирования</div>
      <div className='progressionPoints'>{prograssionPoints()}</div>
    </div>
  );
};
