import {
  ADMIN_TABLE_EXPERT_FILTER_DISPLAY_TOGGLE,
  ADMIN_TABLE_MEMBER_ID_FILTER_DISPLAY_TOGGLE,
  ADMIN_TABLE_MEMBER_NAME_FILTER_DISPLAY_TOGGLE,
  ADMIN_TABLE_STATUS_FILTER_DISPLAY_TOGGLE,
  ADMIN_TABLE_TRIBE_FILTER_DISPLAY_TOGGLE,
} from './types';

export const admin_table_expert_filter_display_toggle = (display_status: boolean) => ({
  type: ADMIN_TABLE_EXPERT_FILTER_DISPLAY_TOGGLE,
  payload: display_status,
});

export const admin_table_member_id_filter_display_toggle = (display_status: boolean) => ({
  type: ADMIN_TABLE_MEMBER_ID_FILTER_DISPLAY_TOGGLE,
  payload: display_status,
});

export const admin_table_member_name_filter_display_toggle = (display_status: boolean) => ({
  type: ADMIN_TABLE_MEMBER_NAME_FILTER_DISPLAY_TOGGLE,
  payload: display_status,
});

export const admin_table_status_filter_display_toggle = (display_status: boolean) => ({
  type: ADMIN_TABLE_STATUS_FILTER_DISPLAY_TOGGLE,
  payload: display_status,
});

export const admin_table_tribe_filter_display_toggle = (display_status: boolean) => ({
  type: ADMIN_TABLE_TRIBE_FILTER_DISPLAY_TOGGLE,
  payload: display_status,
});
