import './ExpertAnketaQuestionAutoRate.scss';
import 'react-quill/dist/quill.snow.css';

import Button from '../../../components/Button';
import { useParams } from 'react-router-dom';

interface Props {
  rate: number;
  questionsCount: number;
  currentQuestionNumber: number;
  subquestionsCount: number;
  currentSubquestionNumber: number;
}

export const ExpertAnketaQuestionAutoRate: React.FC<Props> = ({ rate, questionsCount, currentQuestionNumber, subquestionsCount, currentSubquestionNumber }) => {
  const { anketaID, anketaAnswersID } = useParams<{
    anketaID: string;
    anketaAnswersID: string;
  }>();

  const linkTo = () => {
    if (currentSubquestionNumber < subquestionsCount) {
      return `/expert/review/anketa/${anketaID}/${anketaAnswersID}/questions/${currentQuestionNumber}/subquestions/${currentSubquestionNumber + 1 || 2}/`;
    } else if (currentSubquestionNumber === subquestionsCount && !(currentQuestionNumber === questionsCount)) {
      return `/expert/review/anketa/${anketaID}/${anketaAnswersID}/questions/${currentQuestionNumber + 1}/`;
    } else if (currentQuestionNumber === questionsCount) {
      return `/expert/review/anketa/${anketaID}/${anketaAnswersID}/rate/`;
    }
  };

  return (
    <div className='expertAnketaQuestionAutoRate'>
      {/* // ? Deprecated после того как закрытые вопросы стали true/false, без баллов */}
      {/* <div className='rateTitle'>баллы за ответ</div>
      <div className='rateExplaantion'>оценка</div>
      <div className={`rate${rate === 0 ? ' active' : ''}`}>0</div>
      <div className={`rate${rate === 1 ? ' active' : ''}`}>1</div>
      <div className={`rate${rate === 2 ? ' active' : ''}`}>2</div>
      <div className={`rate${rate === 3 ? ' active' : ''}`}>3</div> */}
      <Button type='link' href={linkTo()} width='100%' size='large' color='blue' text='следующий вопрос' />
    </div>
  );
};
