import './AdminClientMembers.scss';

import { motion } from 'framer-motion';
import { useParams } from 'react-router-dom';

import consoleLog from '../../helpers/consoleLog';
import useAdminMembersData from '../../hooks/useAdminMembersData';
import { standardPageVariants } from '../../animationsVariants/StandardPageVariants';

import Title from '../../components/Title';
import AdminMembersTable from './AdminMembersTable';
import PageLoader from '../../components/PageLoader';
import AnimatedRedirect from '../../components/AnimatedRedirect';

export const AdminClientMembers: React.FC<{}> = () => {
  const { clientID, streamID } = useParams<{ clientID: string; streamID: string }>();
  const { data: membersData, status: membersDataLoadingStatus } = useAdminMembersData(clientID, streamID);

  const createFilterdData = () => {
    const filteredData = membersData.members.map(member => ({
      // * Столбцы
      time: member.interview_time,
      date: member.interview_date,
      status: member.interview_status,
      full_name: member.full_name,
      tribe: member.tribe,
      role: member.role,
      case: member.segment,
      call_type: member.call_method,
      // * Дополнительные данные
      call_link: member.call_link,
      actions: member.id,
      block_user: member.block_user,
      interview_slot_id: member.slot_id,
    }));

    return filteredData;
  };

  if (membersDataLoadingStatus === 'loading') {
    return <PageLoader />;
  }

  if (membersDataLoadingStatus === 'error') {
    return <AnimatedRedirect redirectTo='/500/' />;
  }

  return (
    <motion.main className='adminClientMembers' variants={standardPageVariants} initial='initial' animate='animate' exit='exit'>
      <Title>Участники</Title>
      <AdminMembersTable filteredData={createFilterdData()} />
    </motion.main>
  );
};
