import './ScheduleTabs.scss';


import { format, add } from 'date-fns';
import { ru } from 'date-fns/locale';
import { useDispatch } from 'react-redux';
import { schedule_change_selected_dates } from '../../actions';
import { queryCache } from 'react-query';

interface ScheduleTabs {
  start?: Date;
  end?: Date;
  selectedStartDate?: Date;
  selectedEndDate?: Date;
}

export const ScheduleTabs: React.FC<ScheduleTabs> = ({ start, end, selectedStartDate, selectedEndDate }) => {
  const dispatch = useDispatch();

  return (
    <div className='scheduleTabs'>
      {start && end && selectedStartDate && (
        <>
          <div
            className={`scheduleTab
              ${start.toString() === selectedStartDate.toString() ? ' active' : ''}`}
            onClick={() => {
              dispatch(schedule_change_selected_dates(start, end));
              queryCache.invalidateQueries('timeTableData');
            }}
          >
            {format(start, 'd LLL', { locale: ru }).slice(0, -1)} - {format(end, 'd LLL', { locale: ru }).slice(0, -1)}
          </div>

          <div
            className={`scheduleTab
              ${add(start, { days: 7 }).toString() === selectedStartDate.toString() ? ' active' : ''}`}
            onClick={() => {
              dispatch(schedule_change_selected_dates(add(start, { days: 7 }), add(end, { days: 7 })));
              queryCache.invalidateQueries('timeTableData');
            }}
          >
            {format(add(start, { days: 7 }), 'd LLL', { locale: ru }).slice(0, -1)} - {format(add(end, { days: 7 }), 'd LLL', { locale: ru }).slice(0, -1)}
          </div>
          <div
            className={`scheduleTab
              ${add(start, { days: 14 }).toString() === selectedStartDate.toString() ? ' active' : ''}`}
            onClick={() => {
              dispatch(schedule_change_selected_dates(add(start, { days: 14 }), add(end, { days: 14 })));
              queryCache.invalidateQueries('timeTableData');
            }}
          >
            {format(add(start, { days: 14 }), 'd LLL', { locale: ru }).slice(0, -1)} - {format(add(end, { days: 14 }), 'd LLL', { locale: ru }).slice(0, -1)}
          </div>
          <div
            className={`scheduleTab
              ${add(start, { days: 21 }).toString() === selectedStartDate.toString() ? ' active' : ''}`}
            onClick={() => {
              dispatch(schedule_change_selected_dates(add(start, { days: 21 }), add(end, { days: 21 })));
              queryCache.invalidateQueries('timeTableData');
            }}
          >
            {format(add(start, { days: 21 }), 'd LLL', { locale: ru }).slice(0, -1)} - {format(add(end, { days: 21 }), 'd LLL', { locale: ru }).slice(0, -1)}
          </div>
          <div
            className={`scheduleTab
              ${add(start, { days: 28 }).toString() === selectedStartDate.toString() ? ' active' : ''}`}
            onClick={() => {
              dispatch(schedule_change_selected_dates(add(start, { days: 28 }), add(end, { days: 28 })));
              queryCache.invalidateQueries('timeTableData');
            }}
          >
            {format(add(start, { days: 28 }), 'd LLL', { locale: ru }).slice(0, -1)} - {format(add(end, { days: 28 }), 'd LLL', { locale: ru }).slice(0, -1)}
          </div>
        </>
      )}
      {/* <div className='scheduleTab selectDiffrent'>выбрать другие даты</div> */}
    </div>
  );
};
