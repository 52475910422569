import { useEffect, forwardRef, useRef } from 'react';


const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <label className={`checkbox${rest.checked === true ? ' selected' : ''}`}>
        <input type='checkbox' ref={resolvedRef} {...rest} />
      </label>
    </>
  );
});

export default IndeterminateCheckbox;
