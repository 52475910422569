import { backendURL, debug } from '../App';

import axios from 'axios';
import consoleLog from '../helpers/consoleLog';

interface Props {
  reportID: number;
}

const api_reportCancel = async ({ reportID }: Props) => {
  try {
    const { data } = await axios({
      method: 'post',
      url: `${backendURL}reports/cancel/`,
      headers: {
        Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
      },
      data: {
        report_id: reportID,
      },
    });
    debug && consoleLog('success', 'Report cancel', data);
  } catch (error) {
    debug && consoleLog('error', 'Report cancel', error);
  }
};

export default api_reportCancel;
