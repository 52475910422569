import './QuestionForm.scss';
import 'react-quill/dist/quill.snow.css';

import * as FormData from 'form-data';
import * as Yup from 'yup';

import { FieldArray, Form, Formik } from 'formik';
import { backendURL, debug } from '../../../../App';
import { useHistory, useParams } from 'react-router-dom';

import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import InputError from '../../../../components/InputError';
import InputSelect from '../../../../components/InputSelect';
import SubTitle from '../../SubTitle';
import TextareaWYSIWYG from '../../../../components/TextareaWYSIWYG';
import __ from 'lodash';
import axios from 'axios';
import consoleLog from '../../../../helpers/consoleLog';
import icon_closeBlue from '../../../../assets/images/icon_closeBlue.svg';
import icon_plus from '../../../../assets/images/icon_plus.svg';
import { queryCache } from 'react-query';
import useIndicatorsData from '../../../../hooks/useIndicatorsData';

interface Props {
  questionData: {
    id: number;
    type: number;
    text: string;
    description: string;
    question_text: string;
    help_text: string;
    help_text_0: string;
    help_text_1: string;
    help_text_2: string;
    help_text_3: string;
    file: string;
    image: string;
    video: string;
    order: number;
    uploadingFile: File;
    indicators: any;
    subquestions: any;
  };
  questionsCount: number;
}

export const QuestionForm: React.FC<Props> = ({ questionData, questionsCount }) => {
  const history = useHistory();
  const { clientID, anketaID, questionIndex } = useParams<{ clientID: string; anketaID: string; questionIndex: string }>();
  const { data: indicatorsData, status: indicatorsDataLoadingStatus } = useIndicatorsData(anketaID, undefined);
  const indicatorsOptions = () => {
    let options: object[] = [];

    // * Выкидываем из массива уже использованные индикаторы
    indicatorsData &&
      indicatorsData.map(indicator => {
        if (!questionData?.indicators.find(item => indicator.title === item.title)) {
          options.push({ value: indicator.id, label: indicator.title });
        }
      });
    console.log(options);
    return options;
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        type: questionData?.type || '0',
        text: questionData?.text || '',
        question_text: questionData?.question_text || '',
        help_text: questionData?.help_text || '',
        help_text_0: questionData?.help_text_0 || '',
        help_text_1: questionData?.help_text_1 || '',
        help_text_2: questionData?.help_text_2 || '',
        help_text_3: questionData?.help_text_3 || '',
        file: questionData?.file || '',
        image: questionData?.image || '',
        video: questionData?.video || '',
        order: questionData?.order ? questionIndex : questionsCount + 1,
        uploadingFile: questionData?.uploadingFile || '',
        anketa_id: anketaID || '',
        indicators: questionData?.indicators?.length > 0 ? questionData?.indicators : [{ id: '', title: '' }],
        subquestions:
          questionData?.subquestions?.length > 0
            ? questionData?.subquestions
            : [{ real_weight: 0, text: '', sq_type: '0', order: '1', variants: [{ text: '', weight: 0 }] }],
      }}
      validationSchema={Yup.object({
        text: Yup.string().required('поле должно быть заполнено'),
      })}
      onSubmit={async (values, { setSubmitting, setStatus }) => {
        let formData = new FormData();

        Object.entries(values).forEach(([key, value]) => {
          if (key === 'uploadingFile' && value !== '') {
            // @ts-ignore
            if (value.type?.indexOf('image') !== -1) {
              formData.append('image', value);
            }
            // @ts-ignore
            else if (value.type?.indexOf('video') !== -1) {
              formData.append('video', value);
            }
            // @ts-ignore
            else if (value) {
              formData.append('file', value);
            }
          } else if (key === 'indicators') {
            formData.append(key, JSON.stringify(value));
          } else if (key === 'subquestions') {
            console.log('type', values.type === 1);
            +values.type === 1 && formData.append(key, JSON.stringify(value));
          } else {
            formData.append(key, value);
          }
        });

        try {
          const { data } = await axios({
            method: 'post',
            url: questionData?.id ? `${backendURL}question/${questionData?.id}/` : `${backendURL}questions/`,
            headers: {
              Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
              'Content-Type': 'multipart/form-data',
            },
            data: formData,
          });
          setSubmitting(false);
          if (!questionData) {
            setStatus({ success: 'Вопрос создан успешно. Вы будуте пренаправлены на страницу вопроса через 2 секунды.' });
            setTimeout(() => {
              queryCache.invalidateQueries(['anketaQuestionsData', anketaID]);
              history.push(`/admin/${clientID}/templates/${anketaID}/questions/${questionsCount + 1}/`);
              window.document.querySelector('.adminTemplateForm')?.scrollIntoView({ behavior: 'smooth' });
              setStatus({ success: '' });
            }, 2000);
          } else {
            setStatus({ success: 'Сохранено успешно.' });
            setTimeout(() => {
              queryCache.invalidateQueries(['anketaQuestionsData', anketaID]);
              setStatus({ success: '' });
            }, 2000);
          }
          debug && consoleLog('success', 'Question save', data);
        } catch (error) {
          setSubmitting(false);
          setStatus({ error: 'Упс. Что-то пошло не так.(' });
          setTimeout(() => {
            setStatus({ error: '' });
          }, 3000);
          debug && consoleLog('error', 'Question save', error);
        }
      }}
    >
      {props => (
        <Form>
          {console.log(props)}
          <div className='questionTypeFieldWrapper'>
            <button className={`quertionTypeButton ${+props.values.type === 0 ? 'active' : ''}`} onClick={() => props.setFieldValue('type', '0')} type='button'>
              Открытый вопрос
            </button>
            <button className={`quertionTypeButton ${+props.values.type === 1 ? 'active' : ''}`} onClick={() => props.setFieldValue('type', '1')} type='button'>
              Закрытый вопрос
            </button>
          </div>

          <div className='indicatorsFieldsWrapper'>
            <FieldArray name='indicators'>
              {({ push, remove }) =>
                props.values.indicators.map((_, index) => (
                  <div className='indicator'>
                    <div className='indicatorWrapper'>
                      {!props.values.indicators[index].title ? (
                        <InputSelect
                          name={`indicators[${index}].title`}
                          options={indicatorsOptions()}
                          value={props.values.indicators[index].title}
                          placeholderType='alwaysVisible'
                          placeholder='Индикатор'
                          onChange={option => {
                            props.setFieldValue(`indicators[${index}].title`, option.label);
                            props.setFieldValue(`indicators[${index}].id`, option.value);
                          }}
                          onBlur={() => {
                            props.setFieldTouched(`indicators[${index}].title`, true);
                          }}
                          size='small'
                        />
                      ) : (
                        <Input
                          name={`indicators[${index}].title`}
                          type='text'
                          value={props.values.indicators[index].title}
                          onChange={event => props.setFieldValue(`indicators[${index}].title`, event.target.value)}
                          onBlur={() => props.setFieldTouched(`indicators[${index}].title`, true)}
                          placeholderType='alwaysVisible'
                          placeholder='Индикатор'
                          isDisabled={true}
                          isValid={true}
                          size='small'
                        />
                      )}
                    </div>
                    <div className='addRemoveButtons'>
                      {!(props.values.indicators.length === 1 && index === 0) && (
                        <Button type='button' buttonType='button' width='50rem' size='medium' color='white' onClick={() => remove(index)} className='whiteButton'>
                          <img src={icon_closeBlue} alt='icon' className='iconRemove' />
                        </Button>
                      )}
                      {index + 1 === props.values.indicators.length && (
                        <Button type='button' buttonType='button' width='50rem' size='medium' color='blue' onClick={() => push({ title: '', key: '' })}>
                          <img src={icon_plus} alt='icon' className='iconAdd' />
                        </Button>
                      )}
                    </div>
                  </div>
                ))
              }
            </FieldArray>
          </div>

          {+props.values.type === 0 ? <SubTitle>Описание вопроса</SubTitle> : <SubTitle>Список вопросов</SubTitle>}

          {/* // * Название вопроса / блока вопросов */}
          <Input
            name='text'
            type='text'
            value={props.values.text}
            onChange={event => props.setFieldValue('text', event.target.value)}
            onBlur={() => props.setFieldTouched('text', true)}
            placeholderType='alwaysVisible'
            placeholder={+props.values.type === 0 ? 'Название вопроса' : 'Название блока'}
            isDisabled={props.isSubmitting}
            isValid={!(props.errors.text && props.touched.text)}
            validationError={props.errors.text && props.touched.text ? props.errors.text : undefined}
            autoComplete='off'
          />

          {/* // * Открытый вопрос */}
          {+props.values.type === 0 && (
            <>
              <TextareaWYSIWYG
                value={props.values.question_text}
                onChange={value => props.setFieldValue('question_text', value.replace(/(<([^>]+)>)/gi, '') ? value : '')}
                onBlur={() => props.setFieldTouched('question_text', true)}
                placeholderType='alwaysVisible'
                placeholder='Вопрос'
                isValid={!(props.errors.question_text && props.touched.question_text)}
                validationError={props.errors.question_text && props.touched.question_text ? props.errors.question_text : undefined}
                modulesType='defaultWithLink'
              />

              <div className='helpFieldsContainer'>
                <div className='left'>
                  <TextareaWYSIWYG
                    value={props.values.help_text}
                    onChange={value => props.setFieldValue('help_text', value.replace(/(<([^>]+)>)/gi, '') ? value : '')}
                    onBlur={() => props.setFieldTouched('help_text', true)}
                    placeholderType='alwaysVisible'
                    placeholder='Инструкция для оценки'
                    isValid={!(props.errors.help_text && props.touched.help_text)}
                    validationError={props.errors.help_text && props.touched.help_text ? props.errors.help_text : undefined}
                    modulesType='default'
                  />
                </div>
                <div className='right'>
                  <TextareaWYSIWYG
                    value={props.values.help_text_0}
                    onChange={value => props.setFieldValue('help_text_0', value.replace(/(<([^>]+)>)/gi, '') ? value : '')}
                    onBlur={() => props.setFieldTouched('help_text_0', true)}
                    placeholderType='alwaysVisible'
                    placeholder='Подсказка к оценке 0'
                    modulesType='noModules'
                  />
                  <TextareaWYSIWYG
                    value={props.values.help_text_1}
                    onChange={value => props.setFieldValue('help_text_1', value.replace(/(<([^>]+)>)/gi, '') ? value : '')}
                    onBlur={() => props.setFieldTouched('help_text_1', true)}
                    placeholderType='alwaysVisible'
                    placeholder='Подсказка к оценке 1'
                    modulesType='noModules'
                  />
                  <TextareaWYSIWYG
                    value={props.values.help_text_2}
                    onChange={value => props.setFieldValue('help_text_2', value.replace(/(<([^>]+)>)/gi, '') ? value : '')}
                    onBlur={() => props.setFieldTouched('help_text_2', true)}
                    placeholderType='alwaysVisible'
                    placeholder='Подсказка к оценке 2'
                    modulesType='noModules'
                  />
                  <TextareaWYSIWYG
                    value={props.values.help_text_3}
                    onChange={value => props.setFieldValue('help_text_3', value.replace(/(<([^>]+)>)/gi, '') ? value : '')}
                    onBlur={() => props.setFieldTouched('help_text_3', true)}
                    placeholderType='alwaysVisible'
                    placeholder='Подсказка к оценке 3'
                    modulesType='noModules'
                  />
                </div>
              </div>
              <div className='fileFieldContainer'>
                <div className='fileFieldInfo'>
                  {props.values.file && !props.values.uploadingFile ? (
                    <a href={props.values.file} target='_blank' rel='noopener noreferrer' className='link'>
                      {props.values.file}
                    </a>
                  ) : (
                    ''
                  )}
                  {props.values.image && !props.values.uploadingFile ? (
                    <a href={props.values.image} target='_blank' rel='noopener noreferrer' className='link'>
                      {props.values.image}
                    </a>
                  ) : (
                    ''
                  )}
                  {props.values.video && !props.values.uploadingFile ? (
                    <a href={props.values.video} target='_blank' rel='noopener noreferrer' className='link'>
                      {props.values.video}
                    </a>
                  ) : (
                    ''
                  )}
                  {!props.values.file &&
                    !props.values.image &&
                    !props.values.video &&
                    !props.values.uploadingFile &&
                    'Вы можете загрузить фотоматериалы или другой контент'}
                  {/* // @ts-ignore */}
                  {props.values.uploadingFile && props.values.uploadingFile.name}
                </div>
                {consoleLog('info', 'questionData', questionData)}
                <input
                  type='file'
                  name='uploadingFile'
                  id='uploadingFile'
                  onChange={event => {
                    props.setFieldValue('uploadingFile', event.target.files![0]);
                    props.setFieldValue('file', '');
                    props.setFieldValue('image', '');
                    props.setFieldValue('video', '');
                  }}
                />
                <Button type='button' buttonType='button' width='230rem' size='small' color='black'>
                  <label htmlFor='uploadingFile'>Загрузить файл</label>
                </Button>
              </div>
            </>
          )}

          {/* // * Закрытый вопрос */}
          {+props.values.type === 1 && (
            <FieldArray name='subquestions'>
              {({ push, remove }) =>
                props.values.subquestions.map((_, index) => (
                  <div className='subquetionFieldsWrapper'>
                    <TextareaWYSIWYG
                      value={props.values.subquestions[index].text}
                      onChange={value => props.setFieldValue(`subquestions[${index}].text`, value.replace(/(<([^>]+)>)/gi, '') ? value : '')}
                      onBlur={() => props.setFieldTouched(`subquestions[${index}].text`, true)}
                      placeholderType='alwaysVisible'
                      placeholder={`Вопрос ${index + 1}`}
                      isValid={true}
                      validationError={undefined}
                      modulesType='defaultWithLink'
                    />
                    <div className='subquestionTypeField'>
                      <div className='subquestionTypeLabel'>Тип ответов</div>
                      <button
                        type='button'
                        className={`subquestionTypeButton ${+props.values.subquestions[index].sq_type === 0 ? 'active' : ''}`}
                        onClick={() => props.setFieldValue(`subquestions.${index}.sq_type`, 0)}
                      >
                        Радиобаттон
                      </button>
                      <button
                        type='button'
                        className={`subquestionTypeButton ${+props.values.subquestions[index].sq_type === 1 ? 'active' : ''}`}
                        onClick={() => props.setFieldValue(`subquestions.${index}.sq_type`, 1)}
                      >
                        Чекбокс
                      </button>
                      <button
                        type='button'
                        className={`subquestionTypeButton ${+props.values.subquestions[index].sq_type === 2 ? 'active' : ''}`}
                        onClick={() => props.setFieldValue(`subquestions.${index}.sq_type`, 2)}
                      >
                        Драг и дроп
                      </button>
                    </div>
                     
                    <FieldArray name={`subquestions.${index}.variants`}>
                      {({ push: pushVariant, remove: removeVariant }) =>
                        props.values.subquestions[index].variants.map((_, variantIndex) => (
                          <>
                            <Input
                              name={`subquestions.${index}.variants.${variantIndex}.text`}
                              type='text'
                              value={props.values.subquestions[index].variants[variantIndex].text}
                              onChange={event => props.setFieldValue(`subquestions.${index}.variants.${variantIndex}.text`, event.target.value)}
                              onBlur={() => props.setFieldTouched(`subquestions.${index}.variants.${variantIndex}.text`, true)}
                              placeholderType='alwaysVisible'
                              placeholder={`Ответ ${variantIndex + 1}`}
                              isDisabled={false}
                              isValid={true}
                              size='small'
                            />
                            <div className={'variantWeightField ' + (props.values.subquestions[index].sq_type === 2 ? 'marginTop' : '')}>
                              {props.values.subquestions[index].sq_type === 2 ? null : (
                                <>
                                  <button
                                    type='button'
                                    onClick={() => props.setFieldValue(`subquestions.${index}.variants.${variantIndex}.weight`, 0)}
                                    className={`variantWeightFieldButton ${props.values.subquestions[index].variants[variantIndex].weight === 0 ? 'active' : ''}`}
                                  >
                                    НЕВЕРНЫЙ ОТВЕТ
                                  </button>
                                  <button
                                    type='button'
                                    onClick={() => props.setFieldValue(`subquestions.${index}.variants.${variantIndex}.weight`, 1)}
                                    className={`variantWeightFieldButton ${props.values.subquestions[index].variants[variantIndex].weight === 1 ? 'active' : ''}`}
                                  >
                                    ВЕРНЫЙ ОТВЕТ
                                  </button>
                                </>
                              )}
                              <div className={'addRemoveButtons ' + (props.values.subquestions[index].sq_type === 2 ? '-mt-40' : '')}>
                                {!(props.values.subquestions[index].variants.length === 1 && variantIndex === 0) && (
                                  <Button
                                    type='button'
                                    buttonType='button'
                                    width='230rem'
                                    size='small'
                                    color='black'
                                    onClick={() => removeVariant(variantIndex)}
                                    text='Удалить ответ'
                                  />
                                )}
                                {variantIndex + 1 === props.values.subquestions[index].variants.length && (
                                  <Button
                                    type='button'
                                    buttonType='button'
                                    width='230rem'
                                    size='small'
                                    color='black'
                                    onClick={() => pushVariant({ text: '', weight: 0 })}
                                    text='Добавить ответ'
                                  />
                                )}
                              </div>
                            </div>
                          </>
                        ))
                      }
                    </FieldArray>
                    <div className='addRemoveButtons'>
                      {!(props.values.subquestions.length === 1 && index === 0) && (
                        <Button type='button' buttonType='button' width='230rem' size='medium' color='blue' onClick={() => remove(index)} text='Удалить вопрос' />
                      )}
                      {index + 1 === props.values.subquestions.length && (
                        <Button
                          type='button'
                          buttonType='button'
                          width='230rem'
                          size='medium'
                          color='blue'
                          onClick={() =>
                            push({ real_weight: 0, text: '', sq_type: '0', order: `${props.values.subquestions.length + 1}`, variants: [{ text: '', weight: 0 }] })
                          }
                          text='Добавить вопрос'
                        />
                      )}
                    </div>
                  </div>
                ))
              }
            </FieldArray>
          )}

          <InputError className={`${props.status?.success ? 'success' : ''}`}>{props.status?.success || props.status?.error || ''}</InputError>
          <Button
            type='button'
            buttonType='submit'
            width='330rem'
            size='medium'
            color='blue'
            className='submitButton'
            text='Сохранить'
            loading={props.isSubmitting === true}
          />

          <Button
            type='button'
            onClick={() => {
              history.push(`/admin/${clientID}/templates/${anketaID}/questions/`);
              window.document.querySelector('.adminTemplateForm')?.scrollIntoView({ behavior: 'smooth' });
            }}
            width='330rem'
            size='medium'
            color='black'
            className='newQuestion'
            text='Новый вопрос'
          />
          <Button
            type='link'
            href={`/admin/${clientID}/templates/${anketaID}/recommendations/`}
            width='330rem'
            size='medium'
            color='blue'
            className='next'
            text='К рекомендациям'
          />
        </Form>
      )}
    </Formik>
  );
};
