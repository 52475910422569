import icon_clipboard from '../../../assets/images/icon_clipboard.svg';
import './styles.scss';

import Modal from '../../Modal';
import Title from '../../Title';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { format, utcToZonedTime } from 'date-fns-tz';

interface Props {
  expertName: string;
  timeSlot: Date;
  interviewType: string;
  zoom?: string;
  skype?: string;
  onClose: () => void;
}

export const AdminAssignedInterviewModal: React.FC<Props> = ({ onClose, expertName, timeSlot, interviewType, zoom, skype }) => {
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);

  const copiedAnimationVariants = {
    coppied: { scale: 1.3 },
    default: { scale: 1 },
  };

  const copyToClipboard = str => {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setCopiedToClipboard(true);
    setTimeout(() => {
      setCopiedToClipboard(false);
    }, 500);
  };

  return (
    <Modal className='adminAssignedInterviewModal' onClose={onClose}>
      <Title>Интервью успешно создано!</Title>
      <div className='text'>
        <b>Эксперт</b>: {expertName}
      </div>
      <div className='text'>
        <b>Дата</b>: {format(utcToZonedTime(new Date(timeSlot), 'Europe/Moscow'), 'yyyy-MM-dd в HH:mm')}
      </div>
      <div className='text'>
        <b>Тип</b>: {interviewType}
      </div>

      {interviewType === 'zoom' ? (
        <div className='text'>
          <b>Ссылка на zoom участника</b>:
          <div className='copyToClipboardContainer'>
            <a className='copyToClipboardLink' href={zoom} target='_blank' rel='noopener noreferrer'>
              {zoom}
            </a>
            <motion.img
              src={icon_clipboard}
              onClick={() => copyToClipboard(zoom)}
              className='toClipboardIcon'
              animate={copiedToClipboard ? 'coppied' : 'default'}
              variants={copiedAnimationVariants}
            />
            {copiedToClipboard && <div className='copiedToClipboard'>Скопировано</div>}
          </div>
        </div>
      ) : (
        <div className='text'>
          <div className='copyToClipboardContainer'>
            <div className='copyToClipboardText'>
              <b>Skype участника</b>: {skype}
            </div>
            <motion.img
              src={icon_clipboard}
              onClick={() => copyToClipboard({ skype })}
              className='toClipboardIcon'
              animate={copiedToClipboard ? 'coppied' : 'default'}
              variants={copiedAnimationVariants}
            />
            {copiedToClipboard && <div className='copiedToClipboard'>Скопировано</div>}
          </div>
        </div>
      )}
    </Modal>
  );
};
