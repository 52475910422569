import { useQuery } from 'react-query';
import axios from 'axios';
import { backendURL } from '../App';

const getCompetensionsData = async (_, anketaID) => {
  if (anketaID) {
    const { data } = await axios({
      method: 'get',
      url: `${backendURL}competentions/`,
      headers: {
        Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
      },
      params: {
        anketa_id: anketaID,
      },
    });
    return data;
  }
  return null;
};

export default function useCompetensionsData(anketaID) {
  return useQuery(['competensionsData', anketaID], getCompetensionsData, { retry: false, refetchOnWindowFocus: false });
}
