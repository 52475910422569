import './CompetentionsLevels.scss';
import 'react-circular-progressbar/dist/styles.css';

import _ from 'lodash';
import { reportColors } from '../../../helpers/colorPalete';

interface Role {
  key: {
    junior: { percent: number; count: number; man: string };
    middle: { percent: number; count: number; man: string };
    senior: { percent: number; count: number; man: string };
  };
}

interface Props {
  data: {
    streams_count: number;
    client_color: string;
    shifted_hex_color: string;
    table_1: Role[] | Role;
  };
}

export const CompetentionsLevels: React.FC<Props> = ({ data }) => {
  const { firstStreamColor, firstStreamColorWithOpacity, secondStreamColor, secondStreamColorWithOpacity } = reportColors(data.client_color, data.streams_count);

  return (
    <section
      // @ts-ignore
      className={`competentionsLevels ${_.size(data?.table_1[1]) === 1 || _.size(data?.table_1) === 1 ? 'double' : ''}
                                      ${_.size(data?.table_1[1]) === 2 || _.size(data?.table_1) === 2 ? 'double' : ''}
                                      ${_.size(data?.table_1[1]) === 3 || _.size(data?.table_1) === 3 ? 'triple' : ''}           
                                      ${_.size(data?.table_1[1]) === 4 || _.size(data?.table_1) === 4 ? 'quadra' : ''}`}
      data-cy='competitionsLevelBlock'
    >
      <div className='results'>
        <div className='resultsTitle'>Результат, %</div>
        <div className='result'>
          <div className='level'>Senior</div>
          <div className='value'>{'>'} 80%</div>
        </div>
        <div className='result'>
          <div className='level'>Middle</div>
          <div className='value'>50% - 80%</div>
        </div>
        <div className='result'>
          <div className='level'>Junior</div>
          <div className='value'>0% - 50%</div>
        </div>
      </div>

      {data.streams_count === 2 &&
        Object.entries(data.table_1[1] as Role).map(([key, value]) => (
          <div className='position' key={_.uniqueId()}>
            <div className='positionTitle'>{key}</div>
            <div className='positionNumbers twoStreams'>
              {/* // * secondStream seniors */}
              <div className='positionLineWrapper' style={{ backgroundColor: `rgba(${data.client_color}, 0.2)` }}>
                <div className='positionLine' style={{ width: `${value.senior.percent}%`, backgroundColor: `rgb(${data.client_color})` }}></div>
                <div className={`positionLinePercent${value.senior.percent >= 6 && value.senior.percent <= 11 ? ' black' : ''}`}>{value.senior.percent}%</div>
              </div>
              <div className='positionNumber'>{`${value.senior.count} ${value.senior.man}`}</div>
              {/* // * firstStream seniors */}
              <div className='positionLineWrapper' style={{ backgroundColor: `rgba(${data.shifted_hex_color}, 0.2)` }}>
                <div className='positionLine' style={{ width: `${data.table_1[0][key].senior.percent}%`, backgroundColor: `rgb(${data.shifted_hex_color})` }}></div>
                <div className={`positionLinePercent${data.table_1[0][key].senior.percent >= 6 && data.table_1[0][key].senior.percent <= 11 ? ' black' : ''}`}>
                  {data.table_1[0][key].senior.percent}%
                </div>
              </div>
              <div className='positionNumber'>{`${data.table_1[0][key].senior.count} ${data.table_1[0][key].senior.man}`}</div>
            </div>
            <div className='positionNumbers twoStreams'>
              {/* // * secondStream middle */}
              <div className='positionLineWrapper' style={{ backgroundColor: `rgba(${data.client_color}, 0.2)` }}>
                <div className='positionLine' style={{ width: `${value.middle.percent}%`, backgroundColor: `rgb(${data.client_color})` }}></div>
                <div className={`positionLinePercent${value.middle.percent >= 6 && value.middle.percent <= 11 ? ' black' : ''}`}>{value.middle.percent}%</div>
              </div>
              <div className='positionNumber'>{`${value.middle.count} ${value.middle.man}`}</div>
              {/* // * firstStream middle  */}
              <div className='positionLineWrapper' style={{ backgroundColor: `rgba(${data.shifted_hex_color}, 0.2)` }}>
                <div className='positionLine' style={{ width: `${data.table_1[0][key].middle.percent}%`, backgroundColor: `rgb(${data.shifted_hex_color})` }}></div>
                <div className={`positionLinePercent${data.table_1[0][key].middle.percent >= 6 && data.table_1[0][key].middle.percent <= 11 ? ' black' : ''}`}>
                  {data.table_1[0][key].middle.percent}%
                </div>
              </div>
              <div className='positionNumber'>{`${data.table_1[0][key].middle.count} ${data.table_1[0][key].middle.man}`}</div>
            </div>
            <div className='positionNumbers twoStreams'>
              {/* // * secondStream juniors */}
              <div className='positionLineWrapper' style={{ backgroundColor: `rgba(${data.client_color}, 0.2)` }}>
                <div className='positionLine' style={{ width: `${value.junior.percent}%`, backgroundColor: `rgb(${data.client_color})` }}></div>
                <div className={`positionLinePercent${value.junior.percent >= 6 && value.junior.percent <= 11 ? ' black' : ''}`}>{value.junior.percent}%</div>
              </div>
              <div className='positionNumber'>{`${value.junior.count} ${value.junior.man}`}</div>
              {/* // * firstStream juniors */}
              <div className='positionLineWrapper' style={{ backgroundColor: `rgba(${data.shifted_hex_color}, 0.2)` }}>
                <div className='positionLine' style={{ width: `${data.table_1[0][key].junior.percent}%`, backgroundColor: `rgb(${data.shifted_hex_color})` }}></div>
                <div className={`positionLinePercent${data.table_1[0][key].junior.percent >= 6 && data.table_1[0][key].junior.percent <= 11 ? ' black' : ''}`}>
                  {data.table_1[0][key].junior.percent}%
                </div>
              </div>
              <div className='positionNumber'>{`${data.table_1[0][key].junior.count} ${data.table_1[0][key].junior.man}`}</div>
            </div>
          </div>
        ))}

      {data.streams_count === 1 &&
        Object.entries(data.table_1).map(([key, value]) => (
          <div className='position' key={_.uniqueId()}>
            <div className='positionTitle'>{key}</div>
            <div className='positionNumbers'>
              <div className='positionLineWrapper' style={{ backgroundColor: `rgba(0, 121, 193, 0.1)` }}>
                <div className='positionLine' style={{ width: `${value.senior.percent}%`, backgroundColor: firstStreamColor }}></div>
                <div
                  className={`positionLinePercent${value.senior.percent >= 6 && value.senior.percent <= 11 ? ' black' : ''}${value.senior.percent === 0 ? ' black' : ''}`}
                >
                  {value.senior.percent}%
                </div>
              </div>

              <div className='positionNumber'>{`${value.senior.count} ${value.senior.man}`}</div>
            </div>
            <div className='positionNumbers'>
              <div className='positionLineWrapper' style={{ backgroundColor: `rgba(0, 121, 193, 0.1)` }}>
                <div className='positionLine' style={{ width: `${value.middle.percent}%`, backgroundColor: firstStreamColor }}></div>
                <div
                  className={`positionLinePercent${value.middle.percent >= 6 && value.middle.percent <= 11 ? ' black' : ''}${value.middle.percent === 0 ? ' black' : ''}`}
                >
                  {value.middle.percent}%
                </div>
              </div>

              <div className='positionNumber'>{`${value.middle.count} ${value.middle.man}`}</div>
            </div>
            <div className='positionNumbers'>
              <div className='positionLineWrapper' style={{ backgroundColor: `rgba(0, 121, 193, 0.1)` }}>
                <div className='positionLine' style={{ width: `${value.junior.percent}%`, backgroundColor: firstStreamColor }}></div>
                <div
                  className={`positionLinePercent${value.junior.percent >= 6 && value.junior.percent <= 11 ? ' black' : ''}${value.junior.percent === 0 ? ' black' : ''}`}
                >
                  {value.junior.percent}%
                </div>
              </div>
              <div className='positionNumber'>{`${value.junior.count} ${value.junior.man}`}</div>
            </div>
          </div>
        ))}
    </section>
  );
};
