import './NoInterviewModal.scss';



import Title from '../../../components/Title';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';

interface Props {
  onClose: () => void;
}

export const NoInterviewModal: React.FC<Props> = ({ onClose }) => {
  return (
    <Modal className='noInterviewModal' onClose={onClose}>
      <Title>Вы прошли анкетирование</Title>
      <div className='text'>
        Ваша анкета будет просмотрена экспертом. <br /> После ее проверки результат анкетирования будет отправлен координирующему лицу.
      </div>
      <Button
        type='button'
        onClick={() => {
          onClose();
        }}
        width='260rem'
        size='small'
        color='black'
        text='Понятно'
      />
    </Modal>
  );
};
