import './styles.scss';

import { motion } from 'framer-motion';
import { modalWrapperVariants } from '../../animationsVariants/ModalVariants';

interface ModalProps {
  children: any;
  className?: string;
  dataCy?: string;
  onClose?: (e: React.MouseEvent) => void;
}

export const Modal: React.FC<ModalProps> = ({ children, className, dataCy, onClose }) => (
  <motion.div className='modalWrapper' variants={modalWrapperVariants} initial='initial' animate='animate' exit='exit'>
    <div className={`modal ${className || ''}`} data-cy={dataCy}>
      <div className='close' onClick={onClose}></div>
      {children}
    </div>
  </motion.div>
);
