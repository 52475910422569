import './ExpertRespondents.scss';

import { useEffect, useState } from 'react';

import { motion } from 'framer-motion';
import { useLocation, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import useAnketasAnswersData from '../../hooks/useAnketasAnswersData';

import Title from '../../components/Title/';
import PageLoader from '../../components/PageLoader';
import AnimatedRedirect from '../../components/AnimatedRedirect';
import { AnketsDashboard } from '../../components/AnketsDashboard/AnketsDashboard';
import { RespondentsTabs } from '../../components/RespondentsTabs/RespondentsTabs';
import { RespondentsDaysTabs } from '../../components/RespondentsDaysTabs/RespondentsDaysTabs';
import { RespondentsDasboardStatus } from '../../components/RespondentsDasboard/RespondentsDasboardStatus';
import { standardPageVariants } from '../../animationsVariants/StandardPageVariants';
import { RespondentsDasboard } from '../../components/RespondentsDasboard/RespondentsDasboard';
import { AnketaZoomVideoModal } from '../../components/AnketaZoomVideoModal/AnketaZoomVideoModal';

export const ExpertRespondents: React.FC<{}> = () => {
  const location = useLocation();

  const { data: anketasAnswersData, status: anketasAnswersLoadingStatus } = useAnketasAnswersData() as any;
  const [anketasAnswersDataToSend, setAnketasAnswersDataToSend] = useState() as any;
  const { firstWeekStartDate, expertRespondentsActiveDate } = useSelector((state:any) => state.scheduleReducer);
  const { anketa_zoom_video_modal_display_status } = useSelector((state:any) => state.modalsReducer);
  const [zoomModalDataID, setZoomModalDataID] = useState(0);

  useEffect(() => {
    if (anketasAnswersData) {
      switch (location.pathname) {
        case '/expert/respondents/':
          setAnketasAnswersDataToSend({
            withInterview: anketasAnswersData?.withInterview?.all,
            withoutInterview: anketasAnswersData?.withoutInterview?.all,
          });
          break;
        case '/expert/respondents/without_evaluation/':
          setAnketasAnswersDataToSend({
            withInterview: anketasAnswersData?.withInterview?.reviewNotStated,
            withoutInterview: anketasAnswersData?.withoutInterview?.reviewNotStated,
          });
          break;
        case '/expert/respondents/evaluation/':
          setAnketasAnswersDataToSend({
            withInterview: anketasAnswersData?.withInterview?.reviewInProcess,
            withoutInterview: anketasAnswersData?.withoutInterview?.reviewInProcess,
          });
          break;
        case '/expert/respondents/with_evaluation/':
          setAnketasAnswersDataToSend({
            withInterview: anketasAnswersData?.withInterview?.reviewCompleted,
            withoutInterview: anketasAnswersData?.withoutInterview?.reviewCompleted,
          });
          break;
        case '/expert/respondents/finished/':
          setAnketasAnswersDataToSend({
            withInterview: anketasAnswersData?.withInterview?.closed,
            withoutInterview: anketasAnswersData?.withoutInterview?.closed,
          });
      }
    }
  }, [location, anketasAnswersData]);

  if (anketasAnswersLoadingStatus === 'loading' || !anketasAnswersDataToSend) {
    return <PageLoader />;
  }

  if (anketasAnswersLoadingStatus === 'error') {
    return <AnimatedRedirect redirectTo='/500/' />;
  }

  return (
    <motion.main className='expertRespondents' variants={standardPageVariants} initial='initial' animate='animate' exit='exit'>
      <AnketsDashboard anketaAnswersData={anketasAnswersData} />
      <Title>Cписок участников</Title>
      <Link to='/expert/schedule/' className='subTitleLink'>
        Расписание
      </Link>
      <RespondentsTabs />
      {location.pathname === '/expert/respondents/' && (
        <RespondentsDaysTabs startDay={firstWeekStartDate} anketaAnswersData={anketasAnswersDataToSend.withInterview} selectedDay={expertRespondentsActiveDate} />
      )}
      {location.pathname !== '/expert/respondents/' ? (
        <RespondentsDasboardStatus anketaAnswersData={anketasAnswersDataToSend} setZoomModalDataID={setZoomModalDataID} />
      ) : (
        <RespondentsDasboard startDay={firstWeekStartDate} selectedDay={expertRespondentsActiveDate} anketaAnswersData={anketasAnswersDataToSend} />
      )}
      {anketa_zoom_video_modal_display_status && <AnketaZoomVideoModal zoomModalDataID={zoomModalDataID} />}
    </motion.main>
  );
};
