import './AdminClientStream.scss';

import AdminClientLink from '../P_5.2_Admin_Client/AdminClientLink';
import AdminClientReport from '../P_5.2_Admin_Client/AdminClientReport';
import AdminMetrics from '../P_5.2_Admin_Client/AdminClientMetricsSimplified';
import AdminTribesReports from '../P_5.2_Admin_Client/AdminTribesReports';
import AnimatedRedirect from '../../components/AnimatedRedirect';
import PageLoader from '../../components/PageLoader';
import _ from 'lodash';
import { motion } from 'framer-motion';
import { standardPageVariants } from '../../animationsVariants/StandardPageVariants';
import useClientData from '../../hooks/useClientData';
import useClientMetrics from '../../hooks/useClientMetrics';
import { useParams } from 'react-router-dom';

export const AdminClientStream: React.FC<{}> = () => {
  const { clientID, streamID } = useParams<{ clientID: string; streamID: string }>();
  const { data: clientData, status: clientDataLoadingStatus } = useClientData(clientID, localStorage.getItem('skillsSurveyUserToken'), undefined);
  const { data: clientMetricsData, status: clientMetricsDataLoadingStatus } = useClientMetrics(clientID, streamID);

  if (clientDataLoadingStatus === 'loading' || clientMetricsDataLoadingStatus === 'loading') {
    return <PageLoader />;
  }

  if (clientDataLoadingStatus === 'error' || clientMetricsDataLoadingStatus === 'error') {
    return <AnimatedRedirect redirectTo='/500/' />;
  }

  return (
    <motion.main className='adminClientStream' variants={standardPageVariants} initial='initial' animate='animate' exit='exit'>
      <AdminClientLink clientData={clientData} streamData={_.find(clientData.streams, { id: Number(streamID) })} />
      <AdminMetrics clientData={clientData} clientMetricsData={clientMetricsData} streamData={_.find(clientData.streams, { id: Number(streamID) })} />
      <AdminClientReport />
      {_.size(clientMetricsData.streams_data[0]) > 0 && <AdminTribesReports tribesData={clientMetricsData.streams_data[0]} />}
    </motion.main>
  );
};
