import './AnketaTableOfContents.scss';


import { useParams, Link } from 'react-router-dom';

import Title from '../../../components/Title';

interface Props {
  questionList: [{ text: string }];
}

export const AnketaTableOfContents: React.FC<Props> = ({ questionList }) => {
  const { anketaID, anketaAnswersID } = useParams<{ anketaID: string; anketaAnswersID: string }>();

  return (
    <div className='anketaTableOfContents'>
      <Title>Список вопросов</Title>
      <ol>
        {questionList.map((item, index) => (
          <li key={index}>
            <Link to={`/expert/review/anketa/${anketaID}/${anketaAnswersID}/questions/${index + 1}/`} className='link'>
              {item.text}
            </Link>
          </li>
        ))}
      </ol>
    </div>
  );
};
