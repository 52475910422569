import './ScheduleTable.scss';

import AssignNewInterviewModal from './AdminAssigningInterviewModal';
import CancelInterviewModal from './CancelInterviewModal';
import ChangeExpertModal from './ChangeExpertModal';
import ChangeTimeModal from './ChangeTimeModal';
import add from 'date-fns/add';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { useState } from 'react';
import { utcToZonedTime } from 'date-fns-tz/esm';
import DisabledTimeSlotAlertModal from './DisabledTimeSlotAlertModal';
import AdminAssignedInterviewModal from './AdminAssignedInterviewModal';

interface ScheduleTableProps {
  expertID: number;
  expertData: {
    clients: [
      {
        title: string;
        id: number;
      }
    ];
    clients_count: number;
    free_interview_slots: number;
    full_name: string;
    future_interview_count: number;
    interview_pass: number;
    time_slots: [
      {
        status: string;
        time: Date;
      }
    ];
  };
  start?: Date;
}

export const ScheduleTableAdminExperts: React.FC<ScheduleTableProps> = ({ start, expertID, expertData }) => {
  const [isTimeSlotActions, setIsTimeSlotActions] = useState<null | string>(null);
  const [isChangeExpertModal, setIsChangeExpertModal] = useState<null | number>(null);
  const [isChangeTimeModal, setIsChangeTimeModal] = useState<null | number>(null);
  const [isCancelInterviewModal, setIsCancelInterviewModal] = useState<null | number>(null);
  const [isDisabledTimeSlotAlertModal, setIsDisabledTimeSlotAlertModal] = useState<null | { timeSlot: Date }>(null);
  const [isAssignNewInterviewModal, setIsAssignNewInterviewModal] = useState<null | {
    timeSlotID: number | undefined;
    timeSlot: Date;
  }>(null);
  const [isAdminAssignedInterviewModal, setIsAdminAssignedInterviewModal] = useState<null | {
    expertName: string;
    timeSlot: Date;
    interviewType: string;
    zoom?: string;
    skype?: string;
  }>(null);

  return (
    <>
      <div className='scheduleTable'>
        <div className='daysTable'>
          <div className='day'>
            <div className='dayValue'>{start && format(start, 'dd')}</div>
            <div className='dayName'>{start && format(start, 'EEEEEE', { locale: ru })}</div>
          </div>
          <div className='day'>
            <div className='dayValue'>{start && format(add(start, { days: 1 }), 'dd')}</div>
            <div className='dayName'>{start && format(add(start, { days: 1 }), 'EEEEEE', { locale: ru })}</div>
          </div>
          <div className='day'>
            <div className='dayValue'>{start && format(add(start, { days: 2 }), 'dd')}</div>
            <div className='dayName'>{start && format(add(start, { days: 2 }), 'EEEEEE', { locale: ru })}</div>
          </div>
          <div className='day'>
            <div className='dayValue'>{start && format(add(start, { days: 3 }), 'dd')}</div>
            <div className='dayName'>{start && format(add(start, { days: 3 }), 'EEEEEE', { locale: ru })}</div>
          </div>
          <div className='day'>
            <div className='dayValue'>{start && format(add(start, { days: 4 }), 'dd')}</div>
            <div className='dayName'>{start && format(add(start, { days: 4 }), 'EEEEEE', { locale: ru })}</div>
          </div>
          <div className='day'>
            <div className='dayValue'>{start && format(add(start, { days: 5 }), 'dd')}</div>
            <div className='dayName'>{start && format(add(start, { days: 5 }), 'EEEEEE', { locale: ru })}</div>
          </div>
          <div className='day'>
            <div className='dayValue'>{start && format(add(start, { days: 6 }), 'dd')}</div>
            <div className='dayName'>{start && format(add(start, { days: 6 }), 'EEEEEE', { locale: ru })}</div>
          </div>
        </div>

        <div className='timeTable adminExpertsTimeTable'>
          {expertData.time_slots.map((timeItem: any, index) => (
            <div
              className={
                'time ' +
                // * недоступно для записи
                `${timeItem.status === 'disabled' ? ' disabled' : ''}` +
                // * недоступно для записи, уже есть запись
                `${timeItem.status === 'busy' ? 'busy' : ''}`
              }
              key={timeItem.time}
            >
              <div className='clickWrapper' onClick={() => setIsTimeSlotActions(timeItem.time)}>
                <div className='timeValue'>{format(utcToZonedTime(new Date(timeItem.time), 'Europe/Moscow'), 'HH:mm')}</div>

                {timeItem.status === 'busy' && (
                  <div className='clientInfo'>
                    <div className='clientTitle'>{timeItem.data.client.short_title}</div>
                    <img src={timeItem.data.client?.small_logo} className='clientLogo' alt='Logo' />
                  </div>
                )}
              </div>

              {isTimeSlotActions === timeItem.time ? (
                <>
                  {timeItem.status === 'busy' ? (
                    <div className={`timeSlotActions ${index >= 79 ? 'right' : 'left'}`}>
                      <div className='closeIcon' onClick={() => setIsTimeSlotActions(null)}></div>
                      <button type='button' className='link' onClick={() => setIsCancelInterviewModal(timeItem.data.id)}>
                        Отменить интервью
                      </button>
                      <button type='button' className='link' onClick={() => setIsChangeTimeModal(timeItem.data.id)}>
                        Перенести интервью на другое время
                      </button>
                      <button type='button' className='link' onClick={() => setIsChangeExpertModal(timeItem.data.id)}>
                        Перенести интервью на другого эксперта
                      </button>
                    </div>
                  ) : null}

                  {timeItem.status === 'open' || timeItem.status === 'disabled' ? (
                    <div className={`timeSlotActions ${index >= 79 ? 'right' : 'left'}`}>
                      <div className='closeIcon' onClick={() => setIsTimeSlotActions(null)}></div>
                      <button
                        type='button'
                        className='link'
                        onClick={
                          timeItem.status === 'disabled'
                            ? () => setIsDisabledTimeSlotAlertModal({ timeSlot: timeItem.time })
                            : () => setIsAssignNewInterviewModal({ timeSlotID: timeItem?.data?.id, timeSlot: timeItem.time })
                        }
                      >
                        Назначить интервью
                      </button>
                    </div>
                  ) : null}
                </>
              ) : null}
            </div>
          ))}
        </div>
      </div>

      {isChangeExpertModal && <ChangeExpertModal onClose={() => setIsChangeExpertModal(null)} timeSlotID={isChangeExpertModal} />}
      {isChangeTimeModal && (
        <ChangeTimeModal
          onClose={() => setIsChangeTimeModal(null)}
          timeSlotID={isChangeTimeModal}
          // @ts-ignore
          expertID={expertID}
        />
      )}
      {isCancelInterviewModal && (
        <CancelInterviewModal
          onClose={() => setIsCancelInterviewModal(null)}
          timeSlotID={isCancelInterviewModal}
          // @ts-ignore
          expertID={expertID}
        />
      )}
      {isDisabledTimeSlotAlertModal && (
        <DisabledTimeSlotAlertModal
          onClose={() => setIsDisabledTimeSlotAlertModal(null)}
          onContinue={setIsAssignNewInterviewModal}
          timeSlot={isDisabledTimeSlotAlertModal.timeSlot}
        />
      )}
      {isAssignNewInterviewModal && (
        <AssignNewInterviewModal
          onClose={() => setIsAssignNewInterviewModal(null)}
          onAssigneInterviewSuccess={setIsAdminAssignedInterviewModal}
          timeSlot={isAssignNewInterviewModal.timeSlot}
          timeSlotID={isAssignNewInterviewModal.timeSlotID}
          expertID={expertID}
          expertData={expertData}
        />
      )}

      {isAdminAssignedInterviewModal && (
        <AdminAssignedInterviewModal
          onClose={() => setIsAdminAssignedInterviewModal(null)}
          expertName={isAdminAssignedInterviewModal.expertName}
          timeSlot={isAdminAssignedInterviewModal.timeSlot}
          interviewType={isAdminAssignedInterviewModal.interviewType}
          skype={isAdminAssignedInterviewModal?.skype}
          zoom={isAdminAssignedInterviewModal?.zoom}
        />
      )}
    </>
  );
};
