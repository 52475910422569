import './ChangeSkypeModal.scss';

import { useState } from 'react';

import axios from 'axios';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import * as Yup from 'yup';
import { queryCache } from 'react-query';

import { backendURL, debug } from '../../../../App';
import consoleLog from '../../../../helpers/consoleLog';

import Title from '../../../../components/Title';
import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';
import InputError from '../../../../components/InputError';

interface Props {
  userID: number;
  clientID: number;
  skype: string;
  onClose: () => void;
}

export const ChangeSkypeModal: React.FC<Props> = ({ userID, clientID, skype, onClose }) => {
  const [closingTimeoutID, setClosingTimeoutID] = useState<NodeJS.Timeout>();
  const closingTimeout = () => {
    const ID = setTimeout(() => {
      onClose();
    }, 3000);
    setClosingTimeoutID(ID);
  };

  return (
    <Modal
      className='changeSkypeModal'
      onClose={() => {
        closingTimeoutID && clearTimeout(closingTimeoutID);
        onClose();
      }}
    >
      <Title>Изменить Skype участника</Title>
      <Formik
        initialValues={{ skype }}
        validationSchema={Yup.object({
          skype: Yup.string().required('поле должно быть заполнено'),
        })}
        onSubmit={async (values, { setSubmitting, setStatus }) => {
          try {
            const { data } = await axios({
              method: 'post',
              url: `${backendURL}admin/members/${clientID}/`,
              headers: {
                Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
              },
              data: {
                user_id: userID,
                client_id: clientID,
                skype: values.skype,
              },
            });
            setSubmitting(false);
            setStatus({ success: true });
            queryCache.invalidateQueries(['adminMembersData', clientID]);
            closingTimeout();
            debug && consoleLog('success', 'Change skype', data);
          } catch (error) {
            setSubmitting(false);
            debug && consoleLog('error', 'Change skype', error);
          }
        }}
      >
        {props => (
          <Form>
            <Field
              name='skype'
              type='text'
              placeholder='Введите имя skype'
              disabled={props.isSubmitting === true ? true : false}
              className={`${props.errors.skype && props.touched.skype ? 'invalid' : ''}`}
            />
            <InputError>
              <ErrorMessage name='skype' />
            </InputError>
            <Button type='button' width='330rem' size='medium' color='blue' text='Изменить' loading={props.isSubmitting === true ? true : false} />
            <InputError className={`${props?.status?.success ? 'success' : ''}`}>
              {props?.status?.success && (
                <>
                  Skype участника упешно изменён. <br /> Окно будет закрыто через 3 секунды.
                </>
              )}
            </InputError>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
