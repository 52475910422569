import './AdminClientMetric.scss';
import 'react-circular-progressbar/dist/styles.css';

import AnimatedRedirect from '../../../components/AnimatedRedirect';
import { CircularProgressbar } from 'react-circular-progressbar';
import PageLoader from '../../../components/PageLoader';
import consoleLog from '../../../helpers/consoleLog';
import shadeRGBColor from '../../../helpers/colorDL';
import useClientMetrics from '../../../hooks/useClientMetrics';

interface Props {
  client: {
    id: number;
    title: string;
    logo: string;
    hex_color: string;
    registration_open: boolean;
  };
}

export const AdminClientMetric: React.FC<Props> = ({ client }) => {
  const { data: clientMetricsData, status: clientMetricsDataLoadingStatus } = useClientMetrics(client.id, undefined);

  if (clientMetricsDataLoadingStatus === 'loading') {
    return <PageLoader />;
  }

  if (clientMetricsDataLoadingStatus === 'error') {
    return <AnimatedRedirect redirectTo='/500/' />;
  }

  if (clientMetricsData.length === 3) {
    return (
      <>
        <div className='adminClientMetric'>
          <div className='metricDescription'>
            <div className='clientName'>
              {client.title} <br />
              Тестирование 1-ое
            </div>
            <div className='clientLogoContainer'>
              <img className='clientLogo' src={client.logo} />
            </div>
            <div className='descriptionTitle'>Участники</div>
            <div className='descriptionDataRow'>
              <div className='rowBullet' style={{ backgroundColor: `${shadeRGBColor(`rgb(${client.hex_color})`, -0.9)}` }}></div>
              <div className='rowDesription'>зарегистрировались</div>
              <div className='rowValue'>{clientMetricsData[0][0].common_stats.reg_complete}</div>
            </div>
            <div className='descriptionDataRow'>
              <div className='rowBullet' style={{ backgroundColor: `${shadeRGBColor(`rgb(${client.hex_color})`, -0.5)}` }}></div>
              <div className='rowDesription'>прошли анкетирование</div>
              <div className='rowValue'>{clientMetricsData[0][0].common_stats.anketa_complete}</div>
            </div>
            <div className='descriptionDataRow'>
              <div className='rowBullet' style={{ backgroundColor: `${shadeRGBColor(`rgb(${client.hex_color})`, -0.25)}` }}></div>
              <div className='rowDesription'>опрошены экспертом</div>
              <div className='rowValue'>{clientMetricsData[0][0].common_stats.expert_complete}</div>
            </div>
            <div className='descriptionDataRow'>
              <div className='rowBullet' style={{ backgroundColor: `rgb(${client.hex_color})` }}></div>
              <div className='rowDesription'>проверены администратором</div>
              <div className='rowValue'>{clientMetricsData[0][0].common_stats.admin_complete}</div>
            </div>
            <div className='descriptionDataRow'>
              <div className='rowBullet colorOne'></div>
              <div className='rowDesription'>общее число</div>
              <div className='rowValue'>{clientMetricsData[0][0].common_stats.all}</div>
            </div>
          </div>
          <div className='metricCirclesAndStatus'>
            <div className={`status ${client.registration_open ? 'open' : 'closed'}`}>{client.registration_open ? 'Открыта регистрация' : 'Регистрация закрыта'}</div>
            <div className='circleContainer'>
              <CircularProgressbar
                value={
                  (clientMetricsData[0][0].common_stats.reg_complete / clientMetricsData[0][0].common_stats.all) * 100 === 0
                    ? 1
                    : (clientMetricsData[0][0].common_stats.reg_complete / clientMetricsData[0][0].common_stats.all) * 100
                }
                strokeWidth={3.35}
                styles={{
                  path: {
                    stroke: `${shadeRGBColor(`rgb(${client.hex_color})`, -0.9)}`,
                    strokeLinecap: 'butt',
                    transition: 'stroke-dashoffset 0.5s ease 0s',
                    transform: 'rotate(0.020turn)',
                    transformOrigin: 'center center',
                  },
                  trail: {
                    stroke: 'rgba(242, 242, 242, 1)',
                    strokeLinecap: 'butt',
                    transform: 'rotate(0.20turn)',
                    transformOrigin: 'center center',
                  },
                }}
              />
            </div>
            <div className='circleContainer'>
              <CircularProgressbar
                value={
                  (clientMetricsData[0][0].common_stats.anketa_complete / clientMetricsData[0][0].common_stats.all) * 100 === 0
                    ? 1
                    : (clientMetricsData[0][0].common_stats.anketa_complete / clientMetricsData[0][0].common_stats.all) * 100
                }
                strokeWidth={4.05}
                styles={{
                  path: {
                    stroke: `${shadeRGBColor(`rgb(${client.hex_color})`, -0.5)}`,
                    strokeLinecap: 'butt',
                    transition: 'stroke-dashoffset 0.5s ease 0s',
                    transform: 'rotate(0.020turn)',
                    transformOrigin: 'center center',
                  },
                  trail: {
                    stroke: 'rgba(242, 242, 242, 1)',
                    strokeLinecap: 'butt',
                    transform: 'rotate(0.20turn)',
                    transformOrigin: 'center center',
                  },
                }}
              />
            </div>
            <div className='circleContainer'>
              <CircularProgressbar
                value={
                  (clientMetricsData[0][0].common_stats.expert_complete / clientMetricsData[0][0].common_stats.all) * 100 === 0
                    ? 1
                    : (clientMetricsData[0][0].common_stats.expert_complete / clientMetricsData[0][0].common_stats.all) * 100
                }
                strokeWidth={4.75}
                styles={{
                  path: {
                    stroke: `${shadeRGBColor(`rgb(${client.hex_color})`, -0.25)}`,
                    strokeLinecap: 'butt',
                    transition: 'stroke-dashoffset 0.5s ease 0s',
                    transform: 'rotate(0.020turn)',
                    transformOrigin: 'center center',
                  },
                  trail: {
                    stroke: 'rgba(242, 242, 242, 1)',
                    strokeLinecap: 'butt',
                    transform: 'rotate(0.20turn)',
                    transformOrigin: 'center center',
                  },
                }}
              />
            </div>
            <div className='circleContainer'>
              <CircularProgressbar
                value={
                  (clientMetricsData[0][0].common_stats.admin_complete / clientMetricsData[0][0].common_stats.all) * 100 === 0
                    ? 1
                    : (clientMetricsData[0][0].common_stats.admin_complete / clientMetricsData[0][0].common_stats.all) * 100
                }
                strokeWidth={6.45}
                styles={{
                  path: {
                    stroke: `rgb(${client.hex_color})`,
                    strokeLinecap: 'butt',
                    transition: 'stroke-dashoffset 0.5s ease 0s',
                    transform: 'rotate(0.020turn)',
                    transformOrigin: 'center center',
                  },
                  trail: {
                    stroke: 'rgba(242, 242, 242, 1)',
                    strokeLinecap: 'butt',
                    transform: 'rotate(0.20turn)',
                    transformOrigin: 'center center',
                  },
                }}
              />
            </div>
            <div className='circleText'>
              <span className='value'>{clientMetricsData[0][0].common_stats.all}</span>
              <span className='text'>Человек</span>
            </div>
          </div>
          <div className='expertsWorkload'>
            <div className='workloadTitle'>Загруженность экспертов (количество свободных слотов)</div>
            <div
              className={
                'workloadContainer ' +
                `${clientMetricsData[0][0].common_stats.busy_percent < 20 ? 'smallLoadFont ' : ''}` +
                `${100 - clientMetricsData[0][0].common_stats.busy_percent <= 33 ? 'smallLoad' : ''}` +
                `${100 - clientMetricsData[0][0].common_stats.busy_percent > 33 && 100 - clientMetricsData[0][0].common_stats.busy_percent <= 66 ? 'mediumLoad' : ''}` +
                `${100 - clientMetricsData[0][0].common_stats.busy_percent > 66 ? 'bigLoad' : ''}`
              }
            >
              <div className='workloadValue' style={{ width: `${clientMetricsData[0][0].common_stats.busy_percent}%` }}></div>
              <div className='workloadText'>{clientMetricsData[0][0].common_stats.busy_percent} %</div>
            </div>
            <div className='workloadSlotsLeft'>
              <div className='value'>{clientMetricsData[0][0].common_stats.free_slots}</div>
              <div className='key'>свободных слотов</div>
            </div>
          </div>
          <div className='deadline'>
            <div className='workloadTitle'>Прогресс по времени</div>
            <div className='workloadTitle'>Дней до делайна</div>
            <div
              className={
                'workloadContainer ' +
                `${clientMetricsData[0][0].common_stats.deadline_percent < 20 ? 'smallLoadFont ' : ''}` +
                `${100 - clientMetricsData[0][0].common_stats.deadline_percent <= 33 ? 'smallLoad' : ''}` +
                `${
                  100 - clientMetricsData[0][0].common_stats.deadline_percent > 33 && 100 - clientMetricsData[0][0].common_stats.deadline_percent <= 66
                    ? 'mediumLoad'
                    : ''
                }` +
                `${100 - clientMetricsData[0][0].common_stats.deadline_percent > 66 ? 'bigLoad' : ''}`
              }
            >
              <div className='workloadValue' style={{ width: `${clientMetricsData[0][0].common_stats.deadline_percent}%` }}></div>
              <div className='workloadText'>{clientMetricsData[0][0].common_stats.deadline_percent} %</div>
            </div>
            <div className='workloadLeft'>
              {clientMetricsData[0][0].common_stats.deadline_percent < 0 ? 0 : 100 - clientMetricsData[0][0].common_stats.deadline_percent} %
            </div>
            <div className='workloadLeft'>{clientMetricsData[0][0].common_stats.deadline_days < 0 ? 0 : clientMetricsData[0][0].common_stats.deadline_days}</div>
          </div>
        </div>
        <div className='adminClientMetric'>
          <div className='metricDescription'>
            <div className='clientName'>
              {client.title} <br />
              Тестирование 2-ое
            </div>
            <div className='clientLogoContainer'>
              <img className='clientLogo' src={client.logo} />
            </div>
            <div className='descriptionTitle'>Участники</div>
            <div className='descriptionDataRow'>
              <div className='rowBullet' style={{ backgroundColor: `${shadeRGBColor(`rgb(${client.hex_color})`, -0.9)}` }}></div>
              <div className='rowDesription'>зарегистрировались</div>
              <div className='rowValue'>{clientMetricsData[1][0].common_stats.reg_complete}</div>
            </div>
            <div className='descriptionDataRow'>
              <div className='rowBullet' style={{ backgroundColor: `${shadeRGBColor(`rgb(${client.hex_color})`, -0.5)}` }}></div>
              <div className='rowDesription'>прошли анкетирование</div>
              <div className='rowValue'>{clientMetricsData[1][0].common_stats.anketa_complete}</div>
            </div>
            <div className='descriptionDataRow'>
              <div className='rowBullet' style={{ backgroundColor: `${shadeRGBColor(`rgb(${client.hex_color})`, -0.25)}` }}></div>
              <div className='rowDesription'>опрошены экспертом</div>
              <div className='rowValue'>{clientMetricsData[1][0].common_stats.expert_complete}</div>
            </div>
            <div className='descriptionDataRow'>
              <div className='rowBullet' style={{ backgroundColor: `rgb(${client.hex_color})` }}></div>
              <div className='rowDesription'>проверены администратором</div>
              <div className='rowValue'>{clientMetricsData[1][0].common_stats.admin_complete}</div>
            </div>
            <div className='descriptionDataRow'>
              <div className='rowBullet colorOne'></div>
              <div className='rowDesription'>общее число</div>
              <div className='rowValue'>{clientMetricsData[1][0].common_stats.all}</div>
            </div>
          </div>
          <div className='metricCirclesAndStatus'>
            <div className={`status ${client.registration_open ? 'open' : 'closed'}`}>{client.registration_open ? 'Открыта регистрация' : 'Регистрация закрыта'}</div>
            <div className='circleContainer'>
              <CircularProgressbar
                value={
                  (clientMetricsData[1][0].common_stats.reg_complete / clientMetricsData[1][0].common_stats.all) * 100 === 0
                    ? 1
                    : (clientMetricsData[1][0].common_stats.reg_complete / clientMetricsData[1][0].common_stats.all) * 100
                }
                strokeWidth={3.35}
                styles={{
                  path: {
                    stroke: `${shadeRGBColor(`rgb(${client.hex_color})`, -0.9)}`,
                    strokeLinecap: 'butt',
                    transition: 'stroke-dashoffset 0.5s ease 0s',
                    transform: 'rotate(0.020turn)',
                    transformOrigin: 'center center',
                  },
                  trail: {
                    stroke: 'rgba(242, 242, 242, 1)',
                    strokeLinecap: 'butt',
                    transform: 'rotate(0.20turn)',
                    transformOrigin: 'center center',
                  },
                }}
              />
            </div>
            <div className='circleContainer'>
              <CircularProgressbar
                value={
                  (clientMetricsData[1][0].common_stats.anketa_complete / clientMetricsData[1][0].common_stats.all) * 100 === 0
                    ? 1
                    : (clientMetricsData[1][0].common_stats.anketa_complete / clientMetricsData[1][0].common_stats.all) * 100
                }
                strokeWidth={4.05}
                styles={{
                  path: {
                    stroke: `${shadeRGBColor(`rgb(${client.hex_color})`, -0.5)}`,
                    strokeLinecap: 'butt',
                    transition: 'stroke-dashoffset 0.5s ease 0s',
                    transform: 'rotate(0.020turn)',
                    transformOrigin: 'center center',
                  },
                  trail: {
                    stroke: 'rgba(242, 242, 242, 1)',
                    strokeLinecap: 'butt',
                    transform: 'rotate(0.20turn)',
                    transformOrigin: 'center center',
                  },
                }}
              />
            </div>
            <div className='circleContainer'>
              <CircularProgressbar
                value={
                  (clientMetricsData[1][0].common_stats.expert_complete / clientMetricsData[1][0].common_stats.all) * 100 === 0
                    ? 1
                    : (clientMetricsData[1][0].common_stats.expert_complete / clientMetricsData[1][0].common_stats.all) * 100
                }
                strokeWidth={4.75}
                styles={{
                  path: {
                    stroke: `${shadeRGBColor(`rgb(${client.hex_color})`, -0.25)}`,
                    strokeLinecap: 'butt',
                    transition: 'stroke-dashoffset 0.5s ease 0s',
                    transform: 'rotate(0.020turn)',
                    transformOrigin: 'center center',
                  },
                  trail: {
                    stroke: 'rgba(242, 242, 242, 1)',
                    strokeLinecap: 'butt',
                    transform: 'rotate(0.20turn)',
                    transformOrigin: 'center center',
                  },
                }}
              />
            </div>
            <div className='circleContainer'>
              <CircularProgressbar
                value={
                  (clientMetricsData[1][0].common_stats.admin_complete / clientMetricsData[1][0].common_stats.all) * 100 === 0
                    ? 1
                    : (clientMetricsData[1][0].common_stats.admin_complete / clientMetricsData[1][0].common_stats.all) * 100
                }
                strokeWidth={6.45}
                styles={{
                  path: {
                    stroke: `rgb(${client.hex_color})`,
                    strokeLinecap: 'butt',
                    transition: 'stroke-dashoffset 0.5s ease 0s',
                    transform: 'rotate(0.020turn)',
                    transformOrigin: 'center center',
                  },
                  trail: {
                    stroke: 'rgba(242, 242, 242, 1)',
                    strokeLinecap: 'butt',
                    transform: 'rotate(0.20turn)',
                    transformOrigin: 'center center',
                  },
                }}
              />
            </div>
            <div className='circleText'>
              <span className='value'>{clientMetricsData[1][0].common_stats.all}</span>
              <span className='text'>Человек</span>
            </div>
          </div>
          <div className='expertsWorkload'>
            <div className='workloadTitle'>Загруженность экспертов (количество свободных слотов)</div>
            <div
              className={
                'workloadContainer ' +
                `${clientMetricsData[1][0].common_stats.busy_percent < 20 ? 'smallLoadFont ' : ''}` +
                `${100 - clientMetricsData[1][0].common_stats.busy_percent <= 33 ? 'smallLoad' : ''}` +
                `${100 - clientMetricsData[1][0].common_stats.busy_percent > 33 && 100 - clientMetricsData[1][0].common_stats.busy_percent <= 66 ? 'mediumLoad' : ''}` +
                `${100 - clientMetricsData[1][0].common_stats.busy_percent > 66 ? 'bigLoad' : ''}`
              }
            >
              <div className='workloadValue' style={{ width: `${clientMetricsData[1][0].common_stats.busy_percent}%` }}></div>
              <div className='workloadText'>{clientMetricsData[1][0].common_stats.busy_percent} %</div>
            </div>
            <div className='workloadSlotsLeft'>
              <div className='value'>{clientMetricsData[1][0].common_stats.free_slots}</div>
              <div className='key'>свободных слотов</div>
            </div>
          </div>
          <div className='deadline'>
            <div className='workloadTitle'>Прогресс по времени</div>
            <div className='workloadTitle'>Дней до делайна</div>
            <div
              className={
                'workloadContainer ' +
                `${clientMetricsData[1][0].common_stats.deadline_percent < 20 ? 'smallLoadFont ' : ''}` +
                `${100 - clientMetricsData[1][0].common_stats.deadline_percent <= 33 ? 'smallLoad' : ''}` +
                `${
                  100 - clientMetricsData[1][0].common_stats.deadline_percent > 33 && 100 - clientMetricsData[1][0].common_stats.deadline_percent <= 66
                    ? 'mediumLoad'
                    : ''
                }` +
                `${100 - clientMetricsData[1][0].common_stats.deadline_percent > 66 ? 'bigLoad' : ''}`
              }
            >
              <div className='workloadValue' style={{ width: `${clientMetricsData[1][0].common_stats.deadline_percent}%` }}></div>
              <div className='workloadText'>{clientMetricsData[1][0].common_stats.deadline_percent} %</div>
            </div>
            <div className='workloadLeft'>
              {clientMetricsData[1][0].common_stats.deadline_percent < 0 ? 0 : 100 - clientMetricsData[1][0].common_stats.deadline_percent} %
            </div>
            <div className='workloadLeft'>{clientMetricsData[1][0].common_stats.deadline_days < 0 ? 0 : clientMetricsData[1][0].common_stats.deadline_days}</div>
          </div>
        </div>
        <div className='adminClientMetric'>
          <div className='metricDescription'>
            <div className='clientName'>
              {client.title} <br />
              Тестирование 3-ье
            </div>
            <div className='clientLogoContainer'>
              <img className='clientLogo' src={client.logo} />
            </div>
            <div className='descriptionTitle'>Участники</div>
            <div className='descriptionDataRow'>
              <div className='rowBullet' style={{ backgroundColor: `${shadeRGBColor(`rgb(${client.hex_color})`, -0.9)}` }}></div>
              <div className='rowDesription'>зарегистрировались</div>
              <div className='rowValue'>{clientMetricsData[2][0].common_stats.reg_complete}</div>
            </div>
            <div className='descriptionDataRow'>
              <div className='rowBullet' style={{ backgroundColor: `${shadeRGBColor(`rgb(${client.hex_color})`, -0.5)}` }}></div>
              <div className='rowDesription'>прошли анкетирование</div>
              <div className='rowValue'>{clientMetricsData[2][0].common_stats.anketa_complete}</div>
            </div>
            <div className='descriptionDataRow'>
              <div className='rowBullet' style={{ backgroundColor: `${shadeRGBColor(`rgb(${client.hex_color})`, -0.25)}` }}></div>
              <div className='rowDesription'>опрошены экспертом</div>
              <div className='rowValue'>{clientMetricsData[2][0].common_stats.expert_complete}</div>
            </div>
            <div className='descriptionDataRow'>
              <div className='rowBullet' style={{ backgroundColor: `rgb(${client.hex_color})` }}></div>
              <div className='rowDesription'>проверены администратором</div>
              <div className='rowValue'>{clientMetricsData[2][0].common_stats.admin_complete}</div>
            </div>
            <div className='descriptionDataRow'>
              <div className='rowBullet colorOne'></div>
              <div className='rowDesription'>общее число</div>
              <div className='rowValue'>{clientMetricsData[2][0].common_stats.all}</div>
            </div>
          </div>
          <div className='metricCirclesAndStatus'>
            <div className={`status ${client.registration_open ? 'open' : 'closed'}`}>{client.registration_open ? 'Открыта регистрация' : 'Регистрация закрыта'}</div>
            <div className='circleContainer'>
              <CircularProgressbar
                value={
                  (clientMetricsData[2][0].common_stats.reg_complete / clientMetricsData[2][0].common_stats.all) * 100 === 0
                    ? 1
                    : (clientMetricsData[2][0].common_stats.reg_complete / clientMetricsData[2][0].common_stats.all) * 100
                }
                strokeWidth={3.35}
                styles={{
                  path: {
                    stroke: `${shadeRGBColor(`rgb(${client.hex_color})`, -0.9)}`,
                    strokeLinecap: 'butt',
                    transition: 'stroke-dashoffset 0.5s ease 0s',
                    transform: 'rotate(0.020turn)',
                    transformOrigin: 'center center',
                  },
                  trail: {
                    stroke: 'rgba(242, 242, 242, 1)',
                    strokeLinecap: 'butt',
                    transform: 'rotate(0.20turn)',
                    transformOrigin: 'center center',
                  },
                }}
              />
            </div>
            <div className='circleContainer'>
              <CircularProgressbar
                value={
                  (clientMetricsData[2][0].common_stats.anketa_complete / clientMetricsData[2][0].common_stats.all) * 100 === 0
                    ? 1
                    : (clientMetricsData[2][0].common_stats.anketa_complete / clientMetricsData[2][0].common_stats.all) * 100
                }
                strokeWidth={4.05}
                styles={{
                  path: {
                    stroke: `${shadeRGBColor(`rgb(${client.hex_color})`, -0.5)}`,
                    strokeLinecap: 'butt',
                    transition: 'stroke-dashoffset 0.5s ease 0s',
                    transform: 'rotate(0.020turn)',
                    transformOrigin: 'center center',
                  },
                  trail: {
                    stroke: 'rgba(242, 242, 242, 1)',
                    strokeLinecap: 'butt',
                    transform: 'rotate(0.20turn)',
                    transformOrigin: 'center center',
                  },
                }}
              />
            </div>
            <div className='circleContainer'>
              <CircularProgressbar
                value={
                  (clientMetricsData[2][0].common_stats.expert_complete / clientMetricsData[2][0].common_stats.all) * 100 === 0
                    ? 1
                    : (clientMetricsData[2][0].common_stats.expert_complete / clientMetricsData[2][0].common_stats.all) * 100
                }
                strokeWidth={4.75}
                styles={{
                  path: {
                    stroke: `${shadeRGBColor(`rgb(${client.hex_color})`, -0.25)}`,
                    strokeLinecap: 'butt',
                    transition: 'stroke-dashoffset 0.5s ease 0s',
                    transform: 'rotate(0.020turn)',
                    transformOrigin: 'center center',
                  },
                  trail: {
                    stroke: 'rgba(242, 242, 242, 1)',
                    strokeLinecap: 'butt',
                    transform: 'rotate(0.20turn)',
                    transformOrigin: 'center center',
                  },
                }}
              />
            </div>
            <div className='circleContainer'>
              <CircularProgressbar
                value={
                  (clientMetricsData[2][0].common_stats.admin_complete / clientMetricsData[2][0].common_stats.all) * 100 === 0
                    ? 1
                    : (clientMetricsData[2][0].common_stats.admin_complete / clientMetricsData[2][0].common_stats.all) * 100
                }
                strokeWidth={6.45}
                styles={{
                  path: {
                    stroke: `rgb(${client.hex_color})`,
                    strokeLinecap: 'butt',
                    transition: 'stroke-dashoffset 0.5s ease 0s',
                    transform: 'rotate(0.020turn)',
                    transformOrigin: 'center center',
                  },
                  trail: {
                    stroke: 'rgba(242, 242, 242, 1)',
                    strokeLinecap: 'butt',
                    transform: 'rotate(0.20turn)',
                    transformOrigin: 'center center',
                  },
                }}
              />
            </div>
            <div className='circleText'>
              <span className='value'>{clientMetricsData[2][0].common_stats.all}</span>
              <span className='text'>Человек</span>
            </div>
          </div>
          <div className='expertsWorkload'>
            <div className='workloadTitle'>Загруженность экспертов (количество свободных слотов)</div>
            <div
              className={
                'workloadContainer ' +
                `${clientMetricsData[2][0].common_stats.busy_percent < 20 ? 'smallLoadFont ' : ''}` +
                `${100 - clientMetricsData[2][0].common_stats.busy_percent <= 33 ? 'smallLoad' : ''}` +
                `${100 - clientMetricsData[2][0].common_stats.busy_percent > 33 && 100 - clientMetricsData[2][0].common_stats.busy_percent <= 66 ? 'mediumLoad' : ''}` +
                `${100 - clientMetricsData[2][0].common_stats.busy_percent > 66 ? 'bigLoad' : ''}`
              }
            >
              <div className='workloadValue' style={{ width: `${clientMetricsData[2][0].common_stats.busy_percent}%` }}></div>
              <div className='workloadText'>{clientMetricsData[2][0].common_stats.busy_percent} %</div>
            </div>
            <div className='workloadSlotsLeft'>
              <div className='value'>{clientMetricsData[2][0].common_stats.free_slots}</div>
              <div className='key'>свободных слотов</div>
            </div>
          </div>
          <div className='deadline'>
            <div className='workloadTitle'>Прогресс по времени</div>
            <div className='workloadTitle'>Дней до делайна</div>
            <div
              className={
                'workloadContainer ' +
                `${clientMetricsData[2][0].common_stats.deadline_percent < 20 ? 'smallLoadFont ' : ''}` +
                `${100 - clientMetricsData[2][0].common_stats.deadline_percent <= 33 ? 'smallLoad' : ''}` +
                `${
                  100 - clientMetricsData[2][0].common_stats.deadline_percent > 33 && 100 - clientMetricsData[2][0].common_stats.deadline_percent <= 66
                    ? 'mediumLoad'
                    : ''
                }` +
                `${100 - clientMetricsData[2][0].common_stats.deadline_percent > 66 ? 'bigLoad' : ''}`
              }
            >
              <div className='workloadValue' style={{ width: `${clientMetricsData[2][0].common_stats.deadline_percent}%` }}></div>
              <div className='workloadText'>{clientMetricsData[2][0].common_stats.deadline_percent} %</div>
            </div>
            <div className='workloadLeft'>
              {clientMetricsData[2][0].common_stats.deadline_percent < 0 ? 0 : 100 - clientMetricsData[2][0].common_stats.deadline_percent} %
            </div>
            <div className='workloadLeft'>{clientMetricsData[2][0].common_stats.deadline_days < 0 ? 0 : clientMetricsData[2][0].common_stats.deadline_days}</div>
          </div>
        </div>
      </>
    );
  }

  if (clientMetricsData.length === 2) {
    return (
      <>
        {consoleLog('info', 'metrics', clientMetricsData)}
        <div className='adminClientMetric'>
          <div className='metricDescription'>
            <div className='clientName'>
              {client.title} <br />
              Тестирование 1-ое
            </div>
            <div className='clientLogoContainer'>
              <img className='clientLogo' src={client.logo} />
            </div>
            <div className='descriptionTitle'>Участники</div>
            <div className='descriptionDataRow'>
              <div className='rowBullet' style={{ backgroundColor: `${shadeRGBColor(`rgb(${client.hex_color})`, -0.9)}` }}></div>
              <div className='rowDesription'>зарегистрировались</div>
              <div className='rowValue'>{clientMetricsData[0][0].common_stats.reg_complete}</div>
            </div>
            <div className='descriptionDataRow'>
              <div className='rowBullet' style={{ backgroundColor: `${shadeRGBColor(`rgb(${client.hex_color})`, -0.5)}` }}></div>
              <div className='rowDesription'>прошли анкетирование</div>
              <div className='rowValue'>{clientMetricsData[0][0].common_stats.anketa_complete}</div>
            </div>
            <div className='descriptionDataRow'>
              <div className='rowBullet' style={{ backgroundColor: `${shadeRGBColor(`rgb(${client.hex_color})`, -0.25)}` }}></div>
              <div className='rowDesription'>опрошены экспертом</div>
              <div className='rowValue'>{clientMetricsData[0][0].common_stats.expert_complete}</div>
            </div>
            <div className='descriptionDataRow'>
              <div className='rowBullet' style={{ backgroundColor: `rgb(${client.hex_color})` }}></div>
              <div className='rowDesription'>проверены администратором</div>
              <div className='rowValue'>{clientMetricsData[0][0].common_stats.admin_complete}</div>
            </div>
            <div className='descriptionDataRow'>
              <div className='rowBullet colorOne'></div>
              <div className='rowDesription'>общее число</div>
              <div className='rowValue'>{clientMetricsData[0][0].common_stats.all}</div>
            </div>
          </div>
          <div className='metricCirclesAndStatus'>
            <div className={`status ${client.registration_open ? 'open' : 'closed'}`}>{client.registration_open ? 'Открыта регистрация' : 'Регистрация закрыта'}</div>
            <div className='circleContainer'>
              <CircularProgressbar
                value={
                  (clientMetricsData[0][0].common_stats.reg_complete / clientMetricsData[0][0].common_stats.all) * 100 === 0
                    ? 1
                    : (clientMetricsData[0][0].common_stats.reg_complete / clientMetricsData[0][0].common_stats.all) * 100
                }
                strokeWidth={3.35}
                styles={{
                  path: {
                    stroke: `${shadeRGBColor(`rgb(${client.hex_color})`, -0.9)}`,
                    strokeLinecap: 'butt',
                    transition: 'stroke-dashoffset 0.5s ease 0s',
                    transform: 'rotate(0.020turn)',
                    transformOrigin: 'center center',
                  },
                  trail: {
                    stroke: 'rgba(242, 242, 242, 1)',
                    strokeLinecap: 'butt',
                    transform: 'rotate(0.20turn)',
                    transformOrigin: 'center center',
                  },
                }}
              />
            </div>
            <div className='circleContainer'>
              <CircularProgressbar
                value={
                  (clientMetricsData[0][0].common_stats.anketa_complete / clientMetricsData[0][0].common_stats.all) * 100 === 0
                    ? 1
                    : (clientMetricsData[0][0].common_stats.anketa_complete / clientMetricsData[0][0].common_stats.all) * 100
                }
                strokeWidth={4.05}
                styles={{
                  path: {
                    stroke: `${shadeRGBColor(`rgb(${client.hex_color})`, -0.5)}`,
                    strokeLinecap: 'butt',
                    transition: 'stroke-dashoffset 0.5s ease 0s',
                    transform: 'rotate(0.020turn)',
                    transformOrigin: 'center center',
                  },
                  trail: {
                    stroke: 'rgba(242, 242, 242, 1)',
                    strokeLinecap: 'butt',
                    transform: 'rotate(0.20turn)',
                    transformOrigin: 'center center',
                  },
                }}
              />
            </div>
            <div className='circleContainer'>
              <CircularProgressbar
                value={
                  (clientMetricsData[0][0].common_stats.expert_complete / clientMetricsData[0][0].common_stats.all) * 100 === 0
                    ? 1
                    : (clientMetricsData[0][0].common_stats.expert_complete / clientMetricsData[0][0].common_stats.all) * 100
                }
                strokeWidth={4.75}
                styles={{
                  path: {
                    stroke: `${shadeRGBColor(`rgb(${client.hex_color})`, -0.25)}`,
                    strokeLinecap: 'butt',
                    transition: 'stroke-dashoffset 0.5s ease 0s',
                    transform: 'rotate(0.020turn)',
                    transformOrigin: 'center center',
                  },
                  trail: {
                    stroke: 'rgba(242, 242, 242, 1)',
                    strokeLinecap: 'butt',
                    transform: 'rotate(0.20turn)',
                    transformOrigin: 'center center',
                  },
                }}
              />
            </div>
            <div className='circleContainer'>
              <CircularProgressbar
                value={
                  (clientMetricsData[0][0].common_stats.admin_complete / clientMetricsData[0][0].common_stats.all) * 100 === 0
                    ? 1
                    : (clientMetricsData[0][0].common_stats.admin_complete / clientMetricsData[0][0].common_stats.all) * 100
                }
                strokeWidth={6.45}
                styles={{
                  path: {
                    stroke: `rgb(${client.hex_color})`,
                    strokeLinecap: 'butt',
                    transition: 'stroke-dashoffset 0.5s ease 0s',
                    transform: 'rotate(0.020turn)',
                    transformOrigin: 'center center',
                  },
                  trail: {
                    stroke: 'rgba(242, 242, 242, 1)',
                    strokeLinecap: 'butt',
                    transform: 'rotate(0.20turn)',
                    transformOrigin: 'center center',
                  },
                }}
              />
            </div>
            <div className='circleText'>
              <span className='value'>{clientMetricsData[0][0].common_stats.all}</span>
              <span className='text'>Человек</span>
            </div>
          </div>
          <div className='expertsWorkload'>
            <div className='workloadTitle'>Загруженность экспертов (количество свободных слотов)</div>
            <div
              className={
                'workloadContainer ' +
                `${clientMetricsData[0][0].common_stats.busy_percent < 20 ? 'smallLoadFont ' : ''}` +
                `${100 - clientMetricsData[0][0].common_stats.busy_percent <= 33 ? 'smallLoad' : ''}` +
                `${100 - clientMetricsData[0][0].common_stats.busy_percent > 33 && 100 - clientMetricsData[0][0].common_stats.busy_percent <= 66 ? 'mediumLoad' : ''}` +
                `${100 - clientMetricsData[0][0].common_stats.busy_percent > 66 ? 'bigLoad' : ''}`
              }
            >
              <div className='workloadValue' style={{ width: `${clientMetricsData[0][0].common_stats.busy_percent}%` }}></div>
              <div className='workloadText'>{clientMetricsData[0][0].common_stats.busy_percent} %</div>
            </div>
            <div className='workloadSlotsLeft'>
              <div className='value'>{clientMetricsData[0][0].common_stats.free_slots}</div>
              <div className='key'>свободных слотов</div>
            </div>
          </div>
          <div className='deadline'>
            <div className='workloadTitle'>Прогресс по времени</div>
            <div className='workloadTitle'>Дней до делайна</div>
            <div
              className={
                'workloadContainer ' +
                `${clientMetricsData[0][0].common_stats.deadline_percent < 20 ? 'smallLoadFont ' : ''}` +
                `${100 - clientMetricsData[0][0].common_stats.deadline_percent <= 33 ? 'smallLoad' : ''}` +
                `${
                  100 - clientMetricsData[0][0].common_stats.deadline_percent > 33 && 100 - clientMetricsData[0][0].common_stats.deadline_percent <= 66
                    ? 'mediumLoad'
                    : ''
                }` +
                `${100 - clientMetricsData[0][0].common_stats.deadline_percent > 66 ? 'bigLoad' : ''}`
              }
            >
              <div className='workloadValue' style={{ width: `${clientMetricsData[0][0].common_stats.deadline_percent}%` }}></div>
              <div className='workloadText'>{clientMetricsData[0][0].common_stats.deadline_percent} %</div>
            </div>
            <div className='workloadLeft'>
              {clientMetricsData[0][0].common_stats.deadline_percent < 0 ? 0 : 100 - clientMetricsData[0][0].common_stats.deadline_percent} %
            </div>
            <div className='workloadLeft'>{clientMetricsData[0][0].common_stats.deadline_days < 0 ? 0 : clientMetricsData[0][0].common_stats.deadline_days}</div>
          </div>
        </div>
        <div className='adminClientMetric'>
          <div className='metricDescription'>
            <div className='clientName'>
              {client.title} <br />
              Тестирование 2-ое
            </div>
            <div className='clientLogoContainer'>
              <img className='clientLogo' src={client.logo} />
            </div>
            <div className='descriptionTitle'>Участники</div>
            <div className='descriptionDataRow'>
              <div className='rowBullet' style={{ backgroundColor: `${shadeRGBColor(`rgb(${client.hex_color})`, -0.9)}` }}></div>
              <div className='rowDesription'>зарегистрировались</div>
              <div className='rowValue'>{clientMetricsData[1][0].common_stats.reg_complete}</div>
            </div>
            <div className='descriptionDataRow'>
              <div className='rowBullet' style={{ backgroundColor: `${shadeRGBColor(`rgb(${client.hex_color})`, -0.5)}` }}></div>
              <div className='rowDesription'>прошли анкетирование</div>
              <div className='rowValue'>{clientMetricsData[1][0].common_stats.anketa_complete}</div>
            </div>
            <div className='descriptionDataRow'>
              <div className='rowBullet' style={{ backgroundColor: `${shadeRGBColor(`rgb(${client.hex_color})`, -0.25)}` }}></div>
              <div className='rowDesription'>опрошены экспертом</div>
              <div className='rowValue'>{clientMetricsData[1][0].common_stats.expert_complete}</div>
            </div>
            <div className='descriptionDataRow'>
              <div className='rowBullet' style={{ backgroundColor: `rgb(${client.hex_color})` }}></div>
              <div className='rowDesription'>проверены администратором</div>
              <div className='rowValue'>{clientMetricsData[1][0].common_stats.admin_complete}</div>
            </div>
            <div className='descriptionDataRow'>
              <div className='rowBullet colorOne'></div>
              <div className='rowDesription'>общее число</div>
              <div className='rowValue'>{clientMetricsData[1][0].common_stats.all}</div>
            </div>
          </div>
          <div className='metricCirclesAndStatus'>
            <div className={`status ${client.registration_open ? 'open' : 'closed'}`}>{client.registration_open ? 'Открыта регистрация' : 'Регистрация закрыта'}</div>
            <div className='circleContainer'>
              <CircularProgressbar
                value={
                  (clientMetricsData[1][0].common_stats.reg_complete / clientMetricsData[1][0].common_stats.all) * 100 === 0
                    ? 1
                    : (clientMetricsData[1][0].common_stats.reg_complete / clientMetricsData[1][0].common_stats.all) * 100
                }
                strokeWidth={3.35}
                styles={{
                  path: {
                    stroke: `${shadeRGBColor(`rgb(${client.hex_color})`, -0.9)}`,
                    strokeLinecap: 'butt',
                    transition: 'stroke-dashoffset 0.5s ease 0s',
                    transform: 'rotate(0.020turn)',
                    transformOrigin: 'center center',
                  },
                  trail: {
                    stroke: 'rgba(242, 242, 242, 1)',
                    strokeLinecap: 'butt',
                    transform: 'rotate(0.20turn)',
                    transformOrigin: 'center center',
                  },
                }}
              />
            </div>
            <div className='circleContainer'>
              <CircularProgressbar
                value={
                  (clientMetricsData[1][0].common_stats.anketa_complete / clientMetricsData[1][0].common_stats.all) * 100 === 0
                    ? 1
                    : (clientMetricsData[1][0].common_stats.anketa_complete / clientMetricsData[1][0].common_stats.all) * 100
                }
                strokeWidth={4.05}
                styles={{
                  path: {
                    stroke: `${shadeRGBColor(`rgb(${client.hex_color})`, -0.5)}`,
                    strokeLinecap: 'butt',
                    transition: 'stroke-dashoffset 0.5s ease 0s',
                    transform: 'rotate(0.020turn)',
                    transformOrigin: 'center center',
                  },
                  trail: {
                    stroke: 'rgba(242, 242, 242, 1)',
                    strokeLinecap: 'butt',
                    transform: 'rotate(0.20turn)',
                    transformOrigin: 'center center',
                  },
                }}
              />
            </div>
            <div className='circleContainer'>
              <CircularProgressbar
                value={
                  (clientMetricsData[1][0].common_stats.expert_complete / clientMetricsData[1][0].common_stats.all) * 100 === 0
                    ? 1
                    : (clientMetricsData[1][0].common_stats.expert_complete / clientMetricsData[1][0].common_stats.all) * 100
                }
                strokeWidth={4.75}
                styles={{
                  path: {
                    stroke: `${shadeRGBColor(`rgb(${client.hex_color})`, -0.25)}`,
                    strokeLinecap: 'butt',
                    transition: 'stroke-dashoffset 0.5s ease 0s',
                    transform: 'rotate(0.020turn)',
                    transformOrigin: 'center center',
                  },
                  trail: {
                    stroke: 'rgba(242, 242, 242, 1)',
                    strokeLinecap: 'butt',
                    transform: 'rotate(0.20turn)',
                    transformOrigin: 'center center',
                  },
                }}
              />
            </div>
            <div className='circleContainer'>
              <CircularProgressbar
                value={
                  (clientMetricsData[1][0].common_stats.admin_complete / clientMetricsData[1][0].common_stats.all) * 100 === 0
                    ? 1
                    : (clientMetricsData[1][0].common_stats.admin_complete / clientMetricsData[1][0].common_stats.all) * 100
                }
                strokeWidth={6.45}
                styles={{
                  path: {
                    stroke: `rgb(${client.hex_color})`,
                    strokeLinecap: 'butt',
                    transition: 'stroke-dashoffset 0.5s ease 0s',
                    transform: 'rotate(0.020turn)',
                    transformOrigin: 'center center',
                  },
                  trail: {
                    stroke: 'rgba(242, 242, 242, 1)',
                    strokeLinecap: 'butt',
                    transform: 'rotate(0.20turn)',
                    transformOrigin: 'center center',
                  },
                }}
              />
            </div>
            <div className='circleText'>
              <span className='value'>{clientMetricsData[1][0].common_stats.all}</span>
              <span className='text'>Человек</span>
            </div>
          </div>
          <div className='expertsWorkload'>
            <div className='workloadTitle'>Загруженность экспертов (количество свободных слотов)</div>
            <div
              className={
                'workloadContainer ' +
                `${clientMetricsData[1][0].common_stats.busy_percent < 20 ? 'smallLoadFont ' : ''}` +
                `${100 - clientMetricsData[1][0].common_stats.busy_percent <= 33 ? 'smallLoad' : ''}` +
                `${100 - clientMetricsData[1][0].common_stats.busy_percent > 33 && 100 - clientMetricsData[1][0].common_stats.busy_percent <= 66 ? 'mediumLoad' : ''}` +
                `${100 - clientMetricsData[1][0].common_stats.busy_percent > 66 ? 'bigLoad' : ''}`
              }
            >
              <div className='workloadValue' style={{ width: `${clientMetricsData[1][0].common_stats.busy_percent}%` }}></div>
              <div className='workloadText'>{clientMetricsData[1][0].common_stats.busy_percent} %</div>
            </div>
            <div className='workloadSlotsLeft'>
              <div className='value'>{clientMetricsData[1][0].common_stats.free_slots}</div>
              <div className='key'>свободных слотов</div>
            </div>
          </div>
          <div className='deadline'>
            <div className='workloadTitle'>Прогресс по времени</div>
            <div className='workloadTitle'>Дней до делайна</div>
            <div
              className={
                'workloadContainer ' +
                `${clientMetricsData[1][0].common_stats.deadline_percent < 20 ? 'smallLoadFont ' : ''}` +
                `${100 - clientMetricsData[1][0].common_stats.deadline_percent <= 33 ? 'smallLoad' : ''}` +
                `${
                  100 - clientMetricsData[1][0].common_stats.deadline_percent > 33 && 100 - clientMetricsData[1][0].common_stats.deadline_percent <= 66
                    ? 'mediumLoad'
                    : ''
                }` +
                `${100 - clientMetricsData[1][0].common_stats.deadline_percent > 66 ? 'bigLoad' : ''}`
              }
            >
              <div className='workloadValue' style={{ width: `${clientMetricsData[1][0].common_stats.deadline_percent}%` }}></div>
              <div className='workloadText'>{clientMetricsData[1][0].common_stats.deadline_percent} %</div>
            </div>
            <div className='workloadLeft'>
              {clientMetricsData[1][0].common_stats.deadline_percent < 0 ? 0 : 100 - clientMetricsData[1][0].common_stats.deadline_percent} %
            </div>
            <div className='workloadLeft'>{clientMetricsData[1][0].common_stats.deadline_days < 0 ? 0 : clientMetricsData[1][0].common_stats.deadline_days}</div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className='adminClientMetric'>
      <div className='metricDescription'>
        <div className='clientName'>{client.title}</div>
        <div className='clientLogoContainer'>
          <img className='clientLogo' src={client.logo} />
        </div>
        <div className='descriptionTitle'>Участники</div>
        <div className='descriptionDataRow'>
          <div className='rowBullet' style={{ backgroundColor: `${shadeRGBColor(`rgb(${client.hex_color})`, -0.9)}` }}></div>
          <div className='rowDesription'>зарегистрировались</div>
          <div className='rowValue'>{clientMetricsData.common_stats.reg_complete}</div>
        </div>
        <div className='descriptionDataRow'>
          <div className='rowBullet' style={{ backgroundColor: `${shadeRGBColor(`rgb(${client.hex_color})`, -0.5)}` }}></div>
          <div className='rowDesription'>прошли анкетирование</div>
          <div className='rowValue'>{clientMetricsData.common_stats.anketa_complete}</div>
        </div>
        <div className='descriptionDataRow'>
          <div className='rowBullet' style={{ backgroundColor: `${shadeRGBColor(`rgb(${client.hex_color})`, -0.25)}` }}></div>
          <div className='rowDesription'>опрошены экспертом</div>
          <div className='rowValue'>{clientMetricsData.common_stats.expert_complete}</div>
        </div>
        <div className='descriptionDataRow'>
          <div className='rowBullet' style={{ backgroundColor: `rgb(${client.hex_color})` }}></div>
          <div className='rowDesription'>проверены администратором</div>
          <div className='rowValue'>{clientMetricsData.common_stats.admin_complete}</div>
        </div>
        <div className='descriptionDataRow'>
          <div className='rowBullet colorOne'></div>
          <div className='rowDesription'>общее число</div>
          <div className='rowValue'>{clientMetricsData.common_stats.all}</div>
        </div>
      </div>
      <div className='metricCirclesAndStatus'>
        <div className='circleContainer'>
          <CircularProgressbar
            value={
              (clientMetricsData.common_stats.reg_complete / clientMetricsData.common_stats.all) * 100 === 0
                ? 1
                : (clientMetricsData.common_stats.reg_complete / clientMetricsData.common_stats.all) * 100
            }
            strokeWidth={3.35}
            styles={{
              path: {
                stroke: `${shadeRGBColor(`rgb(${client.hex_color})`, -0.9)}`,
                strokeLinecap: 'butt',
                transition: 'stroke-dashoffset 0.5s ease 0s',
                transform: 'rotate(0.020turn)',
                transformOrigin: 'center center',
              },
              trail: {
                stroke: 'rgba(242, 242, 242, 1)',
                strokeLinecap: 'butt',
                transform: 'rotate(0.20turn)',
                transformOrigin: 'center center',
              },
            }}
          />
        </div>
        <div className='circleContainer'>
          <CircularProgressbar
            value={
              (clientMetricsData.common_stats.anketa_complete / clientMetricsData.common_stats.all) * 100 === 0
                ? 1
                : (clientMetricsData.common_stats.anketa_complete / clientMetricsData.common_stats.all) * 100
            }
            strokeWidth={4.05}
            styles={{
              path: {
                stroke: `${shadeRGBColor(`rgb(${client.hex_color})`, -0.5)}`,
                strokeLinecap: 'butt',
                transition: 'stroke-dashoffset 0.5s ease 0s',
                transform: 'rotate(0.020turn)',
                transformOrigin: 'center center',
              },
              trail: {
                stroke: 'rgba(242, 242, 242, 1)',
                strokeLinecap: 'butt',
                transform: 'rotate(0.20turn)',
                transformOrigin: 'center center',
              },
            }}
          />
        </div>
        <div className='circleContainer'>
          <CircularProgressbar
            value={
              (clientMetricsData.common_stats.expert_complete / clientMetricsData.common_stats.all) * 100 === 0
                ? 1
                : (clientMetricsData.common_stats.expert_complete / clientMetricsData.common_stats.all) * 100
            }
            strokeWidth={4.75}
            styles={{
              path: {
                stroke: `${shadeRGBColor(`rgb(${client.hex_color})`, -0.25)}`,
                strokeLinecap: 'butt',
                transition: 'stroke-dashoffset 0.5s ease 0s',
                transform: 'rotate(0.020turn)',
                transformOrigin: 'center center',
              },
              trail: {
                stroke: 'rgba(242, 242, 242, 1)',
                strokeLinecap: 'butt',
                transform: 'rotate(0.20turn)',
                transformOrigin: 'center center',
              },
            }}
          />
        </div>
        <div className='circleContainer'>
          <CircularProgressbar
            value={
              (clientMetricsData.common_stats.admin_complete / clientMetricsData.common_stats.all) * 100 === 0
                ? 1
                : (clientMetricsData.common_stats.admin_complete / clientMetricsData.common_stats.all) * 100
            }
            strokeWidth={6.45}
            styles={{
              path: {
                stroke: `rgb(${client.hex_color})`,
                strokeLinecap: 'butt',
                transition: 'stroke-dashoffset 0.5s ease 0s',
                transform: 'rotate(0.020turn)',
                transformOrigin: 'center center',
              },
              trail: {
                stroke: 'rgba(242, 242, 242, 1)',
                strokeLinecap: 'butt',
                transform: 'rotate(0.20turn)',
                transformOrigin: 'center center',
              },
            }}
          />
        </div>
        <div className='circleText'>
          <span className='value'>{clientMetricsData.common_stats.all}</span>
          <span className='text'>Человек</span>
        </div>
      </div>
      <div className='expertsWorkload'>
        <div className='workloadTitle'>Загруженность экспертов (количество свободных слотов)</div>
        <div
          className={
            'workloadContainer ' +
            `${clientMetricsData.common_stats.busy_percent < 20 ? 'smallLoadFont ' : ''}` +
            `${100 - clientMetricsData.common_stats.busy_percent <= 33 ? 'smallLoad' : ''}` +
            `${100 - clientMetricsData.common_stats.busy_percent > 33 && 100 - clientMetricsData.common_stats.busy_percent <= 66 ? 'mediumLoad' : ''}` +
            `${100 - clientMetricsData.common_stats.busy_percent > 66 ? 'bigLoad' : ''}`
          }
        >
          <div className='workloadValue' style={{ width: `${clientMetricsData.common_stats.busy_percent}%` }}></div>
          <div className='workloadText'>{clientMetricsData.common_stats.busy_percent} %</div>
        </div>
        <div className='workloadSlotsLeft'>
          <div className='value'>{clientMetricsData.common_stats.free_slots}</div>
          <div className='key'>свободных слотов</div>
        </div>
      </div>
      <div className='deadline'>
        <div className='workloadTitle'>
          До дедлайна письменной части -{' '}
          <span className='workloadLeft'>
            {clientMetricsData.common_stats.writing_deadline_days === null || clientMetricsData.common_stats.writing_deadline_days < 0
              ? 0
              : clientMetricsData.common_stats.writing_deadline_days}{' '}
            дней
          </span>
        </div>
        <div className='workloadTitle'>
          До дедлайна устной части -{' '}
          <span className='workloadLeft'>
            {clientMetricsData.common_stats.writing_deadline_days === null || clientMetricsData.common_stats.deadline_days < 0
              ? 0
              : clientMetricsData.common_stats.deadline_days}{' '}
            дней
          </span>
        </div>
      </div>
    </div>
  );
};
