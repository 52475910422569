import './App.scss';

import { applyMiddleware, createStore } from 'redux';

import { Provider } from 'react-redux';
import ReduxThunk from 'redux-thunk';
import { BrowserRouter as Router } from 'react-router-dom';
import RouterComponent from './Router';
import reducers from './reducers';

function App() {
  const store = createStore(reducers, {}, applyMiddleware(ReduxThunk));

  return (
    <div className='app'>
      <Provider store={store}>
        <Router>
          <RouterComponent />
        </Router>
      </Provider>
    </div>
  );
}

export default App;

export const backendURL = process.env.REACT_APP_BACKEND_URL || 'https://api.survey.dev.skills.iidf.ru/api/';
export const backendURL_PDF = process.env.REACT_APP_BACKEND_URL_PDF || 'http://host.docker.internal:4000/api/';
export const CTP = process.env.REACT_APP_CPT_ENABLE ? process.env.REACT_APP_CPT_ENABLE : false;
export const sentryDSN = process.env.REACT_APP_SENTRY_DSN;
export const sentryEnvironment = process.env.REACT_APP_SENTRY_ENVIRONMENT;

export const debug = true;
