export const formVariants = {
  initial: {
    y: '150vw',
  },
  animate: {
    y: 0,

    transition: {
      duration: '0.75',
      ease: 'easeInOut'
    }
  },
  exit: {
    x: '100vw',

    transition: {
      duration: '0.75',
      ease: 'easeInOut'
    }
  },
}