import { useQuery } from 'react-query';
import axios from 'axios';
import { backendURL } from '../App';

const getAnketaAnswersData = async (_, anketaAnswersID) => {
  const { data } = await axios({
    method: 'get',
    url: `${backendURL}anketa-answers/${anketaAnswersID}/`,
    headers: {
      Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
    },
  });
  return data;
};

export default function useAnketaAnswersData(anketaAnswersID) {
  return useQuery(['anketaAnswersData', anketaAnswersID], getAnketaAnswersData, { retry: false });
}
