import { useMutation } from 'react-query';
import axios from 'axios';
import { backendURL, debug } from '../App';
import consoleLog from '../helpers/consoleLog';

const deleteQuestion = async ({ questionID }) => {
  try {
    const { data } = await axios({
      method: 'post',
      url: `${backendURL}question/${questionID}/`,
      headers: {
        Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
      },
      data: {
        id: questionID,
        delete: true
      },
    });
    debug && consoleLog('success', 'Delete question', data);
    return data;
  } catch (error) {
    debug && consoleLog('error', 'Delete question', error);
  }
};

export default function useDeleteQuestionMutation({onSuccess}) {
  return useMutation(deleteQuestion, {onSuccess});
}
