import './AnketaAnswerCheckboxes.scss';

import { Form, Formik } from 'formik';
import { backendURL, debug } from '../../../App';

import Button from '../../../components/Button';
import Checkbox from '../../../components/Checkbox';
import axios from 'axios';
import consoleLog from '../../../helpers/consoleLog';
import { queryCache } from 'react-query';
import { useHistory } from 'react-router-dom';

interface Props {
  anketaData: {
    anketa_answer_id: number;
    anketa: {
      interview_needless: boolean;
    };
    question: {
      subquestions: [];
    };
    subquestion: {
      id: number;
      variants: [{ id: number; text: string }];
    };
    answer_id: number;
    questions_count: number;
    question_number: number;
    subquestion_number: number;
  };
  setIsNoInterviewModal: () => void;
  setErrorDuringSubmit: () => void;
}

export const AnketaAnswerCheckboxes: React.FC<Props> = ({ anketaData, setIsNoInterviewModal, setErrorDuringSubmit }) => {
  const history = useHistory();

  const initialValues = () => {
    let values = {};
    anketaData.subquestion.variants.forEach(variant => (values[`${variant.id}`] = false));
    return values;
  };

  const isValid = values => {
    let isValid = 0;
    Object.entries(values).forEach(([key, value]) => {
      if (value === true) {
        isValid += 1;
      }
    });
    return isValid;
  };

  const valuesToSubmit = values => {
    let valuesToSubmit: String[] = [];
    Object.entries(values).forEach(([key, value]) => value === true && valuesToSubmit.push(key));
    return valuesToSubmit.join(',');
  };

  return (
    <div className='anketaAnswerCheckboxes'>
      <Formik
        enableReinitialize
        initialValues={initialValues()}
        onSubmit={async (values, { setSubmitting, setStatus, resetForm }) => {
          try {
            const { data } = await axios({
              method: 'post',
              url: `${backendURL}answers/subs/`,
              headers: {
                Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
              },
              data: {
                anketa_answers_id: anketaData.anketa_answer_id,
                subquestion_id: anketaData.subquestion.id,
                answer_id: anketaData.answer_id || '',
                variant_id: valuesToSubmit(values),
              },
            });
            // * Если это последний вопрос, последний подвопрос и анкета без интервью - редирект в /complete/
            if (
              !(
                anketaData.questions_count === anketaData.question_number &&
                anketaData.subquestion_number === anketaData.question.subquestions.length &&
                anketaData.anketa.interview_needless
              )
            ) {
              await queryCache.invalidateQueries('clientAnketaData');
              resetForm();
              setSubmitting(false);
              setStatus({ success: true });
            } else {
              history.push('/client/anketa/complete/');
            }
            debug && consoleLog('success', 'Subquestion answer submit', data);
          } catch (error) {
            setSubmitting(false);
            setErrorDuringSubmit();
            debug && consoleLog('error', 'Subquestion answer submit', error);
          }
        }}
      >
        {props => (
          <Form>
            <div className='text-[14rem] font-bold'>*Вопрос с множественным выбором.</div>
            {anketaData.subquestion.variants.map(variant => (
              <Checkbox
                key={variant.id}
                name={props.values[`${variant.id}`]}
                value={props.values[`${variant.id}`]}
                text={variant.text}
                onClick={() => props.setFieldValue(`${variant.id}`, !props.values[`${variant.id}`])}
              />
            ))}
            <Button
              type='button'
              buttonType='submit'
              width='330rem'
              size='medium'
              color='blue'
              className='submitButton'
              text='Cледующий вопрос'
              loading={props.isSubmitting}
              disabled={isValid(props.values) >= 2 ? false : true}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};
