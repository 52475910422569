import './NewAnketa.scss';

import * as Yup from 'yup';

import { ErrorMessage, Field, Form, Formik } from 'formik';

import { Button } from '../../../components/Button/Button';
import { InputError } from '../../../components/InputError/InputError';
import InputSelect from '../../../components/InputSelect';
import Modal from '../../../components/Modal';
import Title from '../../../components/Title';
import axios from 'axios';
import { backendURL } from '../../../App';
import consoleLog from '../../../helpers/consoleLog';
import { queryCache } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';

interface Props {
  userID: number;
}

export const NewAnketa: React.FC<Props> = ({ userID }) => {
  const history = useHistory();
  const [isModal, setIsModal] = useState(false);
  const [rolesOptions, setRolesOptions] = useState([]);
  const [segmentsOptions, setSegmentsOptions] = useState([]);

  return (
    <Formik
      initialValues={{ key: '', role_id: '', segment_id: '', stream_id: '' }}
      validationSchema={Yup.object({
        key: Yup.string().required('Поле должно быть заполнено'),
      })}
      onSubmit={(values, { setSubmitting, setFieldError, setFieldValue }) => {
        axios({
          method: 'post',
          url: `${backendURL}user/${userID}/`,
          headers: {
            Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
          },
          data: values,
        })
          .then(result => {
            if (result.data.roles || result.data.segments) {
              if (result.data.segments) {
                result.data.segments.length === 1
                  ? setFieldValue('segment_id', result.data.segments[0].id)
                  : setSegmentsOptions(result.data.segments.map(segment => ({ label: segment.title, value: segment.id })));
              }
              if (result.data.roles) {
                setRolesOptions(result.data.roles.map(role => ({ label: role.title, value: role.id })));
              }
              setFieldValue('stream_id', result.data.stream_id);
              setIsModal(true);
              setSubmitting(false);
            } else {
              setSubmitting(false);
            }
            if (result.data.anketa_id) {
              queryCache.invalidateQueries('userData');
              queryCache.invalidateQueries('clientAnketaData');
              history.push(`/client/anketa/${result.data.anketa_id}/intro/`);
            }
            consoleLog('success', 'Add new anketa', result);
          })
          .catch(error => {
            error?.response?.data?.error ? setFieldError('key', error?.response?.data?.error) : setFieldError('key', 'Ошибка! Свяжитесь с администратором');
            setSubmitting(false);
            setTimeout(() => {
              setFieldError('key', '');
            }, 3000);
            consoleLog('error', 'Add new anketa', error);
          });
      }}
    >
      {props => (
        <div className='newAnketa'>
          <Form>
            <Field name='key' type='text' placeholder={'Введите пароль от новой анкеты'} className={`${props.errors.key && props.touched.key ? 'invalid' : ''}`} />
            <InputError>
              <ErrorMessage name='key' />
            </InputError>

            <Button
              type='button'
              buttonType='submit'
              width='330rem'
              size='medium'
              color='blue'
              text='Добавить'
              loading={props.isSubmitting === true}
              disabled={props.isSubmitting === true}
            />

            {isModal ? (
              <Modal
                className='roleSegmentsModal'
                onClose={() => {
                  setIsModal(false);
                  props.setSubmitting(false);
                  props.resetForm();
                }}
              >
                <Title>Анкетирование</Title>

                <div className='subTitle'>Вы планируете начать новое анкетирование</div>

                {rolesOptions.length > 0 ? (
                  <InputSelect
                    name='role_id'
                    options={rolesOptions}
                    // @ts-ignore
                    value={props.values.role_id}
                    placeholderType='alwaysVisible'
                    placeholder='Выберите роль'
                    onChange={option => props.setFieldValue('role_id', option.value)}
                    onBlur={() => props.setFieldTouched('role_id', true)}
                    isValid={!(props.errors.role_id && props.touched.role_id)}
                    validationError={props.errors.role_id && props.touched.role_id ? props.errors.role_id : undefined}
                  />
                ) : null}

                {segmentsOptions.length > 0 ? (
                  <InputSelect
                    name='segment_id'
                    options={segmentsOptions}
                    // @ts-ignore
                    value={props.values.segment_id}
                    placeholderType='alwaysVisible'
                    placeholder='Выберите сегмент'
                    onChange={option => props.setFieldValue('segment_id', option.value)}
                    onBlur={() => props.setFieldTouched('segment_id', true)}
                    isValid={!(props.errors.segment_id && props.touched.segment_id)}
                    validationError={props.errors.segment_id && props.touched.segment_id ? props.errors.segment_id : undefined}
                    size='medium'
                  />
                ) : null}

                <InputError className='submitError'>
                  <ErrorMessage name='key' />
                </InputError>

                <Button
                  type='button'
                  buttonType='submit'
                  width='330rem'
                  size='medium'
                  color='blue'
                  text='Начать'
                  loading={props.isSubmitting === true}
                  disabled={
                    props.isSubmitting === true ||
                    (segmentsOptions.length > 0 && props.values.segment_id === '') ||
                    (rolesOptions.length > 0 && props.values.role_id === '')
                  }
                />
              </Modal>
            ) : null}
          </Form>
        </div>
      )}
    </Formik>
  );
};
