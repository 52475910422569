import './RespondentsDasboard.scss';

import { add, format } from 'date-fns';

import { Link } from 'react-router-dom';
import { utcToZonedTime } from 'date-fns-tz/esm';

interface RespondentsDasboardProps {
  anketaAnswersData: any;
  selectedDay: Date;
  startDay: Date;
}

export const RespondentsDasboard: React.FC<RespondentsDasboardProps> = ({ anketaAnswersData, selectedDay }) => {
  const filteredData = anketaAnswersData?.withInterview?.filter(
    item => format(utcToZonedTime(new Date(item.call_start), 'Europe/Moscow'), 'yyyy-MM-dd').toString() === format(selectedDay, 'yyyy-MM-dd').toString()
  );

  const renderRows = () => {
    let timeWindow = add(selectedDay, { hours: 1 });
    let rows: object[] = [];

    filteredData &&
      filteredData.map(item => {
        if (!(format(timeWindow, 'HH:mm').toString() === '22:00')) {
          while (format(utcToZonedTime(new Date(item.call_start), 'Europe/Moscow'), 'HH:mm').toString() !== format(timeWindow, 'HH:mm').toString()) {
            rows.push(
              <div className='row disabled' key={Math.random()}>
                <div className='time'>{format(timeWindow, 'HH:mm')}</div>
              </div>
            );
            timeWindow = add(timeWindow, { hours: 1 });
          }
          rows.push(
            <div className='row' key={Math.random()}>
              <div className='time'>{format(utcToZonedTime(new Date(item.call_start), 'Europe/Moscow'), 'HH:mm')}</div>
              <div className='fio'>
                {item.user.first_name} {item.user?.last_name}
              </div>
              <div className='client'>
                <span>{item.client.short_title}</span>
                <img src={item.client.small_logo} />
              </div>
              <div className='id'>{item.user.member_id || '------'}</div>
              <div className='phone'>{item.user.phone || '------'}</div>
              <div className='role'>{item.user.status_str}</div>
              <div className='case'>{item.user.case_str}</div>
              {item.zoom ? (
                <div className='callInfo zoom'>
                  <a href={item.zoom_start_link} target='_blank' rel='noopener noreferrer' className='link'>
                    Ссылка на Zoom
                  </a>
                </div>
              ) : (
                <div className='callInfo skype'>
                  <a href={`skype:${item.skype}?add`} target='_blank' rel='noopener noreferrer' className='link'>
                    {item.skype}
                  </a>
                </div>
              )}
              <div className='anketLink'>
                {item.process_status === 4 || item.process_status === 5 ? (
                  <span>Анкета</span>
                ) : (
                  <Link className='link' to={`/expert/review/anketa/${item.anketa_id}/${item.id}/preview/`}>
                    Анкета
                  </Link>
                )}
              </div>
              <div className='anketStatus'>
                {item.process_status === 2 && 'Без оценки'}
                {item.process_status === 3 && 'Оценивается'}
                {item.process_status === 4 && 'Завершена'}
                {item.process_status === 5 && 'Проверенная'}
              </div>
            </div>
          );
          timeWindow = add(timeWindow, { hours: 1 });
        }
      });

    while (format(timeWindow, 'HH:mm').toString() !== '22:00') {
      rows.push(
        <div className='row disabled' key={Math.random()}>
          <div className='time'>{format(timeWindow, 'HH:mm')}</div>
        </div>
      );
      timeWindow = add(timeWindow, { hours: 1 });
    }

    return rows;
  };

  return (
    <div className='respondentsDasboard'>
      <div className='row titleRow'>
        <div className='time'>время</div>
        <div className='fio'>Имя участника</div>
        <div className='client'>Клиент</div>
        <div className='id'>ID</div>
        <div className='phone'>Телефон</div>
        <div className='role'>Роль</div>
        <div className='case'>Сегмент</div>
        <div className='callInfo'>Способ связи</div>
        <div className='anketLink'></div>
        <div className='anketStatus'></div>
      </div>
      {renderRows()}
      {anketaAnswersData?.withoutInterview?.length > 0 && <div className='withoutInterviewTitleRowDummy'>Анкеты без интервью</div>}
      {anketaAnswersData?.withoutInterview?.map(element => (
        <div className='row' key={Math.random()}>
          <div className='time' />
          <div className='fio'>
            {element.user.first_name} {element.user?.last_name}
          </div>
          <div className='client'>
            <span>{element.client.short_title}</span>
            <img src={element.client.small_logo} />
          </div>
          <div className='id'>{element.user.member_id || '------'}</div>
          <div className='phone'>{element.user.phone || '------'}</div>
          <div className='role'>{element.user.status_str}</div>
          <div className='case'>{element.user.case_str}</div>
          <div className='callInfo' />
          <div className='anketLink'>
            {element.process_status === 4 || element.process_status === 5 ? (
              'Анкета'
            ) : (
              <Link className='link' to={`/expert/review/anketa/${element.anketa_id}/${element.id}/rate/`}>
                Анкета
              </Link>
            )}
          </div>
          <div className='anketStatus'>
            {element.process_status === 2 && 'Без оценки'}
            {element.process_status === 3 && 'Оценивается'}
            {element.process_status === 4 && 'Завершена'}
            {element.process_status === 5 && 'Проверенная'}
          </div>
          <div className='zoomVideoLink empty'></div>
        </div>
      ))}
    </div>
  );
};
