import './RespondentsDaysTabs.scss';
import 'react-alice-carousel/lib/alice-carousel.css';

import { useRef, useState, useEffect } from 'react';

import { format, add, isBefore } from 'date-fns';
import { ru } from 'date-fns/locale';
import { useDispatch } from 'react-redux';
import { expertRespondentsActiveDateSelect } from '../../actions';
import AliceCarousel from 'react-alice-carousel';
import consoleLog from '../../helpers/consoleLog';

interface RespondentsDaysTabsProps {
  startDay: Date;
  selectedDay: Date;
  anketaAnswersData: any;
}

export const RespondentsDaysTabs: React.FC<RespondentsDaysTabsProps> = ({ startDay, selectedDay, anketaAnswersData }) => {
  const dispatch = useDispatch();
  const carouselRef = useRef<any>();

  const renderClassName = dateOnTab => {
    if (dateOnTab.toString() === selectedDay.toString()) {
      return 'dayTab active';
    }
    return 'dayTab';
  };

  const renderDays = () => {
    consoleLog('info', 'anketaAnswersData', anketaAnswersData);
    if (anketaAnswersData[anketaAnswersData.length - 1]?.call_start) {
      let start: Date;
      let end: Date;

      if (new Date(anketaAnswersData[0]?.call_start) < startDay) {
        start = new Date(anketaAnswersData[0]?.call_start);
      } else {
        start = startDay;
      }

      if (new Date(anketaAnswersData[anketaAnswersData.length - 1]?.call_start) < add(startDay, { days: 6 })) {
        end = add(startDay, { days: 6 });
      } else {
        end = new Date(anketaAnswersData[anketaAnswersData.length - 1]?.call_start);
      }
      consoleLog('info', 'endDay', start);
      consoleLog('info', 'endDay', end);

      let days: object[] = [];
      let dayToCompare = start;
      let i = 0;
      let index = 0;
      let selectedDayIndex: number | undefined;

      while (isBefore(dayToCompare, end)) {
        format(dayToCompare, 'dd.MM.yyyy').toString() === format(selectedDay, 'dd.MM.yyyy').toString() && (selectedDayIndex = i);
        format(dayToCompare, 'dd.MM.yyyy') !== format(startDay, 'dd.MM.yyyy') ? i++ : (index = i);
        let dispatchDate = dayToCompare;
        dispatchDate.setHours(8);
        dispatchDate.setMinutes(0);
        dispatchDate.setSeconds(0);
        days.push(
          <div
            className={renderClassName(dayToCompare)}
            onClick={() => {
              dispatch(expertRespondentsActiveDateSelect(dispatchDate));
            }}
          >
            <div className='dayValue'>{format(dayToCompare, 'dd', { locale: ru })}</div>
            <div className='dayName'>{format(dayToCompare, 'EEEEEE', { locale: ru })}</div>
          </div>
        );
        dayToCompare = add(dayToCompare, { days: 1 });
      }
      return { days, index, selectedDayIndex };
    }
  };

  return (
    <div className='daysTabsTable'>
      <button className='slideLeft' onClick={() => carouselRef?.current?.slidePrev()} />
      <AliceCarousel
        dotsDisabled={true}
        buttonsDisabled={true}
        responsive={{ 0: { items: 7 } }}
        items={renderDays()?.days}
        startIndex={renderDays()?.selectedDayIndex}
        ref={carouselRef}
        infinite={false}
      />
      <button className='slideRight' onClick={() => carouselRef?.current?.slideNext()} />
    </div>
  );
};
