import './NoAvailableTimeModal.scss';

import { useHistory, useLocation } from 'react-router-dom';

import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import Title from '../../../components/Title';

interface Props {
  onClose: () => void;
}

export const NoAvailableTimeModal: React.FC<Props> = ({ onClose }) => {
  const history = useHistory();
  const location = useLocation();

  return (
    <Modal className='noAvailableTimeModal' onClose={onClose}>
      <Title>Нет свободных слотов</Title>
      <div className='text'>
        К сожалению, сейчас нет свободных слотов для интервью. Проверьте чуть позже. <br /> Если в течение 24 часов слоты не появятся, свяжитесь с техподдержкой.
      </div>
      <Button
        type='button'
        onClick={() => {
          onClose();
          history.push(`${location.pathname}?support/`);
        }}
        width='260rem'
        size='small'
        color='blue'
        text='Связаться с техподдержкой'
      />
    </Modal>
  );
};
