import './styles.scss';

import Button from '../../Button';
import Modal from '../../Modal';
import Title from '../../Title';
import { Dispatch, SetStateAction } from 'react';

interface Props {
  timeSlot: Date;
  onContinue: Dispatch<SetStateAction<{ timeSlotID: number | undefined; timeSlot: Date } | null>>;
  onClose: () => void;
}

export const DisabledTimeSlotAlertModal: React.FC<Props> = ({ onContinue, onClose, timeSlot }) => {
  return (
    <Modal className='disabledTimeSlotAlertModal' onClose={onClose}>
      <Title>Внимание!</Title>
      <div className='text'>Эксперт не отметил этот слот как доступный для интервью.</div>
      <Button
        type='button'
        size='medium'
        width='330rem'
        color='blue'
        text='Продолжить'
        onClick={() => {
          onClose();
          onContinue({ timeSlotID: undefined, timeSlot });
        }}
      />
      <Button type='button' size='medium' width='330rem' color='white' text='Отмена' onClick={() => onClose()} />
    </Modal>
  );
};
