import './ScheduleTabs.scss';

import { Dispatch, SetStateAction } from 'react';
import { add, format, isEqual } from 'date-fns';

import { queryCache } from 'react-query';
import { ru } from 'date-fns/locale';

interface ScheduleTabs {
  currentWeekStart: Date;
  currentWeekEnd: Date;
  selectedWeekStart: Date;
  setSelectedWeekStart: Dispatch<SetStateAction<Date>>;
  selectedWeekEnd: Date;
  setSelectedWeekEnd: Dispatch<SetStateAction<Date>>;
}

export const ScheduleTabs: React.FC<ScheduleTabs> = ({
  currentWeekStart,
  currentWeekEnd,
  selectedWeekStart,
  setSelectedWeekStart,
  selectedWeekEnd,
  setSelectedWeekEnd,
}) => {
  return (
    <div className='scheduleTabs scheduleTabsMember'>
      <div
        className={`scheduleTab ${isEqual(currentWeekStart, selectedWeekStart) ? 'active' : ''}`}
        onClick={() => {
          setSelectedWeekStart(currentWeekStart);
          setSelectedWeekEnd(currentWeekEnd);
          queryCache.invalidateQueries('timeTableData');
        }}
      >
        {format(currentWeekStart, 'd LLL', { locale: ru }).slice(0, -1)} - {format(currentWeekEnd, 'd LLL', { locale: ru }).slice(0, -1)}
      </div>

      <div
        className={`scheduleTab ${!isEqual(currentWeekStart, selectedWeekStart) ? 'active' : ''}`}
        onClick={() => {
          setSelectedWeekStart(add(currentWeekStart, { days: 7 }));
          setSelectedWeekEnd(add(currentWeekEnd, { days: 7 }));
          queryCache.invalidateQueries('timeTableData');
        }}
      >
        {format(add(currentWeekStart, { days: 7 }), 'd LLL', { locale: ru }).slice(0, -1)} -{' '}
        {format(add(currentWeekEnd, { days: 7 }), 'd LLL', { locale: ru }).slice(0, -1)}
      </div>
      <div className='scheduleTab emptyScheduleTab' />
    </div>
  );
};
