import './MemberAnketaHeader.scss';

import { hours_noun, minutes_noun } from '../../../helpers/nounVars';

import Title from '../../Title';

interface Props {
  userData: {
    first_name: string;
    last_name?: string;
    status_str?: string;
    case_str?: string;
  };
  anketaData: {
    anketa: {
      title: string;
      role: string;
      case: string;
    };
    time_for_anketa: number | null;
    time_left: number | null;
  };
}

export const MemberAnketaHeader: React.FC<Props> = ({ userData, anketaData }) => {
  const statusBarPercent = (time_left: number | null, time_for_anketa: number) => {
    if (time_left !== null) {
      const percent = (time_left / time_for_anketa) * 100;
      const percentLeft = 100 - percent;

      return `${percentLeft}%`;
    }
    return `0%`;
  };

  const timeLeftFormatted = (time_left: number | null, time_for_anketa: number) => {
    if (time_left !== null) {
      const hours = Math.trunc(time_left / 60);
      const minutes = time_left - hours * 60;

      return { hours, minutes };
    } else {
      const hours = Math.trunc(time_for_anketa / 60);
      const minutes = time_for_anketa - hours * 60;

      return { hours, minutes };
    }
  };

  return (
    <div className={'memberAnketaHeader ' + (anketaData.time_for_anketa ? 'memberAnketaHeader--withTimer' : '')}>
      <Title>Анкета</Title>

      {anketaData.time_for_anketa ? (
        <div className='timer'>
          <div className='timerTitle'>До окончания тестирования</div>
          <div className='timerTimeLeft'>
            <b>{timeLeftFormatted(anketaData.time_left, anketaData.time_for_anketa).hours}</b>{' '}
            {hours_noun(timeLeftFormatted(anketaData.time_left, anketaData.time_for_anketa).hours)}{' '}
            <b>{timeLeftFormatted(anketaData.time_left, anketaData.time_for_anketa).minutes}</b>{' '}
            {minutes_noun(timeLeftFormatted(anketaData.time_left, anketaData.time_for_anketa).minutes)}
          </div>
          <div className='timerStatusBar'>
            <div className='timerStatusBar--Passed' style={{ width: statusBarPercent(anketaData.time_left, anketaData.time_for_anketa) }} />
          </div>
        </div>
      ) : null}

      <div className='info'>
        <div className='keys'>
          <div className='key'>Имя участника</div>
          <div className='key'>Роль</div>
          {/* <div className='key'>Сегмент</div> */}
        </div>
        <div className='values'>
          <div className='value'>
            {userData.first_name} {userData?.last_name}
          </div>
          <div className='value'>{anketaData.anketa.role ? anketaData.anketa.role : userData.case_str}</div>
          {/* <div className='value'>{userData.case_str || anketaData.anketa.case}</div> */}
        </div>
      </div>
    </div>
  );
};
