import { useQuery } from 'react-query';
import axios from 'axios';
import { backendURL } from '../App';

const getAnketaQestionsAnswersData = async (_, anketa_answers_id) => {
  const { data } = await axios({
    method: 'get', 
    url: `${backendURL}answers/`,
    headers: {
      Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`
    },
    params: {
      anketa_answers_id
    }      
  });
  return data;
};

export default function useAnketaQestionsAnswersData(anketa_answers_id) {
  return useQuery(['anketaQuestionsAnswersData', anketa_answers_id], getAnketaQestionsAnswersData, { retry: false });
}
