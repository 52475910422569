import { useQuery } from 'react-query';
import axios from 'axios';
import { backendURL } from '../App';

const getAdminExpertData = async (_, expertID) => {
  const { data } = await axios({
    method: 'get',
    url: `${backendURL}admin/expert/${expertID}/`,
    headers: {
      Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
    },
  });

  return data;
};

export default function useAdminExpertData(expertID) {
  return useQuery(['adminExpertData', expertID], getAdminExpertData, { retry: false });
}
