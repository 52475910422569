import { useQuery } from 'react-query';
import axios from 'axios';
import { backendURL } from '../App';

const getAdminExpertsScheduleData = async (_, expert_id, start, end) => {
  const { data } = await axios({
    method: 'get',
    url: `${backendURL}admin/timetable/${expert_id}/`,
    headers: {
      Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
    },
    params: {
      start: start,
      end: end,
    },
  });

  return data;
};

export default function useAdminExpertsScheduleData(expert_id, start, end) {
  return useQuery(['adminExpertsScheduleData', expert_id, start, end], getAdminExpertsScheduleData, { retry: false });
}
