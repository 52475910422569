import './AnketaQuestionRateHelp.scss';


import ContainerWYSIWYG from '../../../components/ContainerWYSIWYG';

interface Props {
  helpText: string;
}

export const AnketaQuestionRateHelp: React.FC<Props> = ({ helpText }) => (
  <div className='anketaQuestionRateHelp'>
    <div className='helpSubTitle'>Подсказка</div>
    <ContainerWYSIWYG size='default' data={helpText} />
  </div>
);
