import './QuestionsList.scss';

import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import __ from 'lodash';

interface AnketaProgressionProps {
  questionsData: [];
}

export const QuestionsList: React.FC<AnketaProgressionProps> = ({ questionsData }) => {
  const { clientID, anketaID, questionIndex } = useParams<{ clientID: string; anketaID: string; questionIndex: string }>();

  return (
    <nav className='questionsList'>
      <div className='questionsListTitle'>Список вопросов</div>
      <div className='grid'>
        {questionsData.length === 0 && <div className='progressionPoint active'></div>}
        {questionsData.length !== 0 &&
          questionsData.map((_, index) => (
            <Link
              to={`/admin/${clientID}/templates/${anketaID}/questions/${index + 1}/`}
              className={'progressionPoint ' + `${parseInt(questionIndex) === index + 1 ? 'active' : 'hoverPossible'}`}
              key={__.uniqueId()}
            >
              {index + 1}
            </Link>
          ))}
      </div>
    </nav>
  );
};
