
import { useMutation } from 'react-query';
import axios from 'axios';
import { backendURL, debug } from '../App';
import consoleLog from '../helpers/consoleLog';

const cancelInterview = async ({timeSlotID, expertID}) => {
  try {
    const { data } = await axios({
      method: 'post',
      url: `${backendURL}admin/timetable/${expertID}/`,
      headers: {
        Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
      },
      data: {
        remove_interview_slot_id: timeSlotID,
      },
    });
    debug && consoleLog('success', 'Cancel interview', data);
    return data;
  } catch (error) {
    debug && consoleLog('error', 'Cancel interview', error);
  }
};

export default function useCancelInterviewMutation({onSuccess}) {
  return useMutation(cancelInterview, {onSuccess});
}
