import './AnketaAnswerRadio.scss';

import { Field, Form, Formik } from 'formik';
import { backendURL, debug } from '../../../App';

import Button from '../../../components/Button';
import axios from 'axios';
import consoleLog from '../../../helpers/consoleLog';
import { queryCache } from 'react-query';
import { useHistory } from 'react-router-dom';

interface Props {
  anketaData: {
    anketa_answer_id: number;
    anketa: {
      interview_needless: boolean;
    };
    question: {
      subquestions: [];
    };
    subquestion: {
      id: number;
      variants: [{ id: number; text: string }];
    };
    answer_id: number;
    questions_count: number;
    question_number: number;
    subquestion_number: number;
  };
  setIsNoInterviewModal: () => void;
  setErrorDuringSubmit: () => void;
}

export const AnketaAnswerRadio: React.FC<Props> = ({ anketaData, setIsNoInterviewModal, setErrorDuringSubmit }) => {
  const history = useHistory();

  return (
    <div className='anketaAnswerRadio'>
      <Formik
        enableReinitialize
        initialValues={{ variant_id: '' }}
        onSubmit={async (values, { setSubmitting, setStatus, resetForm }) => {
          try {
            const { data } = await axios({
              method: 'post',
              url: `${backendURL}answers/subs/`,
              headers: {
                Authorization: `Token ${localStorage.getItem('skillsSurveyUserToken')}`,
              },
              data: {
                anketa_answers_id: anketaData.anketa_answer_id,
                subquestion_id: anketaData.subquestion.id,
                answer_id: anketaData.answer_id || '',
                ...values,
              },
            });

            // * Если это последний вопрос, последний подвопрос и анкета без интервью - вначале показать модалку
            if (
              !(
                anketaData.questions_count === anketaData.question_number &&
                anketaData.subquestion_number === anketaData.question.subquestions.length &&
                anketaData.anketa.interview_needless
              )
            ) {
              await queryCache.invalidateQueries('clientAnketaData');
              resetForm();
              setSubmitting(false);
              setStatus({ success: true });
            } else {
              // setIsNoInterviewModal();
              history.push('/client/anketa/complete/');
            }
            debug && consoleLog('success', 'Subquestion answer submit', data);
          } catch (error) {
            setSubmitting(false);
            setErrorDuringSubmit();
            debug && consoleLog('error', 'Subquestion answer submit', error);
          }
        }}
      >
        {props => (
          <Form>
            <div className='radioInputContainer' role='group' aria-labelledby='variant-group'>
              {anketaData.subquestion.variants.map(variant => (
                <label key={variant.id}>
                  <Field type='radio' name='variant_id' value={variant.id} checked={props.values.variant_id === String(variant.id)} />
                  <span>{variant.text}</span>
                </label>
              ))}
            </div>
            <Button
              type='button'
              buttonType='submit'
              width='330rem'
              size='medium'
              color='blue'
              className='submitButton'
              text='Cледующий вопрос'
              loading={props.isSubmitting}
              disabled={!props.values.variant_id}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};
