import './AnketaQuestionsList.scss';


import { Title } from '../Title/Title';

interface AnketaQuestionsListProps {
  questionList: [{ text: string }];
}

export const AnketaQuestionsList: React.FC<AnketaQuestionsListProps> = ({ questionList }) => {
  return (
    <section className='anketaQuestionsList is_admin'>
      <Title>Список вопросов</Title>
      <ul>
        {questionList.map((item, index) => (
          <li key={index} onClick={() => window.document.querySelector(`#q${index}`)?.scrollIntoView({ behavior: 'smooth' })}>
            <span className='questionNubmer'>{index + 1}</span>
            <span className='questionTitle'>{item.text}</span>
          </li>
        ))}
        <li onClick={() => window.document.querySelector('#anketaExpertComment')?.scrollIntoView({ behavior: 'smooth' })}>
          <span className='questionNubmer'>
            <span className='dot'></span>
          </span>
          <span className='questionTitle'>Комментарии эксперта</span>
        </li>
        <li onClick={() => window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })}>
          <span className='questionNubmer'>
            <span className='down'></span>
          </span>
          <span className='questionTitle'>В конец анкеты</span>
        </li>
      </ul>
      <div className='goUpButton' onClick={() => window.document.querySelector(`#q0`)?.scrollIntoView({ behavior: 'smooth' })}>
        <span className='questionNubmer'>
          <span className='up'></span>
        </span>
        <span className='questionTitle'>Переместиться в начало анкеты</span>
      </div>
    </section>
  );
};
